import React, { useState, useRef } from 'react';
import Table from "react-bootstrap/Table";
import { GetAxios } from '../../Shared';
import { config } from '../../Environments';
import { setGlobalLoading } from '../../Store';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { Button } from "react-bootstrap";
import { FaPrint } from "react-icons/fa";
import { useDispatch } from 'react-redux';
import { toast } from "react-toastify";
import ReactToPrint from 'react-to-print';
import InvoiceModal from './InvoiceModal';



const OrderHistoryMain = () => {
    const dispatch = useDispatch();
    const [list, setList] = React.useState<any>([]);
    const [params] = useSearchParams();

    React.useEffect(() => {
        dispatch(setGlobalLoading(true));
        let url = config.apiBase + '/api/Companies/GetCompanyInvoices';
        if (params.has('id')) {
            url += ('?id=' + params.get('id'));

        }
        GetAxios().get(url).then(res => {
            setList(res.data.data || []);
            dispatch(setGlobalLoading(false));
        });
    }, []);





    return (
        <>
            <div className='my-3' style={{ height: '500px' }}>
                <h4 className='fw-700'>Order History</h4>
                <div className="d-flex justify-content-between my-3 px-2">
                    <div>
                        <span className="fw-bold fs-14">{list.length} result</span>
                    </div>
                    <div>

                        {/*  <button type="submit" className="filter-border rounded">
             <FaFilter /> Filters   </button>*/}

                    </div>
                </div>

                <Table bordered responsive className="bg-white">
                    <thead >

                        <tr>
                            <th className="text-center w-50px">
                                <div>
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="checkboxNoLabel"
                                        value=""
                                        aria-label="..."
                                    />
                                </div>
                            </th>
                            <th className="w-50">Invoice #</th>
                            <th>Billing Date</th>
                            <th className="text-end pe-3">Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            list.map((item: any, index: number) => (
                                <tr key={"invoice_key" + index}>
                                    <td className="text-center ">
                                        <div>
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="checkboxNoLabel"
                                                value=""
                                                aria-label="..."
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        {/* onClick={() => {SetInvoiceId(item.id);  SetInvoiceModal(true)}} */}

                                        <InvoiceModal invoiceNo={item.invoiceNo} invoiceId={item.id} />


                                    </td>
                                    <td>{item.billingDate} </td>
                                    <td className="text-end pe-3">
                                        <span className="fw-bold">{item.amount}</span>{" "}
                                        <span className="text-uppercase">Usd</span>
                                    </td>
                                </tr>


                            ))
                        }
                    </tbody>
                </Table>

            </div>




        </>
    )
}





export default OrderHistoryMain;

/* export const InvoicePopup = (props: {

    invoiceNo: string,
    invoiceId: Number
}) => {

    const [interest, setInterest] = useState(false);
    const [data, setData] = React.useState<CompanyInvoiceView>();
    const handleClose = () => setInterest(false);
    const handleShow = () => setInterest(true);
    const navigate = useNavigate();
    let url = config.apiBase + '/api/Companies/GetInvoice?invoiceId=' + props.invoiceId;
    GetAxios(navigate).get(url).then(res => {
        setData(res.data.data);

    });
    const handleSend = () => {
        let url = config.apiBase + '/api/Companies/SendInvoice?invoiceId=' + props.invoiceId;
        GetAxios().post(url).then(res => {
            toast(res.data.message, { type: res.data.success ? 'success' : 'error' });

        });

    }

    const componentRef = useRef<any>();

    return (
        <div>
            {

                <span className="text-decoration-none fw-500 text-blue cursor-pointer"
                    onClick={() => {
                        handleShow();


                    }}>
                    {props.invoiceNo}
                </span>
            }
            <Modal show={interest} onHide={handleClose} size="lg">


                <Modal.Header closeButton onClick={handleClose}>

                    <Modal.Title>Invoice:</Modal.Title>



                </Modal.Header>
                <Modal.Body className="p-4" style={{
                    maxHeight: 'calc(100vh - 210px)',
                    overflowY: 'auto'
                }}>
                    <div className="innersection" ref={componentRef as any}>
                        <div className='header-set'>
                            <div className='d-flex justify-content-between gap-3'>
                                <div>
                                    <h6 className='fw-700'>Invoice</h6>
                                    <p className=''>N. <span className='letter-space-3'>{data?.invoiceNo}</span></p>
                                </div>
                                <div>
                                    <img src="/images/maestroicon.png" className="mb-1" />
                                    <h6 className='fw-700 text-uppercase'>Maestro media</h6>
                                </div>
                                <div>
                                    <div>
                                        <h6>Parent Company of the website: <img src="/images/bonjan-header-logo.png" className="invoice-sec-logo mob-logo" /></h6>
                                    </div>
                                    <div className='text-center'>
                                        <h6 className='text-uppercase mb-0'>Contact us</h6>
                                        <h6 className=''>509-1111-2222</h6>
                                    </div>
                                </div>
                            </div>
                            <div className='border-2 border-bottom my-4'></div>
                        </div>
                        <div className='invoice-detail'>
                            <div className='row'>
                                <div className='col-5'>
                                    <div>
                                        <p className='fw-700 fw-bold mb-0 text-uppercase'>Date:</p>
                                        <p className=''>{data?.invoiceDate}</p>
                                    </div>
                                </div>
                                <div className='col-7'>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div>
                                            <p className='fw-700 fw-bold mb-0 text-uppercase'>Customer #:</p>
                                            <p className=''>{data?.customerNo}</p>
                                        </div>
                                        <div>
                                            <p className='fw-700 fw-bold mb-0 text-uppercase'>Advertsing #:</p>
                                            <p className='text-uppercase'>{data?.advertisingNo}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row invoice-billing-bg mb-3'>
                                <div className='col-4'>
                                    <div className='description'>
                                        <p className='fw-500 mb-0 text-uppercase'>Bill to:</p>
                                        <h6 className='fw-700 fw-bold mb-0'>{data?.companyName}</h6>
                                        <div className='address w-75'>
                                            <p className='mb-0' dangerouslySetInnerHTML={{ __html: data?.companyAddress || '' }}>

                                            </p>
                                            <h6 className='fw-700 fw-bold mb-0'>{data?.firstName} {data?.lastName}</h6>
                                            <p className='fw-500 mb-0 text-uppercase'>{data?.phoneNumber}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-8'>
                                    <div className='amount-detail'>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <div>
                                                <p className='mb-0 fw-600'>Please make check payable to</p>
                                                <p className='fw-700 mb-0 text-uppercase'>Maestro Media</p>
                                            </div>
                                            <div>
                                                <span>{data?.totalAmount}</span>
                                            </div>
                                        </div>
                                        <div className='border-2 border-bottom my-3'></div>
                                    </div>
                                    <div className='amount-detail'>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <div>
                                                <p className='mb-0 fw-600'>Previous Balance</p>
                                            </div>
                                            <div>
                                                <span>{data?.paidAmount}</span>
                                            </div>
                                        </div>
                                        <div className='border-2 border-bottom my-3'></div>
                                    </div>
                                    <div className='amount-detail'>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <div>
                                                <p className='mb-0 fw-600'>Received Payment</p>
                                            </div>
                                            <div>
                                                <span>{data?.paidAmount}</span>
                                            </div>
                                        </div>
                                        <div className='border-2 border-bottom my-3'></div>
                                    </div>
                                    <div className='amount-detail'>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <div>
                                                <p className='mb-0 fw-600'>Balance Due <span className='text-uppercase'>(usd)</span></p>
                                            </div>
                                            <div>
                                                <span>{data?.balanceDue}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='table-invoice'>
                                <Table responsive>
                                    <thead>
                                        <tr>
                                            <th>Term</th>
                                            <th>Product</th>
                                            <th>Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className='invoice-tr-bg'>
                                            <td className='fw-500'>{data?.terms}</td>
                                            <td>
                                                <span className='fw-500'>{data?.description}</span>
                                            </td>
                                            <td>{data?.jobAmount}</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>
                                                <span className='fw-700 text-uppercase'>Bonjanjob.com</span>
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr className='invoice-tr-bg'>
                                            <td></td>
                                            <td>
                                                <span className='fw-500'>Subtotal</span>
                                            </td>
                                            <td>
                                                <span>{data?.jobAmount}</span>
                                            </td>
                                        </tr>
                                        <tr className='invoice-tr-bg'>
                                            <td></td>
                                            <td>
                                                <span className='fw-500'>Taxes</span>
                                            </td>
                                            <td>
                                                <span>{data?.tax}</span>
                                            </td>
                                        </tr>
                                        <tr className='invoice-tr-bg'>
                                            <td></td>
                                            <td>
                                                <span className='fw-500'>Fees</span>
                                            </td>
                                            <td>
                                                <span>{data?.fees}</span>
                                            </td>
                                        </tr>
                                        <tr className='invoice-tr-bg'>
                                            <td></td>
                                            <td>
                                                <span className='fw-500'>Total <span className='text-uppercase'>(usd)</span></span>
                                            </td>
                                            <td>
                                                <span>{data?.totalAmount}</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                                <div className='mb-2'>
                                    <div className='border-2 border-bottom my-3'></div>
                                    <div>
                                        <p className='fw-600 text-uppercase'>Reference</p>
                                    </div>
                                    <div className='d-flex justify-content-between align-items-center invoice-tr-bg p-3'>
                                        <div>
                                            <p className='mb-0 fw-600'>Taxes</p>
                                        </div>
                                        <div>
                                            <span>{data?.tax}</span>
                                        </div>
                                    </div>

                                </div>
                                <div className='print-sec'>
                                    <div className='mb-4'>
                                        <div className='border-2 border-bottom my-3'></div>
                                        <div className='address w-25'>
                                            <p className='mb-0'>Maestro Media</p>
                                            <p className='mb-0'>25 bis, 2eme Ruelle Nazon Port-au-Prince, Haiti Ouest HT-6111</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ReactToPrint
                        documentTitle={new Date().toDateString()}
                        trigger={() =>
                            <button type="submit" className="btn button-default mt-3 mb-3 border-0 print-invoice-btn">
                                <FaPrint className="me-2" />Print to PDF
                            </button>
                        }
                        content={() => componentRef.current}
                    />
                </Modal.Body>

                <Modal.Footer style={{ borderTop: '0px' }}>

                    <Button variant="danger" onClick={handleClose} className="rounded-0">
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}


export interface CompanyInvoiceView {
    invoiceId: Number,
    jobPostId: Number,
    companyId: Number,
    userId: string,
    invoiceNo: string,
    invoiceDate: string,
    companyName: string,
    companyAddress: string,
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber: string,
    companyregistrationNumber: string,
    companyLogo: string,
    paidDate: string,
    terms: string,
    jobAmount: string,
    totalAmount: string,
    balanceDue: string,
    tax: string,
    fees: string,
    paidAmount: string,
    paymentStatus: string,
    customerNo: string,
    advertisingNo: string,
    description: string
} */
