import React, { useState } from "react";
import { config } from "../../../Environments";
import { GetAxios } from "../../../Shared";
import { CountrySelect, DatePickSelect } from '../../../Components';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';


const SideContent = (props) => {
    const { t } = useTranslation('company');
    const { t: jt } = useTranslation('job');
    const { t: ct } = useTranslation('common');
    const { t: ts } = useTranslation('alert');
    const { i18n } = useTranslation();

    const [logo, setLogo] = React.useState("/images/upload-cmpny-logo.jpg");
    const [classifications, setClassifications] = useState([]);
    const [departments, setDepartments] = useState([]);
    const formik = props.formik;
    const dropDown = props.dropDown;
    let myref = null;


    React.useEffect(() => {
        GetAxios().get(config.apiBase + '/api/Companies/GetCompanyNameLogo').then(res => {
            if (i18n.language == 'fr' && res.data?.companyId == 1) {
                setLogo(config.apiBase + res.data?.logo2);
            }
            else {
                setLogo(config.apiBase + res.data?.logo);
            }
            //setLogo(config.apiBase + res.data?.logo);
        })
    }, [i18n.language]);


    React.useEffect(() => {
        GetAxios().get(config.apiBase + '/api/General/GetDepartments').then(res => {
            setDepartments(res.data);
        })

    }, []);
    React.useEffect(() => {
        GetAxios().get(config.apiBase + '/api/General/GetClassifications?lang=' + formik.values.language).then(res => {
            setClassifications(res.data);
        })
    }, [formik.values.language]);




    const handleBrowse = function (e) {
        e.preventDefault();
        myref.click();
    };





    return (
        <>
            <div className="">
                <div className="card border-0 rounded-0">
                    <div className="profile-sec">
                        <img src={logo} id="imgOutput" className="card-img-top rounded-0" alt="..." onError={e => e.target.src = "/images/ImgPlaceholder.png"} />
                        {/* <div className="company-logo">
                        </div> */}
                    </div>
                    <div className="card-body pb-5">
                        <div className="">
                            <div className="text-center mt-2 mb-4">
                            </div>
                            <div className="d-flex flex-column border-bottom">
                                <div className="d-flex align-items-center justify-content-between client-side-desc">
                                    <h5 className="text-color-33 fw-600 text-uppercase mb-2"> {jt('job.jobDetails')}</h5>

                                </div>
                            </div>
                            <div className="row pt-3">
                                <div className="col-5">
                                    <div>
                                        <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600"> {jt('job.jobTitle')}:</label>
                                    </div>
                                </div>
                                <div className="col-7 ps-3">
                                    <div>
                                        <span className="fw-12">
                                            <input type="text" name="title"
                                                onChange={formik.handleChange}
                                                value={formik.values.title}
                                                className="form-control border-0 job-regitration-input font-input" placeholder={jt('addJob.jobTitles').toString()} />
                                            {formik.errors.title && formik.touched.title ? <small className="text-danger">{formik.errors.title}</small> : ""}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="row pt-3">
                                <div className="col-5">
                                    <div>
                                        <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600"> {t('callTender.companyName')}:</label>
                                    </div>
                                </div>
                                <div className="col-7 ps-3">
                                    <div>
                                        <span className="fw-12">
                                            <input type="text" name="companyName"
                                                onChange={formik.handleChange}
                                                value={formik.values.companyName}
                                                className="form-control border-0 job-regitration-input font-input" placeholder={t('callTender.companyName1').toString()} />
                                            {formik.errors.companyName && formik.touched.companyName ? <small className="text-danger">{formik.errors.companyName}</small> : ""}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="row pt-3">
                                <div className="col-5">
                                    <div>
                                        <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600"> {jt('job.contactPerson')}:</label>
                                    </div>
                                </div>
                                <div className="col-7 ps-3">
                                    <div>
                                        <span className="fw-12">
                                            <input type="text" name="contactPerson" onChange={formik.handleChange}
                                                value={formik.values.contactPerson} className="form-control border-0 job-regitration-input font-input" placeholder={jt('addJob.contactPerson1').toString()} />
                                            {/* {formik.errors.contactPerson && formik.touched.contactPerson ? <small className="text-danger">{formik.errors.contactPerson}</small> : ""} */}

                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="row pt-3">
                                <div className="col-5">
                                    <div>
                                        <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600 text-nowrap"> {jt('job.sectorOfActivity')}:</label>
                                    </div>
                                </div>
                                <div className="col-7 ps-3">
                                    <div>
                                        <select name="sectorId" onChange={formik.handleChange}
                                            value={formik.values.sectorId} className='form-select fw-12 form-select-sm'>
                                            <option value=""> {ct('pleaseSelect')} </option>
                                            {
                                                (dropDown?.Sectors || []).map((v, index) => (
                                                    <option key={'sectors+___' + index} value={v.key}>{v.value}</option>
                                                ))
                                            }
                                        </select>
                                        {formik.errors.sectorId && formik.touched.sectorId ? <small className="text-danger">{formik.errors.sectorId}</small> : ""}

                                    </div>
                                </div>
                            </div>
                            <div className="row pt-3">
                                <div className="col-5">
                                    <div>
                                        <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600 text-nowrap"> {jt('job.classification')}:</label>
                                    </div>
                                </div>
                                <div className="col-7 ps-3">
                                    <div>
                                        <select name='classificationId' onChange={formik.handleChange} value={formik.values.classificationId} className="form-select fw-12 form-select-sm">
                                            <option value=""> {ct('pleaseSelect')} </option>
                                            {
                                                classifications?.map((s, index) => (
                                                    <option value={s.key} key={'classss_' + index}>{s.value}</option>
                                                ))
                                            }
                                        </select>
                                        {formik.errors.classificationId && formik.touched.classificationId ? <small className="text-danger">{formik.errors.classificationId}</small> : ""}
                                    </div>
                                </div>
                            </div>
                            <div className="row pt-3">
                                <div className="col-5">
                                    <div>
                                        <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">  {jt('job.ageRange')}:</label>
                                    </div>
                                </div>
                                <div className="col-7 ps-3">
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div>
                                            <input name="ageFrom"
                                                onChange={(e) => {

                                                    const value = e.target.value == "" ? null : e.target.value;
                                                    formik.setFieldValue('ageFrom', value);
                                                    if (e.target.value != '' && e.target.value != null) {
                                                        props.setAgeError(false)
                                                    }

                                                }} type="number" className="form-control fw-12 border-0 job-regitration-input font-input" placeholder={jt('addJob.from').toString()} />
                                            {props.ageError && props.ageError == true ? <small className="text-danger">{ts('account.required')}</small> : ""}

                                        </div>
                                        <div>
                                            <input type="number" name="ageTo" onChange={(e) => {
                                                const value = e.target.value == "" ? null : e.target.value;
                                                formik.setFieldValue('ageTo', value);

                                            }}
                                                value={formik.values.ageTo} className="form-control fw-12 border-0 job-regitration-input font-input" placeholder={jt('addJob.to').toString()} />
                                            {formik.errors.ageTo && formik.touched.ageTo ? <small className="text-danger">{formik.errors.ageTo}</small> : ""}

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row pt-3">
                                <div className="col-5">
                                    <div>
                                        <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600"> {jt('job.gender')}:</label>
                                    </div>
                                </div>
                                <div className="col-7 ps-3 fw-12">
                                    <div className="d-flex">

                                        <div key={'gender' + 1} className="form-check form-check-inline fs-radio">
                                            <input className="form-check-input" checked={formik.values.gender.includes("Male")} value="Male" type="checkbox" name="gender" onChange={formik.handleChange} id="inlineCheckbox1" />
                                            <label className="form-check-label" htmlFor="flexRadioDefault1"> {ct('male')} </label>
                                        </div>
                                        <div key={'gender' + 2} className="form-check form-check-inline fs-radio">
                                            <input className="form-check-input" checked={formik.values.gender.includes("Female")} value="Female" type="checkbox" name="gender" onChange={formik.handleChange} id="inlineCheckbox2" />
                                            <label className="form-check-label" htmlFor="flexRadioDefault1"> {ct('female')}  </label>
                                        </div>

                                    </div>
                                    {formik.errors.gender && formik.touched.gender ? <small className="text-danger">{formik.errors.gender}</small> : ""}
                                </div>
                            </div>
                            <div className="row pt-3">
                                <div className="col-5">
                                    <div>
                                        <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600"> {jt('job.yearsOfExp')}:</label>
                                    </div>
                                </div>
                                <div className="col-7 ps-3">
                                    {/* <div className='d-flex justify-content-between align-items-center'> */}

                                        <div>
                                            <input type="number" name="experienceFrom" onChange={(e) => {
                                                const value = e.target.value == "" ? null : e.target.value;
                                                formik.setFieldValue('experienceFrom', value);
                                                // if (e.target.value != '' && e.target.value != null) {
                                                //     props.setExpError(false)
                                                // }
                                            }}
                                                value={formik.values.experienceFrom} className="form-control fw-12 border-0 job-regitration-input font-input" placeholder={jt('addJob.mins').toString()} />
                                            {/* {props.expError && props.expError == true ? <small className="text-danger">{ts('account.required')}</small> : ""} */}

                                        </div>
                                        {/* <div>
                                            <input type="number" name="experienceTo" onChange={(e) => {
                                                const value = e.target.value == "" ? null : e.target.value;
                                                formik.setFieldValue('experienceTo', value);
                                            }}
                                                value={formik.values.experienceTo} className="form-control fw-12 border-0 job-regitration-input font-input" placeholder={jt('addJob.maxs').toString()} />
                                            {formik.errors.experienceTo && formik.touched.experienceTo ? <small className="text-danger">{formik.errors.experienceTo}</small> : ""}


                                        </div> */}

                                    {/* </div> */}

                                </div>
                            </div>
                            <div className="row pt-3">
                                <div className="col-5">
                                    <div>
                                        <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600"> {jt('job.minDegree')}:</label>
                                    </div>
                                </div>
                                <div className="col-7 ps-3">
                                    <div>
                                        <select name="minDegree" onChange={formik.handleChange}
                                            value={formik.values.minDegree} className='form-select fw-12 form-select-sm'>
                                            <option value=""> {ct('pleaseSelect')} </option>
                                            {
                                                (dropDown?.Degree || []).map((v, index) => (
                                                    <option value={v.key} key={'inde__' + index}>{v.value}</option>
                                                ))
                                            }
                                        </select>
                                        {/* {formik.errors.minDegree && formik.touched.minDegree ? <small className="text-danger">{formik.errors.minDegree}</small> : ""} */}

                                    </div>
                                </div>
                            </div>
                            <div className="row pt-3">
                                <div className="col-5">
                                    <div>
                                        <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600"> {jt('job.workingHours')}:</label>
                                    </div>
                                </div>
                                <div className="col-7 ps-3">
                                    <div>
                                        <select name="workShiftId" onChange={formik.handleChange}
                                            value={formik.values.workShiftId} className='form-select fw-12 form-select-sm'>
                                            <option value="0"> {ct('pleaseSelect')} </option>
                                            {
                                                (dropDown?.Shift || []).map((v, index) => (
                                                    <option key={'sinde__' + index} value={v.key}>{v.value}</option>
                                                ))
                                            }
                                        </select>
                                        {/* {formik.errors.workShiftId && formik.touched.workShiftId ? <small className="text-danger">{formik.errors.workShiftId}</small> : ""} */}

                                    </div>
                                </div>
                            </div>
                            <div className="row pt-3">
                                <div className="col-5">
                                    <div>
                                        <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600 text-nowrap"> {jt('job.salaryRange')}:</label>
                                    </div>
                                </div>
                                <div className="col-7 ps-3">
                                    <div>
                                        <div className='d-flex justify-content-between align-items-center '>
                                            <div style={{ marginRight: "0px", marginleft: "4px" }}>
                                                <input type="text" name="salaryFrom" maxLength={5} pattern="\d{1,5}" onChange={(e) => {
                                                    const value = e.target.value == "" ? null : e.target.value;
                                                    formik.setFieldValue('salaryFrom', value);
                                                    if (e.target.value != '' && e.target.value != null) {
                                                        props.setSalaryError(false)
                                                    }
                                                }}
                                                    value={formik.values.salaryFrom} className="form-control fw-12 border-0 job-regitration-input font-input" placeholder={jt('job.000')} />
                                                {props.salaryError && props.salaryError == true ? <small className="text-danger">{ts('account.required')}</small> : ""}

                                            </div>
                                            <div style={{ marginRight: "16px", marginleft: "0px" }}>
                                                <span className="fw-14 text-gray"> {ct('salaryTo')} </span>
                                            </div>
                                            <div >
                                                <input type="text" name="salaryTo" maxLength={5} pattern="\d{1,5}" onChange={(e) => {
                                                    const value = e.target.value == "" ? null : e.target.value;
                                                    formik.setFieldValue('salaryTo', value);

                                                }}
                                                    value={formik.values.salaryTo} className="form-control fw-12 border-0 job-regitration-input font-input" placeholder={jt('job.000')} />
                                                {formik.errors.salaryTo && formik.touched.salaryTo ? <small className="text-danger">{formik.errors.salaryTo}</small> : ""}

                                            </div>


                                            <select name="currency" className='form-select fw-12 form-select-sm' value={formik.values.currency} onChange={formik.handleChange}>
                                                <option key={"c_" + 0} value="" >{ct('select')}</option>
                                                <option key={"c_" + 1} value="USD" >USD</option>
                                                <option key={"c_" + 2} value="HTG">HTG</option>
                                            </select>
                                            {/* {formik.errors.currency && formik.touched.currency ? <small className="text-danger">{formik.errors.currency}</small> : ""} */}

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row pt-3">
                                <div className="col-5">
                                    <div>
                                        <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600 text-nowrap"> {jt('job.employerBenefits')}:</label>
                                    </div>
                                </div>
                                <div className="col-7 ps-3">
                                    <div>
                                        <span className="fw-12">
                                            <input name="benifits" onChange={formik.handleChange}
                                                value={formik.values.benifits} type="text" className="form-control border-0 job-regitration-input font-input" placeholder={jt('addJob.companyBenefits').toString()} />
                                        </span>
                                        {formik.errors.benifits && formik.touched.benifits ? <small className="text-danger">{formik.errors.benifits}</small> : ""}

                                    </div>
                                </div>
                            </div>

                            <div className="row pt-3">
                                <div className="col-5">
                                    <div>
                                        <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600 text-nowrap">  {jt('job.contractType')}:</label>
                                    </div>
                                </div>
                                <div className="col-7 ps-3">
                                    <div>
                                        <select name='contractType' value={formik.values.contractType}
                                            onChange={formik.handleChange}
                                            className="form-select fw-12 form-select-sm">
                                            <option value=''> {ct('pleaseSelect')} </option>
                                            {
                                                dropDown.ContractType?.map((s, index) => (
                                                    <option key={'ddinde__' + index} value={s.key}>{s.value}</option>
                                                ))
                                            }
                                        </select>
                                        {formik.errors.contractType && formik.touched.contractType ? <small className="text-danger">{formik.errors.contractType}</small> : ""}


                                    </div>
                                </div>
                            </div>
                            <div className="row pt-3">
                                <div className="col-5">
                                    <div>
                                        <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600 text-nowrap"> {jt('job.workExperience')}:</label>
                                    </div>
                                </div>
                                <div className="col-7 ps-3">
                                    <div>
                                        <select name='workExperienceId' value={formik.values.workExperienceId}
                                            onChange={formik.handleChange}
                                            className="form-select fw-12 form-select-sm">
                                            <option value=""> {ct('pleaseSelect')} </option>
                                            {
                                                dropDown.WorkExperience?.map((s, index) => (
                                                    <option key={'we_ddinde__' + index} value={s.key}>{s.value}</option>
                                                ))
                                            }
                                        </select>
                                        {formik.errors.workExperienceId && formik.touched.workExperienceId ? <small className="text-danger">{formik.errors.workExperienceId}</small> : ""}

                                    </div>
                                </div>
                            </div>
                            <div className="row pt-3">
                                <div className="col-5">
                                    <div>
                                        <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">
                                            {jt('job.expiresOn')}:
                                        </label>
                                    </div>
                                </div>
                                <div className="col-7">
                                    <div>

                                        <div className="date-small-size ms-1">
                                            <DatePickSelect
                                                yearFrom={new Date().getFullYear()}
                                                yearTo={2030}
                                                minDate={new Date()}
                                                maxDate={new Date(2030, 12, 31)}
                                                placeHolder={jt('addJob.lastDate').toString()}
                                                selected={formik.values.expiryDate}
                                                onChange={(date) => { formik.setFieldValue("expiryDate", date) }}
                                            />
                                        </div>

                                        {formik.errors.expiryDate && formik.touched.expiryDate ? <small className="text-danger">{formik.errors.expiryDate}</small> : ""}

                                    </div>
                                </div>
                            </div>

                            <div className="row pt-3">
                                <div className="col-5 align-self-center">
                                    <div>
                                        <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600 text-nowrap">  {t('callTender.departments')}:</label>
                                    </div>
                                </div>
                                <div className="col-7 ps-3">
                                    <div>
                                        <Select
                                            onChange={(e) => {
                                                formik.setFieldValue("departmentIds", Array.isArray(e) ? e.map(x => x.value) : [])
                                                // setSelectedOptions(Array.isArray(e) ? e.map(x => x.value) : []);

                                            }}
                                            placeholder={ct('pleaseSelect').toString()}
                                            value={departments.filter(obj => formik.values.departmentIds.includes(obj.value))} // set selected values
                                            className="basic-multi-select fw-12"
                                            isMulti
                                            defaultValue={departments[1]}
                                            options={departments}
                                        // classNamePrefix="select"
                                        />
                                        {formik.errors.departmentIds && formik.touched.departmentIds ? <small className="text-danger">{formik.errors.departmentIds}</small> : ""}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default SideContent;


