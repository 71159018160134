import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link, useNavigate } from "react-router-dom";
import { FaUserTie, FaBuilding } from "react-icons/fa";
import { NavLink } from 'react-router-dom';
import { SignInManager } from '../../../Auth/SignInManager';
import { useDispatch, useSelector } from 'react-redux';
import { setSignout } from '../../../Store';
import { useTranslation } from 'react-i18next';
import NavDropdown from 'react-bootstrap/NavDropdown';
import React, { useState } from 'react';
export const AdminHeader = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [show, setShow] = React.useState(false);

    const { i18n } = useTranslation();
    const { t } = useTranslation('admin');

    return (
        <>
            <Navbar expand="lg" variant="light" fixed="top" className='navbar-admin-bg'>
                <Container fluid>
                    <Navbar.Brand href="#" className='fw-700'>Bonjan  {t('header.admin')}</Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="navbarScroll">
                        <Nav
                            className="me-auto my-2 my-lg-0"
                            style={{ maxHeight: '100px' }}
                            navbarScroll
                        >
                            <NavLink to="/admin/jobs" className='px-3 py-4 text-decoration-none'>{t('header.jobposts')}</NavLink>
                            <NavLink to="/admin/tenders" className='px-3 py-4 text-decoration-none'>
                                {t('header.callfortenders')}
                            </NavLink>
                            <NavLink to="/admin/announces" className='px-3 py-4 text-decoration-none'> {t('header.seminar')}</NavLink>
                            <NavLink to="/admin/invoices" className='px-3 py-4 text-decoration-none'> {t('header.invoices')}</NavLink>

                            <NavLink to="/admin/successstories" className='px-3 py-4 text-decoration-none'>
                                {t('header.successstories')}
                            </NavLink>
                            <NavDropdown title={t('header.members')} className='px-3 fs-17 py-2 admin-header-dropdown'
                                autoClose="inside" show={show} onMouseOver={() => setShow(true)} onMouseLeave={() => setShow(false)}
                            >

                                <NavDropdown.Item className='text-decoration-none text-dark' onClick={() => { navigate('/admin/employers'); }}> {t('header.employers')}</NavDropdown.Item>
                                <NavDropdown.Item className='text-decoration-none text-dark' onClick={() => { navigate('/admin/jobseekers'); }}> {t('header.jobSeekers')}</NavDropdown.Item>

                            </NavDropdown>

                        </Nav>
                        <Form className="d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center">
                                <Form.Select onChange={e => i18n.changeLanguage(e.target.value)} value={i18n.language} className=''>
                                    <option value="fr" >FR</option>
                                    <option value="en" >EN</option>
                                </Form.Select>
                            </div>
                            <div className="mx-2 d-flex align-items-center">
                                <Button onClick={() => { navigate('/admin/editcompany'); }}
                                    variant="outline-primary"><FaBuilding title={t('header.editCompany').toString()} /></Button>
                            </div>
                            <div className=" d-flex align-items-center">
                                <Button

                                    onClick={async () => {
                                        const manager = new SignInManager();
                                        manager.RemoveToken();
                                        dispatch(setSignout());
                                        navigate('/admin');
                                        localStorage.setItem("isLoggedIn", "false");
                                    }}

                                    variant="outline-success">{t('header.signOut')}</Button>
                            </div>
                        </Form>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )
}