import React from "react";

import { useSearchParams } from "react-router-dom";
import { config } from "../../../Environments";
import { GetAxios } from "../../../Shared";
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';

const SideContent = (props: any) => {
  const data = props.data;

  const [logo, setLogo] = React.useState("/images/upload-cmpny-logo.jpg");
  const [name, setName] = React.useState("");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation('company');
  const { t: ct } = useTranslation('common');
  const { t: jt } = useTranslation('job');
  const { i18n } = useTranslation();
  React.useEffect(() => {
    GetAxios().get(config.apiBase + '/api/Companies/GetCompanyNameLogo?id=' + (searchParams.get('tid') || "") + '&type=tender').then(res => {
      setName(res.data?.name);
      if (i18n.language == 'fr' && res.data?.companyId == 1) {
        setLogo(config.apiBase + res.data?.logo2);
      }
      else {
        setLogo(config.apiBase + res.data?.logo);
      }


    })
  }, [i18n.language]);

  return (
    <>
      <aside>


        <div className="">
          <div className="card border-0 rounded-0">
            <img
              src={logo}
              alt="tender profile image"
              className="card-img-top rounded-0"
              onError={(ev: any) => {
                ev.target.src = '/images/ImgPlaceholder.png';
              }}
            />
            <div className="card-body pb-5">
              <div className="">
                <div>
                  <h6 className="text-center mt-3 fw-700">
                    <a
                      className="text-decoration-none text-color-3 fs-18 text-uppercase pointer-event">
                      {data?.companyName}
                    </a>
                  </h6>
                </div>



                <div className="mt-4 border-bottom border-secondary">
                  <h5 className="text-color-33 fw-600 text-uppercase mb-2">
                    {t('callTender.tenderDetails')}
                  </h5>
                </div>

                <div className="row pt-3">
                  <div className="col-6">
                    <div>
                      <label className="mb-0 fw-700 text-uppercase fs-14 fw-bold">
                        {t('callTender.companyName')}:
                      </label>
                    </div>
                  </div>
                  <div className="col-6 px-0">
                    <div>
                      <span className="fs-14 fw-bold">{data?.companyName}</span>
                    </div>
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col-6">
                    <div>
                      <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">
                        {t('callTender.tenderTitle')}:
                      </label>
                    </div>
                  </div>
                  <div className="col-6 px-0">
                    <div>
                      <span className="fw-12">{data?.title}</span>
                    </div>
                  </div>
                </div>

                <div className="row pt-3">
                  <div className="col-6">
                    <div>
                      <label className="mb-0 fw-600 text-uppercase fsize-13">
                        {t('callTender.address')}:
                      </label>
                    </div>
                  </div>
                  <div className="col-6 px-0">
                    <div>
                      <span className="fw-12">{data?.address}</span>
                    </div>
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col-6">
                    <div>
                      <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">
                        {t('callTender.city')}:
                      </label>
                    </div>
                  </div>
                  <div className="col-6 px-0">
                    <div>
                      <span className="fw-12">{data?.city}</span>
                    </div>
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col-6">
                    <div>
                      <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">
                        {t('callTender.departments')}:
                      </label>
                    </div>
                  </div>
                  <div className="col-6 px-0">
                    <div>
                      <span className="fw-12">{data?.department}</span>
                    </div>
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col-6">
                    <div>
                      <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">
                        {t('callTender.tel')}
                      </label>
                    </div>
                  </div>
                  <div className="col-6 px-0">
                    <div>
                      <span className="fw-12">{data?.tel || "N/A"}</span>
                    </div>
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col-6">
                    <div>
                      <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">
                        {t('callTender.email')}:
                      </label>
                    </div>
                  </div>
                  <div className="col-6 px-0">
                    <div>
                      <span className="fw-12">{data?.email || "N/A"}</span>
                    </div>
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col-6">
                    <div>
                      <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">
                        {jt('job.contactPerson')}:
                      </label>
                    </div>
                  </div>
                  <div className="col-6 px-0">
                    <div>
                      <span className="fw-12">{data?.contactPerson || "N/A"}</span>
                    </div>
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col-6">
                    <div>
                      <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">
                        {jt('job.sectorOfActivity')}:
                      </label>
                    </div>
                  </div>
                  <div className="col-6 px-0">
                    <div>
                      <span className="fw-12">
                        {data?.sector}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col-6">
                    <div>
                      <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">
                        {jt('job.classification')}:
                      </label>
                    </div>
                  </div>
                  <div className="col-6 px-0">
                    <div>
                      <span className="fw-12">
                        {data?.classification}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col-6">
                    <div>
                      <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">
                        {t('callTender.informationAboutRecurrence')}:
                      </label>
                    </div>
                  </div>
                  <div className="col-6 px-0">
                    <div>
                      <span className="fw-12">
                        {data?.recurrence}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col-6">
                    <div>
                      <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">
                        {t('callTender.dateOfDispatch')} {t('callTender.ofThisTender')}:
                      </label>
                    </div>
                  </div>
                  <div className="col-6 px-0">
                    <div>
                      <span className="fw-12">
                        {data?.dispatchDate}
                        {/* {new Date(data?.dispatchDate).toLocaleDateString('en-US')} */}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col-12">
                    <div>
                      <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">
                        {t('callTender.conditionsForOpeningTenders')}:
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col-6">
                    <div>
                      <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">
                        {t('callTender.date')}:
                      </label>
                    </div>
                  </div>
                  <div className="col-6 px-0">
                    <div>
                      <span className="fw-12">
                        {data?.openingDate}
                        {/* {new Date(data?.openingDate).toLocaleDateString('en-US')} */}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col-6">
                    <div>
                      <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">
                        {jt('job.expiresOn')}:
                      </label>
                    </div>
                  </div>
                  <div className="col-6 px-0">
                    <div>
                      <span className="fw-12">
                        {data?.expireDate}
                        {/* {new Date(data?.expireDate).toLocaleDateString('en-US')} */}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col-6">
                    <div>
                      <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">
                        {t('callTender.time')}:
                      </label>
                    </div>
                  </div>
                  <div className="col-6 px-0">
                    <div>
                      <span className="fw-12">
                        {data?.time}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </aside>
    </>
  );
};
export default SideContent;
