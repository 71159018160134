import React, { useState, useEffect } from 'react';
import { FaAngleDown, FaAngleUp, FaPlus, FaMinus, FaFacebookF, FaTwitter, FaLinkedin } from "react-icons/fa";
import Collapse from 'react-bootstrap/Collapse';
import { GetAxios } from '../../../Shared';
import { config } from '../../../Environments';
import { RotatingLines } from 'react-loader-spinner';
import { sort } from '../../../Components/Pagination/usePagination';
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import { toast } from "react-toastify";
import { useTranslation } from 'react-i18next';
import { SortList } from "../../../Components/Pagination/usePagination";

const MainContent = (props: { tenderId: number }) => {

    const { t } = useTranslation('company');
    const { t: ct } = useTranslation('common');

    const [list, setList] = useState<ListType>({ title: '', proposals: [] });
    const [sortCol, setSortCol] = useState('name');

    useEffect(() => {
        if (props.tenderId > 0)

            GetAxios().post(config.apiBase + '/api/TenderProposal/GetProposals?id=' + props.tenderId).then(res => {
                setList(res.data.data || { title: '', proposals: [] });
            });

    }, [props, sortCol]);

    return (
        <>
            <div className="container">
                <div className="d-flex w-100 justify-content-between align-items-center">
                    <div className="tab-pane fade show active" id="company-tender-application" role="tabpanel" aria-labelledby="company-application-tab">
                        <p className="text-start mb-0 h5 text-capitalize fw-600"> {t('compApplicant.proposalFor')} : <span className="fw-normal ms-0 fw-16"> {list.title}</span> </p>
                    </div>
                    <select style={{ width: "200px" }} value={sortCol} onChange={e => setSortCol(e.target.value)} className="form-select form-control key-weight w-select-list float-end">
                        <option value={'companyName'}> {ct('name')} </option>
                        <option value={'createdDate'}> {ct('date')} </option>
                    </select>
                </div>
            </div>
            <div className="client-description app-content">
                {
                    list.proposals.length == 0 && <p className='text-danger ms-2 ps-1'> {t('compApplicant.noApplicantsFound')}</p>
                }
                {(SortList(list.proposals, sortCol, 'asc')).map((item: Proposals, index: number) => (
                    <TenderItem key={'appll' + index} tender={item} i={index} />
                ))}

            </div>
        </>
    )
}
export default MainContent;

const TenderItem = (props: { tender: Proposals, i: number }) => {
    const item = props.tender;
    const index = props.i;

    const [show, setShow] = React.useState(false);
    const [expanding, setExpending] = React.useState(false);
    const [detail, setDetail] = React.useState<any>();
    const [modalShow, setModalShow] = useState(false);
    const convertToPlain = (html: string) => {
        var tempDiv = document.createElement("p");
        tempDiv.innerHTML = html;
        var result = (tempDiv.innerText.length >= 160) ? tempDiv.innerText.substring(0, 100) + "..." : tempDiv.innerText;
        return result;


    }

    const downloadFile = (resume: string, name: string) => {

        const path = config.apiBase + resume;
        const filename = name.split(".")[0] + "." + resume.split(".")[1];
        fetch(path).then(response => {
            response.blob().then(blob => {
                const fileURL = window.URL.createObjectURL(blob);
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = filename;
                alink.click();


            })
        })
    }
    const handleShare = (type: string) => {
        const url = `${config.apiBase}/api/TenderProposal/ShareCompany?id=${item.userId}`;


        if (type === "FaceBook") {
            window.open(
                `https://www.facebook.com/sharer/sharer.php?href=${encodeURIComponent(url)}&u=${encodeURIComponent(url)}`, '_blank'
            );


        }
        else if (type === "LinkedIn") {
            window.open(
                `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}`, '_blank'

            );
        }
        else if (type === "Twitter") {
            window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent(item.companyName)}`, '_blank');

        }


    }
    React.useEffect(() => {
        if (expanding)
            GetAxios().get(config.apiBase + '/api/Employer/ProfilePublicView?id=' + item.userId).then(res => {
                setDetail(res.data.data);
                setExpending(false);
                setShow(true);
            })
    }, [expanding]);

    const { t } = useTranslation('company');
    const { t: ct } = useTranslation('common');

    return (
        <div className="card rounded-0 border-0 mt-3 mb-3">
            <div className="card-body">

                <div className="row">
                    <div className="col-md-2 col-sm-4">
                        <div className="text-center mb-3">
                            <img src={config.apiBase + item.companyLogo} className="img-thumbnail rounded-0 border-0 p-0"
                                alt="image placeholder"
                                onError={(ev: any) => {
                                    ev.target.src = '/images/ImgPlaceholder.png';
                                }} />
                        </div>

                    </div>
                    <div className="col-md-8 col-sm-4 p-lg-0">
                        <h4 className="card-title mb-0"><Link to={"/company/profile?id=" + item.userId} target='_blank' className="text-decoration-none text-dark pointer-event">{item.companyName}</Link></h4>
                        <Link to={"/company/profile?id=" + item.userId} target='_blank' className="pointer-event card-subtitle text-decoration-none">{convertToPlain(item.companyDescription)}</Link>
                        <div className="d-flex w-100 justify-content-between mt-3">
                            <div className="client-side-title text-uppercase">
                                <p className="mb-0 fw-600 fw-12"> {t('compApplicant.employerName')}: <span className="fsize-13 fw-normal ">{item.employername}</span></p>
                                <p className="mb-0 fw-600 fw-12"> {t('compApplicant.email')}: <span className="fsize-13 fw-normal ">{item.companyEmail}</span></p>

                            </div>
                            <div className="client-side-title text-uppercase">

                                <p className="mb-0 fw-600 fw-12"> {t('compProfile.organizationType')}: <span className="fsize-13 fw-normal ">{item.companyType}</span></p>
                                <p className="mb-0 fw-600 fw-12"> {t('compApplicant.contactNumber')}: <span className="fsize-13 fw-normal ">{item.companyNumber}</span></p>


                            </div>
                        </div>
                        <div className="d-flex w-100 justify-content-between mt-3">
                            <div className="client-side-title text-uppercase">
                                <p className="mb-0 fw-600 fw-12"> {t('compApplicant.location')}: <span className="fsize-13 fw-normal ">{item.companyAddress}</span></p>

                            </div>

                        </div>
                    </div>
                    <Collapse in={show}>
                        <div id="company-profile1">
                            <div className="row p-3 pb-0">

                                <div className="candidate-sec p-0">
                                    <h5 className="text-color-33 fw-600 py-3 text-uppercase mb-3"> {ct('description')} </h5>
                                    <p className="card-para" dangerouslySetInnerHTML={{ __html: detail?.companyDescription || '' }}>

                                    </p>
                                </div>
                                <div className="candidate-sec p-0">
                                    <div className="candidate-title mt-3">
                                        <ul className="list-inline d-flex p-0">
                                            <li><p className="h5 fw-bold me-2"> {ct('share')}:</p></li>
                                            <li> <a onClick={() => { handleShare("FaceBook") }} className="tags text-decoration-none mb-0 me-2 facebook-color pointer-event"> <FaFacebookF size={18} /></a></li>
                                            <li>  <a onClick={() => { handleShare("LinkedIn") }} className="tags text-decoration-none mb-0 me-2 linkedIn-color pointer-event"><FaLinkedin size={18} /></a></li>
                                            <li>  <a onClick={() => { handleShare("Twitter") }} className="tags text-decoration-none mb-0 me-2 twitter-color pointer-event"><FaTwitter size={18} /></a></li>


                                        </ul>
                                    </div>
                                </div>
                                {item.files.length > 0 &&

                                    <div className="candidate-sec p-0">
                                        <div className="candidate-title mt-3">
                                            {item.files.map((item: any, index: any) => (
                                                <>
                                                    <ul key={"propoal_file" + index + item.userId} className="list-inline d-flex p-0">
                                                        <li key={"propoal_fileno" + index + item.userId}><p className="h5 fw-bold me-2"> {ct('proposal')} {index + 1}:</p></li>
                                                        <li key={"propoal_filepreview" + index + item.userId}>
                                                            <CVPreviewModal item={item.fileName} fileName={item.originalName} />

                                                        </li>
                                                        <li key={"propoal_filedownload" + index + item.userId} className='ms-3'>
                                                            <button type="submit" onClick={() => { downloadFile(item.fileName, item.originalName) }} className="border-0 button-default accordion-header rounded-0 mb-0 me-2"> {ct('download')} </button></li>
                                                    </ul>



                                                </>
                                            ))}

                                        </div>
                                    </div>
                                }

                            </div>
                        </div>
                    </Collapse>
                </div>
                <div className="text-end">
                    <div className="accordion-item mb-2 mt-3">
                        <button type="submit" className="border-0 button-default accordion-header SeeMore1 fw-600 rounded-0"
                            onClick={() => {
                                if (show)
                                    setShow(false);
                                else
                                    setExpending(true);
                            }}>
                            {expanding ? <RotatingLines width="20" /> : show ? (
                                <><FaAngleUp className="me-1" size={25} /> {ct('lessInfo')} </>
                            ) : (
                                <><FaAngleDown className="me-1" size={25} /> {ct('moreInfo')} </>
                            )}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export const CVPreviewModal = (props: any) => {

    const [pdfUrl, setPdfUrl] = useState(config.apiBase + props.item);
    const [preview, setPreview] = useState(false);
    const handleClose = () => setPreview(false);
    const handleShow = () => setPreview(true);


    const { t } = useTranslation('company');
    const { t: ct } = useTranslation('common');

    return (
        <>
            {
                <button type="button"
                    className="border-0 button-default accordion-header rounded-0"
                    onClick={() => handleShow()}> {ct('preview')} </button>
            }
            <Modal show={preview} onHide={handleClose} animation={true} className='popup-modal-width top-cl vh-1000' size='lg'>
                <Modal.Header className='popup-gray-bg' closeButton>
                    <Modal.Title className='fw-700'> {t('compApplicant.previewProposal')} </Modal.Title>
                </Modal.Header>
                <Modal.Body className='p-4'
                    style={{
                        maxHeight: 'calc(1000vh - 210px)',
                        overflowY: 'auto'
                    }}>

                    <div>
                        <embed
                            src={`${pdfUrl}#toolbar=0&navpanes=0&scrollbar=0`} width="100%" height="500"
                            onError={() => toast(t('compApplicant.fileError'), { type: 'error' })} />
                    </div>

                </Modal.Body>
            </Modal>
        </>
    )
}






type ListType = {
    title: string,
    proposals: Proposals[]
}

type Proposals = {

    userId: string,
    employername: string,
    companyName: string,
    companyLogo: string,
    companyAddress: string,
    companyDescription: string,
    companyType: string,
    companyEmail: string,
    companyNumber: string,
    createdDate: Date | string,
    files: ProposalFiles[]
};

type ProposalFiles = {

    fileName: string,
    originalName: string,

};