import React, { useState } from 'react';
import moment from 'moment';
import { DefaultProfileImage, SelectableSectionClicked } from '../SharedData';
import styled from "styled-components";
import { useTranslation } from 'react-i18next';


export const Auckland = (props: TemplateComponentProps) => {

    const { t } = useTranslation('resume');

    const {
        navigate,
        contactInformation,
        image,
        imageData,
        objectives,
        experiences,
        educations,
        languages,
        skills,
        achievements,
        certificates,
        customSections,
        honours,
        projects,
        publications,
        references,
        additionalSettings,
        stepsInformation,
        onNavigatedToSection
    } = props;


    return (
        <>
            <WrapperDiv additionalSettings={additionalSettings}>
                <div className={navigate ? ' p-0' : ''}>
                    <div className='template'>
                        <div className='preview-template'>
                            <div id="template-full">
                                <div className='template-main'>
                                    <div className='template-auckland-header'>
                                        <section className='head mt-2' onClick={_ => SelectableSectionClicked('contact-information', navigate, onNavigatedToSection)}>
                                            {
                                                (contactInformation.firstName || contactInformation.lastName || contactInformation.occupation) &&
                                                <div className='d-flex justify-content-between align-items-center'>

                                                    <div className='d-flex justify-content-between align-items-center'>
                                                        {
                                                            image &&
                                                            <div className='selectable-section me-3'>
                                                                <div className='description'>

                                                                    {/* <img src={image}
                                                            className={imageData.isRounded ? "rounded-img amsterdam-img" : 'simple-img amsterdam-img'}
                                                            onError={e => {
                                                            if (e.target instanceof HTMLImageElement) {
                                                                e.target.src = DefaultProfileImage;
                                                            }
                                                            }} /> */}
                                                                </div>
                                                            </div>
                                                        }
                                                        <div className=' selectable-section'>
                                                            <div className='head-two'>
                                                                <div className='fs-'>
                                                                    <h4 className='mb-0 title-color'>{contactInformation.firstName} {contactInformation.lastName}</h4>
                                                                </div>
                                                                <div className='template_subtitle'>
                                                                    <p className='mb-2'>{contactInformation.occupation}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        Object.values(contactInformation).filter(s => s).length > 0 &&
                                                        <div className='selectable-section' onClick={_ => SelectableSectionClicked('contact-information', navigate, onNavigatedToSection)}>
                                                            <div className='d-block justify-content-end'>
                                                                <ul className='list-unstyled'>
                                                                    {
                                                                        contactInformation.email &&
                                                                        <li className='text-end'><p className='sec-subtitle'> {t('email')}: <span className='color-black ms-2'>{contactInformation.email}</span></p></li>
                                                                    }
                                                                    {
                                                                        contactInformation.address &&
                                                                        <li className='text-end'><p className='sec-subtitle'> {t('address')}: <span className='color-black ms-2'>{contactInformation.address} </span></p></li>
                                                                    }
                                                                    {
                                                                        contactInformation.phone &&
                                                                        <li className='text-end'><p className='sec-subtitle'> {t('phone')}: <span className='color-black ms-2'>{contactInformation.phone}</span></p></li>
                                                                    }
                                                                    {
                                                                        contactInformation.dob &&
                                                                        <li className='text-end'><p className='sec-subtitle'> {t('resDateBirth')}: <span className='color-black ms-2'>{moment(contactInformation.dob).format('MM/DD/YYYY')} </span></p></li>
                                                                    }
                                                                    {
                                                                        contactInformation.nationality &&
                                                                        <li className='text-end'><p className='sec-subtitle'> {t('nationality')}: <span className='color-black ms-2'>{contactInformation.nationality}</span></p></li>
                                                                    }
                                                                    {
                                                                        contactInformation.linkedInLink &&
                                                                        <li className='text-end'><p className='sec-subtitle'> {t('link')}: <span className='color-black ms-2 text-break'>{contactInformation.linkedInLink}</span></p></li>
                                                                    }
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            }
                                        </section>
                                    </div>
                                    <div className='template_content-4'>
                                        {
                                            stepsInformation.findIndex(x => x.title == 'Objective') > -1 && objectives &&
                                            <section className='objective-sec contact-information mt-3 selectable-section' onClick={_ => SelectableSectionClicked('objective', navigate, onNavigatedToSection)}>
                                                <div className='border-top-dashed'></div>
                                                <div className='contact-information mt-3'>
                                                    <div className='row'>
                                                        <div className='col-5'>
                                                            <div className='mb-3'>
                                                                <p className='text-uppercase fs-12'>
                                                                    <span className='title-bg-black fw-600'> {t('Objective')} </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className='col-7'>
                                                            <div className='obj-list-margin-left'>
                                                                <p className='sec-value mb-2' dangerouslySetInnerHTML={{ __html: objectives }}></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        }
                                        {
                                            stepsInformation.findIndex(x => x.title == 'Experiences') > -1 && experiences.length > 0 ?
                                                <section className='experience-sec contact-information mt-3 selectable-section' onClick={_ => SelectableSectionClicked('work-experience', navigate, onNavigatedToSection)}>
                                                    <div className='border-top-dashed'></div>
                                                    <p className='text-uppercase fs-12 fw-600 my-3'>
                                                        <span className='title-bg-black fw-600'>  {t('Experiences')} </span>
                                                    </p>
                                                    <div className='contact-information'>
                                                        {
                                                            experiences.map((e, i) =>
                                                                <div className='row mb-2' key={'exp_' + i}>
                                                                    <div className='col-5'>
                                                                        {
                                                                            e.location ?
                                                                                <p className='fw-600'>{e.location}</p> : <></>
                                                                        }
                                                                        {
                                                                            (e.startDate || e.endDate) ?
                                                                                <p className='fw-500 m-right fst-italic'>
                                                                                    {
                                                                                        e.startDate ?
                                                                                            <span>{new Date(e.startDate).toLocaleString('default', { month: 'short' })} {new Date(e.startDate).getFullYear()}</span> : <></>
                                                                                    }
                                                                                    {
                                                                                        (e.endDate && !e.currentlyWorking) ?
                                                                                            <span> - {new Date(e.endDate).toLocaleString('default', { month: 'short' })} {new Date(e.endDate).getFullYear()}</span> : <></>
                                                                                    }
                                                                                    {
                                                                                        e.currentlyWorking ?
                                                                                            <em> -  {t('present')}</em> : <></>
                                                                                    }
                                                                                </p> : <></>
                                                                        }
                                                                    </div>
                                                                    <div className='col-7'>
                                                                        <div className=''>
                                                                            {
                                                                                e.title &&
                                                                                <p className='sec-subtitle me-2'>{e.title}</p>
                                                                            }
                                                                            {
                                                                                e.company &&
                                                                                <p className='sec-value'>{e.company}</p>
                                                                            }
                                                                            {
                                                                                e.description &&
                                                                                <p className='sec-value editor-list-margin-left' dangerouslySetInnerHTML={{ __html: e.description }}></p>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </section> : <></>
                                        }
                                        {
                                            stepsInformation.findIndex(x => x.title == 'Education') > -1 && educations.length > 0 &&
                                            <section className='education-sec contact-information mt-3 selectable-section' onClick={_ => SelectableSectionClicked('education', navigate, onNavigatedToSection)}>
                                                <div className='border-top-dashed'></div>
                                                <p className='text-uppercase fs-12 fw-600 my-3'>
                                                    <span className='title-bg-black fw-600'> {t('Education')} </span>
                                                </p>
                                                <div className='contact-information'>
                                                    {
                                                        educations.map((s, i) =>
                                                            <div className='row mb-2' key={'edu_' + i}>
                                                                <div className='col-5'>
                                                                    {
                                                                        s.location &&
                                                                        <p className='fw-600'>{s.location}</p>
                                                                    }
                                                                    {
                                                                        (s.startDate || s.completeDate) &&
                                                                        <p className='fw-500 m-right fst-italic'>
                                                                            {
                                                                                s.startDate &&
                                                                                <span>{new Date(s.startDate).toLocaleString('default', { month: 'short' })} {new Date(s.startDate).getFullYear()}</span>
                                                                            }
                                                                            {
                                                                                (s.completeDate && !s.currentlyStudying) &&
                                                                                <span> - {new Date(s.completeDate).toLocaleString('default', { month: 'short' })} {new Date(s.completeDate).getFullYear()}</span>
                                                                            }
                                                                            {
                                                                                (s.currentlyStudying) &&
                                                                                <span> - {t('present')}</span>
                                                                            }
                                                                        </p>
                                                                    }
                                                                </div>
                                                                <div className='col-7'>
                                                                    <div className=''>
                                                                        {
                                                                            s.degree &&
                                                                            <p className='sec-subtitle me-2'>{s.degree}</p>
                                                                        }
                                                                        {
                                                                            s.institute &&
                                                                            <p className='sec-value'>{s.institute}</p>
                                                                        }
                                                                        {
                                                                            s.description &&
                                                                            <p className='mt-1 sec-value editor-list-margin-left' dangerouslySetInnerHTML={{ __html: s.description }}></p>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </section>
                                        }
                                        <section className='language-skill-sec mt-3'>
                                            <div className='row'>
                                                {
                                                    stepsInformation.findIndex(x => x.title == 'Skills') > -1 &&
                                                    <div className='col-4 selectable-section' onClick={_ => SelectableSectionClicked('skills', navigate, onNavigatedToSection)}>
                                                        <div>
                                                            <div className='border-top-dashed'></div>
                                                            <p className='text-uppercase fs-12 fw-600 my-3'>
                                                                <span className='title-bg-black fw-600'> {t('Skills')} </span>
                                                            </p>
                                                            <ul className='ps-3 sec-value'>
                                                                {
                                                                    skills.length > 0 &&
                                                                    skills.map((s, i) =>
                                                                        <li className='mb-1' key={'skill_' + s.name + s.id + i}>
                                                                            <p className='fw-500'>{s.name}</p>
                                                                        </li>
                                                                    )
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                }
                                                <div className='col-8'>
                                                    <div className='row'>
                                                        {
                                                            stepsInformation.findIndex(x => x.title == 'Languages') > -1 &&
                                                            <div className='col-6 px-2 selectable-section' onClick={_ => SelectableSectionClicked('languages', navigate, onNavigatedToSection)}>
                                                                <div>
                                                                    <div className='border-top-dashed'></div>
                                                                    <p className='text-uppercase fs-12 fw-600 my-3'>
                                                                        <span className='title-bg-black fw-600'> {t('Languages')} </span>
                                                                    </p>
                                                                    <div className='sec-value'>
                                                                        {
                                                                            languages.length > 0 &&
                                                                            languages.map((s, i) =>
                                                                                <div className='mb-2' key={'languages_' + s.name + s.id + i}>
                                                                                    {
                                                                                        s.name &&
                                                                                        <p className='fw-600'>{s.name}</p>
                                                                                    }
                                                                                    {
                                                                                        s.level &&
                                                                                        <p className=''>{s.level}</p>
                                                                                    }
                                                                                    {
                                                                                        s.course &&
                                                                                        <p className=''>{s.course}</p>
                                                                                    }
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        {
                                                            stepsInformation.findIndex(x => x.title == 'References') > -1 && references.length > 0 &&
                                                            <div className='col-6 selectable-section' onClick={_ => SelectableSectionClicked('references', navigate, onNavigatedToSection)}>
                                                                <div>
                                                                    <div className='border-top-dashed'></div>
                                                                    <p className='text-uppercase fs-12 fw-600 my-3'>
                                                                        <span className='title-bg-black fw-600'>  {t('References')} </span>
                                                                    </p>
                                                                    <div className='w-objective my-2'>
                                                                        {
                                                                            references.map((v, i) =>
                                                                                <div key={'refer__' + i} className=''>
                                                                                    <p className='sec-value mb-2 editor-list-margin-left'>{v.name}</p>
                                                                                    <p className='sec-value mb-2 editor-list-margin-left'>{v.city} {(v.city != '' && v.tel != '') ? "|" : ""} {v.tel}</p>
                                                                                    {/* <p className='sec-value mb-2 editor-list-margin-left' dangerouslySetInnerHTML={{ __html: v.content || '' }}></p> */}
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>

                                            </div>
                                        </section>
                                        {
                                            stepsInformation.findIndex(x => x.title == 'Projects') > -1 && projects.length > 0 &&
                                            <section className='project-sec contact-information mt-3 selectable-section' onClick={_ => SelectableSectionClicked('projects', navigate, onNavigatedToSection)}>
                                                <div className='border-top-dashed'></div>
                                                <div className='contact-information mt-3'>
                                                    <div className='row'>
                                                        <div className='col-5'>
                                                            <div className='mb-3'>
                                                                <p className='text-uppercase fs-12'>
                                                                    <span className='title-bg-black fw-600'> {t('Projects')} </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className='col-7'>
                                                            <div className=''>
                                                                {
                                                                    projects.map((v, i) =>
                                                                        <div key={'projects__' + i}>
                                                                            <p className='sec-value mb-2 editor-list-margin-left' dangerouslySetInnerHTML={{ __html: v.content || '' }}></p>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        }
                                        {
                                            stepsInformation.findIndex(x => x.title == 'Achievements') > -1 && achievements.length > 0 &&
                                            <section className='achievement-sec contact-information mt-3 selectable-section' onClick={_ => SelectableSectionClicked('achievements', navigate, onNavigatedToSection)}>
                                                <div className='border-top-dashed'></div>
                                                <div className='contact-information mt-3'>
                                                    <div className='row'>
                                                        <div className='col-5'>
                                                            <div className='mb-3'>
                                                                <p className='text-uppercase fs-12'>
                                                                    <span className='title-bg-black fw-600'> {t('Achievements')} </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className='col-7'>
                                                            <div className=''>
                                                                {
                                                                    achievements.map((v, i) =>
                                                                        <div key={'achievements__' + i}>
                                                                            <p className='sec-value mb-2 editor-list-margin-left' dangerouslySetInnerHTML={{ __html: v.content || '' }}></p>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        }
                                        {
                                            stepsInformation.findIndex(x => x.title == 'Certifications & Courses') > -1 && certificates.length > 0 &&
                                            <section className='certificate-course-sec contact-information mt-3 selectable-section' onClick={_ => SelectableSectionClicked('certifications-courses', navigate, onNavigatedToSection)}>
                                                <div className='border-top-dashed'></div>
                                                <div className='contact-information mt-3'>
                                                    <div className='row'>
                                                        <div className='col-5'>
                                                            <div className='mb-3'>
                                                                <p className='text-uppercase fs-12'>
                                                                    <span className='title-bg-black fw-600'> {t('Certifications & Courses')} </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className='col-7'>
                                                            <div className=''>
                                                                {
                                                                    certificates.map((v, i) =>
                                                                        <div key={'certificates__' + i}>
                                                                            <p className='sec-value mb-2 editor-list-margin-left' dangerouslySetInnerHTML={{ __html: v.content || '' }}></p>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        }
                                        {
                                            stepsInformation.findIndex(x => x.title == 'Honors & Awards') > -1 && honours.length > 0 &&
                                            <section className='honours-award-sec contact-information mt-3 selectable-section' onClick={_ => SelectableSectionClicked('honors-awards', navigate, onNavigatedToSection)}>
                                                <div className='border-top-dashed'></div>
                                                <div className='contact-information mt-3'>
                                                    <div className='row'>
                                                        <div className='col-5'>
                                                            <div className='mb-3'>
                                                                <p className='text-uppercase fs-12'>
                                                                    <span className='title-bg-black fw-600'> {t('Honors & Awards')} </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className='col-7'>
                                                            <div className=''>
                                                                {
                                                                    honours.map((v, i) =>
                                                                        <div key={'honour__' + i}>
                                                                            <p className='sec-value mb-2 editor-list-margin-left' dangerouslySetInnerHTML={{ __html: v.content || '' }}></p>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        }
                                        {
                                            stepsInformation.findIndex(x => x.title == 'Publications') > -1 && publications.length > 0 &&
                                            <section className='publications-sec contact-information mt-3 selectable-section' onClick={_ => SelectableSectionClicked('publications', navigate, onNavigatedToSection)}>
                                                <div className='border-top-dashed'></div>
                                                <div className='contact-information mt-3'>
                                                    <div className='row'>
                                                        <div className='col-5'>
                                                            <div className='mb-3'>
                                                                <p className='text-uppercase fs-12'>
                                                                    <span className='title-bg-black fw-600'> {t('Publications')} </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className='col-7'>
                                                            <div className=''>
                                                                {
                                                                    publications.map((v, i) =>
                                                                        <div key={'publication__' + i}>
                                                                            <p className='sec-value mb-2 editor-list-margin-left' dangerouslySetInnerHTML={{ __html: v.content || '' }}></p>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        }
                                        {
                                            stepsInformation.findIndex(x => x.title == 'Custom sections') > -1 && customSections.map((v, i) =>
                                                <section key={'custom_' + i} className='driving-sec contact-information mt-3 selectable-section' onClick={_ => SelectableSectionClicked('custom-sections', navigate, onNavigatedToSection)}>
                                                    <div className='border-top-dashed'></div>
                                                    <div className='contact-information mt-3'>
                                                        <div className='row'>
                                                            <div className='col-5'>
                                                                <div className='mb-3'>
                                                                    <p className='text-uppercase fs-12'>
                                                                        <span className='title-bg-black fw-600'> {v.title} </span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className='col-7'>
                                                                <div className=''>
                                                                    <p className='sec-value mb-2 editor-list-margin-left' dangerouslySetInnerHTML={{ __html: v.content || '' }}></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </WrapperDiv>
        </>
    )
}

const WrapperDiv = styled.div`
& .title-bg-black{
    background-color:${(props: { additionalSettings: AdditionalSettings }) => !!props?.additionalSettings?.color && props?.additionalSettings?.color};
}
& .sec-value , .sec-value *{
    line-height:${(props: { additionalSettings: AdditionalSettings }) => !!props?.additionalSettings?.lineHeight && props?.additionalSettings?.lineHeight};
    font-size:${(props: { additionalSettings: AdditionalSettings }) => !!props?.additionalSettings?.fontSize && props?.additionalSettings?.fontSize + " !important"};
   
}

& * {
    font-family:${(props: { additionalSettings: AdditionalSettings }) => !!props?.additionalSettings?.fontFamily
        &&
        props?.additionalSettings?.fontFamily === "Classic" ? "Noto Serif, serif;" :
        props?.additionalSettings?.fontFamily === "Modern" ? "Rubik, sans-serif;" :
            props?.additionalSettings?.fontFamily === "Elegant" ? "Libre Baskerville, serif;" :
                props?.additionalSettings?.fontFamily === "Tech" ? "Roboto Mono, monospace;" :
                    props?.additionalSettings?.fontFamily === "Professional" ? "Bitter, serif;" : "default;"}
}
`
