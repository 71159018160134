import React, { useState, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button } from "react-bootstrap";
import Table from 'react-bootstrap/Table';
import { FaPrint } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { config } from '../../Environments';
import { GetAxios } from '../../Shared';
import { toast } from "react-toastify";
import ReactToPrint from 'react-to-print';
import { AiOutlineClose } from "react-icons/ai";
import { useTranslation } from 'react-i18next';

const InvoiceModal = (props: {

    invoiceNo: string,
    invoiceId: Number
}) => {

    const { t } = useTranslation('company');

    const [interest, setInterest] = useState(false);
    const [data, setData] = React.useState<CompanyInvoiceView>();
    const handleClose = () => setInterest(false);
    const handleShow = () => setInterest(true);
    const navigate = useNavigate();
    let url = config.apiBase + '/api/Companies/GetInvoice?invoiceId=' + props.invoiceId;
    GetAxios(navigate).get(url).then(res => {
        setData(res.data.data);

    });
    const handleSend = () => {
        let url = config.apiBase + '/api/Companies/SendInvoice?invoiceId=' + props.invoiceId;
        GetAxios().post(url).then(res => {
            toast(res.data.message, { type: res.data.success ? 'success' : 'error' });

        });

    }

    const componentRef = useRef<any>();

    return (
        <div>
            {

                <span className="text-decoration-none fw-500 text-blue cursor-pointer"
                    onClick={() => {
                        handleShow();
                    }}>
                    {props.invoiceNo}
                </span>
            }
            <Modal show={interest} onHide={handleClose} dialogClassName="modal-8inch-wprint">
                {/* <Modal.Header closeButton onClick={handleClose} className="p-4 ps-5">
                    <div className='d-flex align-items-center'>
                        <Modal.Title>Invoice: </Modal.Title>
                        <p className='ms-3 mb-0'>N. <span className='letter-space-3'>{data?.invoiceNo}</span></p>
                    </div>
                </Modal.Header> */}
                <Modal.Body className="p-0">
                    <div className='print-invoice-modal' ref={componentRef as any}>
                        <div className='d-flex align-items-center justify-content-between border-bottom border-2 mb-3 px-5'>
                            <div className='d-flex align-items-center my-2 print-mt-0'>
                                <Modal.Title className='fw-700'> {t('invoiceModal.invoice')}: </Modal.Title>
                                <p className='ms-3 mb-0 fs-12 fw-600'>N. <span className='letter-space-3 fs-12 fw-600'>{data?.invoiceNo}</span></p>
                            </div>
                            <div className='print-cross-hide cursor-pointer' onClick={handleClose}>
                                <AiOutlineClose size={26} className="fw-700" />
                            </div>
                        </div>
                        <div className="innersection p-5 pt-2">
                            <div className='header-set'>
                                <div className='d-flex justify-content-between gap-18'>
                                    <div>
                                        <div className='d-flex'>
                                            <div>
                                                <img src="/images/maestroicon.png" className="mb-1" alt="maestro icon" />
                                            </div>
                                            <div className='ms-3'>
                                                <h6 className='fw-700 text-uppercase mb-0 fs-12'>  {t('invoiceModal.maestroMedia')}</h6>
                                                <h6 className='fsize-13 fw-500'>  {t('invoiceModal.invoiceNumber')}</h6>
                                            </div>
                                        </div>
                                        <h6 className='my-2 fs-12'> {t('invoiceModal.parentCompanyOf')}:</h6>
                                        <img src="/images/bonjan-header-logo.png" className="invoice-sec-logo mob-logo" alt="bonjan job header logo" />
                                    </div>
                                    <div className='description'>
                                        <p className='fw-500 mb-0 text-uppercase fs-12'>  {t('invoiceModal.billTo')}:</p>
                                        <h6 className='fw-700 fw-bold mb-0 fsize-13'>{data?.companyName}</h6>
                                        <div className='address'>
                                            <p className='mb-0 fsize-13' dangerouslySetInnerHTML={{ __html: data?.companyAddress || '' }}>
                                            </p>
                                            <h6 className='fw-700 fw-bold mb-0 fsize-13'>{data?.firstName} {data?.lastName}</h6>
                                            <p className='fw-500 mb-0 text-uppercase fsize-13'>{data?.phoneNumber}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='border-2 border-bottom my-3'></div>
                            </div>
                            <div className='invoice-detail'>
                                <div className='row'>
                                    <div className='col-5'>
                                        <div>
                                            <p className='fw-700 fw-bold mb-0 text-uppercase fs-12'>  {t('invoiceModal.date')}:</p>
                                            <p className='fsize-13 fw-500'>{data?.invoiceDate}</p>
                                        </div>
                                    </div>
                                    <div className='col-7'>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <div>
                                                <p className='fw-700 fw-bold mb-0 text-uppercase fs-12'> {t('invoiceModal.customer')} #:</p>
                                                <p className='fsize-13 fw-500'>{data?.customerNo}</p>
                                            </div>
                                            <div>
                                                <p className='fw-700 fw-bold mb-0 text-uppercase fs-12'> {t('invoiceModal.advertsing')} #:</p>
                                                <p className='text-uppercase fsize-13 fw-500'>{data?.advertisingNo}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row invoice-billing-bg mb-1'>
                                    <div className='col-4'>
                                    </div>
                                    <div className='col-8'>
                                        <div className='amount-detail'>
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <div>
                                                    <p className='mb-0 fw-600 fs-12'>  {t('invoiceModal.pleaseMakeCheckPayable')}</p>
                                                    <p className='fw-700 mb-0 text-uppercase fsize-13'>  {t('invoiceModal.maestroMedia')}</p>
                                                </div>
                                                <div>
                                                    <span className='fw-600 fsize-13'>{data?.totalAmount}</span>
                                                </div>
                                            </div>
                                            <div className='border-2 border-bottom my-2'></div>
                                        </div>
                                        <div className='amount-detail'>
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <div>
                                                    <p className='mb-0 fw-500 fsize-13'> {t('invoiceModal.previousBalance')}</p>
                                                </div>
                                                <div>
                                                <span className='fw-500 fsize-13'>$0.00</span>
                                                </div>
                                            </div>
                                            <div className='border-2 border-bottom my-2'></div>
                                        </div>
                                        <div className='amount-detail'>
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <div>
                                                    <p className='mb-0 fw-500 fsize-13'> {t('invoiceModal.receivedPayment')}</p>
                                                </div>
                                                <div>
                                                    <span className='fw-500 fsize-13'>{data?.paidAmount}</span>
                                                </div>
                                            </div>
                                            <div className='border-2 border-bottom my-2'></div>
                                        </div>
                                        <div className='amount-detail'>
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <div>
                                                    <p className='mb-0 fw-800 fsize-13'> {t('invoiceModal.balanceDue')} <span className='text-uppercase'>(usd)</span></p>
                                                </div>
                                                <div>
                                                    <span className='fw-800 fsize-13'>{data?.balanceDue}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='table-invoice'>
                                    <Table responsive className='mb-1'>
                                        <thead>
                                            <tr>
                                                <th className='fw-800 fs-12'>Term</th>
                                                <th className='border-start border-end border-2 ps-3 fw-800 fs-12'> {t('invoiceModal.product')}</th>
                                                <th className='text-center fw-800 fs-12'> {t('invoiceModal.amount')} </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className='invoice-tr-bg'>
                                                <td className='fw-600 fsize-13 py-1'>{data?.terms}</td>
                                                <td className='border-start border-end border-2 ps-3 py-1'>
                                                    <span className='fw-500 fsize-13'>{data?.description}</span>
                                                </td>
                                                <td className='text-center fw-500 fsize-13 py-1'>{data?.jobAmount}</td>
                                            </tr>
                                            <tr>
                                                <td className=''></td>
                                                <td className='border-start border-end border-2 ps-3 py-1'>
                                                    <span className='fw-700 text-uppercase fsize-13'> {t('invoiceModal.bonjanJobCom')} </span>
                                                </td>
                                                <td className='text-center'></td>
                                            </tr>
                                            <tr className='invoice-tr-bg'>
                                                <td></td>
                                                <td className='py-1'>
                                                    <span className='fw-800 fsize-13'> {t('invoiceModal.subtotal')} </span>
                                                </td>
                                                <td className='text-center fw-700 py-1'>
                                                    <span className='fsize-13'>{data?.jobAmount}</span>
                                                </td>
                                            </tr>
                                            <tr className='invoice-tr-bg'>
                                                <td></td>
                                                <td className='ps-3 py-1'>
                                                    <span className='fw-500'> {t('invoiceModal.taxes')} </span>
                                                </td>
                                                <td className='text-center py-1'>
                                                    <span className='fw-500 fsize-13'>{data?.tax}</span>
                                                </td>
                                            </tr>
                                            <tr className='invoice-tr-bg'>
                                                <td></td>
                                                <td className='ps-3 py-1'>
                                                    <span className='fw-500 fsize-13'> {t('invoiceModal.fees')} </span>
                                                </td>
                                                <td className='text-center py-1'>
                                                    <span className='fw-500 fsize-13'>{data?.fees}</span>
                                                </td>
                                            </tr>
                                            <tr className='invoice-tr-bg'>
                                                <td></td>
                                                <td className='ps-3 py-1'>
                                                    <span className='fw-700 fsize-13'> {t('invoiceModal.total')} <span className='text-uppercase'>(usd)</span></span>
                                                </td>
                                                <td className='text-center fw-700 py-1'>
                                                    <span className='fsize-13'>{data?.totalAmount}</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                    <div className='mb-2'>
                                        <div className='border-2 border-bottom my-3'></div>
                                        <div>
                                            <p className='fw-600 text-uppercase fs-13'> {t('invoiceModal.reference')}</p>
                                        </div>
                                        <div className='d-flex justify-content-between align-items-center invoice-tr-bg px-3 py-1'>
                                            <div>
                                                <p className='mb-0 fw-500 fsize-13'> {t('invoiceModal.taxes')} </p>
                                            </div>
                                            <div>
                                                <span className='fw-500 fsize-13'>{data?.tax}</span>
                                            </div>
                                        </div>

                                    </div>
                                    <div className='print-sec'>
                                        <div className='mb-1'>
                                            <div className='border-2 border-bottom my-2'></div>
                                            <div className='address w-lg-25 w-md-25 fsize-13'>
                                                <p className='mb-0'> {t('invoiceModal.maestroMedia')}</p>
                                                <p className='mb-0'> {t('invoiceModal.maestroAddress')} </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='print-btns'>
                                <ReactToPrint
                                    documentTitle={new Date().toDateString()}
                                    trigger={() =>
                                        <button type="submit" className="print-cross-hide btn button-default mt-3 border-0 print-invoice-btn">
                                            <FaPrint className="me-2" />  {t('invoiceModal.printToPdf')}
                                        </button>
                                    }
                                    content={() => componentRef.current}
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>

                {/* <Modal.Footer style={{ borderTop: '0px' }}>

                    <Button variant="danger" onClick={handleClose} className="rounded-0">
                        Close
                    </Button>
                </Modal.Footer> */}
            </Modal>
        </div>
    )
}



export default InvoiceModal;


export interface CompanyInvoiceView {
    invoiceId: Number,
    jobPostId: Number,
    companyId: Number,
    userId: string,
    invoiceNo: string,
    invoiceDate: string,
    companyName: string,
    companyAddress: string,
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber: string,
    companyregistrationNumber: string,
    companyLogo: string,
    paidDate: string,
    terms: string,
    jobAmount: string,
    totalAmount: string,
    balanceDue: string,
    tax: string,
    fees: string,
    paidAmount: string,
    paymentStatus: string,
    customerNo: string,
    advertisingNo: string,
    description: string
}
