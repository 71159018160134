import React, { useState, useEffect } from 'react';
import NavDropdown from 'react-bootstrap/NavDropdown';
import OutsideClickHandler from 'react-outside-click-handler';
import { FaSortDown } from "react-icons/fa";


function CustomNavBarDropdown(props: { children: any, title: any , color:any}) {
  const [show, setShow] = useState(false);

  return (
    
  <NavDropdown autoClose="inside" show={show} onMouseOver={() => setShow(true)} onMouseLeave={() => setShow(false)}   
    title={
      props.color===true && show===false?
    <span className="customRedDropDownTitle">
      {props.title}<FaSortDown size={12} 
      className="customRedDropDownIcon" style={{marginBottom:"8px"}}></FaSortDown></span>
    :
    <span>
    {props.title}<FaSortDown size={14} 
    style={{marginBottom:"8px"}}></FaSortDown></span>
    }
    className="mobile-toggle customBlackNavDropdown customDropdownNoArrow" 
    id="nav-dropdown-job">
      {props.children}
    </NavDropdown>
   
  )
}


 export default CustomNavBarDropdown