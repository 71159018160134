import React, { useState } from "react";
import { Button, Collapse } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import { FaAngleDown, FaAngleUp, FaFacebookF, FaLinkedin, FaMinus, FaPlus, FaTwitter } from "react-icons/fa";
import { HiUpload } from "react-icons/hi";
import { VscChromeClose } from "react-icons/vsc";
import { RotatingLines } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FacebookShareButton } from "react-share";
import { toast } from 'react-toastify';
import { config } from "../../../Environments";
import { GetAxios, UserRole } from "../../../Shared";
import { setGlobalLoading, setShowModel } from "../../../Store";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

export const JobItem = (props: {
    job: any,
    i: number
}) => {

    const { t: ct } = useTranslation('common');
    const { t: jt } = useTranslation('job');
    const { t: ts } = useTranslation('alert');
    const [gender, setGender] = useState('');
    const navigate = useNavigate();





    const item = props.job
    const index = props.i

    const handleShare = (type: string) => {
        const url = `${config.apiBase}/api/Jobs/ShareJob?id=${item.id}`;
        if (type === "FaceBook") {
            window.open(
                `https://www.facebook.com/sharer/sharer.php?href=${encodeURIComponent(url)}&u=${encodeURIComponent(url)}`, '_blank'
            );
        }
        else if (type === "LinkedIn") {
            window.open(
                `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}`, '_blank'

            );
        }
        else if (type === "Twitter") {
            window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent(item.title)}`, '_blank');

        }
    }
    const [applied, setApplied] = useState(false);
    const [expanded, setExpended] = useState(false);
    const [expanding, setExpending] = useState(false);
    const [jobDetail, setJobdetail] = useState<any>();
    const [modalShow, setModalShow] = useState(false);
    const dispatch = useDispatch();
    const [logo, setLogo] = useState('');
    const { i18n } = useTranslation();
    React.useEffect(() => {
        if (expanding) {
            dispatch(setGlobalLoading(true))
            GetAxios().get(config.apiBase + '/api/Jobs/PublicView?id=' + item.id).then(res => {
                setApplied(res.data?.data?.isAlreadyApplied || false);
                setJobdetail(res.data.data);
                setExpending(false);
                setExpended(true);

                dispatch(setGlobalLoading(false))
            })
        }

    }, [expanding]);

    const auth = useSelector((state: AppStore) => state.auth);
    const userRole = UserRole(auth?.raw?.access_token);
    React.useEffect(() => {
        if (auth.isAuthenticated) {
            GetAxios().get(config.apiBase + '/api/General/GetUserSex')
                .then(res => {
                    setGender(res.data.data || '');
                })
        }
    }, [gender]);
    React.useEffect(() => {

        if (i18n.language == 'fr' && item.isAdminCreated == true) {

            setLogo(config.apiBase + item.logo2);
        }
        else {

            setLogo(config.apiBase + item.logo);
        }

    }, [i18n.language]);

    return (

        <div className="card mb-4 mt-3 border-0 rounded-0 p-0">
            <div className="card-body">
                <div className="row g-0">
                    <div className="col-md-2 col-4">
                        <div className="text-center">

                            <Link to={'/company/profile?id=' + item.companyUserId} target='_blank' >
                                <img src={logo}
                                    className="img-fluid"
                                    alt="company profile image"
                                    onError={(e: any) => {
                                        e.target.src = '/images/ImgPlaceholder.png';
                                    }}
                                />
                            </Link>


                        </div>
                    </div>
                    <div className="col-md-10 col-8">
                        <div className="ms-3">
                            <div className="d-flex align-items-center justify-content-between flexcolumn-mob-start-j02">

                                <div>
                                    <h4 className="card-title mb-0 text-start">
                                        <Link to={"/job/jobpreview?id=" + item.id} state={{ from: "searchPremiumJob" }} title={ct('clickJob').toString()}
                                            className="text-decoration-none text-dark pointer-event preview-link"> {item.title}</Link>

                                    </h4>
                                </div>
                                <div className="mt-10px-j02">
                                    <h6 className="fw-600 mb-0 text-job-id">
                                        {jt('search.jobId')} {item.jobNo}
                                    </h6>
                                </div>

                            </div>
                            <Link to={"/job/jobpreview?id=" + item.id} state={{ from: "searchPremiumJob" }} title={ct('clickJob').toString()} className="card-subtitle text-decoration-none text-start pointer-event preview-sublink">
                                {item.sector}
                            </Link>

                            <p className="card-text pt-1 fs-14 text-start" dangerouslySetInnerHTML={{ __html: item?.shortDescription || '' }}>
                                {/* {convertToPlain()} */}
                            </p>
                        </div>
                    </div>


                    <div className="d-flex justify-content-end mb-2 mt-lg-1 mt-sm-2 m-top-mob">
                        <div className="accordion-item">
                            {
                                (userRole == 'Employee' || !userRole) &&
                                <Button className="border-0 btn-interest fw-600 rounded-0 me-4" onClick={() => {
                                    if (auth.isAuthenticated) {

                                        setModalShow(true)
                                    } else {
                                        localStorage.setItem("isPreviousPath", window.location.pathname);
                                        toast(ts('employee.applyAlert'), { type: 'error' });
                                        dispatch(setShowModel(true));
                                    }
                                }}>
                                    {gender == 'Female' ? ct('femaleImInterested') : ct('maleImInterested')}
                                </Button>
                            }
                            <ApplyPopupModal jobPostId={item.id} handleModalShow={modalShow} handleModalClose={() => setModalShow(false)} />

                        </div>
                        <div className="accordion-item">
                            <Button
                                className="border-0 button-default accordion-header SeeMore1 fw-600 rounded-0 text-nowrap"
                                onClick={() => {
                                    navigate("/job/jobpreview?id=" + item.id, { state: { from: "searchPremiumJob" } });

                                }}
                                aria-controls={"job-description" + index}
                            >
                                {ct('moreInfo')}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}



export const ApplyPopupModal = (props: any) => {

    const fileTypes = [
        "application/pdf",
        ".doc",
        ".docx",
        "application/msword",
        ".pdf",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "text/plain",
        "text/html",
    ];
    const [applied, setApplied] = useState(false);
    const [applying, setApplying] = useState(false);
    const [fileName, setFileName] = useState("");


    const { t: ct } = useTranslation('common');
    const { t: ts } = useTranslation('alert');

    const [resume, setSelectedFile] = useState("");
    function validFileType(file: any) {
        return fileTypes.includes(file.type);
    }
    function checkFileSize(number: any) {
        if (number < 1024) {

            return true;
        } else if (number >= 1024 && number < 1048576) {

            if (Number((number / 1024).toFixed(1)) > 500) {

                return false;
            }
            else {
                return true;
            }
        } else if (number >= 1048576) {

            return false;
        }
    }

    const onFileSelected = (e: any) => {
        if (e.target.files && e.target.files.length > 0) {
            if (validFileType(e.target.files[0])) {
                if (checkFileSize(e.target.files[0].size)) {
                    setSelectedFile(e.target.files[0])
                    setFileName(e.target.files[0].name);
                }
                else {
                    toast(ts('resume.fileSize'), { type: 'error' });
                }
            }
            else {
                toast(ts('resume.inValidFile'), { type: 'error' });
            }
        }

    }

    return (
        <>
            <Modal show={props.handleModalShow} onHide={props.handleModalClose} className="border-blue-resume">
                <Modal.Header style={{ borderBottom: '0px', display: 'block' }}>
                    <div className="d-flex align-items-center justify-content-between">
                        <div>
                            <Modal.Title className="fw-700"> {ct('selectResume')}

                            </Modal.Title>
                        </div>
                        <VscChromeClose onClick={props.handleModalClose} size={28} className="close-padd cursor-pointer" />

                    </div>
                </Modal.Header>
                <Modal.Body style={{ paddingTop: '0px', paddingBottom: '0px' }}>
                    <div className="text-center">
                        <div className="border-bottom border-dark border-1">
                        </div>
                        <div className="my-4">
                            <p className="text-start fw-500 fs-15"> {ct('uploadPdfText')} </p>
                            <div className="custom-file-button d-flex justify-content-center">
                                <label className="custom-label-files" htmlFor="raised-button-file">
                                    <span className="custom-file-button">
                                        <HiUpload size={26} /> {ct('uploadResume')}
                                    </span>
                                </label>

                                <input type="file" accept=".doc,.docx,application/msword,.pdf,application/pdf,text/plain,text/html" style={{ display: 'none' }}
                                    onChange={onFileSelected} id="raised-button-file" />
                                <input type="text" className="w-50 form-control" value={fileName} />

                            </div>
                        </div>
                        <div className="border-bottom border-dark border-1 mb-3">
                        </div>
                        <p className="float-start mb-0 fw-700 fs-18"> {ct('dontResumeOne')} <Link to="/resumes" className="text-decoration-none">{ct('here')}</Link> <span className="text-uppercase"> {ct('free')} </span></p>
                    </div>
                </Modal.Body>
                <Modal.Footer style={{ borderTop: '0px' }}>
                    <Button className="fs-20 rounded ptb-15 text-white bg-dark-blue border-0 fw-600"
                        disabled={applying || applied}
                        onClick={e => {
                            e.preventDefault();
                            const formData = new FormData();
                            formData.append('Id', props.jobPostId);
                            formData.append('Resume', resume);
                            if (fileName === "") {
                                toast(ts('resume.selectResume'), { type: 'error' });
                                return;
                            }
                            setApplying(true);
                            GetAxios().post(config.apiBase + '/api/JobApply/ApplyJob', formData
                                , { headers: { "Content-Type": "multipart/form-data" } },
                            ).then(res => {
                                if (res.data.success) {
                                    toast(ts('resume.applySuccess'), { type: 'success' });
                                    setApplying(false);
                                    setApplied(true);
                                } else {
                                    toast(ts('resume.alreadyApply'), { type: 'warning' })
                                    setApplying(false);
                                    setApplied(true);
                                }
                            }).catch(err => {
                                if (err.status == 401 || err.status == 403) {
                                    toast(ts('resume.onlySeeker'), { type: 'error' });
                                }
                                setApplying(false);
                            });
                        }}>{applying ? <RotatingLines width="20" /> : applied ? <div>{ct('applied')}</div> : <div>{ct('apply')}</div>}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}