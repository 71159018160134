import React, { useState } from 'react';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { RotatingLines } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
import { SignInManager } from '../../Auth/SignInManager';
import { parseJwt } from '../../Shared';
import { setSignout } from '../../Store';
import LoginPoup from '../LoginPage/Index';
import { LeftSideMenu } from './LeftSideMenu';
import Form from 'react-bootstrap/Form';
import { setPlan } from '../../Store';

import { useTranslation } from 'react-i18next';

function Topbar() {
  const auth = useSelector((state: AppStore) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showLanguage, setShowLanguage] = useState(false);
  const [showAccountID, setShowAccountID] = useState(false);
  const [loggingout, setLoggingOut] = useState(false);
  const [role, setRole] = useState('');
  const [gender, setGender] = useState('');
  const [id, setId] = useState<string>('');
  const [name, setName] = useState<string>('');
  const { t } = useTranslation('home');
  const { i18n } = useTranslation();

  React.useEffect(() => {
    if (auth.isAuthenticated) {
      const parsedClaims = parseJwt(auth.raw?.access_token || '.');
      setGender(parsedClaims?.gender);
      setRole(parsedClaims?.role);

      if (parsedClaims?.role == 'Employer') {

        setName(parsedClaims?.org || (parsedClaims.given_name + ' ' + parsedClaims.family_name));
        setId(parsedClaims?.employerNo)

      }

      else {

        setName(parsedClaims.given_name + ' ' + parsedClaims.family_name);
        setId(parsedClaims?.sub);
      }
    }
  }, [auth, auth.isAuthenticated]);



  return (
    <div>
      {
        auth.isAuthenticated == true && role == 'Employee' ?
          <div>
            <LeftSideMenu />
          </div>
          : null}
      <div className="notification-section text-right mobile-header d-flex justify-content-between align-items-center container">

        <div className="logo-section d-flex align-items-center">
          <Link to="/">
            <img src="/images/bonjan-header-logo.png" className="header-logo mob-logo" alt="Bonjan Header Logo" />
          </Link>
          {
            auth.isAuthenticated &&
            <></>
            // <div className='d-flex align-items-center ms-2 border-start border-3'>
            //   <span className="border-start border-3 me-3">
            //   </span>
            //   <img src='/images/balls-account.png' className='w-40-account me-2' />
            //   <div className='align-self-center me-2'>
            //     <h6 className='text-white fw-600 text-uppercase mb-0 font-mob text-nowrap'>My Account</h6>
            //   </div>
            //   <OutsideClickHandler onOutsideClick={()=>setSettings(false)}>
            //     <NavDropdown autoClose="outside" title="My Account" id="" className='account-dropdown text-white fw-600 text-uppercase mb-0 font-mob text-nowrap'>
            //       <NavDropdown.Item className='fs-15' as={Link} to="/skill-matcher" >My Skill Matcher</NavDropdown.Item>
            //       <NavDropdown.Item className='fs-15' as={Link} to="/job-matcher" >My Job Matcher</NavDropdown.Item>
            //       <NavDropdown.Item className="setting-menu-box fs-15" onClick={() => setSettings(!settings)}>
            //         <div className="d-flex align-items-center justify-content-between">
            //           <span>Settings</span>
            //           {settings?
            //           <HiOutlineChevronUp />
            //           :
            //           <HiOutlineChevronDown />
            //           }

            //         </div>
            //       </NavDropdown.Item>
            //       <Collapse in={settings}>
            //         <div id="settings-menu-box">
            //           <ul className='settings-menu-list'>
            //             <li className='settings-menu-item'>
            //               <Link to="" className='fs-15 text-muted text-decoration-none'>Billing</Link>
            //             </li>
            //             <li className='settings-menu-item fs-15'>
            //               <Link to="" className='fs-15 text-muted text-decoration-none'>Payment Methods</Link>
            //             </li>
            //             <li className='settings-menu-item fs-15'>
            //               <Link to="/employer-account/order-history" className='fs-15 text-muted text-decoration-none' >Order History</Link>
            //             </li>
            //             <li className='settings-menu-item fs-15'>
            //               <Link to="/employer-account/upgrade-account" className='fs-15 text-muted text-decoration-none'>Upgrade</Link>
            //             </li>
            //           </ul>
            //         </div>
            //       </Collapse>
            //       <NavDropdown.Item className="setting-menu-box fs-15" onClick={() => setAccount(!account)}>
            //         <div className="d-flex align-items-center justify-content-between">
            //           <span>Account</span>
            //           {account?
            //           <HiOutlineChevronUp />
            //           :
            //           <HiOutlineChevronDown />
            //           }

            //         </div>
            //       </NavDropdown.Item>
            //       <Collapse in={account}>
            //         <div id="settings-menu-box">
            //           <ul className='settings-menu-list'>
            //             <li className='settings-menu-item'>
            //               <Link to="/employer-account/account-setting" className='fs-15 text-muted text-decoration-none'>My Profile</Link>
            //             </li>
            //             <li className='settings-menu-item fs-15'>
            //               <Link to="/account/changepassword" className='fs-15 text-muted text-decoration-none'>Change Password</Link>
            //             </li>
            //           </ul>
            //         </div>
            //       </Collapse>
            //     </NavDropdown>
            //   </OutsideClickHandler>
            // </div>
          }
        </div>

        <ul className="my-lg-0 mb-2 mobile-notification-bar d-flex justify-content-center align-items-center list-unstyled">
          {/* <li className="list-lang border-end fs-13 fw-6-white pe-2">
          <span className="list-lang fw-6-white">
            <NavDropdown title="FR" show={showLanguage} onMouseOver={() => setShowLanguage(true)} onMouseLeave={() => setShowLanguage(false)} 
             className="dropdown-menu-desk language-dropdown mob-language mt-0 rounded-0 fs-15 mob-dropdown1 fw-400" id="nav-dropdown-language" style={{ position: 'absolute' }}>
              <NavDropdown.Item className="language-list text-decoration-none fw-400 fs-15 text-white-01" as={Link} to="">
                EN
              </NavDropdown.Item>
              <NavDropdown.Item className="text-decoration-none fw-400 fs-15 language-list text-white-01" as={Link} to="">
                DE
              </NavDropdown.Item>
              <NavDropdown.Item className="language-list text-decoration-none fw-400 fs-15 text-white-01" as={Link} to="">
                ES
              </NavDropdown.Item>
              <NavDropdown.Item className="language-list text-decoration-none fw-400 fs-15 text-white-01" as={Link} to="">
                IT
              </NavDropdown.Item>
            </NavDropdown>
          </span>
        </li> */}
          <li className='border-end fw-6-white pe-2'>
            <Form.Select onChange={e => i18n.changeLanguage(e.target.value)} value={i18n.language} className='fw-600 custom-multilanguage-select'>
              <option value="fr" className="text-uppercase fw-600 fs-14 text-dark">Fr</option>
              <option value="en" className="language-list text-uppercase fw-600 fs-14 text-dark">En</option>
            </Form.Select>
          </li>
          <li className="px-2">
            <LoginPoup />
          </li>
          {/* {
          auth.isAuthenticated == false && loggingout == false ? <>
            <li className="m-top-3" style={{ width: '100px' }}>
              <NavDropdown autoClose={true} title="Register" id="basic-nav-dropdown" className='fw-6-white welcome-dropdown ms-2'>
                <NavDropdown.Item className='cursor-no'><b>Please register as:</b></NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item as={Link} className='wel-color' to="/candidate/register">Job seeker</NavDropdown.Item>
                <NavDropdown.Item className='cursor-no'><b>or</b></NavDropdown.Item>
                <NavDropdown.Item as={Link} className='wel-color' to="/company/registration">Employer</NavDropdown.Item>
              </NavDropdown>
            </li>
          </> : null
        } */}

          {
            auth.isAuthenticated == false ?
              loggingout ?
                <RotatingLines
                  strokeColor="white"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="20"
                  visible={true}
                /> : null : null
          }
          {
            auth.isAuthenticated == true && role == 'Employee' ?
              <li style={{ marginTop: '4px' }}>
                <NavDropdown align={{ lg: 'end', md: 'end', sm: 'end' }} autoClose="outside" title={name} id="basic-nav-dropdown" className='fw-400 text-white welcome-dropdown profile-icon-topbar ms-0 font-mob fs-16 text-uppercase fw-dropdown-right'>

                  <NavDropdown.Item title={id} className='cursor-no' onClick={() => setShowAccountID(showAccountID)}><small> {t('loginPage.accountNo')} {id.split('-')[0] || ""}</small></NavDropdown.Item>
                  <NavDropdown.Divider />
                  {/* <NavDropdown.Item as={Link} to="/jobseeker-account" className='text-dark'><small><b>Account</b></small></NavDropdown.Item>
                <NavDropdown.Item as={Link} className='wel-color' to="/resumes">My Resume(s)</NavDropdown.Item>
                <NavDropdown.Item as={Link} className='wel-color' to="/job-matcher">My Job Matcher</NavDropdown.Item>
                <NavDropdown.Item as={Link} className='wel-color' to="/skill-matcher">My Skills Matcher</NavDropdown.Item>
                <NavDropdown.Item as={Link} className='wel-color' to="/jobseeker-account">Account Settings</NavDropdown.Item>
                <NavDropdown.Divider /> */}
                  <NavDropdown.Item className='wel-color' onClick={async () => {
                    const manager = new SignInManager();
                    manager.RemoveToken();
                    dispatch(setSignout());
                    navigate('/');
                    localStorage.setItem("isLoggedIn", "false");

                  }}>
                    {t('loginPage.signOut')}
                  </NavDropdown.Item>
                </NavDropdown>
              </li> : null
          }

          {
            auth.isAuthenticated == true && role == 'Employer' ?
              <li style={{ marginTop: '4px' }}>
                <NavDropdown align={{ lg: 'end', md: 'end', sm: 'end' }} autoClose="outside" title={name} id="basic-nav-dropdown" className='fw-400 text-white welcome-dropdown profile-icon-topbar ms-0 font-mob fs-16 text-uppercase fw-dropdown-right'>
                  {/* {showAccountID && <NavDropdown.Item href="" title={id} className='cursor-no'><small>Account #: {id.split('-')[0] || ''}</small></NavDropdown.Item>} */}
                  <NavDropdown.Item href="" title={id} className='cursor-no'><small> {t('loginPage.accountNo')}: {id || ''}</small></NavDropdown.Item>
                  <NavDropdown.Divider />
                  {/* <NavDropdown.Item href="" className='cursor-no'><b>Account</b></NavDropdown.Item>
                <NavDropdown.Item as={Link} className='wel-color' to="/employer-account">My Ad(s)</NavDropdown.Item>
                <NavDropdown.Item as={Link} className='wel-color' to="/employer-account/account-setting">Account Settings</NavDropdown.Item>
                <NavDropdown.Item as={Link} className='wel-color' to="/employer-account/upgrade-account">Upgrade My Account</NavDropdown.Item>
                <NavDropdown.Item as={Link} className='wel-color' to="/employer-account/order-history">Order Hisory</NavDropdown.Item>
                <NavDropdown.Item as={Link} className='wel-color' to="/employer-account/pending-invoice">Pending Invoice(s)</NavDropdown.Item> */}
                  <NavDropdown.Item className='wel-color' onClick={async () => {
                    const manager = new SignInManager();
                    manager.RemoveToken();
                    dispatch(setSignout());
                    navigate('/');
                    localStorage.setItem("isLoggedIn", "false");
                  }}>
                    {t('loginPage.signOut')}
                  </NavDropdown.Item>
                </NavDropdown>
              </li> : null
          }
          {
            auth.isAuthenticated == true && role == 'Admin' ?
              <li style={{ marginTop: '4px' }}>
                <NavDropdown align={{ lg: 'end', md: 'end', sm: 'end' }} autoClose="outside" title={name} id="basic-nav-dropdown" className='fw-400 text-white welcome-dropdown profile-icon-topbar ms-0 font-mob fs-16 text-uppercase fw-dropdown-right'>

                  <NavDropdown.Item title={id} className='cursor-no' onClick={() => setShowAccountID(showAccountID)}><small> {t('loginPage.accountNo')} {id.split('-')[0] || ""}</small></NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item className='wel-color' onClick={async () => {
                    const manager = new SignInManager();
                    manager.RemoveToken();
                    dispatch(setSignout());
                    navigate('/admin');
                    localStorage.setItem("isLoggedIn", "false");
                  }}>
                    {t('loginPage.signOut')}
                  </NavDropdown.Item>
                </NavDropdown>
              </li> : null
          }

        </ul>

      </div>
    </div>

  )
}

export default Topbar
