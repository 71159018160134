import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';
// https://www.i18next.com/overview/configuration-options
import auth from './locales/en/auth.json';
import common from './locales/en/common.json';
import home from './locales/en/home.json';
import job from './locales/en/job.json';
import resume from './locales/en/resume.json';
import footer from './locales/en/footer.json';
import seminar from './locales/en/seminar.json';
import contact from './locales/en/contact.json';
import header from './locales/en/header.json';
import candidate from './locales/en/candidate.json';
import company from './locales/en/company.json';
import sendFeedback from './locales/en/sendFeedback.json';
import alert from './locales/en/alert.json';
import admin from './locales/en/admin.json';
import plan from './locales/en/plan.json';

import auth_fr from './locales/fr/auth.json';
import common_fr from './locales/fr/common.json';
import home_fr from './locales/fr/home.json';
import job_fr from './locales/fr/job.json';
import resume_fr from './locales/fr/resume.json';
import footer_fr from './locales/fr/footer.json';
import seminar_fr from './locales/fr/seminar.json';
import contact_fr from './locales/fr/contact.json';
import header_fr from './locales/fr/header.json';
import candidate_fr from './locales/fr/candidate.json';
import company_fr from './locales/fr/company.json';
import sendFeedback_fr from './locales/fr/sendFeedback.json';
import alert_fr from './locales/fr/alert.json';
import admin_fr from './locales/fr/admin.json';
import plan_fr from './locales/fr/plan.json';

export const defaultNS = 'common';

const en = {
    auth,
    common,
    home,
    job,
    resume,
    footer,
    seminar,
    contact,
    header,
    candidate,
    company,
    sendFeedback,
    alert,
    admin,
    plan
};

const fr: typeof en = {
    auth: auth_fr,
    common: common_fr,
    home: home_fr,
    job: job_fr,
    resume: resume_fr,
    footer: footer_fr,
    seminar: seminar_fr,
    contact: contact_fr,
    header: header_fr,
    candidate: candidate_fr,
    company: company_fr,
    sendFeedback: sendFeedback_fr,
    alert: alert_fr,
    admin:admin_fr,
    plan:plan_fr
};

export const resources = {
    fr,
    en
} as const;

i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        defaultNS,
        ns: 'common',
        resources,
        fallbackLng: 'fr',
        lng: 'fr', // default language
        debug: false,
        detection: {
            lookupQuerystring: 'lang',
            lookupFromPathIndex: 0,
            lookupLocalStorage: 'i18nextLng',
            caches: ['localStorage', 'cookie'],
        },
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
