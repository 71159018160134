import React, { useState } from "react";
import { config } from "../../../Environments";
import { CountrySelect, DatePickSelect } from '../../../Components';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams, useLocation } from 'react-router-dom';
import { GetAxios, } from "../../../Shared";
import Select from 'react-select';
const SideContent = (props) => {

    const { t } = useTranslation('company');
    const { t: ct } = useTranslation('common');
    const { t: jt } = useTranslation('job');
    const { i18n } = useTranslation();
    const [searchParams] = useSearchParams();
    const [logo, setLogo] = React.useState("/images/upload-cmpny-logo.jpg");
    const [classifications, setClassifications] = useState([]);
    const [departments, setDepartments] = useState([]);
    const formik = props.formik;
    const dropDown = props.dropDown;

    let myref = null;

    React.useEffect(() => {
        GetAxios().get(config.apiBase + '/api/Companies/GetCompanyNameLogo').then(res => {
            if (i18n.language == 'fr' && res.data?.companyId == 1) {


                setLogo(config.apiBase + res.data?.logo2);
            }
            else {

                setLogo(config.apiBase + res.data?.logo);
            }
            // setLogo(config.apiBase + res.data?.logo);
        })
    }, [i18n.language]);

    React.useEffect(() => {
        GetAxios().get(config.apiBase + '/api/General/GetDepartments').then(res => {
            setDepartments(res.data);
        })
    }, []);
    React.useEffect(() => {
        if (searchParams.get('tid')) {
            GetAxios().get(config.apiBase + '/api/General/GetClassifications?lang=' + formik.values.lang || i18n.language).then(res => {
                setClassifications(res.data);
            })
        }
        else {
            GetAxios().get(config.apiBase + '/api/General/GetClassifications?lang=' + i18n.language).then(res => {
                setClassifications(res.data);
            })
        }

    }, [i18n.language]);




    return (
        <>
            <div className="">
                <div className="card border-0 rounded-0">
                    <div className="profile-sec">
                        <img src={logo} id="imgOutput" className="card-img-top rounded-0" alt="image placeholder" onError={e => e.target.src = "/images/ImgPlaceholder.png"} />

                    </div>
                    <div className="card-body pb-5">
                        <div className="">
                            <div className="text-center mt-2 mb-4">
                            </div>
                            <div className="d-flex flex-column border-bottom">
                                <div className="d-flex align-items-center justify-content-between client-side-desc">
                                    <h5 className="text-color-33 fw-600 text-uppercase mb-2"> {t('callTender.tenderDetails')} </h5>

                                </div>
                            </div>
                            <div className="row pt-3">
                                <div className="col-5">
                                    <div>
                                        <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600"> {t('callTender.tenderTitle')}:</label>
                                    </div>
                                </div>
                                <div className="col-7 ps-3">
                                    <div>
                                        <span className="fw-12">
                                            <input type="text" name="title"
                                                onChange={formik.handleChange}
                                                value={formik.values.title}
                                                className="form-control border-0 job-regitration-input font-input" placeholder={t('callTender.tenderTitle1').toString()} />
                                            {formik.errors.title && formik.touched.title ? <small className="text-danger">{formik.errors.title}</small> : ""}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="row pt-3">
                                <div className="col-5">
                                    <div>
                                        <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600"> {t('callTender.companyName')}:</label>
                                    </div>
                                </div>
                                <div className="col-7 ps-3">
                                    <div>
                                        <span className="fw-12">
                                            <input type="text" name="companyName"
                                                onChange={formik.handleChange}
                                                value={formik.values.companyName}
                                                className="form-control border-0 job-regitration-input font-input" placeholder={t('callTender.companyName1').toString()} />
                                            {formik.errors.companyName && formik.touched.companyName ? <small className="text-danger">{formik.errors.companyName}</small> : ""}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="row pt-3">
                                <div className="col-5">
                                    <div>
                                        <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600"> {t('callTender.address')}:</label>
                                    </div>
                                </div>
                                <div className="col-7 ps-3">
                                    <div>
                                        <span className="fw-12">
                                            <input type="text" name="address"
                                                onChange={formik.handleChange}
                                                value={formik.values.address}
                                                className="form-control border-0 job-regitration-input font-input" placeholder={t('callTender.address1')} />
                                            {formik.errors.address && formik.touched.caddress ? <small className="text-danger">{formik.errors.address}</small> : ""}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="row pt-3">
                                <div className="col-5">
                                    <div>
                                        <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600"> {t('callTender.city')}:</label>
                                    </div>
                                </div>
                                <div className="col-7 ps-3">
                                    <div>
                                        <span className="fw-12">
                                            <input type="text" name="city"
                                                onChange={formik.handleChange}
                                                value={formik.values.city}
                                                className="form-control border-0 job-regitration-input font-input" placeholder={t('callTender.city1').toString()} />
                                          
                                        </span>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="row pt-3">
                                <div className="col-5">
                                    <div>
                                        <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600 text-nowrap">  {t('callTender.departments')}:</label>
                                    </div>
                                </div>
                                <div className="col-7 ps-3">
                                    <div>
                                        <select name='departmentId' value={formik.values.departmentId}
                                            onChange={formik.handleChange}
                                            className="form-select fw-12 form-select-sm">
                                            <option value="" className="fw-600"> {ct('pleaseSelect')} </option>
                                            {
                                                departments?.map((s, index) => (
                                                    <option key={'ddinde__' + index} value={s.key}>{s.value}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div> */}
                            <div className="row pt-3">
                                <div className="col-5">
                                    <div>
                                        <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600"> {t('callTender.tel')}:</label>
                                    </div>
                                </div>
                                <div className="col-7 ps-3">
                                    <div>
                                        <span className="fw-12">
                                            <input type="text" name="tel"
                                                onChange={formik.handleChange}
                                                value={formik.values.tel}
                                                className="form-control border-0 job-regitration-input font-input" placeholder={t('callTender.tel1').toString()} />
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="row pt-3">
                                <div className="col-5">
                                    <div>
                                        <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600"> {t('callTender.email')}:</label>
                                    </div>
                                </div>
                                <div className="col-7 ps-3">
                                    <div>
                                        <span className="fw-12">
                                            <input type="email" name="email"
                                                onChange={formik.handleChange}
                                                value={formik.values.email}
                                                className="form-control border-0 job-regitration-input font-input" placeholder={t('callTender.email1').toString()} />

                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="row pt-3">
                                <div className="col-5">
                                    <div>
                                        <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600"> {jt('job.contactPerson')}:</label>
                                    </div>
                                </div>
                                <div className="col-7 ps-3">
                                    <div>
                                        <span className="fw-12">
                                            <input type="text" name="contactPerson" onChange={formik.handleChange}
                                                value={formik.values.contactPerson} className="form-control border-0 job-regitration-input font-input" placeholder={jt('addJob.contactPerson1').toString()} />

                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="row pt-3">
                                <div className="col-5">
                                    <div>
                                        <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600 text-nowrap"> {jt('job.sectorOfActivity')}:</label>
                                    </div>
                                </div>
                                <div className="col-7 ps-3">
                                    <div>
                                        <select name="sectorId" onChange={formik.handleChange}
                                            value={formik.values.sectorId} className='form-select fw-12 form-select-sm'>
                                            <option value="" className="fw-600"> {ct('pleaseSelect')} </option>
                                            {
                                                (dropDown?.Sectors || []).map((v, index) => (
                                                    <option key={'sectors+___' + index} value={v.key}>{v.value}</option>
                                                ))
                                            }
                                        </select>
                                        {formik.errors.sectorId && formik.touched.sectorId ? <small className="text-danger">{formik.errors.sectorId}</small> : ""}

                                    </div>
                                </div>
                            </div>
                            <div className="row pt-3">
                                <div className="col-5">
                                    <div>
                                        <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600 text-nowrap"> {jt('job.classification')}:</label>
                                    </div>
                                </div>
                                <div className="col-7 ps-3">
                                    <div>
                                        <select name='classificationId' onChange={formik.handleChange} value={formik.values.classificationId} className="form-select fw-12 form-select-sm">
                                            <option value="" className="fw-600"> {ct('pleaseSelect')} </option>
                                            {
                                                classifications?.map((s, index) => (
                                                    <option value={s.key} key={'classss_' + index}>{s.value}</option>
                                                ))
                                            }
                                        </select>
                                        {formik.errors.classificationId && formik.touched.classificationId ? <small className="text-danger">{formik.errors.classificationId}</small> : ""}
                                    </div>
                                </div>
                            </div>


                            <div className="row pt-3">
                                <div className="col-12">
                                    <div>
                                        <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600"> {t('callTender.informationAboutRecurrence')}:</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row pt-3">
                                <div className="col-12 ps-3 fw-12">
                                    <div className='d-flex align-items-center mt-1'>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" checked={formik.values.recurrence === "Yes" ? true : false} type="radio" onChange={formik.handleChange} name="recurrence" id="inlineCheckbox1" value="Yes" />
                                            <label className="form-check-label key-weight" htmlFor="inlineCheckbox1"> {ct('yes')} </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" checked={formik.values.recurrence === "No" ? true : false} onChange={formik.handleChange} type="radio" name="recurrence" id="inlineCheckbox2" value="No" />
                                            <label className="form-check-label key-weight" htmlFor="inlineCheckbox2"> {ct('no')} </label>
                                        </div>
                                        {formik.errors.recurrence && formik.touched.recurrence ? <small className='text-danger d-block'>{formik.errors.recurrence}</small> : ""}
                                    </div>

                                </div>
                            </div>
                            <div className="row pt-3">
                                <div className="col-5">
                                    <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600 text-nowrap"> {t('callTender.dateOfDispatch')}</label>
                                    <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600 text-nowrap"> {t('callTender.ofThisTender')}:</label>
                                </div>
                                <div className="col-7">
                                    <div className="date-small-size ms-1">
                                        <DatePickSelect
                                            yearFrom={new Date().getFullYear()}
                                            yearTo={2030}
                                            maxDate={new Date(2030, 12, 31)}
                                            minDate={new Date()}
                                            placeHolder={t('callTender.dispatchDate').toString()}
                                            selected={formik.values.dispatchDate}
                                            onChange={(date) => { formik.setFieldValue("dispatchDate", date) }}
                                        />
                                    </div>
                                    {formik.errors.dispatchDate && formik.touched.dispatchDate ? <small className="text-danger">{formik.errors.dispatchDate}</small> : ""}

                                </div>
                            </div>

                            <div className="row pt-3">
                                <div className="col-5">
                                    <div>
                                        <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600 text-nowrap"> {t('callTender.conditionsForOpeningTenders')}:</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row pt-3">
                                <div className="col-5">
                                    <div>
                                        <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">
                                            {t('callTender.date')}:
                                        </label>
                                    </div>
                                </div>
                                <div className="col-7">
                                    <div className="date-small-size ms-1">
                                        <DatePickSelect
                                            yearFrom={new Date().getFullYear()}
                                            yearTo={2030}
                                            maxDate={new Date(2030, 12, 31)}
                                            minDate={new Date()}
                                            placeHolder='Date'
                                            selected={formik.values.openingDate}
                                            onChange={(date) => {

                                                formik.setFieldValue("openingDate", date)

                                            }}
                                        />
                                        {formik.errors.openingDate && formik.touched.openingDate ? <small className="text-danger">{formik.errors.openingDate}</small> : ""}

                                    </div>
                                </div>
                            </div>
                            <div className="row pt-3">
                                <div className="col-5">
                                    <div>
                                        <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">
                                            {jt('job.expiresOn')}:
                                        </label>
                                    </div>
                                </div>
                                <div className="col-7">
                                    <div>
                                        <div className="date-small-size ms-1">
                                            <DatePickSelect
                                                yearFrom={new Date().getFullYear()}
                                                yearTo={2030}
                                                maxDate={new Date(2030, 12, 31)}
                                                minDate={formik.values.openingDate == '' ? new Date() : new Date(formik.values.openingDate)}
                                                placeHolder={jt('job.expiresOn').toString()}
                                                selected={formik.values.expireDate}
                                                onChange={(date) => { formik.setFieldValue("expireDate", date) }}
                                            />
                                        </div>
                                        {formik.errors.expireDate && formik.touched.expireDate ? <small className="text-danger">{formik.errors.expireDate}</small> : ""}

                                    </div>
                                </div>
                            </div>
                            <div className="row pt-3">
                                <div className="col-5">
                                    <div>
                                        <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">
                                            {t('callTender.time')}:
                                        </label>
                                    </div>
                                </div>
                                <div className="col-7">
                                    <div className="d-flex justify-content-between ms-1">


                                        <select name='hour' onChange={formik.handleChange} value={formik.values.hour} className="w-35 form-select form-select-sm">

                                            {
                                                [...Array(24).keys()].map((v, index) => (
                                                    <option key={'eeddddd_prodf' + index}>{Number(v).toLocaleString('en-GB', {
                                                        minimumIntegerDigits: 2
                                                    })}</option>
                                                ))
                                            }
                                        </select>
                                        <div className="fw-bold">:</div>
                                        <select name='min' onChange={formik.handleChange} value={formik.values.min} className="form-select w-35 form-select-sm">

                                            {
                                                [0, 15, 30, 45].map(v => (
                                                    <option key={'state_v' + v}>{Number(v).toLocaleString('en-GB', {
                                                        minimumIntegerDigits: 2
                                                    })}</option>
                                                ))
                                            }
                                        </select>


                                    </div>
                                    {formik.errors.expireDate && formik.touched.expireDate ? <small className="text-danger">{formik.errors.expireDate}</small> : ""}

                                </div>
                            </div>
                            <div className="row pt-3">
                                <div className="col-5">
                                    <div>
                                        <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600 text-nowrap">  {t('callTender.departments')}:</label>
                                    </div>
                                </div>
                                <div className="col-7 ps-3">
                                    <div>
                                        <Select
                                            onChange={(e) => {
                                                formik.setFieldValue("departmentIds", Array.isArray(e) ? e.map(x => x.value) : [])


                                            }}
                                            placeholder={ct('pleaseSelect').toString()}
                                            value={departments.filter(obj => formik.values.departmentIds.includes(obj.value))} // set selected values
                                            className="basic-multi-select fw-12"
                                            isMulti
                                            defaultValue={departments[1]}
                                            options={departments}
                                        // classNamePrefix="select"
                                        />
                                        {formik.errors.departmentIds && formik.touched.departmentIds ? <small className="text-danger">{formik.errors.departmentIds}</small> : ""}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default SideContent;


