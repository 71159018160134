import * as React from 'react';
import { useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { FaArrowUp, FaTrashAlt } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { DatePickSelect } from '../../../../../Components';
import { ParseHTMLTips } from '../../../../../Components/Common/ParseTipsHTML';
import { config } from '../../../../../Environments';
import { GetAxios } from '../../../../../Shared';
import { setGlobalLoading } from '../../../../../Store';
import { setResumeActiveStep, setResumeContactInformation, setResumeImage, setResumeImageData } from '../../../../../Store/Slices/ResumeSlice';
import { ProfilePhotoTips } from '../../../Components/ProfilePhotoTips';
import TipsPopup from '../../../Components/TipsPopup';
import { BottomButtons } from '../BottomButtons';
import { useTranslation } from 'react-i18next';

function ContactInformation() {

    const { t } = useTranslation('resume');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { resumeId } = useParams();

    const resume = useSelector((state: AppStore) => state.resume);
    const information = resume.contactInformation;

    useEffect(() => {
        dispatch(setResumeActiveStep(3));
    }, [resume]);

    return (
        <>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='mb-4'>
                        <TipsPopup type="contact-information" title="contactStep.tips" description="contactStep.contactTipsMdl.description"
                            tipsList={ParseHTMLTips(contactTipsBody, t)} />
                    </div>
                    <div className="mb-3">
                        <div className="dZcjzV sections-header">
                            <div className="feVKyO"> {t('contactStep.contactTitle')} </div>
                        </div>
                        <div className="gJaquO"> {t('contactStep.contactDesc')}.</div>
                    </div>
                    <div className="feVKyO mb-2"> {t('steps.contactInformation').toString()} </div>
                </div>
                <div className='col-md-6'>
                    <div className='form-float'>
                        <div className="form-floating mb-3 rounded fw-500">
                            <input type="text" className="form-control rounded bg-field" placeholder={t('contactStep.chloe').toString()} value={information.firstName || ''} onChange={e => dispatch(setResumeContactInformation({ firstName: e.target.value }))} />
                            <label className='label-float'>  {t('contactStep.firstName')} </label>
                            <span className='helper-text ms-3'> {t('contactStep.egChloe')} </span>
                        </div>
                    </div>
                    <div className='form-float'>
                        <div className="form-floating mb-3 rounded fw-500">
                            <input type="text" className="form-control rounded bg-field" placeholder={t('contactStep.chloe').toString()} value={information.lastName || ''} onChange={e => dispatch(setResumeContactInformation({ lastName: e.target.value }))} />
                            <label className='label-float'> {t('contactStep.lastName')} </label>
                            <span className='helper-text ms-3'> {t('contactStep.egAnderson')} </span>
                        </div>
                    </div>
                </div>
                <div className='col-md-6 mb-4 mb-sm-4'>
                    <ImageUploader />
                </div>
            </div>
            <div className='wrapper'>
                <div className='form-float'>
                    <div className="form-floating mb-3 rounded fw-500">
                        <input type="text" className="form-control rounded bg-field" placeholder={t('contactStep.chloe').toString()} value={information.occupation || ''} onChange={e => dispatch(setResumeContactInformation({ occupation: e.target.value }))} />
                        <label className='label-float'>  {t('contactStep.occupation')} </label>
                        <span className='helper-text ms-3'> {t('contactStep.egSalesAssociate')} </span>
                    </div>
                </div>
                <div className='form-float'>
                    <div className="form-floating mb-3 rounded fw-500">
                        <input type="text" className="form-control rounded bg-field" placeholder={t('contactStep.chloe').toString()} value={information.address || ''} onChange={e => dispatch(setResumeContactInformation({ address: e.target.value }))} />
                        <label className='label-float'> {t('address')} </label>
                        <span className='helper-text ms-3'> {t('contactStep.egSalesAssociate')} </span>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-6'>
                    <div className='form-float'>
                        <div className="form-floating mb-3 rounded fw-500">
                            <input type="text" className="form-control rounded bg-field" placeholder='551 123-7676' value={information.phone || ''} onChange={e => dispatch(setResumeContactInformation({ phone: e.target.value }))} />
                            <label className='label-float'> {t('phone')} </label>
                            <span className='helper-text ms-3'>{t('contactStep.eg')}. {`(551 123-7676)`}</span>
                        </div>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='form-float'>
                        <div className="form-floating mb-3 rounded fw-500">
                            <input type="text" className="form-control rounded bg-field" placeholder={t('contactStep.andersonEmailCom').toString()} value={information.email || ''} onChange={e => dispatch(setResumeContactInformation({ email: e.target.value }))} />
                            <label className='label-float'> {t('email')} </label>
                            <span className='helper-text ms-3'>{t('contactStep.eg')}. {t('contactStep.andersonEmailCom')}</span>
                        </div>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='form-float'>
                        <div className="form-floating mb-3 rounded fw-500">
                            <input type="text" className="form-control rounded bg-field" placeholder={t('contactStep.chloe').toString()} value={information.nationality || ''} onChange={e => dispatch(setResumeContactInformation({ nationality: e.target.value }))} />
                            <label className='label-float'> {t('contactStep.nationality')}</label>
                            <span className='helper-text ms-3'> {t('contactStep.egAmerican')} </span>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 date-zindex">
                    <DatePickSelect
                        yearFrom={1930}
                        yearTo={new Date().getFullYear()}
                        minDate={new Date(1930, 1, 1)}
                        maxDate={new Date()}
                        placeHolder={t('dateOfBirth').toString()}
                        selected={new Date(information.dob || new Date())}
                        onChange={e => dispatch(setResumeContactInformation({ dob: e?.toISOString() }))}

                        customInput={<CustomDatePickerInput  placeholder={t('dateOfBirth').toString()} example={t('contactStep.eg').toString()} />}
                    />
                </div>
            </div>
            <div className='form-float'>
                <div className="form-floating mb-3 rounded fw-500">
                    <input type="text" className="form-control rounded bg-field" placeholder={t('contactStep.linkedinCom').toString()} value={information.linkedInLink || ''} onChange={e => dispatch(setResumeContactInformation({ linkedInLink: e.target.value }))} />
                    <label className='label-float'> {t('contactStep.link')} </label>
                    <span className='helper-text ms-3'>{t('contactStep.eg')}. {t('contactStep.linkedinCom')}</span>
                </div>
            </div>

            <BottomButtons onSubmit={e => {
                dispatch(setGlobalLoading(true));
                GetAxios(navigate, dispatch)
                    .patch(config.apiBase + '/api/Resume/SaveContactInformation', { ...information, id: resumeId })
                    .then(s => {
                        e();
                        dispatch(setGlobalLoading(false));
                    });
            }} />
        </>
    );
}

export default ContactInformation;

const CustomDatePickerInput = React.forwardRef<any, {
    value?: InputValue,
    placeholder?: string,
    example?:string,
    onClick?: React.MouseEventHandler<HTMLInputElement>,
    onChange?: React.ChangeEventHandler<HTMLInputElement>
}>(({ value, onClick, onChange , placeholder ,example},  ref) => (
    <div className='form-float'>
        <div className="form-floating mb-3 rounded fw-500">
            <input type="text" value={value || ''}   placeholder={placeholder} onChange={onChange} className="form-control rounded bg-field" onClick={onClick} ref={ref} />
            <label className='label-float'>{placeholder}</label>
            <span className='helper-text ms-3'>{example}. Oct 23 1985</span>
        </div>
    </div>
));

export const ImageUploader = () => {

    const { t } = useTranslation('resume');
    const { t :ts} = useTranslation('alert');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const resume = useSelector((state: AppStore) => state.resume);


    const { resumeId } = useParams();

    const ProfileImg = '/images/profile-photo-resume.svg';

    const imageSelectorRef = React.createRef<HTMLInputElement>();

    const handleBrowse = (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e?.preventDefault();
        imageSelectorRef.current?.click();
    };

    const onFileSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                dispatch(setResumeImage(reader.result?.toString()))
            });
            reader.readAsDataURL(e.target.files[0]);
            Save(e.target.files[0]);
        }
    }

    const removeImage = () => {
        dispatch(setGlobalLoading(true));
        GetAxios(navigate, dispatch).delete(config.apiBase + '/api/Resume/RemoveImage', {
            params: {
                resumeId
            }
        }).then(s => {
            dispatch(setResumeImage(''))
            dispatch(setGlobalLoading(false));
        }).catch(e => {
            dispatch(setGlobalLoading(false));
        })
    }

    const Save = (selectedImage?: File) => {
        dispatch(setGlobalLoading(true));
        const formData = new FormData();
        if (selectedImage) {
            formData.append('file', selectedImage);
        }
        formData.append('imageData', JSON.stringify(resume.imageData));
        GetAxios(navigate, dispatch).patch(config.apiBase + '/api/Resume/SetImage', formData, {
            params: {
                resumeId
            }
        }).then(s => {
            toast(ts('account.picUpdate'), { type: 'success' });
            dispatch(setGlobalLoading(false));
        }).catch(e => {
            dispatch(setGlobalLoading(false));
        });
    }

    return (
        <>
            <input className="hidden d-none" accept='image/*' ref={imageSelectorRef} type='file' onChange={onFileSelected} />
            <div className='d-flex'>
                <ImgIconBox onClick={handleBrowse}>
                    {resume.imageData.isRounded ?
                        <ImgBorder>
                            <Img src={resume.image ? resume.image : ProfileImg} className="mx-auto" onError={e => {
                                if (e.target instanceof HTMLImageElement) {
                                    e.target.src = ProfileImg
                                }
                            }} />
                        </ImgBorder>
                        :
                        <Img src={resume.image ? resume.image.toString() : ProfileImg} className="mx-auto" onError={e => {
                            if (e.target instanceof HTMLImageElement) {
                                e.target.src = ProfileImg
                            }
                        }} />
                    }
                </ImgIconBox>
                <div className='ms-3'>
                    <div className="d-flex justify-content-start">
                        <div className="cursor-pointer">
                            <h6 className='fw-600 mb-3'>  {t('contactStep.profilePhoto')} </h6>
                        </div>
                        <div className='ms-2'>
                            <ProfilePhotoTips />
                        </div>
                    </div>

                    {!!resume.image ?
                        <div className='d-flex mb-2'>
                            <div className='d-flex align-items-center cursor-pointer' onClick={() => removeImage()}>
                                <FaTrashAlt className='me-2 jss1800' />
                                <span className='fw-500 text-danger text-nowrap'> {t('contactStep.removePhoto')} </span>
                            </div>
                        </div>
                        :
                        <div className='d-flex mb-3'>
                            <div className='d-flex align-items-center cursor-pointer' onClick={handleBrowse}>
                                <FaArrowUp className='me-2 jss1766' />
                                <span className='fw-500 jss1766-text'>{t('contactStep.addPhoto')}</span>
                            </div>
                        </div>
                    }
                    <Form.Check
                        type="switch"
                        label={t('contactStep.roundPhoto').toString()}
                        className='switch-photo-label'
                        value={''}
                        checked={resume.imageData.isRounded || false}
                        onChange={() => {
                            dispatch(setResumeImageData({ isRounded: !resume.imageData.isRounded }));
                            Save();
                        }}
                    />
                </div>
            </div>
        </>
    )
}




const Img = styled.img`
width: 100%;
height: 158px;
min-width: 111px;
max-width: 111px;
`
const ImgIconBox = styled.div`
position: relative;
display: flex;
-webkit-box-align: center;
align-items: center;
-webkit-box-pack: center;
justify-content: center;
cursor: pointer;
flex: 0 0 auto;
overflow: hidden;
height: auto;
border-radius: 4px;
line-height: 0;
background: white;
text-align: center;
`

const ImgBorder = styled.div`
position: relative;
display: flex;
-webkit-box-align: center;
align-items: center;
-webkit-box-pack: center;
justify-content: center;
cursor: pointer;
flex: 0 0 auto;
overflow: hidden;
height: 111px;
border-radius: 100%;
line-height: 0;
background: white;
text-align: center;
`
const contactTipsBody = {
    "<b>Tips</b>": ['contactStep.contactTipsMdl.tipsList1', 'contactStep.contactTipsMdl.tipsList2', 'contactStep.contactTipsMdl.tipsList3', 'contactStep.contactTipsMdl.tipsList4', 'contactStep.contactTipsMdl.tipsList5', 'contactStep.contactTipsMdl.tipsList6'],
}