import React, { useState, useEffect } from 'react';
import { FaAngleDown, FaAngleUp, FaPlus, FaMinus, FaFacebookF, FaTwitter, FaLinkedin } from "react-icons/fa";
import Collapse from 'react-bootstrap/Collapse';
import { GetAxios } from '../../../Shared';
import { config } from '../../../Environments';
import { RotatingLines } from 'react-loader-spinner';
import { sort } from '../../../Components/Pagination/usePagination';
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import { toast } from "react-toastify";
import { useTranslation } from 'react-i18next';
import { SortList } from "../../../Components/Pagination/usePagination";

const MainContent = (props: { jobId: number }) => {

    const { t } = useTranslation('company');
    const { t: ct } = useTranslation('common');

    const [list, setList] = useState<ListType>({ title: '', applications: [] });
    const [sortCol, setSortCol] = useState('name');

    useEffect(() => {
        if (props.jobId > 0)

            GetAxios().post(config.apiBase + '/api/JobApply/GetApplications?id=' + props.jobId).then(res => {
                setList(res.data.data || { title: '', applications: [] });
            });

    }, [props, sortCol]);

    return (
        <>
            <div className="container">
                <div className="d-flex w-100 justify-content-between align-items-center">
                    <div className="tab-pane fade show active" id="company-application" role="tabpanel" aria-labelledby="company-application-tab">
                        <p className="text-start mb-0 h5 text-capitalize fw-600"> {t('compApplicant.applicantsFor')} : <span className="fw-normal ms-0 fw-16"> {list.title}</span> </p>
                    </div>
                    <select style={{ width: "200px" }} value={sortCol} onChange={e => setSortCol(e.target.value)} className="form-select form-control key-weight w-select-list float-end">
                        <option value={'name'}> {ct('name')} </option>
                        <option value={'createdDateUtc'}> {ct('date')} </option>
                    </select>
                </div>
            </div>
            <div className="client-description app-content">
                {
                    list.applications.length == 0 && <p className='text-danger ms-2 ps-1'> {t('compApplicant.noApplicantsFound')}</p>
                }
                {(SortList(list.applications, sortCol, 'asc')).map((item: Applications, index: number) => (
                    <JobItem key={'appll' + index} job={item} i={index} />
                ))}
                {/* 
                {(sort(list.applications, sortCol, 'asc')).map((item: Applications, index) =>
                    <JobItem key={'appll' + index} job={item} i={index} />
                )} */}
            </div>
        </>
    )
}
export default MainContent;

const JobItem = (props: { job: Applications, i: number }) => {
    const item = props.job;
    const index = props.i;
    const MaleAvatar = '/images/MalePlaceholder.png';
    const FemaleAvatar = '/images/FemalePlaceholder.png';
    const [show, setShow] = React.useState(false);
    const [expanding, setExpending] = React.useState(false);
    const [detail, setDetail] = React.useState<any>();
    const [modalShow, setModalShow] = useState(false);
    const convertToPlain = (html: string) => {
        var tempDiv = document.createElement("p");
        tempDiv.innerHTML = html;
        var result = (tempDiv.innerText.length >= 160) ? tempDiv.innerText.substring(0, 100) + "..." : tempDiv.innerText;
        return result;


    }

    const downloadFile = (resume: string, name: string) => {

        const path = config.apiBase + resume;
    
        const filename = name.split(".")[0] + "." + resume.split(".")[1];
        fetch(path).then(response => {
            response.blob().then(blob => {
                const fileURL = window.URL.createObjectURL(blob);
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = filename;
                alink.click();


            })
        })
    }
    const handleShare = (type: string) => {
        const url = `${config.apiBase}/api/JobApply/ShareApplicant?id=${item.userId}`;


        if (type === "FaceBook") {
            window.open(
                `https://www.facebook.com/sharer/sharer.php?href=${encodeURIComponent(url)}&u=${encodeURIComponent(url)}`, '_blank'
            );


        }
        else if (type === "LinkedIn") {
            window.open(
                `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}`, '_blank'

            );
        }
        else if (type === "Twitter") {
            window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent(item.name)}`, '_blank');

        }


    }
    React.useEffect(() => {
        if (expanding)
            GetAxios().get(config.apiBase + '/api/Employee/ProfilePublicView?id=' + item.userId).then(res => {
                setDetail(res.data.data);
                setExpending(false);
                setShow(true);
            })
    }, [expanding]);

    const { t } = useTranslation('company');
    const { t: ct } = useTranslation('common');

    return (
        <div className="card rounded-0 border-0 mt-3 mb-3">
            <div className="card-body">

                <div className="row">
                    <div className="col-md-2 col-sm-4">
                        <div className="text-center mb-3">
                            <img src={config.apiBase + item.image} className="img-thumbnail rounded-0 border-0 p-0"
                                onError={(ev: any) => {
                                    ev.target.src = (item.gender === "Male") ? MaleAvatar : FemaleAvatar;

                                }} />
                        </div>
                        {/* <ul className="list-inline d-flex p-0 justify-content-center profile-icon">
                            <li><a className="text-decoration-none pointer-event profile-icon ms-1"><FaRegEye /></a></li>
                            <li><a className="text-decoration-none pointer-event ms-3"><FaBookmark /></a></li>
                            <li><a className="text-decoration-none pointer-event ms-4"><FaTrashAlt /></a></li>
                        </ul> */}
                    </div>
                    <div className="col-md-8 col-sm-4 p-lg-0">
                        <h4 className="card-title mb-0"><Link to={"/candidate/candidateprofile?id=" + item.userId} target='_blank' className="text-decoration-none text-dark pointer-event">{item.name}</Link></h4>
                        <Link to={"/candidate/candidateprofile?id=" + item.userId} target='_blank' className="pointer-event card-subtitle text-decoration-none">{convertToPlain(item.description)}</Link>
                        <div className="d-flex w-100 justify-content-between mt-3">
                            <div className="client-side-title text-uppercase">
                                <p className="mb-0 fw-600 fw-12"> {t('compApplicant.location')}: <span className="fsize-13 fw-normal ">{item.location}</span></p>
                                <p className="mb-0 fw-600 fw-12"> {t('compApplicant.nationality')}: <span className="fsize-13 fw-normal ">{item.nationality}</span></p>
                                {/* <p className="mb-0 fw-600 fw-12">Years of Experience: <span className="fsize-13 fw-normal ">{item.yearOfExpiry}</span></p> */}
                            </div>
                            <div className="client-side-title text-uppercase">
                                {/* <p className="mb-0 fw-600 fw-12">Employment Status: <span className="fsize-13 fw-normal ">{item.isEmployeed ? 'Employed' : 'Un Employed'}</span></p> */}
                                <p className="mb-0 fw-600 fw-12"> {ct('gender')}: <span className="fsize-13 fw-normal ">{item.gender}</span></p>
                                <p className="mb-0 fw-600 fw-12"> {t('compApplicant.availabilty')}: <span className="fsize-13 fw-normal ">{item.availabilty}</span></p>

                                {/* <p className="mb-0 fw-600 fw-12">Work Permit: <span className="fsize-13 fw-normal ">{item.workPermit || 'N/A'}</span></p> */}
                            </div>
                        </div>
                    </div>

                    {/* <div className="col-md-2 col-sm-2">
                        <p className="rating text-end me-2">{0}</p>
                    </div> */}
                    <Collapse in={show}>
                        <div id="company-profile1">
                            <div className="row p-3 pb-0">
                                {/* <div className="candidate-title mb-3 ps-0 mt-3">
                                    <p className="h3 text-uppercase">{detail?.description}</p>
                                </div> */}
                                <div className="candidate-sec p-0">
                                    <p className="card-para" dangerouslySetInnerHTML={{ __html: detail?.description || '' }}>

                                    </p>
                                </div>
                                <div className="candidate-sec p-0">
                                    <h5 className="text-color-33 fw-600 py-3 text-uppercase mb-3"> {ct('design')} </h5>
                                    {(detail?.candidateSkill || [])?.map((x: any, i: number) =>
                                        <Skill skill={x} index={i} key={'com__applica' + i} />
                                    )}

                                    <div className="mt-5">
                                        <h5 className="text-color-33 fw-600 text-uppercase mb-3"> {ct('additionalSkill')} </h5>
                                        <div className="d-flex pt-3 additional-requirement-btn">

                                            <ul className="list-unstyled list-inline-requirement">
                                                {
                                                    !!detail?.additionalSkils ? ((
                                                        detail?.additionalSkils || '').split(',') || []).map((item: any, index: number) =>
                                                            <li key={'li__ca_' + index}><a className="text-decoration-none me-1 rounded-0 pointer-event">{item}</a></li>
                                                        ) : ""}


                                            </ul>
                                        </div>
                                    </div>
                                </div>


                            
                                <div className="candidate-sec p-0">
                                    <div className="candidate-title mt-3">
                                        <ul className="list-inline d-flex p-0">
                                            <li><p className="h5 fw-bold me-2"> {ct('share')}:</p></li>
                                            <li> <a onClick={() => { handleShare("FaceBook") }} className="tags text-decoration-none mb-0 me-2 facebook-color pointer-event"> <FaFacebookF size={18} /></a></li>
                                            <li>  <a onClick={() => { handleShare("LinkedIn") }} className="tags text-decoration-none mb-0 me-2 linkedIn-color pointer-event"><FaLinkedin size={18} /></a></li>
                                            <li>  <a onClick={() => { handleShare("Twitter") }} className="tags text-decoration-none mb-0 me-2 twitter-color pointer-event"><FaTwitter size={18} /></a></li>


                                        </ul>
                                    </div>
                                </div>
                                {item.resume &&
                                    <div className="candidate-sec p-0">
                                        <div className="candidate-title mt-3">
                                            <ul className="list-inline d-flex p-0">
                                                <li><p className="h5 fw-bold me-2"> {ct('resume')} :</p></li>
                                                <li>   <button type="submit" className="border-0 button-default accordion-header rounded-0" onClick={() => setModalShow(true)}> {ct('preview')} </button>
                                                </li>
                                                <li className='ms-3'>  <button type="submit" onClick={() => { downloadFile(item.resume, item.originalCVName) }} className="border-0 button-default accordion-header rounded-0 mb-0 me-2"> {ct('download')} </button></li>


                                            </ul>
                                            <CVPreviewModal item={item.resume}   handleModalShow={modalShow} handleModalClose={() => setModalShow(false)} />

                                        </div>
                                    </div>
                                }

                            </div>
                        </div>
                    </Collapse>
                </div>
                <div className="text-end">
                    <div className="accordion-item mb-2 mt-3">
                        <button type="submit" className="border-0 button-default accordion-header SeeMore1 fw-600 rounded-0"
                            onClick={() => {
                                if (show)
                                    setShow(false);
                                else
                                    setExpending(true);
                            }}>
                            {expanding ? <RotatingLines width="20" /> : show ? (
                                <><FaAngleUp className="me-1" size={25} /> {ct('lessInfo')} </>
                            ) : (
                                <><FaAngleDown className="me-1" size={25} /> {ct('moreInfo')} </>
                            )}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
const Skill = (props: { skill: any, index: number }) => {
    const x = props.skill;
    const i = props.index;
    const [show, setShow] = React.useState(false);
    return (
        <>
            <div className="row mb-3">
                <div className="col-md-3">
                    <div>
                        <div className="d-flex">
                            <div className="col-2">
                                <a
                                    className="minus rounded-0 d-block text-dark border icon-collaps1 pointer-event"
                                    aria-expanded={show}
                                    aria-controls={"logodesign" + i}
                                    onClick={() => setShow(!show)}
                                >
                                    {show ? <FaMinus size={12} /> : <FaPlus size={12} />}
                                </a>
                            </div>
                            <div className="col-10">
                                <p className="mb-0 ms-3 fsize-13 fw-normal mt-1">
                                    {x.skillTitle}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-9 p-right-mobile">

                    <div className='d-flex'>
                        <div className='col-md-11 col-12'>
                            <div>
                                <div className="progress mb-2 rounded-0 mt-2">
                                    <div
                                        className="progress-bar"
                                        style={{ width: `${x.skillPercentage}%` }}></div>
                                </div>
                                <Collapse in={show}>
                                    <div>
                                        <p className="fs-14 fw-normal mb-1 lh-lg">
                                            {x.description}
                                        </p>
                                    </div>
                                </Collapse>
                            </div>
                        </div>

                        <div className='col-md-1'>
                            <span className="text-nowrap ms-2">{x.skillPercentage}%</span>
                        </div>
                    </div>

                </div>
            </div>
        </>

    );
}


export const CVPreviewModal = (props: any) => {

    const pdfUrl = config.apiBase + props.item;

    const { t } = useTranslation('company');

    return (
        <>
            <Modal show={props.handleModalShow} onHide={props.handleModalClose} animation={true} className='popup-modal-width top-cl vh-1000' size='lg'>
                <Modal.Header className='popup-gray-bg' closeButton>
                    <Modal.Title className='fw-700'> {t('compApplicant.previewResume')} </Modal.Title>
                </Modal.Header>
                <Modal.Body className='p-4'
                    style={{
                        maxHeight: 'calc(1000vh - 210px)',
                        overflowY: 'auto'
                    }}>

                    <div>
                        <embed
                            src={`${pdfUrl}#toolbar=0&navpanes=0&scrollbar=0`} width="100%" height="500"
                            onError={() => toast(`You've been logged out due to inactivity.`, { type: 'error' })} />
                    </div>
                   
                </Modal.Body>
            </Modal>
        </>
    )
}






type ListType = {
    title: string,
    applications: Applications[]
}

type Applications = {
    description: string,
    userId: string,
    name: string,
    resume: string,
    image: string,
    nationality: string,
    availabilty: string,
    workPermit: string,
    isEmployeed: boolean,
    location: string,
    gender: string,
    isMarried: boolean,
    yearOfExpiry: number,
    createdDateUtc: Date | string,
    originalCVName:string
};