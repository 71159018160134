import React, { useCallback, useMemo, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaCcMastercard, FaChevronCircleDown, FaCreditCard, FaEllipsisV, FaPaypal, FaPencilAlt, FaRegTrashAlt } from 'react-icons/fa';
import Modal from 'react-bootstrap/Modal';

export const OrderConfirmation = () => {

    const [cardNumber, setCardNumber] = useState<boolean>(false);
    const [payCheque, setPayCheque] = useState<boolean>(false);

    const handleCardNumber = () => {
        setCardNumber(true);
        setPayCheque(false);
    }
    const handlePayCheque = () => {
        setCardNumber(false);
        setPayCheque(true);
    }

    return (
        <>
            <div className='container pb-5'>
                <h2 className='fw-700 my-4'> Order Confirmation </h2>
                <div className='card my-4'>
                    <div className='row pb-4 p-3'>
                        <div className='col-md-8 mb-3'>
                            <div className='billing border border-1 p-3 mb-3'>
                                <div className='d-flex align-items-center justify-content-between'>
                                    <h6 className='fw-600'> Billing Information</h6>
                                    <FaChevronCircleDown size={18} className='text-blue' />
                                </div>
                                <div className='d-flex align-items-center justify-content-between'>
                                    <p className='fw-500 text-gray mb-0'> Expiress </p>
                                    <p className='fw-500 text-gray mb-0 cursor-pointer'>
                                        <Link to="" className=''>Edit</Link>
                                    </p>
                                </div>
                                <p className='fw-500 text-gray mb-0'> 25bis, 2eme ruelle Nazon, Port-au-Prince, Ouest </p>
                            </div>
                            <div className='billing border border-1 p-3'>
                                <div className='fs-14'>
                                    <div className='detail'>
                                        <div className='top-bottom'>
                                            <div className='row'>
                                                <div className='col-md-12 mb-2'>
                                                    <div className='payment-method'>
                                                        <div className='title'>
                                                            <h5 className='fw-700'> Payment Method </h5>
                                                            <div className='border border-top border-1 my-3'></div>
                                                        </div>
                                                        <div className="form-check my-2 d-flex align-items-center justify-content-start">
                                                            <input className="form-check-input" type="radio" name="card" id="inlineCheckbox2" value="card" />
                                                            <img src="/images/content/mastercard-img.png" alt="Master Icon" className='h-28 mx-1' />
                                                            <label className="form-check-label key-weight fs-21 fw-600" htmlFor="inlineCheckbox2"> Paid with 6045 </label>
                                                        </div>
                                                        <div className="form-check my-2 d-flex align-items-center justify-content-start">
                                                            <input className="form-check-input" type="radio" name="card" id="ending8040" value="card" />
                                                            <img src="/images/content/bank-img.png" alt="Bank Icon" className='h-22 mx-1' />
                                                            <label className="form-check-label key-weight fs-21 fw-600" htmlFor="ending8040"> Paid with Cheque </label>
                                                        </div>
                                                    </div>
                                                    {/* <div className='border border-top border-1 my-3'></div> */}
                                                </div>
                                                {/* <div className='col-md-12 col-12 mb-2'>
                                                    <div className='d-flex align-items-center justify-content-between'>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" checked={cardNumber} onChange={handleCardNumber} name="cardNumber" id="cardNumber1" />
                                                            <label className="form-check-label key-weight" htmlFor="cardNumber1"> Card Number </label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name="payCheque" checked={payCheque} onChange={handlePayCheque} id="payCheque1" />
                                                            <label className="form-check-label key-weight" htmlFor="payCheque1"> Pay By Cheque </label>
                                                        </div>
                                                        <div></div>
                                                    </div>
                                                </div> */}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='p-3 order-summary-bg'>
                                <h5 className='fw-700 my-2'> Order Summary </h5>
                                <div className='edit-sec'>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div>
                                            <p className='fsize-13 fw-500 mb-0'> 6 items </p>
                                        </div>
                                        <div>
                                            <p className='fsize-13 fw-500 mb-0'>
                                                <Link to="" className=''> Edit order </Link>
                                            </p>
                                        </div>
                                    </div>
                                    <div className='border border-top border-1 my-2'></div>
                                </div>
                                <div className='detail-order'>
                                    <div>
                                        <Link to="" className='fs-12'> View Offer Disclaminers </Link>
                                    </div>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div>
                                            <p className='fs-14 fw-500 mb-0'> Subtotal </p>
                                        </div>
                                        <div>
                                            <p className='fs-14 fw-500 mb-0'> $71.94 </p>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div>
                                            <Link to="" className='fs-14'> Taxes $ Fees </Link>
                                        </div>
                                        <div>
                                            <p className='fs-14 fw-500 mb-0'> $1.08 </p>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div>
                                            <Link to="" className='fs-14'> Have a promo code? </Link>
                                        </div>
                                    </div>
                                    <div className='border border-top border-1 my-2'></div>
                                    <div className='d-flex justify-content-between align-items-center mb-1'>
                                        <div>
                                            <h5 className='fw-500 mb-0'> Total (USD) </h5>
                                        </div>
                                        <div>
                                            <h5 className='fw-500 mb-0 text-blue'> $73.02 </h5>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div>
                                            <p className='fs-14 fw-500 mb-0'> Package Expiry Date: </p>
                                        </div>
                                        <div>
                                            <p className='fs-14 fw-500 mb-0'> 110/05/2023 </p>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div>
                                            <p className='fs-14 fw-500 mb-0'> Paid Amount: </p>
                                        </div>
                                        <div>
                                            <p className='fs-14 fw-500 mb-0'> $205.10 </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}