import React, { useState, useEffect } from "react";
import RangeSlider from 'react-bootstrap-range-slider';
import Collapse from 'react-bootstrap/Collapse';
import Button from 'react-bootstrap/Button';
import { FaCaretUp, FaCaretDown, FaBars, FaSearch } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { GetAxios, KeyPairValue, JobCountValue } from "../../Shared";
import { config } from "../../Environments";
import { useDispatch } from "react-redux";
import { setGlobalLoading } from '../../Store';
import { useTranslation } from 'react-i18next';

const Collapse_section = () => {
  const { t } = useTranslation('home');
  const { t: ct } = useTranslation('common');
  //const [value1, setValue1] = useState(2147483647);
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();
  const [sectors, setSectors] = useState<JobCountValue[]>([]);
  const [departments, setDepartments] = useState<JobCountValue[]>([]);
  const [Classification, setClassifications] = useState<JobCountValue[]>([]);
  const [workExperiences, setExperiences] = useState<KeyPairValue[]>([]);
  const [contractTypes, setContractTypes] = useState<KeyPairValue[]>([]);

  const [query, setQuery] = useState('');
  const [sector, setSector] = useState(0);
  const [dept, setDept] = useState(0);
  const [classId, setClassId] = useState(0);
  const [contract, setContract] = useState('');
  const [workExp, setWorkExp] = useState('');


  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const submit = () => {


    const params = new URLSearchParams();
    // params.append('days', value1.toString());
    params.append('query', query);
    params.append('workExperience', workExp);
    params.append('contractTypes', contract);
    params.append('classification', classId.toString());
    params.append('department', dept.toString());
    params.append('sector', sector.toString());
    navigate('/job/jobsearch?' + params.toString());
  }

  useEffect(() => {
    dispatch(setGlobalLoading(true));
    GetAxios(navigate).post(config.apiBase + '/api/General/GetListBySystemType',
      {
        lang: i18n.language,
        types: ['WorkExperience', 'ContractType']
      }

      // ['Sectors', 'WorkExperience', 'ContractType']
    ).then(res => {
      //setSectors(res.data.Sectors);
      setExperiences(res.data.WorkExperience);
      setContractTypes(res.data.ContractType);
      dispatch(setGlobalLoading(false));
    });
  }, [i18n.language]);

  const ResultCount = (sectId: any, departId: any, classfyId: any) => {
    GetAxios().post(config.apiBase + '/api/Jobs/ResultCounts', {
      lang: i18n.language,
      SectorId: sectId,
      DeptId: departId,
      ClassId: classfyId,
      IsPremium: false
    }).then(res => {
      setClassifications(res.data.data.classifications);
      setSectors(res.data.data.sectors);
      setDepartments(res.data.data.departments);
    })
  }

  useEffect(() => {

    ResultCount(sector, dept, classId);
    // GetAxios().get(config.apiBase + '/api/General/GetDepartments').then(res => {
    //   setDepartments(res.data);
    // });
    // GetAxios().get(config.apiBase + '/api/General/GetClassifications?lang='+i18n.language).then(res => {
    //   setClassifications(res.data);
    // });
  }, [i18n.language]);

  const onSearchChange = (id: any, type: string) => {
    if (type === "Sector") {
      setSector(id);
      ResultCount(id, dept, classId);
    }
    else if (type === "Department") {
      setDept(id);
      ResultCount(sector, id, classId);
    }
    else if (type === "Classification") {
      setClassId(id);
      ResultCount(sector, dept, id);
    }
  }



  return (
    <div className="slider-text slider-div2">
      <div className="carousel-caption d-md-block slider-div">
        <h1 className="slider-div-h1">{t('title')}</h1>
        <h2 className="text-uppercase mb-4 fw-normal fs-4 mob-h2">
          {t('subTitle')}
        </h2>
        <div className="bg-white p-4 p-form-mob">
          <div className="row">
            <div className="col-md-4 mobile-width d-flex">
              <div >
                <div className="accordion-item">
                  <Button
                    className="dropdown-search line-bar btn-collapsed accordion-header rounded-0 p-2 d-flex"
                    onClick={() => setOpen(!open)}
                    aria-controls="example-collapse-text"
                    aria-expanded={open}
                  >
                    {open === false ? <FaCaretDown className="fa-lg svg-black" /> : <FaCaretUp className="fa-lg svg-black" />}

                    <FaBars className="fa-lg svg-black" />
                  </Button>
                </div>
              </div>
              <input
                type="text"
                className="form-control ms-3 key-weight"
                onChange={e => setQuery(e.target.value)}
                placeholder={ct('keywords').toString()} />
              <div className="d-lg-none d-xl-block d-xl-none d-md-none">
                <button
                  type="submit"
                  className="bg-btn ms-3 d-inline-flex p-2 border-0 align-items-center"
                  onClick={submit}>
                  <FaSearch className="me-2 mt-lg-1 fa-lg" />
                  <span className="d-none d-sm-block d-md-none d-lg-inline fw-400">
                    {ct('search')}
                  </span>
                </button>
              </div>
            </div>
            <div className="col-md-4 mobile-width d-flex mobile-none mt-10px-j02">
              <div className="hsb-text-1 text-muted">
                {t('in')}
              </div>
              <select
                className="form-select form-control ms-3 key-weight margin-mob"
                onChange={(e) => { onSearchChange((Number(e.target.value)), "Sector"); }}>
                <option value={0}>{ct('selectSector')}</option>
                {
                  sectors.map((v, i) => (
                    <option key={"_sector" + i} value={v.id}>{v.name} ({v.postedJobCount})</option>
                  ))
                }
              </select>
            </div>
            <div className="col-md-4 mobile-width d-flex mobile-none mt-10px-j02">
              <select
                className="form-select form-control key-weight mobile-none m-left"
                onChange={(e) => { onSearchChange((Number(e.target.value)), "Department"); }}>
                <option value={0}>{ct('selectDepartment')}</option>
                {
                  departments.map((v, i) => (
                    <option key={"_dept" + i} value={v.id}>{v.name} ({v.postedJobCount})</option>
                  ))
                }
              </select>
              <div className="desktop-search">
                <button
                  type="submit"
                  className="bg-btn ms-3 h-70 d-inline-flex p-2 rounded-0 border-0"
                  onClick={submit}>
                  <FaSearch className="me-2 mt-lg-1 fa-lg" />
                  <span className="d-none d-sm-block d-lg-inline fw-600">
                    {ct('search')}
                  </span>
                </button>
              </div>
            </div>
          </div>
          <Collapse in={open}>
            <div>
              <div className="row">
                <div className="advance-form pb-0">
                  {/* <div className="row mb-1 mx-lg-3 align-items-center">
                    <label
                      className="col-sm-3 col-form-label text-dark text-start form-lable-collape mt-2">
                         Jobs Posted Within
                    </label>
                    <div className="col-sm-9 mb-2 mt-3">
                      <RangeSlider
                        step={10}
                        value={value1}
                        onChange={changeEvent => { setValue1(Number(changeEvent.target.value)) }}
                        tooltipLabel={currentValue => { return `<${currentValue}` }}
                        min={10}
                        max={100}
                        tooltipPlacement='top'
                        tooltip='on'
                        itemType="range"
                        variant='primary'
                      />
                    </div>
                  </div> */}
                  <div className="row mb-0 mx-lg-3 mb-2">
                    <label
                      className="col-sm-3 ps-lg-5 ps-xl-5 col-form-label text-dark text-start form-lable-collape">
                      {t('classification')}
                    </label>
                    <div className="col-sm-9">
                      <select
                        className="form-select form-control key-weight"
                        onChange={(e) => { onSearchChange(Number(e.target.value), "Classification"); }}>
                        <option value={0}>{ct('selectClassification')}</option>
                        {
                          Classification.map((v, i) => (
                            <option key={"class_" + i} value={v.id}>{v.name} ({v.postedJobCount})</option>
                          ))
                        }
                      </select>
                    </div>
                  </div>
                  <div className="row mb-0 mx-lg-3 mb-2">
                    <label
                      htmlFor="inputPassword"
                      className="col-sm-3 ps-lg-5 ps-xl-5 col-form-label text-dark text-start form-lable-collape">
                      {t('contractType')}
                    </label>
                    <div className="col-sm-9">
                      <select
                        className="form-select form-control key-weight"
                        onChange={e => setContract(e.target.value)}>
                        <option value={''}>{ct('selectContractTypes')}</option>
                        {
                          contractTypes.map((v, i) => (
                            <option key={"contract_" + i} value={v.key}>{v.value}</option>
                          ))
                        }
                      </select>
                    </div>
                  </div>
                  <div className="row mb-0 mx-lg-3">
                    <label
                      className="col-sm-3 ps-lg-5 ps-xl-5 col-form-label text-dark text-start form-lable-collape">
                      {t('workExper')}
                    </label>
                    <div className="col-sm-9">
                      <select
                        className="form-select form-control key-weight"
                        onChange={e => setWorkExp(e.target.value)}>
                        <option value={''}>{ct('selectWorkExperiences')}</option>
                        {
                          workExperiences.map((v, i) => (
                            <option key={"workexp_" + i} value={v.key}>{v.value}</option>
                          ))
                        }
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Collapse>
        </div>
      </div>
    </div>
  );
};
export default Collapse_section;
