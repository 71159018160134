
import { GetAxios } from "../../../Shared";
import { config } from "../../../Environments";
import { setGlobalLoading } from "../../../Store";
import { Link, useSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch } from "react-redux";
import { FaPlayCircle } from "react-icons/fa";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useMemo, useEffect, useState } from 'react';
import { FaCcMastercard, FaChevronCircleDown, FaCreditCard, FaEllipsisV, FaPaypal, FaPencilAlt, FaRegTrashAlt } from 'react-icons/fa';
import { EditBillingInformation } from '../../AccountSetting/EditBillingInformation';


export const AnnouncePlanCheckout = () => {

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<any>({});
    const [searchParams] = useSearchParams();
    const { t: ts } = useTranslation('alert');
    const { t: ct } = useTranslation('common');
    const { t: t } = useTranslation('plan');
    const { i18n } = useTranslation();
    const navigate = useNavigate();
    const [selectedOption, setSelectedOption] = useState(0);
    const planschema = Yup.object().shape({
        paymentType: Yup.string().required(ts('account.required') || 'Required'),
        lang: Yup.string().required(ts('account.required') || 'Required'),
        planId: Yup.number().required(ts('account.required') || 'Required'),
        cardNumber: Yup.string().when("paymentType", {
            is: "CC",
            then: Yup.string().required(ts('account.required') || 'Required'),
            otherwise: Yup.string().notRequired(),

        }),
        expiryYear: Yup.string().when("paymentType", {
            is: "CC",
            then: Yup.string().required(ts('account.required') || 'Required'),
            otherwise: Yup.string().notRequired(),

        }),
        expiryMonth: Yup.string().when("paymentType", {
            is: "CC",
            then: Yup.string().required(ts('account.required') || 'Required'),
            otherwise: Yup.string().notRequired(),

        }),
        cVV: Yup.string().when("paymentType", {
            is: "CC",
            then: Yup.string().required(ts('account.required') || 'Required'),
            otherwise: Yup.string().notRequired(),

        }),

    });
    const formik = useFormik({
        initialValues: {
            planId: 0,
            paymentType: "CC",
            total: 0,
            email: "",
            currency: "",
            lang: i18n.language,
            isBackupPayment: false,
            cardType: "Card",
            cardNumber: "",
            nameOnCard: "",
            expiryYear: "",
            expiryMonth: "",
            cVV: "",
            paymentId: 0,
            billingId: 0,
            status: "UnPaid",
            transactionId: "",


        },
        validationSchema: planschema,
        onSubmit: values => {
            if (loading) {
                toast(ts('account.pleaseWait'), { type: 'info' });

            }
            if (values.billingId == 0 && values.paymentType != 'Cheque') {
                toast(ts('company.addBilling'), { type: 'error' });
                return;
            }
            setLoading(true);
            GetAxios().post(config.apiBase + '/api/Announce/ChargeAnnouncePlanPayment', values).then(res => {
                if (res.data.success) {

                    navigate({
                        pathname: '/employer-account/announce-plan-confirmation',
                        search: '?' + new URLSearchParams({ id: res.data.data }).toString(),
                    });
                    //params.push()
                    toast(ts('seminar.planSuccess'), { type: 'success' });
                } else {
                    console.warn(res);
                    toast(res.data.message, { type: 'error' });
                }
                setLoading(false);
            }).catch(err => {
                setLoading(false);
                console.error(err);
                toast(ts('error.wentWrong'), { type: 'error' });

            });
        },
    });
    const setBillingAddress = () => {
        GetAxios().get(config.apiBase + '/api/Payment/GetDefaultBillingDetail').then(res => {
            if (res.data.success) {
                setBilling(res.data.data)
                formik.setFieldValue("billingId", res.data.data.id)
                dispatch(setGlobalLoading(false));
            }
        });
    }
    const handlePaymentMethodChange = (e: any) => {
        setSelectedOption(e.target.value);
        console.log("PaymentId" + e.target.value)
        formik.setFieldValue("paymentId", e.target.value)
        formik.setFieldValue("paymentType", "Card");
    }

    const handlePaymentTypeChange = (e: any) => {
        setSelectedOption(0);
        console.log("paymentType" + e.target.value)
        formik.setFieldValue("paymentType", e.target.value)
        formik.setFieldValue("paymentId", 0);
    }


    const [billing, setBilling] = useState<any>();
    const [paymentMethods, setPaymentMethods] = useState<any>([]);

    const loadData = () => {
        if (searchParams.has('sId')) {
            formik.setFieldValue("planId", Number(searchParams.get('sId')));
            dispatch(setGlobalLoading(true));
            GetAxios().get(config.apiBase + '/api/Announce/GetAnnouncePlan?planId=' + searchParams.get('sId') + '&lang=' + i18n.language).then(res => {
                if (res.data.success) {

                    setData(res.data.data)
                    dispatch(setGlobalLoading(false));
                }
                else {
                    toast(res.data.message, { type: 'error' });
                    dispatch(setGlobalLoading(false));
                    navigate("/employer-account/announce-plans");
                }
            });
        }
        dispatch(setGlobalLoading(true));
        setBillingAddress();
        GetAxios().get(config.apiBase + '/api/Payment/GetPaymentMethods').then(res => {
            if (res.data.success) {
                setPaymentMethods(res.data.data)

                dispatch(setGlobalLoading(false));
            }
        });
    }

    React.useMemo(() => {

        loadData();

    }, [i18n.language]);




    return (
        <>
            <div className='container pb-5'>
                <h2 className='fw-700 my-4'> {t('common.orderCheckout')} </h2>
                <div className='card my-4'>
                    <div className='row pb-4 p-3'>
                        <div className='col-md-8 mb-3'>
                            <div className='billing border border-1 p-3 mb-3'>
                                <div className='d-flex align-items-center justify-content-between'>
                                    <h6 className='fw-600'> {t('common.billingInformation')}</h6>
                                    <FaChevronCircleDown size={18} className='text-blue' />
                                </div>
                                <div className='d-flex align-items-center justify-content-between'>
                                    <p className='fw-500 text-gray mb-0'> {billing?.firstName} {billing?.lastName} </p>
                                    <p className='fw-500 text-gray mb-0 cursor-pointer'>
                                        <EditBillingInformation id={billing?.id} loadData={setBillingAddress} />
                                    </p>
                                </div>
                                <p className='fw-500 text-gray mb-0'> {billing?.fullDetail} </p>
                            </div>
                            <div className='billing border border-1 p-3'>
                                <div className='fs-14'>
                                    <div className='detail'>
                                        <div className='top-bottom'>
                                            <div className='row'>
                                                <div className='col-md-12 mb-2'>
                                                    <div className='payment-method'>
                                                        <div className='title'>
                                                            <h5 className='fw-700'> {t('common.paymentMethod')} </h5>
                                                            <div className='border border-top border-1 my-3'></div>
                                                        </div>
                                                        {paymentMethods.map((item: any, index: any) => {
                                                            return (
                                                                <>
                                                                    <div className="form-check my-2 d-flex align-items-center justify-content-start" key={"methods" + index}>
                                                                        <input className="form-check-input" type="radio" name="paymentMethod" id={"paymentMethod" + index}
                                                                            checked={selectedOption == item.id}
                                                                            value={item.id} onChange={handlePaymentMethodChange} />
                                                                        <img src="/images/content/mastercard-img.png" alt="master card" className='h-28 mx-1' />
                                                                        <label className="form-check-label key-weight fs-21 fw-600" htmlFor="inlineCheckbox2"> {t('common.endingWith')} {item.cardNumberAsteriks} </label>
                                                                    </div>
                                                                </>
                                                            )
                                                        })}
                                                    </div>
                                                    <div className='border border-top border-1 my-3'></div>
                                                </div>
                                                <div className='col-md-12 col-12 mb-2'>
                                                    <div className='d-flex align-items-center justify-content-between'>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" checked={formik.values.paymentType === "CC" ? true : false} value="CC" name="paymentType" onChange={handlePaymentTypeChange} id="cardNumber1" />
                                                            <label className="form-check-label key-weight" htmlFor="cardNumber1"> {t('common.cardNumber')} </label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input" value="Cheque" id="payCheque1" checked={formik.values.paymentType == "Cheque" ? true : false} type="radio" name="paymentType" onChange={handlePaymentTypeChange} />
                                                            <label className="form-check-label key-weight" htmlFor="payCheque1"> {t('common.payByCheque')}  </label>
                                                        </div>
                                                        <div></div>
                                                    </div>
                                                </div>
                                            </div>
                                            {formik.values.paymentType == "CC" || selectedOption != 0 ?
                                                <>
                                                    <div className='row'>
                                                        {/* <div className='col-md-12 mb-2'>                                       
                                                               <input type="text" className="form-control key-weight" name='nameOnCard' onChange={formik.handleChange} value={formik.values.nameOnCard}  />
                                                                
                                                                {formik.errors.nameOnCard && formik.touched.nameOnCard ? <small className='text-danger'>{formik.errors.nameOnCard}</small> : ""}

                                                            </div> */}
                                                        <div className='col-md-12 mb-2'>
                                                            <div className="input-group">
                                                                <span className="input-group-text group-round"> <FaCreditCard /></span>
                                                                <input type="text" className="form-control key-weight" name='cardNumber' onChange={(e) => {
                                                                    const reg = /^[0-9\b]+$/;
                                                                    if (e.target.value === '' || reg.test(e.target.value)) {
                                                                        formik.setFieldValue("cardNumber", e.target.value);
                                                                    }
                                                                }} maxLength={16} value={formik.values.cardNumber} placeholder="**** **** **** ****" aria-describedby="cardNumber" />
                                                            </div>
                                                            {formik.errors.cardNumber && formik.touched.cardNumber ? <small className='text-danger'>{formik.errors.cardNumber}</small> : ""}

                                                        </div>
                                                        <div className='col-md-4 mb-2'>
                                                            <div className='line-height-sta'>
                                                                <label className="form-label key-weight" htmlFor="expiryYear"> {t('common.expirationDate')}: </label>
                                                            </div>
                                                            <select name="expiryMonth" onChange={formik.handleChange} value={formik.values.expiryMonth} className=' form-select'>
                                                                <option key={'month+___' + 1} value=""> {ct('pleaseSelect')} </option>
                                                                <option key={'month+___' + 2} value="01">01</option>
                                                                <option key={'month+___' + 3} value="02">02</option>
                                                                <option key={'month+___' + 4} value="03">03</option>
                                                                <option key={'month+___' + 5} value="04">04</option>
                                                                <option key={'month+___' + 6} value="05">05</option>
                                                                <option key={'month+___' + 7} value="06">06</option>
                                                                <option key={'month+___' + 8} value="07">07</option>
                                                                <option key={'month+___' + 9} value="08">08</option>
                                                                <option key={'month+___' + 10} value="09">09</option>
                                                                <option key={'month+___' + 11} value="10">10</option>
                                                                <option key={'month+___' + 12} value="11">11</option>
                                                                <option key={'month+___' + 13} value="12">12</option>
                                                            </select>
                                                            {formik.errors.expiryMonth && formik.touched.expiryMonth ? <small className='text-danger'>{formik.errors.expiryMonth}</small> : ""}

                                                        </div>
                                                        <div className='col-md-4 mb-2'>
                                                            <div className='line-height-sta invisible'>
                                                                <label className="form-label key-weight" htmlFor="expiryYear"></label>
                                                            </div>
                                                            <select name="expiryYear" onChange={formik.handleChange} value={formik.values.expiryYear} className='form-select mt-2'>
                                                                <option key={'years+___' + 0} value=""> {ct('pleaseSelect')} </option>
                                                                <option key={'years+___' + 1} value="2023">2023</option>
                                                                <option key={'years+___' + 2} value="2024">2024</option>
                                                                <option key={'years+___' + 3} value="2025">2025</option>
                                                                <option key={'years+___' + 4} value="2026">2026</option>
                                                                <option key={'years+___' + 5} value="2027">2027</option>
                                                                <option key={'years+___' + 6} value="2028">2028</option>
                                                                <option key={'years+___' + 7} value="2029">2029</option>
                                                                <option key={'years+___' + 8} value="2030">2030</option>
                                                                <option key={'years+___' + 9} value="2031">2031</option>
                                                                <option key={'years+___' + 10} value="2032">2032</option>
                                                            </select>
                                                            {formik.errors.expiryYear && formik.touched.expiryYear ? <small className='text-danger'>{formik.errors.expiryYear}</small> : ""}

                                                        </div>
                                                        <div className='col-md-4 col-12 mb-2'>
                                                            <div className='line-height-sta'>
                                                                <label className="form-label key-weight" htmlFor="securityCode"> {t('common.securityCode')}  </label>
                                                            </div>
                                                            <div className="input-group">
                                                                <input type="text" onChange={(e) => {
                                                                    const reg = /^[0-9\b]+$/;
                                                                    if (e.target.value === '' || reg.test(e.target.value)) {
                                                                        formik.setFieldValue("cVV", e.target.value);
                                                                    }
                                                                }}

                                                                    maxLength={3} value={formik.values.cVV} className="form-control key-weight" name='cVV' placeholder="***" aria-describedby="cVV" />
                                                                <span className="input-group-text group-round"> <FaCreditCard /></span>
                                                            </div>
                                                            {formik.errors.cVV && formik.touched.cVV ? <small className='text-danger'>{formik.errors.cVV}</small> : ""}

                                                        </div>
                                                    </div>
                                                </>
                                                : null}

                                            {formik.values.paymentType != "" && formik.values.paymentType == "Cheque" ?
                                                <>
                                                    <div className='mt-2 mb-3'>
                                                        <p className='fw-500 text-gray mb-0'>
                                                            {t('common.bankNote')}
                                                        </p>
                                                    </div>
                                                </>
                                                : null}

                                            <div className='row'>
                                                <div className='col-md-6 col-12 mb-2'>
                                                    <div className='d-grid gap-2'>
                                                        <button disabled={loading} type="submit" onClick={formik.handleSubmit as any} className="border-0 fs-18 p-btn btn-contine text-white fw-600">
                                                            {ct('submitOrder')}
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className='col-md-6 col-12 mb-2'>
                                                    <div className='d-grid gap-2'>
                                                        <button type="submit" onClick={() => { navigate("/employer-account/announce-plans") }} className="border-0 fs-18 p-btn button-danger text-white fw-600">
                                                            {ct('cancel')}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            {formik.values.paymentType == "CC" || selectedOption != 0 ?
                                                <>
                                                    <div className="form-check my-2 d-flex align-items-start">
                                                        <input className="form-check-input feedback-input" type="checkbox" name="isBackupPayment" onChange={e => { formik.setFieldValue('isBackupPayment', e.target.checked); formik.handleChange(e) }} checked={formik.values.isBackupPayment} id="defaultBackupCheck1" />
                                                        <label className="form-check-label fs-14 ms-2 fw-600" htmlFor="backupPayment">
                                                            {t('common.useBackupMethod')}
                                                        </label>
                                                    </div>
                                                </>
                                                : null}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='p-3 order-summary-bg'>
                                <h5 className='fw-700 my-2'> {t('common.orderSum')} </h5>
                                <div className='edit-sec'>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div>
                                            <p className='fsize-13 fw-500 mb-0'> {data.packageName} </p>
                                        </div>
                                        <div>
                                            <p className='fsize-13 fw-500 mb-0'>
                                                <Link to="/employer-account/announce-plans" className=''> {t('common.changePlan')} </Link>
                                            </p>
                                        </div>
                                    </div>
                                    <div className='border border-top border-1 my-2'></div>
                                </div>
                                <div className='detail-order'>

                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div>
                                            <p className='fs-14 fw-500 mb-0'> {t('common.credit')} </p>
                                        </div>
                                        <div>
                                            <p className='fs-14 fw-500 mb-0'> {data?.unlimited == true ? t('seminar.unlimited') : data?.noOfFlyers} </p>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div>
                                            <p className='fs-14 fw-500 mb-0'> {t('common.subTotal')} </p>
                                        </div>
                                        <div>
                                            <p className='fs-14 fw-500 mb-0'> {data?.packagePrice} </p>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div>
                                            <p className='fs-14 fw-500 mb-0 text-blue'>{t('common.taxfee')}  </p>
                                        </div>
                                        <div>
                                            <p className='fs-14 fw-500 mb-0'>{data?.tax} </p>
                                        </div>
                                    </div>
                                    <div className='border border-top border-1 my-2'> </div>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div>
                                            <h5 className='fw-500 mb-0'> {t('common.total')} (USD) </h5>
                                        </div>
                                        <div>
                                            <h5 className='fw-500 mb-0 text-blue'>{data?.totalPrice}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>





        </>
    )
}


