import { useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { TemplateList } from "../../../TemplateImageJSON";
import { useTranslation } from 'react-i18next';

export const TemplateSlider = (props: {
    onTemplateClick: (e: Step1TemplateSelection) => void
}) => {

    const { t } = useTranslation('resume');

    const templateId = useSelector((state: AppStore) => state.resume.templateId);

    const [showTemp, setShowTemp] = useState(false);

    const handleClose = () => setShowTemp(false);
    const handleShow = () => setShowTemp(true);

    return (
        <>
            <button className="me-1 temp-btn fw-600" onClick={handleShow}>
                <svg xmlns="http://www.w3.org/2000/svg" className="me-1 template-fill" width="24" height="24" viewBox="0 0 24 24">
                    <g fillRule="evenodd"><g><g><g><path d="M1 15c.55 0 1-.45 1-1v-4c0-.55-.45-1-1-1s-1 .45-1 1v4c0 .55.45 1 1 1zm3 2c.55 0 1-.45 1-1V8c0-.55-.45-1-1-1s-1 .45-1 1v8c0 .55.45 1 1 1zm18-7v4c0 .55.45 1 1 1s1-.45 1-1v-4c0-.55-.45-1-1-1s-1 .45-1 1zm-2 7c.55 0 1-.45 1-1V8c0-.55-.45-1-1-1s-1 .45-1 1v8c0 .55.45 1 1 1zM16.5 3h-9C6.67 3 6 3.67 6 4.5v15c0 .83.67 1.5 1.5 1.5h9c.83 0 1.5-.67 1.5-1.5v-15c0-.83-.67-1.5-1.5-1.5zM16 19H8V5h8v14z"
                        transform="translate(-268 -28) translate(256 24) translate(12 4)">
                    </path></g></g></g>
                    </g>
                </svg>
                {t('templates')}
            </button>

            <Offcanvas show={showTemp} onHide={handleClose} placement="bottom" className="template-slider-bottom">
                <Offcanvas.Header closeButton className='border-bottom-slider'>
                    <Offcanvas.Title className='text-uppercase text-center'> {t('findFavoriteTemplate')}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className='template-sliders'>
                        <Swiper
                            initialSlide={TemplateList.findIndex(s => templateId === s.id)}
                            // slidesPerView={8}
                            breakpoints={sliderBreakpoints}
                            centeredSlides
                            effect={'creative'}
                            modules={[Navigation]}
                            loop
                            navigation={{
                                enabled: true
                            }}
                            className="px-3">
                            {
                                TemplateList.map((item, index) =>
                                    <SwiperSlide key={'resume_' + item.id} onClick={_ => {
                                        props.onTemplateClick(item);
                                        handleClose();
                                    }}>
                                        <div className={"template-container-resume " + (templateId === item.id && 'active')} key={index}>
                                            <div className="img-cont">
                                                <img src={item.imgsrc} className="template-img-resume" />
                                                {
                                                    templateId === item.id &&
                                                    <img src='/blue-check.svg' className="image-active-icon" />
                                                }
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                )}
                        </Swiper>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

const sliderBreakpoints = {
    1600: {
        slidesPerView: 8,
        spaceBetween: 20
    },
    1200: {
        slidesPerView: 7,
        spaceBetween: 20
    },
    992: {
        slidesPerView: 6,
        spaceBetween: 20
    },
    760: {
        slidesPerView: 5,
        spaceBetween: 20
    },
    640: {
        slidesPerView: 4,
        spaceBetween: 20
    },
    510: {
        slidesPerView: 3,
        spaceBetween: 20
    },
    410: {
        slidesPerView: 2,
        spaceBetween: 20
    },
    320: {
        slidesPerView: 1,
        spaceBetween: 20
    },
};