import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import { FaCheck, FaLaptop, FaShareSquare, FaRegEnvelopeOpen } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { RotatingLines } from 'react-loader-spinner';
import { FaPlayCircle } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';

export const TenderPlans = () => {


    const { t: t } = useTranslation('plan');
    const navigate = useNavigate();




    return (
        <>

            <div className='container call-tender-sec mb-4'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='my-4 mx-auto text-center'>
                            <h2 className='fw-700 text-blue' dangerouslySetInnerHTML={{ __html: t('tender.planHeading')??"" }}>

                            </h2>
                        </div>
                    </div>
                </div>
                <div className='row py-3 px-5 g-4 g-lg-4 g-md-4'>
                    <div className='col-md-4 col-12'>
                        <div>
                            <Card className='upgrade-account-card position-relative'>
                                <div className='px-xl-3 px-lg-3 px-md-3'>
                                    <ListGroup variant="flush" className='list-group-border-none'>
                                        <ListGroup.Item>
                                            <div className='d-flex align-items-center justify-content-between py-3 mt-2'>
                                                <div>
                                                    <label className="form-check-label plan-label-radio" htmlFor="free">
                                                        3 {t('common.days')}
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="bor-bottom-plan-account mb-2">
                                            </div>
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <div>
                                                <h2 className='fw-800'>$249.00</h2>
                                            </div>
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <div className='d-flex align-items-start'>
                                                <div className='me-2'>
                                                    <FaLaptop size={18} className="plan-icon-color" />
                                                </div>
                                                <div>
                                                    <p className='mb-0 fw-600'>{t('tender.upload')} <span className='text-uppercase'>{t('tender.one')}</span> {t('tender.tender')}</p>
                                                </div>
                                            </div>
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <div className='d-flex align-items-start'>
                                                <div className='me-2'>
                                                    <FaShareSquare size={18} className="plan-icon-color" />
                                                </div>
                                                <div>
                                                    <p className='mb-0 fw-600'>{t('tender.abilityToShare')} <br /> Facebook</p>
                                                </div>
                                            </div>
                                        </ListGroup.Item>

                                    </ListGroup>
                                    <div className='plan-btn-box mb-3'>
                                        <button type="submit" onClick={() => {
                                            navigate("/employer-account/tender-plan-checkout?pId=1");
                                        }
                                        } className="border-0 btn-contine text-white fw-600 text-uppercase">
                                            {t('common.select')}
                                        </button>
                                    </div>
                                </div>
                            </Card>
                        </div>
                    </div>
                    <div className='col-md-4 col-12'>
                        <div>
                            <Card className='upgrade-account-card position-relative'>
                                <div className='account-badge-plan text-uppercase fw-600'>{t('common.mostPopular')}</div>
                                <div className='px-xl-3 px-lg-3 px-md-3'>
                                    <ListGroup variant="flush" className='list-group-border-none'>
                                        <ListGroup.Item>
                                            <div className='d-flex align-items-center justify-content-between py-3 mt-2'>
                                                <div>
                                                    <label className="form-check-label plan-label-radio" htmlFor="days">
                                                        10 {t('common.days')}
                                                    </label>
                                                </div>
                                                {/* <div>
                                                    <input className="form-check-input" type="radio" id="selectedPlan" name="selectedPlan" value="2"
                                                        checked={selectedPlan == 2 ? true : false}
                                                        onChange={handlePlanChange} />
                                                </div> */}
                                            </div>
                                            <div className="bor-bottom-plan-account mb-2">
                                            </div>
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <div className='me-2'>
                                                <h2 className='fw-800'>$399.00</h2>
                                            </div>
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <div className='d-flex align-items-start'>
                                                <div className='me-2'>
                                                    <FaLaptop size={18} className="plan-icon-color" />
                                                </div>
                                                <div>
                                                    <p className='mb-0 fw-600'>{t('tender.upload')} <span className='text-uppercase'>{t('tender.one')}</span> {t('tender.tender')}</p>
                                                </div>
                                            </div>
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <div className='d-flex align-items-start'>
                                                <div className='me-2'>
                                                    <FaShareSquare size={18} className="plan-icon-color" />
                                                </div>
                                                <div>
                                                    <p className='mb-0 fw-600'>{t('tender.abilityToShare')}<br />
                                                        Facebook, Linkedin,<br /> WhatsApp, Tweeter
                                                    </p>
                                                </div>
                                            </div>
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <div className='d-flex align-items-start'>

                                            </div>
                                        </ListGroup.Item>

                                    </ListGroup>
                                    <div className='plan-btn-box mb-3'>
                                        <button type="submit" onClick={() => {
                                            navigate("/employer-account/tender-plan-checkout?pId=2");
                                        }
                                        } className="border-0 btn-contine text-white fw-600 text-uppercase">
                                            {t('common.select')}
                                        </button>
                                    </div>
                                </div>
                            </Card>
                        </div>
                    </div>
                    <div className='col-md-4 col-12'>
                        <div>
                            <Card className='upgrade-account-card position-relative'>
                                <div className='px-xl-3 px-lg-3 px-md-3 '>
                                    <ListGroup variant="flush" className='list-group-border-none'>
                                        <ListGroup.Item>
                                            <div className='d-flex align-items-center justify-content-between py-3 mt-2'>
                                                <div>
                                                    <label className="form-check-label plan-label-radio" htmlFor="monthly">
                                                        30 {t('common.days')}
                                                    </label>
                                                </div>

                                            </div>
                                            <div className="bor-bottom-plan-account mb-2">
                                            </div>
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <div className='me-2'>
                                                <h2 className='fw-800'>$599.00</h2>
                                            </div>
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <div className='d-flex align-items-start'>
                                                <div className='me-2'>
                                                    <FaLaptop size={18} className="plan-icon-color" />
                                                </div>
                                                <div>
                                                    <p className='mb-0 fw-600 lh-base'>{t('tender.uploadupto')} <span className='text-uppercase'>{t('tender.two')}</span> {t('tender.tenders')}</p>
                                                </div>
                                            </div>
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <div className='d-flex align-items-start'>
                                                <div className='me-2'>
                                                    <FaShareSquare size={18} className="plan-icon-color" />
                                                </div>
                                                <div>
                                                    <p className='mb-0 fw-600 lh-base'>{t('tender.abilityToShare')} <br />
                                                        Facebook, Linkedin, <br />
                                                        WhatsApp, Tweeter
                                                    </p>
                                                </div>
                                            </div>
                                        </ListGroup.Item>

                                    </ListGroup>
                                    <div className='plan-btn-box mb-3'>
                                        <button type="submit" onClick={() => {
                                            navigate("/employer-account/tender-plan-checkout?pId=3");
                                        }
                                        } className="border-0 btn-contine text-white fw-600 text-uppercase">
                                            {t('common.select')}
                                        </button>
                                    </div>

                                </div>

                            </Card>

                        </div>
                    </div>


                </div>



            </div>
        </>
    )
}