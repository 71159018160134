import React, { useState, useEffect, useRef, useCallback } from 'react';
import { ContentState, convertFromHTML, convertToRaw, EditorState } from 'draft-js';
import Container from 'react-bootstrap/Container';
import { Row, Col } from 'react-bootstrap';
import Tagify from '@yaireo/tagify/dist/react.tagify';
import "@yaireo/tagify/dist/tagify.css" // Tagify CSS
import { useFormik, FieldArray, FormikProvider } from 'formik';
import Form from 'react-bootstrap/Form';
import * as Yup from "yup"
import { config } from '../../../Environments';
import { GetAxios, KeyPairValue, LabelValuePair } from '../../../Shared';
import { toast, ToastContainer } from 'react-toastify';
import { FaYoutube, FaFacebookF, FaGooglePlusG, FaTwitter, FaLinkedinIn, FaInstagram, FaTimes, FaMapMarkerAlt, FaSitemap } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { CountrySelect } from '../../../Components';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setGlobalLoading } from '../../../Store';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import { useTranslation } from 'react-i18next';


const openingHoursList = [
    {
        day: "Monday",
        fromHour: "09",
        fromMin: "00",
        toHour: "18",
        ToMin: "00",
        enabled: true
    },
    {
        day: "Tuesday",
        fromHour: "09",
        fromMin: "00",
        toHour: "18",
        ToMin: "00",
        enabled: true
    },
    {
        day: "Wednesday",
        fromHour: "09",
        fromMin: "00",
        toHour: "18",
        ToMin: "00",
        enabled: true
    },
    {
        day: "Thursday",
        fromHour: "09",
        fromMin: "00",
        toHour: "18",
        ToMin: "00",
        enabled: true
    },
    {
        day: "Friday",
        fromHour: "09",
        fromMin: "00",
        toHour: "18",
        ToMin: "00",
        enabled: true
    },
    {
        day: "Saturday",
        fromHour: "09",
        fromMin: "00",
        toHour: "18",
        ToMin: "00",
        enabled: false
    },
    {
        day: "Sunday",
        fromHour: "09",
        fromMin: "00",
        toHour: "18",
        ToMin: "00",
        enabled: false
    },
]




const Index = () => {

    const { t } = useTranslation('company');
    const { t: ct } = useTranslation('common');
    const { t: ts } = useTranslation('alert');
    const profileschema = Yup.object().shape({
        email: Yup.string().email(ts('signIn.inValidEmail') || "Invalid email address").required(ts('account.required') || 'Required')
            .test(
                "not-yopmail",
                ts('account.yopmail') || "not allowed",
                (value) => !value || !value.endsWith("@yopmail.com")
            ),
        phoneNumber: Yup.string().required(ts('account.required') || 'Required'),
        companyName: Yup.string().required(ts('account.required') || 'Required'),
        industry: Yup.string().required(ts('account.required') || 'Required'),
        department: Yup.number().min(1, ts('account.required') || 'Required'),
        sector: Yup.string().required(ts('account.required') || 'Required'),
        companyDescription: Yup.string().required(ts('account.required') || 'Required'),
        noOfEmployees: Yup.string().required(ts('account.required') || 'Required'),
        profitorNonProfit: Yup.string().required(ts('account.required') || 'Required'),
        lang: Yup.string().required(ts('account.required') || 'Required'),
    });
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [picture, setPicture] = useState('');
    // const [picture1, setPicture1] = useState('');
    const [picture2, setPicture2] = useState('');
    const [editorState, setEditState] = useState(EditorState.createEmpty());
    const [departments, setDepartments] = useState<LabelValuePair[]>([]);
    const [dropDowns, setDropDowns] = useState<any>();
    const { i18n } = useTranslation();

    let myref = useRef();
    //let myref1 = useRef();
    let myref2 = useRef();
    const formik = useFormik({
        initialValues: {
            companyName: "",
            department: 0,
            fax: "",
            email: "",
            phoneNumber: "",
            website: "",
            contactPerson: "",
            industry: "",
            sector: "",
            profitorNonProfit: "",
            companyDescription: "",
            noOfEmployees: "",
            legalEntity: "",
            logo: "",
            logo2: "",
            banner: "",
            companyRegistrationNumber: "",
            employerNo: "",
            service: '',
            aboutJob: '',
            lang: i18n.language,
            location: [{ country: "", city: "", zipCode: '', streetNo: "", streetNo2: '', streetNo3: '' }],
            // contacts: [{ name: "", email: "", tel: "", fax: "" }],
            openingHour: [],
            socialNetwork: {
                facebook: "",
                twitter: "",
                google: "",
                instagram: "",
                youtube: "",
                linkedin: ""
            }
        },
        validationSchema: profileschema,
        onSubmit: values => {
            GetAxios(navigate).put(config.apiBase + '/api/Employer/SaveProfile', values).then(res => {
                if (res.data.success) {
                    toast(ts('account.profileUpdate'), { type: 'success' });
                } else {
                    toast(ts('error.unSave'), { type: 'error' });
                }
            });
            //alert(JSON.stringify(values, null, 2));
        },
    });

    const onChangeofTags = useCallback((e: any) => {

        formik.setFieldValue('service', e.detail.value)
    }, [])

    useEffect(() => {
        dispatch(setGlobalLoading(true));
        GetAxios().get(config.apiBase + '/api/Employer/GetEditProfile').then(res => {
            setPicture(config.apiBase + res.data.data.logo);
            // setPicture1(config.apiBase + res.data.data.logo2);
            setPicture2(config.apiBase + res.data.data.banner);
            formik.setValues(res.data.data);
            const htmlContent = res?.data?.data?.companyDescription;
            const html = convertFromHTML(htmlContent || '');
            setEditState(EditorState.createWithContent(ContentState.createFromBlockArray(html.contentBlocks, html.entityMap)))
            // setEditState(res.data.data.companyDescription) 

            dispatch(setGlobalLoading(false));
        });
    }, []);

    useEffect(() => {
        // ['Industry', 'Sectors', 'NOEmployees']).then(res => {
        //     setDropDowns(res.data);
        // });
        GetAxios().post(config.apiBase + '/api/General/GetListBySystemType',
            {
                lang: i18n.language,
                types: ['Industry', 'Sectors', 'NOEmployees']
            }).then(res => {
                setDropDowns(res.data);
            });
        //again set opening hours list
        GetAxios().get(config.apiBase + '/api/Employer/GetEmployerCompanyHours?lang=' + i18n.language).then(res => {
            formik.setFieldValue('openingHour', res.data.data.openingHour);
        });

    }, [i18n.language]);

    useEffect(() => {
        formik.setFieldValue('lang', i18n.language);
    }, [i18n.language]);

    const onFileSelected = (e: any) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                if (e.target.name === 'companylogo') {
                    setPicture(reader.result?.toString() || '');
                    const formData = new FormData();
                    formData.append('image', e.target.files[0])
                    GetAxios().patch(config.apiBase + '/api/Employer/UpdateLogo', formData).then(res => {
                        if (res.data.success) {
                            toast(ts('account.picUpdate'), { type: 'success' });
                        } else {
                            toast(ts('error.updatePic'), { type: 'error' });
                        }
                    }).catch(err => {
                        console.error(err);
                        toast(ts('error.updatePic'), { type: 'error' });
                    });
                }
                //    else if (e.target.name === 'companylogo2') {
                //         setPicture1(reader.result?.toString() || '');
                //         const formData = new FormData();
                //         formData.append('image', e.target.files[0])
                //         GetAxios().patch(config.apiBase + '/api/Employer/UpdateFrLogo', formData).then(res => {
                //             if (res.data.success) {
                //                 toast(ts('account.picUpdate'), { type: 'success' });
                //             } else {
                //                 toast(ts('error.updatePic'), { type: 'error' });
                //             }
                //         }).catch(err => {
                //             console.error(err);
                //             toast(ts('error.updatePic'), { type: 'error' });
                //         });
                //     }

                else {
                    setPicture2(reader.result?.toString() || '')
                    const formData = new FormData();
                    formData.append('image', e.target.files[0])
                    GetAxios().patch(config.apiBase + '/api/Employer/UpdateBanner', formData).then(res => {
                        if (res.data.success) {
                            toast(ts('account.picUpdate'), { type: 'success' });
                        } else {
                            toast(ts('error.updatePic'), { type: 'error' });
                        }
                    }).catch(err => {
                        console.error(err);
                        toast(ts('error.updatePic'), { type: 'error' });
                    });
                }
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    }
    const [defaultCountry, setDefaultCountry] = useState('HT');
    React.useEffect(() => {
        axios.get(config.apiBase + '/api/General/Info').then(res => {
            setDefaultCountry(res.data?.country || 'HT');
        });

        GetAxios().get(config.apiBase + '/api/General/GetDepartments').then(res => {
            setDepartments(res.data);
        });
    }, []);
    return (
        <>
            <div id="page-editprofile">
                <Container>
                    <Row className="py-50 mx-auto">
                        <Col md={12} xs={12}>
                            <div className='mb-4 ps-lg-0'>
                                <h5 className="text-uppercase"> {t('editEmployerProfileInformation')} </h5>
                            </div>
                            <form className="mb-0">
                                <div className="row bg-field border-bottom border-3 border-white">
                                    <div className="col-md-3 col-12 p-2 border-end border-no border-3 border-white">
                                        <label className="col-form-label label-text ps-3 text-capitalize pe-none"> {t('editCompProfile.employerName')} </label>
                                    </div>
                                    <div className="col-md-9 col-12 p-2 ps-3 pe-3">
                                        <input name="companyName" onChange={formik.handleChange} value={formik.values.companyName} type="text" className="form-control key-weight" placeholder="" required />
                                        {formik.errors.companyName && formik.touched.companyName ? <small className="text-danger">{formik.errors.companyName}</small> : ""}
                                    </div>
                                </div>
                                <div className="row bg-field border-bottom border-3 border-white">
                                    <div className="col-md-3 col-12 p-2 border-end border-no border-3 border-white">
                                        <label className="col-form-label label-text ps-3 text-capitalize pe-none"> {t('editCompProfile.industry')} </label>
                                    </div>
                                    <div className="col-md-9 p-2 pe-3 ps-3">
                                        <select id="inputState" name="industry" onChange={formik.handleChange} value={formik.values.industry} className="form-select form-control key-weight text-muted" required>
                                            <option value=""> {t('editCompProfile.pleaseSelect')} </option>
                                            {
                                                dropDowns?.Industry?.map((v: any) => (
                                                    <option key={'v_' + v.key} value={v.key}>{v.value}</option>
                                                ))
                                            }
                                        </select>
                                        {formik.errors.industry && formik.touched.industry ? <small className="text-danger">{formik.errors.industry}</small> : ""}
                                    </div>
                                </div>
                                <div className="row bg-field border-bottom border-3 border-white">
                                    <div className="col-md-3 col-12 p-2 border-end border-no border-3 border-white">
                                        <label className="col-form-label label-text ps-3 text-capitalize pe-none"> {t('editCompProfile.sectors')} <span className="text-danger fs-6">*</span></label>
                                    </div>
                                    <div className="col-md-9 p-2 px-3">
                                        <select id="inputState" name="sector" onChange={formik.handleChange} value={formik.values.sector} className="form-select form-control key-weight text-muted" required>
                                            <option value=""> {t('editCompProfile.pleaseSelect')} </option>
                                            {
                                                dropDowns?.Sectors?.map((v: any) => (
                                                    <option key={'a_' + v.key} value={v.key}>{v.value}</option>
                                                ))
                                            }
                                        </select>
                                        {formik.errors.sector && formik.touched.sector ? <small className="text-danger">{formik.errors.sector}</small> : ""}
                                    </div>
                                </div>
                                <div className="row bg-field border-bottom border-3 border-white">
                                    <div className="col-md-3 col-12 p-2 border-end border-no border-3 border-white">
                                        <label className="col-form-label label-text ps-3 text-capitalize pe-none"> {t('editCompProfile.website')}</label>
                                    </div>
                                    <div className="col-md-9 col-12 p-2 ps-3 pe-3">
                                        <input name="website" onChange={formik.handleChange} value={formik.values.website} type="text" className="form-control key-weight" placeholder="" required />
                                        {formik.errors.website && formik.touched.website ? <small className="text-danger">{formik.errors.website}</small> : ""}
                                    </div>
                                </div>
                                <div className="row bg-field border-bottom border-3 border-white">
                                    <div className="col-md-3 col-12 p-2 border-end border-no border-3 border-white">
                                        <label className="col-form-label label-text ps-3 text-capitalize pe-none"> {t('editCompProfile.contactPerson')} <span className="text-danger fs-6">*</span></label>
                                    </div>
                                    <div className="col-md-9 col-12 p-2 ps-3 pe-3">
                                        <input name="contactPerson" onChange={formik.handleChange} value={formik.values.contactPerson} type="text" className="form-control key-weight" placeholder="" required />
                                        {formik.errors.contactPerson && formik.touched.contactPerson ? <small className="text-danger">{formik.errors.contactPerson}</small> : ""}
                                    </div>
                                </div>
                                <div className="row bg-field border-bottom border-3 border-white">
                                    <div className="col-md-3 col-12 p-2 border-end border-no border-3 border-white">
                                        <label className="col-form-label label-text ps-3 text-capitalize pe-none"> {ct('emailAddress')}<span className="text-danger fs-6">*</span></label>
                                    </div>
                                    <div className="col-md-9 col-12 p-2 ps-3 pe-3">
                                        <input name="email" onChange={formik.handleChange} value={formik.values.email} type="text" className="form-control key-weight" placeholder="" required />
                                        {formik.errors.email && formik.touched.email ? <small className="text-danger">{formik.errors.email}</small> : ""}
                                    </div>
                                </div>
                                <div className="row bg-field border-bottom border-3 border-white">
                                    <div className="col-md-3 col-12 p-2 border-end border-no border-3 border-white">
                                        <label className="col-form-label label-text ps-3 text-capitalize pe-none"> {t('editCompProfile.contactPhoneNumber')}  </label>
                                    </div>
                                    <div className="col-md-9 col-12 p-2 ps-3 pe-3">
                                        <input name="phoneNumber" onChange={formik.handleChange} value={formik.values.phoneNumber} type="text" className="form-control key-weight" placeholder="" required />
                                        {formik.errors.phoneNumber && formik.touched.phoneNumber ? <small className="text-danger">{formik.errors.phoneNumber}</small> : ""}
                                    </div>
                                </div>
                                <div className="row bg-field border-bottom border-3 border-white">
                                    <div className="col-md-3 col-12 p-2 border-end border-no border-3 border-white">
                                        <label className="col-form-label label-text ps-3 text-capitalize pe-none"> {t('editCompProfile.profitorNonProfit')} </label>
                                    </div>
                                    <div className="col-md-9 p-2 pe-3 ps-3">
                                        <select onChange={e => formik.setFieldValue("profitorNonProfit", e.target.value)} value={formik.values.profitorNonProfit} className="form-select form-control key-weight text-muted">
                                            <option value="">{t('editCompProfile.pleaseSelect')}</option>

                                            <option key={'pro_' + 1} value="Profit"> {ct('profit')} </option>
                                            <option key={'pro_' + 2} value="Non-Profit"> {ct('nonProfit')} </option>

                                        </select>

                                        {formik.errors.profitorNonProfit && formik.touched.profitorNonProfit ? <small className='text-danger'>{formik.errors.profitorNonProfit}</small> : ""}
                                    </div>
                                </div>
                                <div className="row bg-field border-bottom border-3 border-white mt-5">
                                    <div className="col-md-3 col-12 p-2 border-end border-no border-3 border-white">
                                        <label className="col-form-label label-text ps-3 text-capitalize pe-none"> {t('editCompProfile.employerDescription')} </label>
                                    </div>
                                    <div className="col-md-9 col-12 p-2 pe-3 ps-3">
                                        <div>
                                            <Editor
                                                editorState={editorState}
                                                editorClassName="editor-height edit-comp-bg"
                                                wrapperClassName='bg-white'
                                                stripPastedStyles={true}
                                                onEditorStateChange={e => {
                                                    setEditState(e);
                                                    formik.setFieldValue("companyDescription", draftToHtml(convertToRaw(e.getCurrentContent())));
                                                    // setValue({ content: draftToHtml(convertToRaw(e.getCurrentContent())) });
                                                }}
                                            />
                                            {/* <textarea onChange={formik.handleChange} value={formik.values.companyDescription} name="companyDescription" className="form-control key-weight" id="exampleFormControlTextarea1" rows={8} required></textarea> */}
                                            {formik.errors.companyDescription && formik.touched.companyDescription ? <small className="text-danger">{formik.errors.companyDescription}</small> : ""}
                                        </div>
                                    </div>
                                </div>

                                <div className="row bg-field border-bottom border-3 border-white">
                                    <div className="col-md-3 col-12 p-2 border-end border-no border-3 border-white">
                                        <label className="col-form-label label-text ps-3 text-capitalize pe-none"> {t('editCompProfile.location')} </label>
                                    </div>
                                    <div className="col-md-9 col-12 p-2 pe-3 ps-3">
                                        <FormikProvider value={formik}>
                                            <FieldArray
                                                name="location"
                                                render={arrayHelpers => (
                                                    <div>
                                                        {formik.values.location?.map((item, index) =>
                                                            <div className="row" key={'edo__' + index}>
                                                                <div className="col-md-4 col-12 my-sm-2 xs-margin">
                                                                    <CountrySelect
                                                                        name={`location.${index}.country`}
                                                                        value={item.country || defaultCountry}
                                                                        onChange={(code) => {
                                                                            item.country = code;
                                                                            formik.setFieldValue('location', formik.values.location);
                                                                            formik.setFieldValue(`location.${index}.zipCode`, code + "-" + item.zipCode.replace(/[^0-9\.]+/g, ""));
                                                                        }} />
                                                                </div>
                                                                <div className="col-md-4 col-12 my-sm-2 xs-margin">
                                                                    <div className="">
                                                                        <input type="text" name={`location.${index}.city`} value={item.city} onChange={formik.handleChange} className="form-control key-weight" placeholder={ct('city').toString()} />
                                                                    </div>

                                                                </div>
                                                                <div className="col-md-4 col-12 my-sm-2 xs-margin">
                                                                    <div className="">
                                                                        <input type="text" name={`location.${index}.zipCode`} value={item.zipCode} onChange={formik.handleChange} maxLength={9} pattern="[0-9]*" className="form-control key-weight" placeholder={ct('zipCode').toString()} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4 col-12 my-sm-2 xs-margin">
                                                                    <div className="mt-2">
                                                                        <input type="text" className="form-control key-weight" name={`location.${index}.streetNo`} value={item.streetNo} onChange={formik.handleChange} placeholder={t('editCompProfile.streetAddress1').toString()} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4 col-12 my-sm-2 xs-margin">
                                                                    <div className="mt-2">
                                                                        <input type="text" className="form-control key-weight" name={`location.${index}.streetNo2`} value={item.streetNo2} onChange={formik.handleChange} placeholder={t('editCompProfile.streetAddress2').toString()} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4 col-12 my-sm-2 xs-margin">
                                                                    <div className="mt-2">
                                                                        <input type="text" className="form-control key-weight" name={`location.${index}.streetNo3`} value={item.streetNo3} onChange={formik.handleChange} placeholder={t('editCompProfile.streetAddress3').toString()} />
                                                                    </div>
                                                                </div>

                                                                <div >
                                                                    <a onClick={() => arrayHelpers.remove(index)} className="text-uppercase border-bottom border-1 text-decoration-none cursor-pointer text-danger">
                                                                        <FaTimes /><span className="text-dark ms-2"> {ct('remove')} </span>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        )}
                                                        <div className="mt-2 mb-3">
                                                            <a onClick={() => arrayHelpers.push({ country: defaultCountry, city: "", zipCode: defaultCountry + "-", streetNo: "", streetNo2: "", streetNo3: "" })} className="text-uppercase text-decoration-none border-bottom border-1 border-dark cursor-pointer">
                                                                <FaMapMarkerAlt /><span className="text-dark ms-2"> {t('editCompProfile.addOffice')} </span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                )}
                                            />
                                        </FormikProvider>
                                    </div>
                                </div>
                                <div className="row bg-field border-bottom border-3 border-white">
                                    <div className="col-md-3 col-12 p-2 border-end border-no border-3 border-white">
                                        <label className="col-form-label label-text ps-3 text-capitalize pe-none"> {t('editCompProfile.numberOfEmployees')} </label>
                                    </div>
                                    <div className="col-md-9 col-12 p-2 pe-3 ps-3">
                                        <select id="inputState" className="form-select form-control key-weight text-muted" onChange={formik.handleChange} value={formik.values.noOfEmployees} name="noOfEmployees">
                                            <option value=""> {t('editCompProfile.pleaseSelect')} </option>
                                            {
                                                dropDowns?.NOEmployees?.map((v: any, i: number) => (
                                                    <option key={'b_' + i} value={v.key}>{v.value}</option>
                                                ))
                                            }
                                        </select>
                                        {formik.errors.noOfEmployees && formik.touched.noOfEmployees ? <small className='text-danger'>{formik.errors.noOfEmployees}</small> : ""}
                                    </div>
                                </div>
                                <div className="row bg-field border-bottom border-3 border-white">
                                    <div className="col-md-3 col-12 p-2 border-end border-no border-3 border-white">
                                        <label className="col-form-label label-text ps-3 text-capitalize pe-none"> {t('editCompProfile.legalEntity')} </label>
                                    </div>
                                    <div className="col-md-9 col-12 p-2 ps-3 pe-3">
                                        <input type="text" className="form-control key-weight" placeholder="" onChange={formik.handleChange} value={formik.values.legalEntity} name="legalEntity" />
                                        {formik.errors.legalEntity && formik.touched.legalEntity ? <small className='text-danger'>{formik.errors.legalEntity}</small> : ""}
                                    </div>
                                </div>
                                <div className="row bg-field border-bottom border-3 border-white">
                                    <div className="col-md-3 col-12 p-2 border-end border-no border-3 border-white">
                                        <label className="col-form-label label-text ps-3 text-capitalize pe-none"> {t('editCompProfile.employerRegistrationNumber')} </label>
                                    </div>
                                    <div className="col-md-9 col-12 p-2 ps-3 pe-3">
                                        <input type="text" disabled className="form-control key-weight" onChange={formik.handleChange} value={formik.values.employerNo} name="companyRegistrationNumber" />
                                        {formik.errors.employerNo && formik.touched ? <small className='text-danger'>{formik.errors.employerNo}</small> : ""}
                                    </div>
                                </div>
                                <div className="row bg-field border-bottom border-3 border-white">
                                    <div className="col-md-3 col-12 p-2 border-end border-no border-3 border-white">
                                        <label className="col-form-label label-text ps-3 text-capitalize pe-none"> {t('editCompProfile.service')} </label>
                                    </div>
                                    <div className="col-md-9 col-12 p-2 ps-3 pe-3">
                                        <TagifyInputField formik={formik} onChangeofTags={onChangeofTags} />
                                    </div>
                                </div>
                                <div className="row bg-field border-bottom border-3 border-white">
                                    <div className="col-md-3 col-12 p-2 border-end border-no border-3 border-white">
                                        <label className="col-form-label label-text ps-3 text-capitalize pe-none"> {t('editCompProfile.openingHours')}:</label>
                                    </div>
                                    <div className="col-md-9 col-12 p-2 pe-3 ps-3">
                                        <div className="">
                                            <OpeningHourTable formik={formik} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row bg-field border-bottom border-3 border-white">
                                    <div className="col-md-3 col-12 p-2 border-end border-no border-3 border-white">
                                        <label className="col-form-label label-text ps-3 text-capitalize pe-none"> {t('editCompProfile.contacts')} </label>
                                    </div>
                                    <div className="col-md-9 col-12 p-2 pe-3 ps-3">
                                        <div>
                                            <div className="d-flex mobdepartmentcolumn w-100 alignment-list justify-content-between align-items-center mt-2">
                                                <div className="mb-2">
                                                    <ul className="mobdepartmentcolumn list-inline p-0 d-flex d-inline-flex mb-0">
                                                        <li className="w-45 ms-3 mb-2">
                                                            <select onChange={e => formik.setFieldValue("department", Number(e.target.value))} value={formik.values.department} className="form-select form-control key-weight d-inline">
                                                                <option value={0}> {ct('selectDepartment')} </option>
                                                                {
                                                                    departments.map((v, i) => (
                                                                        <option key={'%__' + i} value={v.value}>{v.label}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                            {formik.errors.department && formik.touched.department ? <small className='text-danger d-block'>{formik.errors.department}</small> : ""}

                                                            {/* <input type="text" disabled name="departmentName" onChange={formik.handleChange} value={formik.values.departmentName} className="form-control key-weight d-inline" placeholder="Department Name" /> */}

                                                        </li>
                                                        <li className="w-50 ms-3 mb-2"><input type="email" name="email" onChange={formik.handleChange} value={formik.values.email} className="form-control key-weight" placeholder={ct('email').toString()} /></li>
                                                        <li className="w-2 ms-3 mb-2"><input type="tel" name="phoneNumber" onChange={formik.handleChange} value={formik.values.phoneNumber} className="form-control key-weight" placeholder={ct('tel').toString()} /></li>
                                                        <li className="w-3 ms-3 mb-2"><input type="text" name="fax" onChange={formik.handleChange} value={formik.values.fax} className="form-control key-weight" placeholder={ct('editCompProfile.tel2').toString()} /></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row bg-field border-bottom border-3 border-white">
                                    <div className="col-md-3 col-12 p-2 border-end border-no border-3 border-white">
                                        <label className="col-form-label label-text ps-3 text-capitalize pe-none">  {t('editCompProfile.socialNetworks')} </label>
                                    </div>
                                    <div className="col-md-9 col-12 p-2 pe-3 ps-3 mt-1 mb-2">
                                        <div className="row">
                                            <div className="col-md-4 col-12 my-sm-2 my-md-2 xs-margin">
                                                <div className="input-group">
                                                    <span className="social-icon facebook-color me-2 rounded text-white" >
                                                        <FaFacebookF />
                                                    </span>
                                                    <input type="text" className="form-control key-weight ms-1" onChange={formik.handleChange} value={formik.values.socialNetwork?.facebook || ''} name="socialNetwork.facebook" placeholder={t('editCompProfile.facebook').toString()} />
                                                </div>
                                                <div className="input-group mt-2">
                                                    <span className="social-icon me-2 rounded instagram-color text-white">
                                                        <FaInstagram />
                                                    </span>
                                                    <input type="text" className="form-control key-weight" placeholder={t('editCompProfile.instagram').toString()} onChange={formik.handleChange} value={formik.values.socialNetwork?.instagram || ''} name="socialNetwork.instagram" />
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-12 my-md-2 my-sm-2 xs-margin">
                                                <div className="input-group">
                                                    <span className="me-2 social-icon rounded twitter-color text-white">
                                                        <FaTwitter />
                                                    </span>
                                                    <input type="text" className="form-control key-weight" placeholder={t('editCompProfile.twitter').toString()} onChange={formik.handleChange} value={formik.values.socialNetwork?.twitter || ''} name="socialNetwork.twitter" />
                                                </div>
                                                <div className="input-group mt-2">
                                                    <span className="me-2 social-icon rounded youtube-color text-white">
                                                        <FaYoutube />
                                                    </span>
                                                    <input type="text" className="form-control key-weight" placeholder={t('editCompProfile.youtube').toString()} onChange={formik.handleChange} value={formik.values.socialNetwork?.youtube || ''} name="socialNetwork.youtube" />
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-12 my-md-2 my-sm-2 xs-margin">
                                                <div className="input-group">
                                                    <span className="social-icon me-2 rounded google-color text-dark pe-2">
                                                        <FaGooglePlusG />
                                                    </span>
                                                    <input type="text" className="form-control key-weight ms-1" placeholder={t('editCompProfile.Google+').toString()} onChange={formik.handleChange} value={formik.values.socialNetwork?.google || ''} name="socialNetwork.google" />
                                                </div>
                                                <div className="input-group mt-2">
                                                    <span className="me-2 social-icon rounded linkedin-color text-white">
                                                        <FaLinkedinIn />
                                                    </span>
                                                    <input type="text" className="form-control key-weight ms-1" placeholder={t('editCompProfile.linkedin').toString()} onChange={formik.handleChange} value={formik.values.socialNetwork?.linkedin || ''} name="socialNetwork.linkedin" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row bg-field border-bottom border-3 border-white mt-5">
                                    <div className="col-md-3 col-12 p-2 border-end border-no border-3 border-white">
                                        <label className="col-form-label label-text ps-3 text-capitalize pe-none"> {t('editCompProfile.employerLogo')} </label>
                                    </div>
                                    <div className="col-md-9 col-12 p-3 pe-3 ps-3">
                                        <div className="">
                                            <img src={!!picture ? picture : "/images/logo-banner-bg.jpg"} alt="logo banner bg" className="img-fluid rounded institution-logo" />
                                        </div>
                                        <div className="text-start mb-3">
                                            <input name="companylogo" className="hidden d-none" id='logo-input' ref={(r: any) => { myref = r }} type='file' onChange={onFileSelected} />
                                            <button type="button" className="button-default mt-3 border-0" onClick={() => (myref as any)?.click()}> {t('editCompProfile.chooseImage')} </button>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="row bg-field border-bottom border-3 border-white">
                                    <div className="col-md-3 col-12 p-2 border-end border-no border-3 border-white">
                                        <label className="col-form-label label-text ps-3 text-capitalize pe-none"> {t('editCompProfile.employerLogo')} </label>
                                    </div>
                                    <div className="col-md-9 col-12 p-3 pe-3 ps-3">
                                        <div className="">
                                            <img src={!!picture1 ? picture1: "/images/logo-banner-bg.jpg"} alt="" className="img-fluid rounded institution-logo" />
                                        </div>
                                        <div className="text-start mb-3">
                                            <input name="companylogo2" className="hidden d-none" id='logo-input' ref={(r: any) => { myref1 = r }} type='file' onChange={onFileSelected} />
                                            <button type="button" className="button-default mt-3 border-0" onClick={() => (myref1 as any)?.click()}> {t('editCompProfile.chooseImage')} </button>
                                        </div>
                                    </div>
                                </div> */}


                                <div className="row bg-field border-bottom border-3 border-white">
                                    <div className="col-md-3 col-12 p-2 border-end border-no border-3 border-white">
                                        <label className="col-form-label label-text ps-3 text-capitalize pe-none"> {t('editCompProfile.employerBanner')} </label>
                                    </div>
                                    <div className="col-md-9 col-12 p-3 pe-3 ps-3">
                                        <div className="">
                                            <img src={!!picture2 ? picture2 : "/images/logo-banner-bg.jpg"} alt="logo banner bg" className="img-fluid rounded w-100" />
                                        </div>
                                        <div className="text-start mb-3">
                                            <input name="companyBanner" className="hidden d-none" id='logo-input' ref={(r: any) => { myref2 = r }} type='file' onChange={onFileSelected} />
                                            <button type="button" className="button-default mt-3 border-0" onClick={() => (myref2 as any).click()}> {t('editCompProfile.chooseImage')} </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="ps-lg-0 mt-2 ms-11">
                                    <button type="button" onClick={(ev: any) => formik.handleSubmit(ev)} className="button-default border-0 rounded-0 mb-0 ms-2"> {t('editCompProfile.saveChange')}</button>
                                </div>
                            </form>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}
export default Index;


function OpeningHourTable(props: { formik: any }) {
    const formik = props.formik;

    const { t } = useTranslation('company');

    return (
        <div className='table-responsive'>
            <table className="table table-striped border border-2">
                <thead className="table-light">
                    <tr>
                        <th scope="col" className="text-center text-muted"></th>
                        <th scope="col" className="text-center text-muted"> {t('editCompProfile.day')} </th>
                        <th scope="col" className="text-center text-muted"> {t('editCompProfile.from')} </th>
                        <th scope="col" className="text-center text-muted"> {t('editCompProfile.to')} </th>
                    </tr>
                </thead>
                <tbody>
                    <FormikProvider value={formik}>
                        <FieldArray
                            name="openingHour"
                            render={arrayHelpers => (
                                formik.values?.openingHour?.map((x: any, i: number) =>
                                    <tr key={'openind__dhou' + i}>
                                        <td className="text-center label-text text-muted pt-3">
                                            <Form.Check name={`openingHour.${i}.enabled`} type="checkbox" checked={x.enabled} value={x.enabled} onChange={(e) => {
                                                x.enabled = e.target.checked;
                                                formik.setFieldValue('openingHour', formik.values.openingHour);
                                            }} />
                                        </td>
                                        <td className="text-center label-text text-muted pt-3 text-capitalize">{x.day}</td>
                                        <td className='border-end border-5 border-white'>
                                            <div className="d-flex align-items-center justify-content-around">
                                                <select id="inputState" name={`openingHour.${i}.fromHour`} disabled={!x.enabled} className="w-25 form-select form-control key-weight" value={Number(x.fromHour).toLocaleString('en-GB', {
                                                    minimumIntegerDigits: 2
                                                })} onChange={formik.handleChange}>
                                                    {
                                                        [...Array(24).keys()].map((v, index) => (
                                                            <option key={'eeddddd_prodf' + index}>{Number(v).toLocaleString('en-GB', {
                                                                minimumIntegerDigits: 2
                                                            })}</option>
                                                        ))
                                                    }
                                                </select>
                                                <span className="ps-2 pe-2">:</span>
                                                <select id="inputState" name={`openingHour.${i}.fromMin`} disabled={!x.enabled} className="w-25 form-select form-control key-weight" value={x.fromMin} onChange={formik.handleChange}>
                                                    {
                                                        [0, 15, 30, 45].map(v => (
                                                            <option key={'state_v' + v}>{Number(v).toLocaleString('en-GB', {
                                                                minimumIntegerDigits: 2
                                                            })}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="d-flex align-items-center justify-content-around">
                                                <select id="inputState" name={`openingHour.${i}.toHour`} disabled={!x.enabled} className="w-25 form-select form-control key-weight" value={x.toHour} onChange={formik.handleChange}>
                                                    {
                                                        [...(Array(24).keys())].map(v => (
                                                            <option key={'sku__v' + v}>{Number(v).toLocaleString('en-GB', {
                                                                minimumIntegerDigits: 2
                                                            })}</option>
                                                        ))
                                                    }
                                                </select>
                                                <span className="ps-2 pe-2">:</span>
                                                <select id="inputState" name={`openingHour.${i}.toMin`} disabled={!x.enabled} className="w-25 form-select form-control key-weight" value={x.toMin} onChange={formik.handleChange}>
                                                    {
                                                        [0, 15, 30, 45].map(v => (
                                                            <option key={'optionsss+__' + v}>{Number(v).toLocaleString('en-GB', {
                                                                minimumIntegerDigits: 2
                                                            })}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </td>

                                    </tr>
                                )
                            )}
                        />
                    </FormikProvider>
                </tbody>
            </table>
        </div>
    );
}


function TagifyInputField(props: any) {
    const { formik, onChangeofTags } = props;

    const visibleTodos = React.useMemo(
        () => JSON.parse(formik.values.service || '[]').map((v: any) => v.value),
        [formik.values.service]
    );

    const { t } = useTranslation('company');

    return (
        <Tagify
            value={visibleTodos}
            placeholder={t('editCompProfile.typeYourKeywordPress').toString()}
            onChange={onChangeofTags}
            className="service-tagifys"
        />
    );
}
