import React, { useRef } from 'react';
import SocialIconBar from '../../Components/Common/SocialIconBar';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const MainContent = () => {

    const componentRef = useRef();
   

    return (
        <>
            <div className=''>
                <div className="d-flex justify-content-between align-items-center border-bottom-light">
                    <div className="border-0">
                        {/* <div className="cursor-pointer border-none" onClick={handlePrint}>
                            <PrintIcon />
                        </div> */}
                    </div>
                    <div className="border-none">
                        <SocialIconBar />
                    </div>
                </div>
                <div className='print-sec'>
                    <ComponentToPrint ref={componentRef} />
                </div>
            </div>
        </>
    )
}
export default MainContent;


export const ComponentToPrint = React.forwardRef((props, ref:any) => {

    const { t } = useTranslation('candidate');

    return (
        <>
            <div ref={ref}>
                <div className='matcher-para fw-600'>
                    <p> {t('skill.matcher.tellUs')}.</p>
                    <p> {t('skill.matcher.dontLike')}.</p>
                    <p>{t('skill.matcher.showCareers')}.</p>
                </div>
                <div className="skill-border-btn text-center">
                    <Link to={'/skill-matcher-assessment'} className="matcher-skill-btn">
                        {t('skill.matcher.startAssessment')}
                    </Link>
                </div>
            </div>
        </>
    )
})