import React from "react";
import { FaDownload } from "react-icons/fa";
import { useSearchParams } from "react-router-dom";
import { config } from "../../../Environments";
import { GetAxios } from "../../../Shared";
import { useTranslation } from 'react-i18next';


const SideContent = (props: any) => {

  const { t: jt } = useTranslation('job');
  const { i18n } = useTranslation();
  const data = props.data;
  const [logo, setLogo] = React.useState("/images/upload-cmpny-logo.jpg");
  const [name, setName] = React.useState("");
  const [searchParams] = useSearchParams();
  React.useEffect(() => {
    GetAxios().get(config.apiBase + '/api/Companies/GetCompanyNameLogo?id=' + (searchParams.get('id') || '')).then(res => {
      setName(res.data?.name);
      if (i18n.language == 'fr' && res.data?.companyId == 1) {
        setLogo(config.apiBase + res.data?.logo2);
      }
      else {
        setLogo(config.apiBase + res.data?.logo);
      }
    })


  }, [i18n.language]);



  return (
    <>
      <aside>
        <div className="mb-3">
          <div className="card border-0 rounded-0">
            <img
              src={logo}
              className="card-img-top rounded-0"
              alt="..."
              onError={(ev: any) => {
                ev.target.src = '/images/ImgPlaceholder.png';
              }}
            />
            <div className="card-body pb-5">
              <div className="">
                <div>
                  <h6 className="text-center mt-3 fw-700">
                    <a
                      className="text-decoration-none text-color-3 fs-18 text-uppercase pointer-event">
                      {data?.companyName}
                    </a>
                  </h6>
                </div>
                <div className="mt-4 border-bottom border-secondary">
                  <h5 className="text-color-33 fw-600 text-uppercase mb-2">
                    {jt('job.jobDetails')}
                  </h5>
                </div>
                <div className="row pt-3">
                  <div className="col-6">
                    <div>
                      <label className="mb-0 fw-700 text-uppercase fs-14 fw-bold">
                        {jt('job.employerName')}:
                      </label>
                    </div>
                  </div>
                  <div className="col-6 px-0">
                    <div>
                      <span className="fs-14 fw-bold">{data?.companyName}</span>
                    </div>
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col-6">
                    <div>
                      <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">
                        {jt('job.jobTitle')}:
                      </label>
                    </div>
                  </div>
                  <div className="col-6 px-0">
                    <div>
                      <span className="fw-12">{data?.title}</span>
                    </div>
                  </div>
                </div>

                {
                  data?.contactPerson &&
                  <div className="row pt-3">
                    <div className="col-6">
                      <div>
                        <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">
                          {jt('job.contactPerson')}:
                        </label>
                      </div>
                    </div>
                    <div className="col-6 px-0">
                      <div>
                        <span className="fw-12">{data?.contactPerson}</span>
                      </div>
                    </div>
                  </div>
                }

                <div className="row pt-3">
                  <div className="col-6">
                    <div>
                      <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">
                        {jt('job.sectorOfActivity')}:
                      </label>
                    </div>
                  </div>
                  <div className="col-6 px-0">
                    <div>
                      <span className="fw-12">{data?.sector}</span>
                    </div>
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col-6">
                    <div>
                      <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">
                        {jt('job.classification')}:
                      </label>
                    </div>
                  </div>
                  <div className="col-6 px-0">
                    <div>
                      <span className="fw-12">{data?.classification}</span>
                    </div>
                  </div>
                </div>
                {
                  data?.ageFrom &&
                  <div className="row pt-3">
                    <div className="col-6">
                      <div>
                        <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">
                          {jt('job.ageRange')}:
                        </label>
                      </div>
                    </div>
                    <div className="col-6 px-0">
                      <div>
                        <span className="fw-12">{data?.ageFrom} - {data?.ageTo}</span>
                      </div>
                    </div>
                  </div>
                }

                <div className="row pt-3">
                  <div className="col-6">
                    <div>
                      <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">
                        {jt('job.gender')}:
                      </label>
                    </div>
                  </div>
                  <div className="col-6 px-0">
                    <div>
                      <span className="fw-12">{data?.gender}</span>
                    </div>
                  </div>
                </div>
                {data?.experienceFrom &&
                  <div className="row pt-3">
                    <div className="col-6">
                      <div>
                        <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">
                          {jt('job.yearsOfExp')}:
                        </label>
                      </div>
                    </div>
                    <div className="col-6 px-0">
                      <div>
                        <span className="fw-12">{data?.experienceFrom} {data?.experienceFrom === 1 ? jt('job.year') : jt('job.years')} </span>
                        {/* <span className="fw-12">{data?.experienceFrom} - {data?.experienceTo} {jt('job.years')} </span> */}
                      </div>
                    </div>
                  </div>
                }

                <div className="row pt-3">
                  <div className="col-6">
                    <div>
                      <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">
                        {jt('job.minDegree')}:
                      </label>
                    </div>
                  </div>
                  <div className="col-6 px-0">
                    <div>
                      <span className="fw-12">{data?.minDegree}</span>
                    </div>
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col-6">
                    <div>
                      <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">
                        {jt('job.workingHours')}:
                      </label>
                    </div>
                  </div>
                  <div className="col-6 px-0">
                    <div>
                      <span className="fw-12">
                        {data?.workShift}
                      </span>
                    </div>
                  </div>
                </div>
                {data?.salaryFrom &&
                  <div className="row pt-3">
                    <div className="col-6">
                      <div>
                        <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">
                          {jt('job.salaryRange')}:
                        </label>
                      </div>
                    </div>
                    <div className="col-6 px-0">
                      <div>
                        <span className="fw-12">
                          {data?.salaryFrom}, {jt('job.000')} <strong> {jt('job.to')}</strong> {data?.salaryTo}, {jt('job.000')} /  {jt('job.yr')} {data?.currency}
                        </span>
                      </div>
                    </div>
                  </div>
                }

                <div className="row pt-3">
                  <div className="col-6">
                    <div>
                      <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">
                        {jt('job.employerBenefits')}:
                      </label>
                    </div>
                  </div>
                  <div className="col-6 px-0">
                    <div>
                      <span className="fw-12">
                        {data?.benifits}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col-6">
                    <div>
                      <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">
                        {jt('job.departments')}:
                      </label>
                    </div>
                  </div>
                  <div className="col-6 px-0">
                    <div>
                      <span className="fw-12">
                        {data?.department}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col-6">
                    <div>
                      <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">
                        {jt('job.contractType')}:
                      </label>
                    </div>
                  </div>
                  <div className="col-6 px-0">
                    <div>
                      <span className="fw-12">
                        {/* {type} */}
                        {data?.contractType}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col-6">
                    <div>
                      <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">
                        {jt('job.workExperience')}:
                      </label>
                    </div>
                  </div>
                  <div className="col-6 px-0">
                    <div>
                      <span className="fw-12">
                        {data?.workExperience}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col-6">
                    <div>
                      <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">
                        {jt('job.expiresOn')}:
                      </label>
                    </div>
                  </div>
                  <div className="col-6 px-0">
                    <div>
                      <span className="fw-12">
                        {data?.expiryDate}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </aside>
    </>
  );
};
export default SideContent;
