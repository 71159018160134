import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaStar, FaPrint, FaPlus, FaMinus } from "react-icons/fa";
import Collapse from 'react-bootstrap/Collapse';
import { useTranslation } from 'react-i18next';


const MainContent = ({ data }) => {


  const { t } = useTranslation('candidate');
  const { t: ct } = useTranslation('common');

  const [skills, setSkills] = useState((data?.candidateSkill || []).map(v => ({
    isShow: false,
    skillTitle: v.title,
    level: v.skillPercentage,
    skillDescription: v.description
  })));
  const [isFrActive, setFrActive] = useState(false);
  const [isEnActive, setEnActive] = useState(false);
  const [isItActive, setItActive] = useState(false);
  const [isDeActive, setDeActive] = useState(false);
 

  const toggleopen = (index) => {
    let newArr = [...skills]
    newArr[index].isShow = !newArr[index].isShow;
    setSkills(newArr);
  };
  
  React.useEffect(() => {

    setSkills((data?.candidateSkill || []).map(v=> ({
      isShow: false,
      skillTitle: v.skillTitle,
      level: v.skillPercentage,
      skillDescription: v.description
    })));
  }, [data?.candidateSkill]);
  React.useEffect(() => {
   
    (data?.languages || []).map((x) => {
     if(x.value==="FR")
     setFrActive(true); 
     if(x.value==="EN")
      setEnActive(true); 
      if(x.value==="IT")
      setItActive(true);
      if(x.value==="DE")
      setDeActive(true);
   })
  }, [data?.languages]);

  return (
    <>
      <div className="card border-0 rounded-0 p-4">
        <div className="card-header bg-white border-0">
          <div className="language-print text-end">
            <ul className="list-inline mb-2">
         
              <li className={`list-inline-item text-uppercase ${isFrActive ? "active" : ""}`}>
                <a className="pointer-event">Fr</a>
              </li>
              <li className={`list-inline-item text-uppercase ${isEnActive ? "active" : ""}`}>
                <a className="pointer-event">En</a>
              </li>
              <li className={`list-inline-item text-uppercase ${isItActive? "active" : ""}`}>
                <a className="pointer-event">It</a>
              </li>
              
              <li className={`list-inline-item text-uppercase  ${isDeActive ? "active" : ""}`}>
                <a className="pointer-event">De</a>
              </li>
              {/* 
                <li className={`list-inline-item text-uppercase  border-right ${isDeActive ? "active" : ""}`}>
                <a className="pointer-event">De</a>
              </li>
              <li className="list-inline-item text-uppercase print">
                <a className="pointer-event">
                  <FaPrint />
                </a>
              </li>
              <li className="list-inline-item text-uppercase star-rating">
                <a className="pointer-event">
                  <FaStar />
                </a>
              </li> */}
            </ul>
          </div>
        </div>
        <div className="card-body bg-white p-0">
          <h5 className="text-color-33 fw-600 text-uppercase mb-3">
          {t('candProfile.helloMyNameIs')} {data?.firstName} {data?.lastName}
          </h5>
          <p className="text-color-66" dangerouslySetInnerHTML={{ __html: data?.description || '' }}>
          </p>
          <h5 className="text-color-33 fw-600 py-3 mb-3 text-uppercase"> {ct('design')} </h5>
          {skills.map((Item, index) => {
            return (<div className="row mb-3" key={"s_"+index}>
              <div className="col-md-3">
                <div>
                  <div className="d-flex">
                    <div className="col-2">
                      <a
                        className="minus rounded-0 d-block text-dark border icon-collaps1 pointer-event"
                        aria-controls={"logodesign" + index}
                        aria-expanded={Item.isShow}
                        onClick={() => toggleopen(index)}
                      >
                        {Item.isShow ? <FaMinus size={12} /> : <FaPlus size={12} />}
                      </a>
                    </div>
                    <div className="col-10">
                      <p className="mb-0 ms-3 fsize-13 fw-normal mt-1">
                        {Item.skillTitle}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-9 p-right-mobile">
                <div>
                  <div className='d-flex'>
                    <div className='col-md-11 col-12'>
                      <div>
                        <div className="progress mb-2 rounded-0 mt-2">
                          <div
                            className="progress-bar"
                            style={{ width: Item.level + '%' }}></div>
                        </div>
                        <Collapse in={Item.isShow}>
                          <div>
                            <p className="fs-14 fw-normal mb-1 lh-lg" dangerouslySetInnerHTML={{ __html: Item?.skillDescription || '' }}>
                         
                            </p>
                          </div>
                        </Collapse>
                      </div>
                    </div>

                    <div className='col-md-1'>
                      <span className="text-nowrap ms-2">{Item.level}%</span>
                    </div>
                  </div>
                </div>

              </div>
            </div>)
          })}

          {/* <div className="row pt-4">
            <div className="col-md-3">
              <div className="d-flex">
                <Link
                  className="minus rounded-0 text-dark border icon-collaps2"
                  // data-bs-toggle="collapse"
                  // href="#webdesign"
                  // role="button"
                  // aria-expanded="false"
                  // aria-controls="webdesign"
                  // onClick={toggleplus1}
                  onClick={() => {setOpen(!open);toggleplus()}}
                  aria-controls="webdesign"
                  aria-expanded={open}
                >
                  {plusicon ? (
                    <i className="fa fa-minus"></i>
                    ) : (
                      <i className="fa fa-plus"></i>
                  )}
                </Link>
                <p className="mb-0 ms-2 fsize-13 fw-normal mt-2">Web Design</p>
              </div>
            </div>
            <div className="col-md-9">
              <div className="progress mb-3 rounded-0 mt-2">
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: "75%" }}
                  aria-valuenow="50"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <Collapse in={open}>
                <div className="collapse" id="webdesign">
                  <p className="fs-14 fw-normal lh-lg">
                    Preferred languages are Arabic, French & Italian. Proin nibh
                    augue, suscipit a, scelerisque sed, lacinia in, mi.
                  </p>
                </div>
              </Collapse>
            </div>
          </div>
          <div className="row pt-4">
            <div className="col-md-3">
              <div className="d-flex">
                <Link
                  className="minus rounded-0 text-dark border icon-collaps3"
                  // data-bs-toggle="collapse"
                  // href="#uidesign"
                  // role="button"
                  // aria-expanded="false"
                  // aria-controls="uidesign"
                  onClick={() => {setOpen(!open);toggleplus()}}
                  aria-controls="uidesign"
                  aria-expanded={open}
                  // onClick={toggleplus2}
                >
                  {plusicon ? (
                    <i className="fa fa-minus"></i>
                    ) : (
                    <i className="fa fa-plus"></i>
                  )}
                </Link>
                <p className="mb-0 ms-2 fsize-13 fw-normal mt-2">UX / UI</p>
              </div>
            </div>
            <div className="col-md-9">
              <div className="progress mb-3 rounded-0 mt-2">
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: "62%" }}
                  aria-valuenow="50"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <Collapse in={open}>
                <div className="collapse" id="uidesign">
                  <p className="fs-14 fw-normal lh-lg">
                    Preferred languages are Arabic, French & Italian. Proin nibh
                    augue, suscipit a, scelerisque sed, lacinia in, mi.
                  </p>
                </div>
              </Collapse>
            </div>
          </div> */}
          <div className="mt-5">
            <h5 className="text-color-33 fw-600 text-uppercase mb-3">
               {ct('additionalSkill')}
            </h5>
            <div className="d-flex pt-3 additional-requirement-btn">
           
                  <ul className="list-unstyled list-inline-requirement">
                  {
                 !!data?.additionalSkils ?  ((
                  data?.additionalSkils || '').split(',') || [] ).map((v, index) => (
              
                  <li key={'sskke++_' + index}>
                    <Link
                      to=""
                      className="text-decoration-none me-1 rounded-0">
                      {v}
                    </Link>
                  </li>
                )) : ""
                   
                  }
                      </ul>
             
            
                {/* <li>
                  <Link
                    to=""
                    className="text-decoration-none me-1 rounded-0"
                  >
                    5 Years Experience
                  </Link>
                </li>
                <li>
                  <Link
                    to=""
                    className="text-decoration-none me-1 rounded-0"
                  >
                    MBA
                  </Link>
                </li>
                <li>
                  <Link
                    to=""
                    className="text-decoration-none me-1 rounded-0"
                  >
                    Magento Certifies
                  </Link>
                </li>
                <li>
                  <Link
                    to=""
                    className="text-decoration-none me-1 rounded-0"
                  >
                    Parfect Written & Spoken English
                  </Link>
                </li> */}
          
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default MainContent;
