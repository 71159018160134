import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import { FaCheck, FaLaptop } from "react-icons/fa";
import { FiDownload, FiCheck } from "react-icons/fi";
import { GoChecklist } from "react-icons/go";
import { MdAttachment } from "react-icons/md";



export const UpgradeAccountIndex = () => {

  const [selectedValue, setSelectedValue] = useState('');

  const handleRadioChange = (event: any) => {
    setSelectedValue(event.target.value);
  };

  return (
    <>
      <div className='container account-sec-padd my-4'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='mb-3'>
              <h4 className='fw-700'>Upgrade now and get hired fast!</h4>
            </div>
          </div>
        </div>
        <div className='row py-3 px-5 g-4 g-lg-4 g-md-4'>
          <div className='col-md-4 col-12'>
            <div>
              <Card className='account-card-border'>
                <div className='px-3'>
                  <ListGroup variant="flush" className='list-group-border-none'>
                    <ListGroup.Item>
                      <div className='d-flex align-items-center justify-content-between py-3 mt-2'>
                        <div>
                          <label className="form-check-label plan-label-radio" htmlFor="free">
                            Free (2 days)
                          </label>
                        </div>
                        <div>
                          <input className="form-check-input" type="radio" id="free" name="free" value="free"
                            checked={selectedValue === 'free'}
                            onChange={handleRadioChange} />
                        </div>
                      </div>
                      <div className="bor-bottom-plan-account mb-2">
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <div>
                        <h2 className='fw-700'>$0.00</h2>
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <div className='d-flex align-items-center'>
                        <div className='me-2'>
                          <FaLaptop size={20} className="plan-icon-color" />
                        </div>
                        <div>
                          <p className='mb-0 fw-500'>1 Resume</p>
                        </div>
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <div className='d-flex align-items-center'>
                        <div className='me-2'>
                          <svg xmlns="http://www.w3.org/2000/svg" className="plan-icon-color template-plan-icon" width="20" height="20" viewBox="0 0 24 24">
                            <g fillRule="evenodd"><g><g><g><path d="M1 15c.55 0 1-.45 1-1v-4c0-.55-.45-1-1-1s-1 .45-1 1v4c0 .55.45 1 1 1zm3 2c.55 0 1-.45 1-1V8c0-.55-.45-1-1-1s-1 .45-1 1v8c0 .55.45 1 1 1zm18-7v4c0 .55.45 1 1 1s1-.45 1-1v-4c0-.55-.45-1-1-1s-1 .45-1 1zm-2 7c.55 0 1-.45 1-1V8c0-.55-.45-1-1-1s-1 .45-1 1v8c0 .55.45 1 1 1zM16.5 3h-9C6.67 3 6 3.67 6 4.5v15c0 .83.67 1.5 1.5 1.5h9c.83 0 1.5-.67 1.5-1.5v-15c0-.83-.67-1.5-1.5-1.5zM16 19H8V5h8v14z"
                              transform="translate(-268 -28) translate(256 24) translate(12 4)">
                            </path></g></g></g>
                            </g>
                          </svg>
                        </div>
                        <div>
                          <p className='mb-0 fw-500'>2 download</p>
                        </div>
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <div className='d-flex align-items-center'>
                        <div className='me-2'>
                          <GoChecklist size={20} className="plan-icon-color" />
                        </div>
                        <div>
                          <p className='mb-0 fw-500 text-decoration-line-through'>Unlimited tips and real examples</p>
                        </div>
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <div className='d-flex align-items-center'>
                        <div className='me-2'>
                          <svg xmlns="http://www.w3.org/2000/svg" className="plan-icon-color template-plan-icon" width="20" height="20" viewBox="0 0 24 24">
                            <g fillRule="evenodd"><g><g><g><path d="M1 15c.55 0 1-.45 1-1v-4c0-.55-.45-1-1-1s-1 .45-1 1v4c0 .55.45 1 1 1zm3 2c.55 0 1-.45 1-1V8c0-.55-.45-1-1-1s-1 .45-1 1v8c0 .55.45 1 1 1zm18-7v4c0 .55.45 1 1 1s1-.45 1-1v-4c0-.55-.45-1-1-1s-1 .45-1 1zm-2 7c.55 0 1-.45 1-1V8c0-.55-.45-1-1-1s-1 .45-1 1v8c0 .55.45 1 1 1zM16.5 3h-9C6.67 3 6 3.67 6 4.5v15c0 .83.67 1.5 1.5 1.5h9c.83 0 1.5-.67 1.5-1.5v-15c0-.83-.67-1.5-1.5-1.5zM16 19H8V5h8v14z"
                              transform="translate(-268 -28) translate(256 24) translate(12 4)">
                            </path></g></g></g>
                            </g>
                          </svg>
                        </div>
                        <div>
                          <p className='mb-0 fw-500 text-decoration-line-through'>Access to premium templates</p>
                        </div>
                      </div>
                    </ListGroup.Item>
                  </ListGroup>
                </div>
              </Card>
            </div>
          </div>
          <div className='col-md-4 col-12'>
            <div>
              <Card className='account-card-border position-relative'>
                <div className='account-badge-plan text-uppercase fw-600'>Most popular</div>
                <div className='px-3'>
                  <ListGroup variant="flush" className='list-group-border-none'>
                    <ListGroup.Item>
                      <div className='d-flex align-items-center justify-content-between py-3 mt-2'>
                        <div>
                          <label className="form-check-label plan-label-radio" htmlFor="days">
                            10 Days
                          </label>
                        </div>
                        <div>
                          <input className="form-check-input" type="radio" id="days" name="days" value="10days"
                            checked={selectedValue === '10days'}
                            onChange={handleRadioChange} />
                        </div>
                      </div>
                      <div className="bor-bottom-plan-account mb-2">
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <div className='d-flex align-items-center'>
                        <div className='me-2'>
                          <h2 className='fw-700'>$2.95</h2>
                        </div>
                        <div>
                          <p className='mb-0'> <span className='fw-600'>(USD)</span> <span className='fw-500'>10 days</span></p>
                        </div>
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <div className='d-flex align-items-center'>
                        <div className='me-2'>
                          <FaLaptop size={20} className="plan-icon-color" />
                        </div>
                        <div>
                          <p className='mb-0 fw-500'>2 Resumes</p>
                        </div>
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <div className='d-flex align-items-center'>
                        <div className='me-2'>
                          <FiDownload size={20} className="plan-icon-color" />
                        </div>
                        <div>
                          <p className='mb-0 fw-500'>Unlimited downloads and storage</p>
                        </div>
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <div className='d-flex align-items-center'>
                        <div className='me-2'>
                          <MdAttachment size={20} className="plan-icon-color" />
                        </div>
                        <div>
                          <p className='mb-0 fw-500'>Share resumes online</p>
                        </div>
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <div className='d-flex align-items-center'>
                        <div className='me-2'>
                          <svg xmlns="http://www.w3.org/2000/svg" className="plan-icon-color template-plan-icon" width="20" height="20" viewBox="0 0 24 24">
                            <g fillRule="evenodd"><g><g><g><path d="M1 15c.55 0 1-.45 1-1v-4c0-.55-.45-1-1-1s-1 .45-1 1v4c0 .55.45 1 1 1zm3 2c.55 0 1-.45 1-1V8c0-.55-.45-1-1-1s-1 .45-1 1v8c0 .55.45 1 1 1zm18-7v4c0 .55.45 1 1 1s1-.45 1-1v-4c0-.55-.45-1-1-1s-1 .45-1 1zm-2 7c.55 0 1-.45 1-1V8c0-.55-.45-1-1-1s-1 .45-1 1v8c0 .55.45 1 1 1zM16.5 3h-9C6.67 3 6 3.67 6 4.5v15c0 .83.67 1.5 1.5 1.5h9c.83 0 1.5-.67 1.5-1.5v-15c0-.83-.67-1.5-1.5-1.5zM16 19H8V5h8v14z"
                              transform="translate(-268 -28) translate(256 24) translate(12 4)">
                            </path></g></g></g>
                            </g>
                          </svg>
                        </div>
                        <div>
                          <p className='mb-0 fw-500'>Access to premium templates</p>
                        </div>
                      </div>
                      <div className="bor-bottom-plan-account my-2">
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <div className='d-flex align-items-center'>
                        <div className='me-2'>
                          <FiCheck size={20} className="plan-icon-color" />
                        </div>
                        <div>
                          <p className='mb-0 fw-500'>Cancel free at any time</p>
                        </div>
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <div className='d-flex align-items-center'>
                        <div className='me-2'>
                          <FiCheck size={20} className="plan-icon-color" />
                        </div>
                        <div>
                          <p className='mb-0 fw-500'>14-day money back guarantee</p>
                        </div>
                      </div>
                    </ListGroup.Item>
                  </ListGroup>
                </div>
              </Card>
            </div>
          </div>
          <div className='col-md-4 col-12'>
            <div>
              <Card className='account-card-border card-month-mob-930px'>
                <div className='px-3'>
                  <ListGroup variant="flush" className='list-group-border-none'>
                    <ListGroup.Item>
                      <div className='d-flex align-items-center justify-content-between py-3 mt-2'>
                        <div>
                          <label className="form-check-label plan-label-radio" htmlFor="monthly">
                            Monthly Subscription
                          </label>
                        </div>
                        <div>
                          <input className="form-check-input" type="radio" id="days" name="days" value="30days"
                            checked={selectedValue === '30days'}
                            onChange={handleRadioChange} />
                        </div>
                      </div>
                      <div className="bor-bottom-plan-account mb-2">
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <div className='d-flex align-items-center'>
                        <div className='me-2'>
                          <h2 className='fw-700'>$7.95</h2>
                        </div>
                        <div>
                          <p className='mb-0'> <span className='fw-600'>(USD)</span> <span className='fw-500'>Monthly</span></p>
                        </div>
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <div className='d-flex align-items-center'>
                        <div className='me-2'>
                          <FaLaptop size={20} className="plan-icon-color" />
                        </div>
                        <div>
                          <p className='mb-0 fw-500'>3 Resumes per month</p>
                        </div>
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <div className='d-flex align-items-center'>
                        <div className='me-2'>
                          <FiDownload size={20} className="plan-icon-color" />
                        </div>
                        <div>
                          <p className='mb-0 fw-500'>Unlimited downloads and storage</p>
                        </div>
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <div className='d-flex align-items-center'>
                        <div className='me-2'>
                          <MdAttachment size={20} className="plan-icon-color" />
                        </div>
                        <div>
                          <p className='mb-0 fw-500'>Share resumes online</p>
                        </div>
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <div className='d-flex align-items-center'>
                        <div className='me-2'>
                          <svg xmlns="http://www.w3.org/2000/svg" className="plan-icon-color template-plan-icon" width="20" height="20" viewBox="0 0 24 24">
                            <g fillRule="evenodd"><g><g><g><path d="M1 15c.55 0 1-.45 1-1v-4c0-.55-.45-1-1-1s-1 .45-1 1v4c0 .55.45 1 1 1zm3 2c.55 0 1-.45 1-1V8c0-.55-.45-1-1-1s-1 .45-1 1v8c0 .55.45 1 1 1zm18-7v4c0 .55.45 1 1 1s1-.45 1-1v-4c0-.55-.45-1-1-1s-1 .45-1 1zm-2 7c.55 0 1-.45 1-1V8c0-.55-.45-1-1-1s-1 .45-1 1v8c0 .55.45 1 1 1zM16.5 3h-9C6.67 3 6 3.67 6 4.5v15c0 .83.67 1.5 1.5 1.5h9c.83 0 1.5-.67 1.5-1.5v-15c0-.83-.67-1.5-1.5-1.5zM16 19H8V5h8v14z"
                              transform="translate(-268 -28) translate(256 24) translate(12 4)">
                            </path></g></g></g>
                            </g>
                          </svg>
                        </div>
                        <div>
                          <p className='mb-0 fw-500'>Access to premium templates</p>
                        </div>
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <div className='d-flex align-items-center'>
                        <div className='me-2'>
                          <GoChecklist size={20} className="plan-icon-color" />
                        </div>
                        <div>
                          <p className='mb-0 fw-500'>Unlimited tips and real examples</p>
                        </div>
                      </div>
                      <div className="bor-bottom-plan-account my-2">
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <div className='d-flex align-items-center'>
                        <div className='me-2'>
                          <FiCheck size={20} className="plan-icon-color" />
                        </div>
                        <div>
                          <p className='mb-0 fw-500'>Cancel free at any time</p>
                        </div>
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <div className='d-flex align-items-center'>
                        <div className='me-2'>
                          <FiCheck size={20} className="plan-icon-color" />
                        </div>
                        <div>
                          <p className='mb-0 fw-500'>14-day money back guarantee</p>
                        </div>
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <div className='d-flex align-items-center'>
                        <div className='me-2'>
                          <FiCheck size={20} className="plan-icon-color" />
                        </div>
                        <div>
                          <p className='mb-0 fw-500'>Online professional support</p>
                        </div>
                      </div>
                    </ListGroup.Item>
                  </ListGroup>
                </div>
              </Card>
            </div>
          </div>
        </div>
        <div className='d-grid justify-content-center mx-auto mt-4'>
          <h5 className='text-uppercase text-center'>Safe Payment</h5>
          <div className='text-center'>
            <img src="/images/payment-logos.png" className='w-100 h-28' alt="payment logo" />
          </div>
        </div>
      </div>
    </>
  )
}