import axios from 'axios';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import { FaAddressBook, FaBuilding, FaCity, FaEnvelope, FaEye, FaEyeSlash, FaGlobe, FaLock, FaPhoneAlt, FaPlayCircle, FaUserAlt } from "react-icons/fa";
import { RotatingLines } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import * as Yup from "yup";
import { CountrySelect } from '../../../Components';
import { config } from '../../../Environments';
import { GetAxios, KeyPairValue, LabelValuePair } from '../../../Shared';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';




const RegisterCompany = () => {

    const { t: ct } = useTranslation('common');
    const { t: at } = useTranslation('auth');
    const { t: ts } = useTranslation('alert');
    const profileschema = Yup.object().shape({
        firstName: Yup.string().required(ts('account.required') || 'Required'),
        lastName: Yup.string().required(ts('account.required') || 'Required'),
        email: Yup.string().email(ts('signIn.inValidEmail') || "Invalid email address").required(ts('account.required') || 'Required')
            .test(
                "not-yopmail",
                ts('account.yopmail') || "not allowed",
                (value) => !value || !value.endsWith("@yopmail.com")
            ),
        phone: Yup.string().required(ts('account.required') || 'Required'),
        password: Yup.string().required(ts('account.required') || 'Required').min(6, ts('account.tooShort') || "Password too short").matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])/,
            ts('account.passwordPattern') || "1 Upper, Lowercase, 1 Number and 1 Special Character"
        ),
        cpassword: Yup.string().required("Required").oneOf([Yup.ref('password'), null], ts('account.passwordMatch') || 'Passwords must match'),
        organization: Yup.string().required(ts('account.required') || 'Required'),
        profitorNonProfit: Yup.string().required(ts('account.required') || 'Required'),
        address1: Yup.string().required(ts('account.required') || 'Required'),
        city: Yup.string().required(ts('account.required') || 'Required'),
        department: Yup.number().min(1, ts('account.required') || 'Required'),
        country: Yup.string().required(ts('account.required') || 'Required'),
        preferredMethod: Yup.string().required(ts('account.required') || 'Required'),
    });
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [departments, setDepartments] = useState<LabelValuePair[]>([]);
    var navigate = useNavigate();
    const { i18n } = useTranslation();

    const formik = useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            password: "",
            cpassword: "",
            organization: "",
            profitorNonProfit: "",
            address1: "",
            website: "",
            logo: null,
            city: "",
            department: 0,
            zipCode: "HT-",
            country: "HT",
            preferredMethod: "Email",
            profileCheckbox: false,
            lang: ""
        },
        validationSchema: profileschema,
        onSubmit: values => {
            if (!values.profileCheckbox) {
                toast(ts('company.termsAndConditions'), { type: 'warning' });
                return;
            }
            if (loading) {
                toast(ts('account.pleaseWait'), { type: 'info' });
            }
            setLoading(true);
            const formData = new FormData();
            Object.keys(values).map(s => {
                formData.append(s, (values as any)[s]);
            });
            GetAxios().post(config.apiBase + '/api/Employer/Register', formData).then(response => {
                if (response.data.success) {
                    navigate('/require-confirm');
                } else {
                    if (response.data.errors) {
                        alert(response.data.errors.map((v: any) => v.description));
                    } else {
                        toast(ts('error.requestSubmit'), { type: 'info' });
                    }
                }
                setLoading(false);
            }).catch(err => {
                console.error(err);
                if (err.status >= 500) {
                    toast(ts('error.internalServer'), { type: 'error' });
                }
            });
        },
    });

    const customStyles = {
        indicatorsContainer: (provided: any, state: any) => ({
            ...provided,
            backgroundColor: '#dcd9d9',
            color: '#000'
        }),
        indicatorSeparator: (provided: any, state: any) => ({
            ...provided,
            marginTop: '0px',
            marginBottom: '0px',
            backgroundColor: "#fff"
        }),
        control: (provided: any, state: any) => ({
            ...provided,
            borderColor: 'transparent',
            "&:hover": {
                borderColor: "transparent"
            }
        }),

    }
    React.useEffect(() => {
        formik.setFieldValue('lang', i18n.language)

    }, [i18n.language]);
    React.useEffect(() => {
        axios.get(config.apiBase + '/api/General/Info').then(res => {
            if (!formik.values.country) {

                formik.setFieldValue('country', res?.data?.country || 'HT')
            }
        });

        GetAxios().get(config.apiBase + '/api/General/GetDepartments').then(res => {
            setDepartments(res.data);
        });

    }, []);
    return (
        <>
            <div className="process-content">
                <div className="container">
                    <div className='row form-m-center'>
                        <div className="form-institute-register">
                            <div className="padding-step1">
                                <div className='row mt-4'>
                                    <div className='col-md-7 mx-auto'>
                                        <div className='text-center'>
                                            <h3 className="step1-heading"> {at('companyRegister.mainTitleCompanyRegister')}. </h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-12">
                                    <div className="col-md-6 col-12 mb-12">
                                        <div className='d-flex line-height-star align-items-center'>
                                            <span className="text-danger fs-1 mt-2">*</span>
                                            <label htmlFor="firstName" className="form-label key-weight"> {ct('firstName')} </label>
                                        </div>
                                        <div className="input-group">
                                            <span className="input-group-text group-round" id="Email"><FaUserAlt /></span>
                                            <input type="text" name="firstName" onChange={formik.handleChange} value={formik.values.firstName} className="form-control gray-form" id="firstName" />
                                        </div>
                                        {formik.errors.firstName && formik.touched.firstName ? <small className='text-danger'>{formik.errors.firstName}</small> : ""}
                                    </div>
                                    <div className="col-md-6 col-12 mb-12">
                                        <div className='d-flex line-height-star align-items-center'>
                                            <span className="text-danger fs-1 mt-2">*</span>
                                            <label htmlFor="lastName" className="form-label key-weight"> {ct('lastName')} </label>
                                        </div>
                                        <div className="input-group">
                                            <span className="input-group-text group-round" id="Email"><FaUserAlt /></span>
                                            <input type="text" name='lastName' onChange={formik.handleChange} value={formik.values.lastName} className="form-control gray-form" id="lastName" />
                                        </div>
                                        {formik.errors.lastName && formik.touched.lastName ? <small className='text-danger'>{formik.errors.lastName}</small> : ""}
                                    </div>
                                    <div className="col-md-6 col-12 mb-12">
                                        <div className='d-flex line-height-star align-items-center'>
                                            <span className="text-danger fs-1 mt-2">*</span>
                                            <label htmlFor="emailaddress" className="form-label key-weight"> {ct('emailAddress')} </label>
                                        </div>
                                        <div className="input-group">
                                            <span className="input-group-text group-round" id="Email"><FaEnvelope /></span>
                                            <input type="text" name='email' onChange={formik.handleChange} value={formik.values.email} className="form-control gray-form" id="emailaddress" />
                                        </div>
                                        {formik.errors.email && formik.touched.email ? <small className='text-danger'>{formik.errors.email}</small> : ""}
                                    </div>
                                    <div className="col-md-6 col-12 mb-12">
                                        <div className='d-flex line-height-star align-items-center'>
                                            <span className="text-danger fs-1 mt-2">*</span>
                                            <label htmlFor="phone" className="form-label key-weight"> {ct('phone')} </label>
                                        </div>
                                        <div className="input-group">
                                            <span className="input-group-text group-round" id="Email"><FaPhoneAlt /></span>
                                            <input type="tel" name='phone' onChange={formik.handleChange} value={formik.values.phone} className="form-control gray-form" id="Phone" />
                                        </div>
                                        {formik.errors.phone && formik.touched.phone ? <small className='text-danger'>{formik.errors.phone}</small> : ""}
                                    </div>
                                    <div className="col-md-6 col-12 mb-12">
                                        <div className='d-flex line-height-star align-items-center'>
                                            <span className="text-danger fs-1 mt-2">*</span>
                                            <label htmlFor="password" className="form-label key-weight"> {ct('password')} </label>
                                        </div>
                                        <div className="input-group mb-3">
                                            <span className="input-group-text group-round" id="Email"><FaLock /></span>
                                            <input type={showPassword !== false ? 'text' : 'password'} name='password' onChange={formik.handleChange} value={formik.values.password} className="form-control gray-form" id="password" />
                                            <span className="input-group-text group-round" onClick={() => setShowPassword(!showPassword)}>{showPassword ? <FaEyeSlash /> : <FaEye />}</span>
                                        </div>
                                        {formik.errors.password && formik.touched.password ? <small className='text-danger'>{formik.errors.password}</small> : ""}
                                    </div>
                                    <div className="col-md-6 col-12 mb-12">
                                        <div className='d-flex line-height-star align-items-center'>
                                            <span className="text-danger fs-1 mt-2">*</span>
                                            <label htmlFor="cpassword" className="form-label key-weight"> {ct('confirmPassword')} </label>
                                        </div>
                                        <div className="input-group">
                                            <span className="input-group-text group-round" id="Email"><FaLock /></span>
                                            <input type="password" name='cpassword' onChange={formik.handleChange} value={formik.values.cpassword} className="form-control gray-form" id="cpassword" />
                                        </div>
                                        {formik.errors.cpassword && formik.touched.cpassword ? <small className='text-danger'>{formik.errors.cpassword}</small> : ""}
                                    </div>
                                    <div className="col-md-6 col-12 mb-12">
                                        <div className='d-flex line-height-star align-items-center'>
                                            <span className="text-danger fs-1 mt-2">*</span>
                                            <label htmlFor="organization" className="form-label key-weight">  {ct('organization')} </label>
                                        </div>
                                        <div className="input-group">
                                            <span className="input-group-text group-round" id="Email"><FaBuilding /></span>
                                            <input type="text" name='organization' onChange={formik.handleChange} value={formik.values.organization} className="form-control gray-form" id="organization" />
                                        </div>
                                        {formik.errors.organization && formik.touched.organization ? <small className='text-danger'>{formik.errors.organization}</small> : ""}
                                    </div>
                                    <div className="col-md-6 col-12 mb-12">
                                        <div className='d-flex line-height-star align-items-center'>
                                            <span className="text-danger fs-1 mt-2">*</span>
                                            <label htmlFor="profit-or-nonProfit" className="form-label key-weight"> {ct('profitorNonProfit')} </label>
                                        </div>
                                        <div className="input-group">
                                            <span className="input-group-text group-round" id="Email"><FaBuilding /></span>
                                            <select onChange={e => formik.setFieldValue("profitorNonProfit", e.target.value)} value={formik.values.profitorNonProfit} className="form-select form-control key-weight gray-form">
                                                <option value="">{ct('selectTypeOrgn')}</option>

                                                <option key={'pro_' + 1} value="Profit"> {ct('profit')} </option>
                                                <option key={'pro_' + 2} value="Non-Profit"> {ct('nonProfit')} </option>

                                            </select>
                                        </div>
                                        {formik.errors.profitorNonProfit && formik.touched.profitorNonProfit ? <small className='text-danger'>{formik.errors.profitorNonProfit}</small> : ""}

                                    </div>

                                    <div className="col-md-6 col-12 mb-12">
                                        <label htmlFor="institution-logo" className="form-label key-weight"> {at('companyRegister.uploadEmployerLogo')} </label>
                                        <input className="form-control gray-form" type="file" id="institution-logo" onChange={e => {
                                            if (e.target.files)
                                                formik.setFieldValue('logo', e.target.files[0]);
                                        }} />
                                    </div>
                                    <div className="col-md-6 col-12 mb-12">
                                        <label htmlFor="web-address" className="form-label key-weight"> {at('companyRegister.websiteAddress')} </label>
                                        <div className="input-group">
                                            <span className="input-group-text group-round" id="Email"><FaGlobe /></span>
                                            <input type="text" name='website' value={formik.values.website} onChange={formik.handleChange} className="form-control gray-form" id="web-address" />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 mb-12">
                                        <div className='d-flex line-height-star align-items-center'>
                                            <span className="text-danger fs-1 mt-2">*</span>
                                            <label htmlFor="street-address1" className="form-label key-weight"> {at('companyRegister.streetAddress1')} </label>
                                        </div>
                                        <div className="input-group">
                                            <span className="input-group-text group-round" id="Email"><FaAddressBook /></span>
                                            <input type="text" name='address1' onChange={formik.handleChange} value={formik.values.address1} className="form-control gray-form" id="street-address1" />
                                        </div>
                                        {formik.errors.address1 && formik.touched.address1 ? <small className='text-danger'>{formik.errors.address1}</small> : ""}
                                    </div>
                                    <div className="col-md-6 col-12 mb-12">
                                        <div className='d-flex line-height-star align-items-center'>
                                            <span className="text-danger fs-1 mt-2">*</span>
                                            <label htmlFor="city" className="form-label key-weight"> {ct('city')} </label>
                                        </div>
                                        <div className="input-group">
                                            <span className="input-group-text group-round" id="Email"><FaCity /></span>
                                            <input type="text" name='city' onChange={formik.handleChange} value={formik.values.city} className="form-control gray-form" id="city" />
                                        </div>
                                        {formik.errors.city && formik.touched.city ? <small className='text-danger'>{formik.errors.city}</small> : ""}
                                    </div>
                                    <div className="col-md-6 col-12 mb-12">
                                        <label htmlFor="state" className="form-label key-weight"> {ct('department')} </label>
                                        <select onChange={e => formik.setFieldValue("department", Number(e.target.value))} value={formik.values.department} className="form-select form-control key-weight gray-form">
                                            <option value={0}>{ct('selectDepartment')}</option>
                                            {
                                                departments.map((v, i) => (
                                                    <option key={'%__' + i} value={v.value}>{v.label}</option>
                                                ))
                                            }
                                        </select>
                                        {/* <input type="text" name='state' onChange={formik.handleChange} value={formik.values.state} className="form-control gray-form" id="state" /> */}
                                        {formik.errors.department && formik.touched.department ? <small className='text-danger'>{formik.errors.department}</small> : ""}
                                    </div>
                                    <div className="col-md-6 col-12 mb-12">
                                        <div className='d-flex line-height-star align-items-center'>
                                            <span className="text-danger fs-1 mt-2">*</span>
                                            <label htmlFor="counrtry" className="form-label key-weight"> {ct('counrtry')} </label>
                                        </div>
                                        <div className='CustomCountrySelectBg'>
                                            <CountrySelect colorConfig={customStyles}
                                                value={formik.values.country}
                                                onChange={(code) => {
                                                    formik.setFieldValue("country", code);
                                                    formik.setFieldValue("zipCode", code + "-" + formik.values.zipCode.replace(/[^0-9\.]+/g, ""));
                                                }}
                                            />
                                        </div>
                                        {formik.errors.country && formik.touched.country ? <small className='text-danger'>{formik.errors.country}</small> : ""}
                                    </div>
                                    <div className="col-md-6 col-12 mb-12">
                                        <label htmlFor="zipcode" className="form-label key-weight"> {at('companyRegister.postalCode')} </label>
                                        <div className="input-group ht-post-code">

                                            <input type="text" name='zipCode' maxLength={9} onChange={formik.handleChange} value={formik.values.zipCode} className="form-control gray-form" id="zipcode" />
                                        </div>
                                        {formik.errors.zipCode && formik.touched.zipCode ? <small className='text-danger'>{formik.errors.zipCode}</small> : ""}
                                    </div>
                                    <div className="col-md-6 col-12 mb-12">
                                        <label htmlFor="communication" className="form-label key-weight"> {at('companyRegister.preferredMethodCommunication')} </label>
                                        <select name='preferredMethod' onChange={formik.handleChange} value={formik.values.preferredMethod} className="form-select form-control gray-form" id="communication">
                                            <option> {ct('email')} </option>
                                            <option> {ct('phone')} </option>
                                        </select>
                                        {formik.errors.preferredMethod && formik.touched.preferredMethod ? <small className='text-danger'>{formik.errors.preferredMethod}</small> : ""}
                                    </div>
                                    <div className='col-8 mb-12 mx-auto'>
                                        <div className="form-check mt-2">
                                            <input className="form-check-input" type="checkbox" id="defaultCheck2" onChange={e => { formik.setFieldValue('profileCheckbox', e.target.checked); formik.handleChange(e) }} checked={formik.values.profileCheckbox} />
                                            <label className="form-check-label checkbok-company-color" htmlFor="defaultCheck2">
                                                {at('companyRegister.agreeEmployersProfileSiteText')} <br /> {at('companyRegister.andAgreeBoundText')} <Link to="/terms-of-use" className='text-decoration-none'>{at('companyRegister.termsOfUse')}</Link> & <Link to="/privacy-policy" className='text-decoration-none'> {at('companyRegister.privacy')} </Link>.
                                            </label>
                                            {formik.errors.profileCheckbox && formik.touched.profileCheckbox ? <small className='text-danger d-block'>{formik.errors.profileCheckbox}</small> : ""}
                                        </div>
                                    </div>
                                    <div className="text-center mt-3">
                                        <button disabled={loading} type="submit" onClick={formik.handleSubmit as any} className="border-0  p-btn btn-contine text-white fw-600">
                                            {
                                                loading ? <RotatingLines
                                                    strokeColor="white"
                                                    strokeWidth="5"
                                                    animationDuration="0.75"
                                                    width="18"
                                                    visible={true}
                                                /> : <div className='text-uppercase'>
                                                    {ct('signUp')} <FaPlayCircle className='fa-lg ms-1' />
                                                </div>
                                            }
                                        </button>
                                    </div>
                                    {/* our services section is start */}
                                    <div className='text-center'>
                                        <h1> {at('companyRegister.our')} <span className='fw-bold fw-700'> {at('companyRegister.services')} </span></h1>
                                        <p> {at('companyRegister.employersFindBetterFaster')}.</p>
                                    </div>
                                    <div className='row mt-1'>
                                        <div className='col-md-4'>
                                            <div className='database-card'>
                                                <Card className='border-0'>
                                                    <Card.Img variant="top" src="/images/database-service.jpg" className='rounded-0' />
                                                    <Card.Body className='ps-0'>
                                                        <Card.Title> {at('companyRegister.resumeDatabaseAccessTitle')} </Card.Title>
                                                        <Card.Text>
                                                            {at('companyRegister.resumeDatabaseAccessSubtitle')}.
                                                        </Card.Text>
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className='job-posting'>
                                                <Card className='border-0'>
                                                    <Card.Img variant="top" src="/images/job-post-service.png" className='rounded-0 h-148' />
                                                    <Card.Body className='ps-0 pt-2'>
                                                        <Card.Title className='mb-0'>{at('companyRegister.jobPostingTitle')} </Card.Title>
                                                        <Card.Text className='pt-lg-3 pt-md-3'>
                                                            {at('companyRegister.jobPostingSubtitle')}.
                                                        </Card.Text>
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className='social-ads'>
                                                <Card className='border-0'>
                                                    <Card.Img variant="top" src="/images/soial-service.png" className='rounded-0 h-138' />
                                                    <Card.Body className='ps-0'>
                                                        <Card.Title> {at('companyRegister.bonjanAdsTitle')} </Card.Title>
                                                        <Card.Text>
                                                            {at('companyRegister.bonjanAdsSubtitle')}.
                                                        </Card.Text>
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                        </div>
                                    </div>
                                    {/* our services section is end */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default RegisterCompany;