import {
    Europass,
    JohannesBurg,
    MonteVideo,
    NewYork,
    Toronto,
    Omsk,
    Amsterdam,
    Illinois,
    Auckland,
    Haiphong,
} from "./Pages/Resumes/ResumeTemplatesAll";

export const TemplateList: Step1TemplateSelection[] = [
    // {
    //     id: "d1e3a5d4-da4d-428c-880e-2a85f233ef62",
    //     component: Europass,
    //     type: ["All", "Modern", "Minimalist"],
    //     name: "ZURICH",
    //     badge: "NEW",
    //     imgsrc: "/images/resume-img/template-images/Zurich-template.png",
    //     colors: [{ color: "rgb(24, 160, 149)", borderColor: "rgb(37, 224, 209)" }, { color: "rgb(49, 118, 223)", borderColor: "rgb(118, 164, 234)" }, { color: "rgb(220, 127, 56)", borderColor: "rgb(253, 33, 59)" }, { color: "rgb(48, 48, 48)", borderColor: "rgb(87, 87, 87)" }, { color: "rgb(191, 191, 191)", borderColor: "rgb(191, 191, 191)" }, { color: "rgb(110, 87, 80)", borderColor: "rgb(152, 122, 113)" },]

    // },
    // {
    //     id: "4d816916-bddc-4ec9-9a85-ab434fab7533",
    //     type: ["All", "Modern"],
    //     name: "VIENNA",
    //     badge: "New",
    //     imgsrc: "/images/resume-img/template-images/Vienna-template.png",
    //     colors: [{ color: "rgb(226, 233, 232)", borderColor: "rgb(37, 224, 209)" }, { color: "rgb(243, 228, 221)", borderColor: "rgb(253, 33, 59)" }, { color: "rgb(230, 229, 226)", borderColor: "rgb(191, 191, 191)" }]

    // },
    // {
    //     id: "b98e664e-3abf-4619-990d-a73d7845fcb5",
    //     type: ["All", "Modern"],
    //     name: "QUEBEC",
    //     badge: "MOST POPULAR",
    //     imgsrc: "/images/resume-img/template-images/quebec-template.png",
    //     colors: [{ color: "rgb(129, 246, 220)", borderColor: "rgb(37, 224, 209)" }, { color: "rgb(208, 226, 255)", borderColor: "rgb(118, 164, 234)" }, { color: "rgb(253, 206, 211)", borderColor: "rgb(253, 33, 59)" }, { color: "rgb(208, 208, 208)", borderColor: "rgb(87, 87, 87)" }, { color: "rgb(238, 238, 238)", borderColor: "rgb(191, 191, 191)" }, { color: "rgb(244, 223, 205)", borderColor: "rgb(152, 122, 113)" }]

    // },
    {
        id: "b7b6c96f-a925-425b-ac70-a55833eb79ef",
        component: Toronto,
        type: ["All", "Minimalist", "Classic"],
        name: "TORONTO",
        badge: "RECOMMENDED",
        imgsrc: "/images/resume-img/template-images/toronto-template.png",
        colors: [{ color: "rgb(23, 160, 149)", borderColor: "rgb(37, 224, 209)" }, { color: "rgb(49, 118, 224)", borderColor: "rgb(118, 164, 234)" }, { color: "rgb(208, 2, 27)", borderColor: "rgb(253, 33, 59)" }, { color: "rgb(48, 48, 48)", borderColor: "rgb(87, 87, 87)" }, { color: "rgb(154, 154, 154)", borderColor: "rgb(191, 191, 191)" }, { color: "rgb(110, 87, 80)", borderColor: "rgb(152, 122, 113)" }],
        fontFamily: ['Classic', 'Modern', 'Elegant', 'Tech', 'Professional'],
        fontSize: ['small', 'medium', 'large'],
        lineHeight:1.0
    },
    {
        id: "7647e16e-3079-4720-837c-7cb05283363e",
        component: Europass,
        type: ["All", "Modern", "Minimalist"],
        name: "EUROPASS",
        badge: "EUROPEAN FORMAT",
        imgsrc: "/images/resume-img/template-images/europass-template.png",
        colors: [{ color: "rgb(49, 118, 224)", borderColor: "rgb(118, 164, 234)" }],
        fontFamily: ['Classic', 'Modern', 'Elegant', 'Tech', 'Professional'],
        fontSize: ['small', 'medium', 'large'],
        lineHeight:1.0
    },
    {
        id: "a8cb0f41-5615-4609-97c5-f30a8d34111f",
        component: MonteVideo,
        type: ["All", "Modern"],
        name: "MONTEVIDEO",
        badge: "RECOMMENDED",
        imgsrc: "/images/resume-img/template-images/montvideo-template.png",
        colors: [{ color: "rgb(23, 160, 149)", borderColor: "rgb(37, 224, 209)" }, { color: "rgb(49, 118, 224)", borderColor: "rgb(118, 164, 234)" }, { color: "rgb(208, 2, 27)", borderColor: "rgb(253, 33, 59)" }, { color: "rgb(48, 48, 48)", borderColor: "rgb(87, 87, 87)" }, { color: "rgb(154, 154, 154)", borderColor: "rgb(191, 191, 191)" }, { color: "rgb(110, 87, 80)", borderColor: "rgb(152, 122, 113)" }],
        fontFamily: ['Classic', 'Modern', 'Elegant', 'Tech', 'Professional'],
        fontSize: ['small', 'medium', 'large'],
        lineHeight:1.0
    },
    {
        id: "f16ecb7f-9cfd-47de-b5c0-288bb395547a",
        component: NewYork,
        type: ["All", "Modern"],
        name: "NEW YORK",
        imgsrc: "/images/resume-img/template-images/newyork-template.png",
        colors: [{ color: "rgb(23, 160, 149)", borderColor: "rgb(37, 224, 209)" }, { color: "rgb(49, 118, 224)", borderColor: "rgb(118, 164, 234)" }, { color: "rgb(208, 2, 27)", borderColor: "rgb(253, 33, 59)" }, { color: "rgb(48, 48, 48)", borderColor: "rgb(87, 87, 87)" }, { color: "rgb(154, 154, 154)", borderColor: "rgb(191, 191, 191)" }, { color: "rgb(110, 87, 80)", borderColor: "rgb(152, 122, 113)" }],
        fontFamily: ['Classic', 'Modern', 'Elegant', 'Tech', 'Professional'],
        fontSize: ['small', 'medium', 'large'],
        lineHeight:1.0
    },
    {
        id: "dd1ffbfe-6ca5-4978-a004-f31f384945d6",
        component: Omsk,
        type: ["All", "Modern"],
        name: "OMSK",
        imgsrc: "/images/resume-img/template-images/Omsk-template.png",
        colors: [{ color: "rgb(129, 246, 220)", borderColor: "rgb(37, 224, 209)" }, { color: "rgb(208, 226, 255)", borderColor: "rgb(118, 164, 234)" }, { color: "rgb(253, 206, 211)", borderColor: "rgb(253, 33, 59)" }, { color: "rgb(208, 208, 208)", borderColor: "rgb(87, 87, 87)" }, { color: "rgb(238, 238, 238)", borderColor: "rgb(191, 191, 191)" }, { color: "rgb(244, 223, 205)", borderColor: "rgb(152, 122, 113)" }],
        fontFamily: ['Classic', 'Modern', 'Elegant', 'Tech', 'Professional'],
        fontSize: ['small', 'medium', 'large'],
        lineHeight:1.0
    },
    {
        id: "6bfd5e83-effc-4963-bb91-a5c539db76e7",
        component: JohannesBurg,
        type: ["All", "Minimalist"],
        name: "JOHANNESBURG",
        imgsrc: "/images/resume-img/template-images/johannesburg-template.png",
        colors: [{ color: "rgb(23, 160, 149)", borderColor: "rgb(37, 224, 209)" }, { color: "rgb(49, 118, 224)", borderColor: "rgb(118, 164, 234)" }, { color: "rgb(208, 2, 27)", borderColor: "rgb(253, 33, 59)" }, { color: "rgb(48, 48, 48)", borderColor: "rgb(87, 87, 87)" }, { color: "rgb(154, 154, 154)", borderColor: "rgb(191, 191, 191)" }, { color: "rgb(110, 87, 80)", borderColor: "rgb(152, 122, 113)" }],
        fontFamily: ['Classic', 'Modern', 'Elegant', 'Tech', 'Professional'],
        fontSize: ['small', 'medium', 'large'],
        lineHeight:1.0
    },
    {
        id: "71d9b5e3-15b5-40a8-98e3-fb00889cf1bc",
        component: Illinois,
        type: ["All", "Minimalist"],
        name: "ILLINOIS",
        imgsrc: "/images/resume-img/template-images/illinois-template.png",
        colors: [{ color: "rgb(23, 160, 149)", borderColor: "rgb(37, 224, 209)" }, { color: "rgb(49, 118, 224)", borderColor: "rgb(118, 164, 234)" }, { color: "rgb(208, 2, 27)", borderColor: "rgb(253, 33, 59)" }, { color: "rgb(48, 48, 48)", borderColor: "rgb(87, 87, 87)" }, { color: "rgb(154, 154, 154)", borderColor: "rgb(191, 191, 191)" }, { color: "rgb(110, 87, 80)", borderColor: "rgb(152, 122, 113)" }],
        fontFamily: ['Classic', 'Modern', 'Elegant', 'Tech', 'Professional'],
        fontSize: ['small', 'medium', 'large'],
        lineHeight:1.0
    },
    {
        id: "769e4a65-2151-4771-984b-04fdb1d52112",
        component: Auckland,
        type: ["All", "Minimalist", "Classic"],
        name: "AUCKLAND",
        imgsrc: "/images/resume-img/template-images/Auckland-template.png",
        colors: [{ color: "rgb(23, 160, 149)", borderColor: "rgb(37, 224, 209)" }, { color: "rgb(49, 118, 224)", borderColor: "rgb(118, 164, 234)" }, { color: "rgb(208, 2, 27)", borderColor: "rgb(253, 33, 59)" }, { color: "rgb(48, 48, 48)", borderColor: "rgb(87, 87, 87)" }, { color: "rgb(154, 154, 154)", borderColor: "rgb(191, 191, 191)" }, { color: "rgb(110, 87, 80)", borderColor: "rgb(152, 122, 113)" }],
        fontFamily: ['Classic', 'Modern', 'Elegant', 'Tech', 'Professional'],
        fontSize: ['small', 'medium', 'large'],
        lineHeight:1.0
    },
    {
        id: "47830ac3-6126-4b81-ada0-43b37850256b",
        component: Haiphong,
        type: ["All", "Modern"],
        name: "HAIPHONG",
        imgsrc: "/images/resume-img/template-images/Haiphong-template.png",
        colors: [{ color: "rgb(23, 160, 149)", borderColor: "rgb(37, 224, 209)" }, { color: "rgb(49, 118, 224)", borderColor: "rgb(118, 164, 234)" }, { color: "rgb(208, 2, 27)", borderColor: "rgb(253, 33, 59)" }, { color: "rgb(48, 48, 48)", borderColor: "rgb(87, 87, 87)" }, { color: "rgb(154, 154, 154)", borderColor: "rgb(191, 191, 191)" }, { color: "rgb(110, 87, 80)", borderColor: "rgb(152, 122, 113)" }],
        fontFamily: ['Classic', 'Modern', 'Elegant', 'Tech', 'Professional'],
        fontSize: ['small', 'medium', 'large'],
        lineHeight:1.0
    },
    {
        id: "a5053e2e-cebe-4616-b68a-8291b0eed4c7",
        component: Amsterdam,
        type: ["All", "Modern"],
        name: "AMSTERDAM",
        imgsrc: "/images/resume-img/template-images/Amsterdam-template.png",
        colors: [{ color: "rgb(153, 162, 152)", borderColor: "rgb(150, 155, 158)" }, { color: "rgb(153, 162, 179)", borderColor: "rgb(150, 155, 158)" }, { color: "rgb(191, 169, 169)", borderColor: "rgb(150, 155, 158)" }, { color: "rgb(163, 163, 163)", borderColor: "rgb(150, 155, 158)" }, { color: "rgb(154, 154, 154)", borderColor: "rgb(191, 191, 191)" }, { color: "rgb(191, 180, 167)", borderColor: "rgb(150, 155, 158)" }],
        fontFamily: ['Classic', 'Modern', 'Elegant', 'Tech', 'Professional'],
        fontSize: ['small', 'medium', 'large'],
        lineHeight:1.0
    },

    // {
    //     id: "a5053e2e-cebe-4616-b68a-8291b0eed4c7",
    //     type: ["All", "Classic"],
    //     name: "SAN FRANCISCO",
    //     imgsrc: "/images/resume-img/template-images/sanfrancisco-template.png",
    //     colors: [{ color: "rgb(23, 160, 149)", borderColor: "rgb(37, 224, 209)" }, { color: "rgb(49, 118, 224)", borderColor: "rgb(118, 164, 234)" }, { color: "rgb(208, 2, 27)", borderColor: "rgb(253, 33, 59)" }, { color: "rgb(48, 48, 48)", borderColor: "rgb(87, 87, 87)" }, { color: "rgb(154, 154, 154)", borderColor: "rgb(191, 191, 191)" }, { color: "rgb(110, 87, 80)", borderColor: "rgb(152, 122, 113)" }]
    // },
    // {
    //     id: "3aea1389-fb3c-419a-9b0a-84249c8241de",
    //     type: ["All", "Modern", "Classic"],
    //     name: "PRAGUE",
    //     imgsrc: "/images/resume-img/template-images/prague-template.png",
    //     colors: [{ color: "rgb(23, 160, 149)", borderColor: "rgb(37, 224, 209)" }, { color: "rgb(49, 118, 224)", borderColor: "rgb(118, 164, 234)" }, { color: "rgb(208, 2, 27)", borderColor: "rgb(253, 33, 59)" }, { color: "rgb(48, 48, 48)", borderColor: "rgb(87, 87, 87)" }, { color: "rgb(154, 154, 154)", borderColor: "rgb(191, 191, 191)" }, { color: "rgb(110, 87, 80)", borderColor: "rgb(152, 122, 113)" }]
    // },
]