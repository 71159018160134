import React from 'react';
import Table from "react-bootstrap/Table";
import { GetAxios, UserRole } from '../../Shared';
import { config } from '../../Environments';
import { useDispatch } from 'react-redux';
import { setGlobalLoading } from '../../Store';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from "react-redux";
import InvoiceModal from './InvoiceModal';
import { Link } from 'react-router-dom';

const PendingInoice = () => {
    const dispatch = useDispatch();
    const [list, setList] = React.useState<any>([]);
    const [params] = useSearchParams();
    const auth = useSelector((state: AppStore) => state.auth);
    const userRole = UserRole(auth?.raw?.access_token);


    React.useEffect(() => {
        dispatch(setGlobalLoading(true));
        let url = config.apiBase + '/api/Companies/GetCompanyInvoices';
        if (params.has('id')) {
            url += ('?id=' + params.get('id'));

        }
        GetAxios().get(url).then(res => {
            setList(res.data.data || []);
            dispatch(setGlobalLoading(false));
        });
    }, []);



    return (
        <div className='container my-3' style={{ height: '500px' }}>
            <h4 className='fw-700 mt-2'>Pending Invoice(s)</h4>
            <div className="row bg-white my-4">
                <div className="col-md-5 my-2">
                    <p className="mb-0 text-danger fw-500">
                        The following ads are <span className="text-uppercase">not</span>{" "}
                        published, pending payment.
                    </p>
                    <p className="mb-0 fw-500 fs-14">Please send your check to:</p>
                    <h5 className="mb-0 fw-700">Maestro Media</h5>
                    <p className="mb-0 fw-500 fs-14">25 bia, 2eme ruelle Nazon</p>
                    <p className="mb-0 fw-500 fs-14">Port-au-Prince, Haiti HT-6111</p>
                </div>
                <div className="col-md-7">
                    <div className="d-flex justify-content-between bg-pending">
                        <div className="">
                            <h2 className="text-uppercase fw-700 color-pink">
                                call us here:
                            </h2>
                            <div className="d-flex">
                                <img
                                    src="/images/phone-icon.png"
                                    className="img-fluid icon-phone"
                                    alt="phone icon"
                                />
                                <h3>
                                    <span className="fw-bolder ms-3 color-silver">
                                        509 3114 3168
                                    </span>
                                </h3>
                            </div>
                        </div>
                        <div className="">
                            <h4 className="fw-600 mt-25 color-pink">or</h4>
                        </div>
                        <div className="">
                            <h2 className="text-uppercase fw-700 color-pink">
                                text us here:
                            </h2>
                            <div className="d-flex">
                                <img
                                    src="/images/whatsapp-icon.png"
                                    className="img-fluid icon-whatsapp"
                                    alt="whatsapp icon"
                                />
                                <h3>
                                    <span className="fw-bolder ms-3 color-silver">
                                        509 3276 8738
                                    </span>
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <Table bordered responsive className="bg-white">
                        <thead>
                            <tr>
                                <th className="text-center w-50px">
                                    <div>
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="checkboxNoLabel"
                                            value=""
                                            aria-label="..."
                                        />
                                    </div>
                                </th>
                                <th>Pending Invoice #</th>
                                <th>Status</th>
                                <th>Advertising #</th>
                                <th>Billing Date</th>
                                <th className="text-end pe-3">Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                list.map((item: any, index: number) => (
                                    <tr key={"invoice_key" + index}>
                                        <td className="text-center ">
                                            <div>
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="checkboxNoLabel"
                                                    value=""
                                                    aria-label="..."
                                                />
                                            </div>
                                        </td>
                                        <td>



                                            <InvoiceModal invoiceNo={item.invoiceNo} invoiceId={item.id} />

                                        </td>
                                        <td>
                                            <span className="text-decoration-none fw-500 color-status">
                                                Not published
                                            </span>
                                        </td>
                                        <td>
                                            <Link to="" className="text-decoration-none fw-500">
                                                {item.advertisingNo}
                                            </Link>
                                        </td>
                                        <td className="fw-500">{item.billingDate}</td>
                                        <td className="text-end pe-3">
                                            <span className="fw-bold">{item.amount}</span>{" "}
                                            <span className="text-uppercase fw-500">Usd</span>
                                        </td>
                                    </tr>


                                ))
                            }
                        </tbody>
                    </Table>
                </div>
            </div>
            {/* <InvoiceModal showModal={invoiceModal} closeModal={() => SetInvoiceModal(false)} invoiceId={invoiceId} />
     */}
        </div>
    )
}


export default PendingInoice;




