import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { config } from "../../../../../../Environments";
import { decodeHTMLEntities, GetAxios, reorder } from "../../../../../../Shared";
import { setGlobalLoading } from "../../../../../../Store";
import { setResumeActive, setResumeAddRecord, setResumeRemoveRecord, setResumeReordered } from "../../../../../../Store/Slices/ResumeSlice";
import { AddAnother } from "../../../../Components/AddAnother";
import { BottomButtons } from "../../BottomButtons";
import { FaPencilAlt, FaRegTrashAlt, FaArrowsAlt } from "react-icons/fa";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip, { TooltipProps } from 'react-bootstrap/Tooltip';
import { confirmAlert } from "react-confirm-alert";
import { useTranslation } from 'react-i18next';


const currentSectionId = 11;
const currentSectionTitle = 'Achievements';
export const AchievementList = () => {

    const { t } = useTranslation('resume');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { resumeId } = useParams();

    const records = useSelector((state: AppStore) => state.resume.customSection).filter(x => x.sectionId == currentSectionId);

    const Add = () => {
        dispatch(setGlobalLoading(true));
        GetAxios(navigate, dispatch)
            .post(config.apiBase + '/api/Resume/SaveAdditionalStep', {
                resumeId: resumeId,
                sectionId: currentSectionId,
                title: currentSectionTitle,
                content: '',
                isCustomSection: false
            })
            .then(s => {
                const section = {
                    id: s.data.data,
                    resumeId: Number(resumeId),
                    isCustomSection: false,
                    sectionId: currentSectionId,
                    title: currentSectionTitle,
                };
                dispatch(setResumeAddRecord({
                    customSection: section
                }));
                dispatch(setResumeActive({
                    customSection: section
                }));
                navigate('edit/' + s.data.data);
                dispatch(setGlobalLoading(false));
            });
    };

    const Delete = (s: ResumeCustomSection) => {
        confirmAlert({
            title: t('newResumeCard.deleteResumeMsg.title').toString(),
            message: t('newResumeCard.deleteResumeMsg.description').toString(),
            closeOnClickOutside: false,
            closeOnEscape: false,
            buttons: [
                {
                    label: t('yes').toString(),
                    className: 'bg-danger',
                    onClick: _ => {
                        GetAxios(navigate, dispatch).delete(config.apiBase + '/api/Resume/DeleteRecord', {
                            params: {
                                id: s.id,
                                resumeId,
                                type: 'CustomSections'
                            }
                        }).then(res => {
                            dispatch(setResumeRemoveRecord({ customSection: s }));
                        })
                    }
                },
                {
                    label: t('no').toString(),
                    onClick: _ => {
                        return
                    }
                }
            ]
        })

    }
    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <div className="subsection-container">
                        <div className="mb-3">
                            <div className="dZcjzV sections-header">
                                <div className="feVKyO"> {t('achiveStep.achiveTitle')}?
                                </div>
                            </div>
                            <div className="gJaquO"> {t('achiveStep.achiveDesc')}.
                            </div>
                        </div>
                        <div className="gQiRPX">
                            <div className="fFIUJl sections-header">
                                <div className="dJqmzX">
                                    <div className="hfzZao">
                                        <div className="czFa-Df"> {t('achiveStep.achive')}</div>
                                    </div>
                                    <svg className="hMpiDu" focusable="false" viewBox="0 0 24 24" aria-hidden="true" >
                                        <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34a.9959.9959 0 00-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"></path>
                                    </svg>
                                </div>
                            </div>
                            <div className="gBqHRE"></div>
                        </div>
                        <div className="cards-main">
                            <div className="sections-cards">
                                <DragDropContext onDragEnd={e => {
                                    if (!e.destination) {
                                        return;
                                    }

                                    const items = reorder(records, e.source.index, e.destination.index);

                                    dispatch(setResumeReordered({ customSection: items }));
                                    GetAxios(navigate, dispatch).patch(config.apiBase + '/api/Resume/UpdateSortOrder', items.map(s => s.id), {
                                        params: {
                                            resumeId: resumeId,
                                            type: 'CustomSections'
                                        }
                                    });
                                }}>
                                    <Droppable droppableId="droppable">
                                        {
                                            (provider, _) => (
                                                <div {...provider.droppableProps}
                                                    ref={provider.innerRef}>
                                                    {
                                                        records.map((s, i) =>
                                                            <Draggable key={"i_" + s.id} draggableId={s.id + '1'} index={i}>
                                                                {(provided, _) => (
                                                                    <div className="cards-list" ref={provided.innerRef} {...provided.draggableProps}>
                                                                        <div className="sections-cards-Card">
                                                                            <div className="card-wrapper">
                                                                                <div className="section-card-content">
                                                                                    <div className="MuiCardHeader-root" >
                                                                                        <div className="MuiCardHeader-content">
                                                                                            <span className="MuiCardHeader-title text-darker">{decodeHTMLEntities(s.content)?.substring(0, 25)}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="edit-icon d-flex align-items-center">
                                                                                    <Link to={''} onClick={() => Delete(s)}>
                                                                                        <span className="">
                                                                                            <FaRegTrashAlt className="icon-gray" size={17} />
                                                                                        </span>
                                                                                    </Link>
                                                                                    <Link to={'edit/' + s.id}>
                                                                                        <span className="mx-4">
                                                                                            <FaPencilAlt className="icon-gray" size={18} />
                                                                                        </span>
                                                                                    </Link>
                                                                                    <div {...provided.dragHandleProps}>
                                                                                        <span>
                                                                                            <OverlayTrigger
                                                                                                overlay={<Tooltip>{t('dragDropRecord')}</Tooltip>}
                                                                                                placement="top">
                                                                                                {({ ref, ...triggerHandler }) => (
                                                                                                    <span className="cursor-pointer" {...triggerHandler} ref={ref}><FaArrowsAlt className="icon-gray" size={18} /></span>
                                                                                                )}
                                                                                            </OverlayTrigger>
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        )
                                                    }
                                                </div>
                                            )
                                        }
                                    </Droppable>
                                </DragDropContext>
                                <AddAnother action={Add} />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <BottomButtons onSubmit={e => e()} />
        </>
    );
};
