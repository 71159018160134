
export const NavbarLIST = [
	// {
	// 	link: "",
	// 	border: false,
	// 	title: "jobs",
	// 	forAllInRole: ["Employer", "Employee", ""],
	// 	children: [
	// 		{
	// 			link: "/job/jobsearch",
	// 			title: "jobSearch",
	// 		},
	// 		{
	// 			link: "/call-for-tenders",
	// 			title: "callForTender",
	// 			requireAuthenticatedUser: true,
	// 			mustBeInRoles: ['Employer']
	// 		},
	// 	]
	// },
	{
		link: "/job/jobsearch",
		border: false,
		color:false,
		display:true,
		title: "jobSearch",
		forAllInRole: ["Employer", "Employee", "Admin",""],
		children: []
	},
	{
		link: "",
		border: false,
		color:false,
		display:true,
		title: "jobSeeker",
		forAllInRole: ["Employee"],
		children: [
			{
				link: "/candidate/candidateprofile",
				title: "myProfile",
				requireAuthenticatedUser: false,
				color:false,
				mustBeInRoles: ['Employee']
			},
			{
				link: "/candidate/editcandidateprofile",
				title: "buildMyProfile",
				requireAuthenticatedUser: true,
				color:false,
				mustBeInRoles: ['Employee']
			}
		]
	},
	{
		link: "/announces",
		border: false,
		display:true,
		color:false,
		title: "seminars",
		forAllInRole: ["Employer", "Employee","Admin",""],
		children: []
	},
	{
		link: "/call-for-tenders",
		border: false,
		display:true,
		color:false,
		title: "callForTender",
		forAllInRole: ["Employer", "Employee", "Admin",""],
		children: []
	},
	{
		link: "/job/jobregistration",
		border: false,
		display:true,
		color:false,
		title: "postANewJob",
		requireAuthenticatedUser: true,
		forAllInRole: [""],
		mustBeInRoles: ['Employer'],
		children: []
	},
	// {
	// 	link: "",
	// 	border: false,
	// 	title: "uploadANewJob",
	// 	forAllInRole: [""],
	// 	children: [
	// 		{
	// 			link: "/company/list",
	// 			title: "employersListed"
	// 		}
	// 	]
	// },
	//change title
	{
		link: "/resumes",
		border: false,
		display:true,
		color:true,
		title: "buildMyFreeResume",
		forAllInRole: ["Employee", ""],
		children: [
			{
				link: "/resumes",
				title: "resumeBuilder",
				color:true,
			},
			// {
			// 	link: "/resumes/builder/templates",
			// 	title: "resumeTemplates",
			// },
			{
				link: "/resumes/examples",
				title: "resumeExamples",
				color:true,
			},
			{
				link: "/resumes/format",
				title: "resumeFormat",
				color:true
			},
		]
	},
	// {
	// 	link: "/businesslisting",
	// 	border: false,
	// 	title: "businessListing",
	// 	forAllInRole: ["Employer", "Employee", ""],
	// 	children: []
	// },
	{
		link: "/contact",
		border: false,
		display:true,
		color:false,
		title: "contact",
		forAllInRole: ["Employer", "Employee", "Admin",""],
		children: []
	},
	{
		link: "",
		border: true,
		display:true,
		color:false,
		title: "myInstitution",
		forAllInRole: ["Employer"],
		children: [
			// {
			// 	link: "/company/list",
			// 	title: "employersListed",
			// 	requireAuthenticatedUser: true,
			// 	mustBeInRoles: ['Employer']
			// },
			{
				link: "/company/profile",
				title: "myEmployerProfile",
				color:false,
				//requireAuthenticatedUser: true,
				//	mustBeInRoles: ['Employer']
			},
			{
				link: "/company/applicants",
				title: "employerApplicants",
				requireAuthenticatedUser: true,
				color:false,
				mustBeInRoles: ['Employer']
			},
			{
				link: "/company/proposals",
				title: "employerProposals",
				requireAuthenticatedUser: true,
				color:false,
				mustBeInRoles: ['Employer']
			},
			{
				link: "/company/editcompanyprofile",
				title: "editEmployerProfile",
				requireAuthenticatedUser: true,
				mustBeInRoles: ['Employer']
			},
			// {
			// 	link: "/company/registration",
			// 	title: "registration",
			// 	allowAnonymous: true,
			// 	forAllInRole: ['']
			// },
		]
	},
	{
		link: "/resume/bank",
		border: false,
		display:false,
		color:false,
		title: "resumeBank",
		forAllInRole: ["Employer"],
		children: []
	},
	// {
	// 	link: "/employer-account/upgrade-my-posting-account",
	// 	border: false,
	// 	title: "upgradeMyPostingAccount",
	// 	forAllInRole: ["Employer"],
	// 	children: []
	// },
	{
		link: "",
		border: false,
		display:true,
		color:false,
		title: "account",
		forAllInRole: ["Employer"],
		children: [
			{
				link: "/employer-account/account-setting",
				title: "myProfileAccount",
				color:false,
			},
			{
				link: "/account/changepassword",
				title: "changePassword",
				color:false,
			},
			// {
			// 	link: "/account/account-details",
			// 	title: "accountDetails",
			// }
		]
	},
	{
		link: "/employer-account",
		border: false,
		color:false,
		display:true,
		title: "myAds",
		forAllInRole: ["Employer"],
		children: []
	},
	// {
	// 	link: "",
	//color:false,
	//display:true,
	// 	border: false,
	// 	title: "Account Settings",
	// 	forAllInRole: ["Employer"],
	// 	children: [
	// 		{
	// 			link: "",
	// 			title: "billing",
	// 		},
	// 		{
	// 			link: "",
	// 			title: "paymentMethods",
	// 		},
	// 		{
	// 			link: "/employer-account/order-history",
	// 			title: "orderHistory",
	// 		}
	// 	]
	// },

]

