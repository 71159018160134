import { createSlice } from "@reduxjs/toolkit"
import { AvailableExtraSteps, AvailableSteps, DefaultProfileImage } from "../../Pages/Resumes/SharedData";
import { Sorter } from "../../Shared";

const maxPossibleSteps = AvailableSteps.concat(AvailableExtraSteps);

const initialState: ResumeSlice = {
    activeStep: 1,
    image: '',
    imageData: {
        isRounded: false
    },
    objectives:"<p></p>",
    additionalSettings: {
        color: '',
        fontFamily: 'Classic',
        fontSize: 'medium',
        lineHeight: 1.0
    },
    title: '',
    submitting: false,
    contactInformation: {
        dob: new Date().toISOString()
    },
    stepsInformation: maxPossibleSteps.map(s => ({
        path: s.path,
        position: s.position,
        title: s.title,
        description: s.description
    })),
    educations: [],
    experiences: [],
    languages: [],
    references:[],
    skills: [],
    accessInformation:{classId:0,deptId:0,sectorId:0},
    activeEducation: undefined,
    activeExperience: undefined,
    activeLanguage: undefined,
    activeSkill: undefined,
    appliedSections: [],
    customSection: []
}

const resumeSlice = createSlice({
    name: 'resume',
    initialState,
    reducers: {
        setResumeSubmitting: (state, action: IAction<boolean>) => { state.submitting = action.payload },
        setResumeActiveStep: (state, action: IAction<AvailableSteps>) => { state.activeStep = action.payload },
        setResumeContactInformation: (state, action: IAction<ResumeContactInformation>) => {
            state.contactInformation = { ...state.contactInformation, ...action.payload };
        },
        setResumeAccessInformation: (state, action: IAction<ResumeAccessInformation>) => {
            state.accessInformation = { ...state.accessInformation, ...action.payload };
        },
        setResumeAddRecord: (state, action: IAction<RecordsTypes>) => {
            if (action.payload.skills) {
                const index = state.skills.findIndex(s => s.id === action.payload.skills?.id);
                if (index > -1)
                    state.skills[index] = action.payload.skills;
                else
                    state.skills = [...state.skills, action.payload.skills];
            }
            if (action.payload.languages) {
                const index = state.languages.findIndex(s => s.id === action.payload.languages?.id);
                if (index > -1)
                    state.languages[index] = action.payload.languages;
                else
                    state.languages = [...state.languages, action.payload.languages];
            }
            if (action.payload.references) {
                const index = state.references.findIndex(s => s.id === action.payload.references?.id);
                if (index > -1)
                    state.references[index] = action.payload.references;
                else
                    state.references = [...state.references, action.payload.references];
            }
            if (action.payload.experiences) {
                const index = state.experiences.findIndex(s => s.id === Number(action.payload.experiences?.id));
                if (index > -1) {
                    state.experiences[index] = action.payload.experiences;
                }
                else
                    state.experiences.push(action.payload.experiences);
            }
            if (action.payload.educations) {
                const index = state.educations.findIndex(s => s.id === action.payload.educations?.id);
                if (index > -1)
                    state.educations[index] = action.payload.educations;
                else
                    state.educations = [...state.educations, action.payload.educations];
            }
            if (action.payload.customSection) {
                const index = state.customSection.findIndex(s => s.id === action.payload.customSection?.id);
                if (index > -1)
                    state.customSection[index] = action.payload.customSection;
                else
                    state.customSection = [...state.customSection, action.payload.customSection];
            }
        },
        setResumeReordered: (state, action: IAction<RecordsListTypes>) => {
            if (action.payload.educations) {
                state.educations = action.payload.educations;
            }
            if (action.payload.customSection) {
                const ids = action.payload.customSection.map(s => s.id);
                ids.forEach(s => {
                    state.customSection.splice(state.customSection.findIndex(x => x.id == s), 1);
                });
                action.payload.customSection.forEach(s => state.customSection.push(s));
            }
            if (action.payload.experiences) {
                state.experiences = action.payload.experiences;
            }
            if (action.payload.languages) {
                state.languages = action.payload.languages;
            }
            if (action.payload.references) {
                state.references = action.payload.references;
            }
            if (action.payload.skills) {
                state.skills = action.payload.skills;
            }
        },
        setResumeRemoveRecord: (state, action: IAction<RecordsTypes>) => {
            if (action.payload.skills) {
                const index = state.skills.findIndex(s => s.id === action.payload.skills?.id);
                if (index > -1)
                    state.skills.splice(index, 1)
            }
            if (action.payload.languages) {
                const index = state.languages.findIndex(s => s.id === action.payload.languages?.id);
                if (index > -1)
                    state.languages.splice(index, 1)
            }
            if (action.payload.references) {
                const index = state.references.findIndex(s => s.id === action.payload.references?.id);
                if (index > -1)
                    state.references.splice(index, 1)
            }
            if (action.payload.experiences) {
                const index = state.experiences.findIndex(s => s.id === action.payload.experiences?.id);
                if (index > -1)
                    state.experiences.splice(index, 1)
            }
            if (action.payload.educations) {
                const index = state.educations.findIndex(s => s.id === action.payload.educations?.id);
                if (index > -1)
                    state.educations.splice(index, 1)
            }
            if (action.payload.customSection) {
                const index = state.customSection.findIndex(s => s.id === action.payload.customSection?.id);
                if (index > -1)
                    state.customSection.splice(index, 1)
            }
        },
        setResumeActive: (state, action: IAction<RecordsTypes>) => {
            state.activeSkill = action.payload.skills;
            state.activeEducation = action.payload.educations;
            state.activeExperience = action.payload.experiences;
            state.activeLanguage = action.payload.languages;
            state.activeReference=action.payload.references;
            state.activeCustomSection = action.payload.customSection;
        },
        setAddStepInformation: (state, action: IAction<StepsInformation[]>) => {
            action.payload.sort(Sorter('position')).forEach(s => {
                const index = state.stepsInformation.indexOf(s);
                if (index === -1) {
                    state.stepsInformation.splice(s.position - 1, 0, s);
                }
                state.stepsInformation = state.stepsInformation.sort(Sorter('position'))
            });
        },
        setRemoveStepInformation: (state, action: IAction<AvailableSteps>) => {
            const index = state.stepsInformation.findIndex(x => x.position == action.payload);
            if (index > -1) {
                state.stepsInformation.splice(index, 1);
                if (action.payload == 3)
                    state.experiences.splice(0, state.experiences.length);

                if (action.payload == 4)
                    state.educations.splice(0, state.educations.length);

                if (action.payload == 5) {
                    state.skills.splice(0, state.skills.length);
                  
                }
                if (action.payload == 6) {
                    state.languages.splice(0, state.languages.length);
                }
                if (action.payload == 7)
                    state.objectives = '';
                if (action.payload == 8) {
                        state.references.splice(0, state.references.length);
                }
                //Search Information will not be deleted 
                if (action.payload >= 10) {
                    const section = state.customSection.filter(s => s.sectionId == action.payload);
                    section.forEach(s => {
                        const index = state.customSection.indexOf(s);
                        if (index > -1) {
                            state.customSection.splice(index, 1);
                        }
                    });
                }
            }
        },
        setResumeForEdit: (state, action: IAction<{ [x in keyof ResumeSlice]?: ResumeSlice[x] }>) => {
            if (action.payload.image)
                state.image = action.payload.image;
            if (action.payload.objectives)
                state.objectives = action.payload.objectives;
            if (action.payload.imageData)
                state.imageData = action.payload.imageData;
            if (action.payload.title)
                state.title = action.payload.title;
            if (action.payload.templateId)
                state.templateId = action.payload.templateId;
            if (action.payload.contactInformation)
                state.contactInformation = action.payload.contactInformation;
            if (action.payload.educations)
                state.educations = action.payload.educations;
            if (action.payload.experiences)
                state.experiences = action.payload.experiences;
            if (action.payload.languages)
                state.languages = action.payload.languages;
            if (action.payload.references)
                state.references = action.payload.references;
            if (action.payload.skills)
                state.skills = action.payload.skills;
            if (action.payload.accessInformation)
                state.accessInformation = action.payload.accessInformation;
            if (action.payload.appliedSections) {
                state.stepsInformation = action.payload.appliedSections.map(s => (maxPossibleSteps[s.position - 1])).sort(Sorter('position'))
            }
            if (action.payload.customSection)
                state.customSection = action.payload.customSection;
            if (action.payload.additionalSettings)
                state.additionalSettings = action.payload.additionalSettings;
        },
        setResumeObjectives: (state, action: IAction<string>) => {
            state.objectives = action.payload;
        },
        setResumeTemplate: (state, action: IAction<string>) => {
            state.templateId = action.payload;
        },
        setResumeImage: (state, action: IAction<string | undefined>) => {
            state.image = action.payload;
        },
        setResumeImageData: (state, action: IAction<{ isRounded: boolean }>) => {
            state.imageData = {
                isRounded: action.payload.isRounded
            }
        },
        setResumeSettings: (state, action: IAction<{ [n in keyof AdditionalSettings]?: AdditionalSettings[n] }>) => {
            state.additionalSettings = { ...state.additionalSettings, ...action.payload };
        }
    },
});

export const {
    setResumeSubmitting,
    setResumeActiveStep,
    setResumeContactInformation,
    setResumeAccessInformation,
    setResumeAddRecord,
    setResumeRemoveRecord,
    setResumeActive,
    setAddStepInformation,
    setResumeForEdit,
    setResumeObjectives,
    setResumeTemplate,
    setRemoveStepInformation,
    setResumeReordered,
    setResumeImage,
    setResumeImageData,
    setResumeSettings
} = resumeSlice.actions;

export default resumeSlice.reducer

interface RecordsTypes {
    skills?: ResumeSkills,
    languages?: ResumeLanguages,
    references?:ResumeReferences, 
    experiences?: ResumeExperiences,
    educations?: ResumeEducation,
    customSection?: ResumeCustomSection
}
interface RecordsListTypes {
    skills?: ResumeSkills[],
    languages?: ResumeLanguages[],
    references?:ResumeReferences[], 
    experiences?: ResumeExperiences[],
    educations?: ResumeEducation[],
    customSection?: ResumeCustomSection[]
}
