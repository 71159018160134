import React, { useState } from 'react';
import moment from 'moment';
import { DefaultProfileImage, SelectableSectionClicked } from '../SharedData';
import styled from "styled-components";
import { useTranslation } from 'react-i18next';


export const Amsterdam = (props: TemplateComponentProps) => {

    const { t } = useTranslation('resume');

    const {
        navigate,
        contactInformation,
        image,
        imageData,
        objectives,
        experiences,
        educations,
        languages,
        skills,
        achievements,
        certificates,
        customSections,
        honours,
        projects,
        publications,
        references,
        additionalSettings,
        stepsInformation,
        onNavigatedToSection
    } = props;


    return (
        <>
            <WrapperDiv additionalSettings={additionalSettings}>
                <div className={navigate ? ' p-0' : ''}>
                    <div className='template'>
                        <div className='preview-template'>
                            <div id="template-full">
                                <div className='template-main'>
                                    <div className='template_contentss row'>
                                        <div className='main-content-template col-7'>
                                            <div className='border-start border-2 ms-2'>
                                                <div className='template-header-amsterdam '>
                                                    {
                                                        (contactInformation.firstName || contactInformation.lastName || contactInformation.occupation) &&
                                                        <section className='head-two text-end selectable-section' onClick={_ => SelectableSectionClicked('contact-information', navigate, onNavigatedToSection)}>
                                                            <div className='fs-'>
                                                                <span className='mb-0 text-uppercase'>{contactInformation.firstName} {contactInformation.lastName}</span>
                                                            </div>
                                                            <div className='template_subtitle'>
                                                                <p className='mb-2'>{contactInformation.occupation}</p>
                                                            </div>
                                                        </section>
                                                    }
                                                </div>
                                                {
                                                    stepsInformation.findIndex(x => x.title == 'Experiences') > -1 && experiences.length > 0 ?
                                                        <section className='experience-sec padding-side-amsterdam' onClick={_ => SelectableSectionClicked('work-experience', navigate, onNavigatedToSection)}>
                                                            <div className='mt-3 mb-16 selectable-section'>
                                                                <p className='text-uppercase title-objective mb-16 fw-600'> {t('Experiences')} </p>
                                                                <div className='contact-information position-relative mb-16'>
                                                                    <div className='contact-information'>
                                                                        {
                                                                            experiences.map((e, i) =>
                                                                                <div className='row mb-3' key={'exp_' + i}>
                                                                                    <div className='col-4'>
                                                                                        <div className='border-left-amsterdam'>
                                                                                            {
                                                                                                (e.startDate || e.endDate) ?
                                                                                                    <p className='fw-400'>
                                                                                                        {
                                                                                                            e.startDate ?
                                                                                                                <span>{new Date(e.startDate).toLocaleString('default', { month: 'short' })} {new Date(e.startDate).getFullYear()}</span> : <></>
                                                                                                        }
                                                                                                        {
                                                                                                            (e.endDate && !e.currentlyWorking) ?
                                                                                                                <span> - {new Date(e.endDate).toLocaleString('default', { month: 'short' })} {new Date(e.endDate).getFullYear()}</span> : <></>
                                                                                                        }
                                                                                                        {
                                                                                                            e.currentlyWorking ?
                                                                                                                <em> - {t('present')} </em> : <></>
                                                                                                        }
                                                                                                    </p> : <></>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-8'>
                                                                                        <div className=''>
                                                                                            {
                                                                                                e.title &&
                                                                                                <p className='sec-subtitle me-2 text-uppercase'>{e.title}</p>
                                                                                            }
                                                                                            {
                                                                                                e.company &&
                                                                                                <p className='sec-value'>{e.company}</p>
                                                                                            }
                                                                                            {
                                                                                                e.location ?
                                                                                                    <p className='sec-value address-amsterdam'>{e.location}</p> : <></>
                                                                                            }
                                                                                            {
                                                                                                e.description &&
                                                                                                <p className='mt-1 sec-value editor-list-margin-left' dangerouslySetInnerHTML={{ __html: e.description }}></p>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </section> : <></>
                                                }
                                                {
                                                    stepsInformation.findIndex(x => x.title == 'Education') > -1 && educations.length > 0 &&
                                                    <section className='education-sec padding-side-amsterdam' onClick={_ => SelectableSectionClicked('education', navigate, onNavigatedToSection)}>
                                                        <div className='mt-3 mb-16 selectable-section'>
                                                            <p className='text-uppercase title-objective mb-16 fw-600'>  {t('Education')} </p>
                                                            <div className='contact-information d-flex position-relative mb-16'>
                                                                <div className='contact-information'>
                                                                    {
                                                                        educations.map((s, i) =>
                                                                            <div className='row mb-3' key={'edu_' + i}>
                                                                                <div className='col-4'>
                                                                                    <div className='border-left-amsterdam'>
                                                                                        {
                                                                                            (s.startDate || s.completeDate) &&
                                                                                            <p className='m-right'>
                                                                                                {
                                                                                                    s.startDate &&
                                                                                                    <span>{new Date(s.startDate).toLocaleString('default', { month: 'short' })} {new Date(s.startDate).getFullYear()}</span>
                                                                                                }
                                                                                                {
                                                                                                    (s.completeDate && !s.currentlyStudying) &&
                                                                                                    <span> - {new Date(s.completeDate).toLocaleString('default', { month: 'short' })} {new Date(s.completeDate).getFullYear()}</span>
                                                                                                }
                                                                                                {
                                                                                                    (s.currentlyStudying) &&
                                                                                                    <span> - {t('present')}</span>
                                                                                                }
                                                                                            </p>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                <div className='col-8'>
                                                                                    <div className=''>
                                                                                        {
                                                                                            s.degree &&
                                                                                            <p className='sec-subtitle me-2 text-uppercase'>{s.degree}</p>
                                                                                        }
                                                                                        {
                                                                                            s.institute &&
                                                                                            <p className='sec-value'>{s.institute}</p>
                                                                                        }
                                                                                        {
                                                                                            s.location &&
                                                                                            <p className='sec-value address-amsterdam'>{s.location}</p>
                                                                                        }
                                                                                        {
                                                                                            s.description &&
                                                                                            <p className='mt-1 sec-value editor-list-margin-left' dangerouslySetInnerHTML={{ __html: s.description }}></p>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                }
                                                {
                                                    stepsInformation.findIndex(x => x.title == 'Projects') > -1 && projects.length > 0 &&
                                                    <section className='projects-sec padding-side-amsterdam' onClick={_ => SelectableSectionClicked('projects', navigate, onNavigatedToSection)}>
                                                        <div className='mt-3 mb-16 selectable-section'>
                                                            <p className='text-uppercase title-objective mb-16 fw-600'> {t('Projects')} </p>
                                                            <div className='mt-2'>
                                                                {
                                                                    projects.map((v, i) =>
                                                                        <div key={'projects__' + i}>
                                                                            <p className='sec-value my-2 editor-list-margin-left' dangerouslySetInnerHTML={{ __html: v.content || '' }}></p>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </section>
                                                }
                                                {
                                                    stepsInformation.findIndex(x => x.title == 'Achievements') > -1 && achievements.length > 0 &&
                                                    <section className='Achievements-sec padding-side-amsterdam' onClick={_ => SelectableSectionClicked('achievements', navigate, onNavigatedToSection)}>
                                                        <div className='mt-3 mb-16 selectable-section'>
                                                            <p className='text-uppercase title-objective mb-16 fw-600'> {t('Achievements')} </p>
                                                            <div className='mt-2'>
                                                                {
                                                                    achievements.map((v, i) =>
                                                                        <div key={'achievements__' + i}>
                                                                            <p className='sec-value my-2 editor-list-margin-left' dangerouslySetInnerHTML={{ __html: v.content || '' }}></p>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </section>
                                                }
                                                {
                                                    stepsInformation.findIndex(x => x.title == 'Certifications & Courses') > -1 && certificates.length > 0 &&
                                                    <section className='certificates-courses-sec padding-side-amsterdam' onClick={_ => SelectableSectionClicked('certifications-courses', navigate, onNavigatedToSection)}>
                                                        <div className='mt-3 mb-16 selectable-section'>
                                                            <p className='text-uppercase title-objective mb-16 fw-600'>  {t('Certifications & Courses')} </p>
                                                            <div className='mt-2'>
                                                                {
                                                                    certificates.map((v, i) =>
                                                                        <div key={'certificates__' + i}>
                                                                            <p className='sec-value my-2 editor-list-margin-left' dangerouslySetInnerHTML={{ __html: v.content || '' }}></p>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </section>
                                                }
                                                {
                                                    stepsInformation.findIndex(x => x.title == 'Honors & Awards') > -1 && honours.length > 0 &&
                                                    <section className='honours-awards-sec padding-side-amsterdam' onClick={_ => SelectableSectionClicked('honors-awards', navigate, onNavigatedToSection)}>
                                                        <div className='mt-3 mb-16 selectable-section'>
                                                            <p className='text-uppercase title-objective mb-16 fw-600'>  {t('Honors & Awards')} </p>
                                                            <div className='mt-2'>
                                                                {
                                                                    honours.map((v, i) =>
                                                                        <div key={'honour__' + i}>
                                                                            <p className='sec-value my-2 editor-list-margin-left' dangerouslySetInnerHTML={{ __html: v.content || '' }}></p>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </section>
                                                }
                                                {
                                                    stepsInformation.findIndex(x => x.title == 'Publications') > -1 && publications.length > 0 &&
                                                    <section className='publication-sec padding-side-amsterdam' onClick={_ => SelectableSectionClicked('publications', navigate, onNavigatedToSection)}>
                                                        <div className='mt-3 mb-16 selectable-section'>
                                                            <p className='text-uppercase title-objective mb-16 fw-600'> {t('Publications')} </p>
                                                            <div className='mt-2'>
                                                                {
                                                                    publications.map((v, i) =>
                                                                        <div key={'publication__' + i}>
                                                                            <p className='sec-value my-2 editor-list-margin-left' dangerouslySetInnerHTML={{ __html: v.content || '' }}></p>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </section>
                                                }
                                                {/* {
                                                stepsInformation.findIndex(x => x.title == 'References') > -1 && references.length > 0 &&
                                                <section className='reference-sec padding-side-amsterdam' onClick={_ => SelectableSectionClicked('references', navigate, onNavigatedToSection)}>
                                                    <div className='mt-3 mb-16 selectable-section'>
                                                        <p className='text-uppercase title-objective mb-16 fw-600'>  {t('References')} </p>
                                                        <div className='mt-2'>
                                                            {
                                                                references.map((v, i) =>
                                                                    <div key={'refer__' + i}>
                                                                        <p className='sec-value my-2 editor-list-margin-left' dangerouslySetInnerHTML={{ __html: v.content || '' }}></p>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </section>
                                            } */}
                                                {
                                                    stepsInformation.findIndex(x => x.title == 'References') > -1 && references.length > 0 &&
                                                    <section className='reference-sec padding-side-amsterdam' onClick={_ => SelectableSectionClicked('references', navigate, onNavigatedToSection)}>
                                                        <div className='mt-3 mb-16 selectable-section'>
                                                            <p className='text-uppercase title-objective mb-16 fw-600'>  {t('References')} </p>
                                                            <div className='mt-2'>
                                                                {
                                                                    references.map((v, i) =>
                                                                        <div key={'refer__' + i}>

                                                                            <p className='sec-value my-2 editor-list-margin-left'>{v.name}</p>
                                                                            <p className='sec-value my-2 editor-list-margin-left'>{v.city} {(v.city != '' && v.tel != '') ? "|" : ""} {v.tel}</p>
                                                                            {/* <p className='sec-value my-2 editor-list-margin-left' dangerouslySetInnerHTML={{ __html: v.content || '' }}></p> */}

                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </section>
                                                }
                                                {
                                                    stepsInformation.findIndex(x => x.title == 'Custom sections') > -1 && customSections.map((v, i) =>
                                                        <section key={'custom_' + i} className='driving-sec padding-side-amsterdam' onClick={_ => SelectableSectionClicked('custom-sections', navigate, onNavigatedToSection)}>
                                                            <div className='mt-3 mb-16 selectable-section'>
                                                                <p className='text-uppercase title-objective mb-16 fw-600'>{v.title}</p>
                                                                <div className='mt-2'>
                                                                    <p className='sec-value my-2 editor-list-margin-left' dangerouslySetInnerHTML={{ __html: v.content || '' }}></p>
                                                                </div>
                                                            </div>
                                                        </section>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <div className='sidebar-template-amsterdam col-5'>
                                            <div>
                                                {
                                                    Object.values(contactInformation).filter(s => s).length > 0 &&

                                                    <div className='imgage-sec selectable-section'>
                                                        {
                                                            image &&
                                                            <div className='template-header-logo mb-4' onClick={_ => SelectableSectionClicked('contact-information', navigate, onNavigatedToSection)}>

                                                                <img src={image}
                                                                    className={imageData.isRounded ? "rounded-img mx-auto d-block h-148 amsterdam-img" : 'simple-img mx-auto d-block h-148 amsterdam-img'}
                                                                    onError={e => {
                                                                        if (e.target instanceof HTMLImageElement) {
                                                                            e.target.src = DefaultProfileImage;
                                                                        }
                                                                    }} />
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                                {
                                                    Object.values(contactInformation).filter(s => s).length > 0 &&
                                                    <section className='contact-sec contact-information selectable-section' onClick={_ => SelectableSectionClicked('contact-information', navigate, onNavigatedToSection)}>
                                                        <div className='template-head'>
                                                            <div className='list-unstyled'>
                                                                {
                                                                    contactInformation.email &&
                                                                    <div className='d-flex align-items-start justify-content-start my-1'>
                                                                        <p className=''>
                                                                            <svg width="14" height="10" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M12 0a2 2 0 012 2v6a2 2 0 01-2 2H2a2 2 0 01-2-2V2a2 2 0 012-2h10zm.935 1.644L7.267 5.416a.5.5 0 01-.555 0L1.065 1.644A.998.998 0 001 2v6a1 1 0 001 1h10a1 1 0 001-1V2a.998.998 0 00-.065-.356zM12 1H2l-.093.005 5.082 3.393 5.1-3.394A1.013 1.013 0 0012 1z"></path>
                                                                            </svg>
                                                                        </p>
                                                                        <span className='color-black ms-2'>{contactInformation.email}</span>
                                                                    </div>
                                                                }
                                                                {
                                                                    contactInformation.address &&
                                                                    <div className='d-flex align-items-start justify-content-start my-1'>
                                                                        <p className=''>
                                                                            <svg width="12" height="14" xmlns="http://www.w3.org/2000/svg"><path d="M6 0l5.521 4.685c.304.257.479.635.479 1.034v5.569A2.712 2.712 0 019.288 14H2.712A2.712 2.712 0 010 11.288v-5.57c0-.398.175-.776.479-1.033L6 0zm0 1.311L1.126 5.447c-.06.051-.1.12-.118.195L1 5.719v5.569C1 12.233 1.767 13 2.712 13H4v-2.644C4 9.607 4.607 9 5.356 9h1.288C7.393 9 8 9.607 8 10.356V13h1.288c.945 0 1.712-.767 1.712-1.712v-5.57a.356.356 0 00-.126-.27L6 1.31z"></path></svg>
                                                                        </p>
                                                                        <span className='color-black ms-2'>{contactInformation.address} </span>
                                                                    </div>
                                                                }
                                                                {
                                                                    contactInformation.phone &&
                                                                    <div className='d-flex align-items-start justify-content-start my-1'>
                                                                        <p className=''>
                                                                            <svg width="10" height="16" xmlns="http://www.w3.org/2000/svg"><path d="M7.288 0A2.712 2.712 0 0110 2.712v10.576A2.712 2.712 0 017.288 16H2.712A2.712 2.712 0 010 13.288V2.712A2.712 2.712 0 012.712 0h4.576zm0 1H2.712C1.767 1 1 1.767 1 2.712v10.576C1 14.233 1.767 15 2.712 15h4.576C8.233 15 9 14.233 9 13.288V2.712C9 1.767 8.233 1 7.288 1zM6.5 12a.5.5 0 010 1h-3a.5.5 0 010-1h3z" fillRule="evenodd"></path></svg>
                                                                        </p>
                                                                        <span className='color-black ms-2'>{contactInformation.phone}</span>
                                                                    </div>
                                                                }
                                                                {
                                                                    contactInformation.dob &&
                                                                    <div className='d-flex align-items-start justify-content-start my-1'>
                                                                        <p className=''>
                                                                            <svg width="14" height="13" xmlns="http://www.w3.org/2000/svg"><path d="M11.288 2A2.712 2.712 0 0114 4.712v5.576A2.712 2.712 0 0111.288 13H2.712A2.712 2.712 0 010 10.288V4.712A2.712 2.712 0 012.712 2h8.576zM1 6v4.288C1 11.233 1.767 12 2.712 12h8.576c.945 0 1.712-.767 1.712-1.712V5.999L1 6zm0-1l12-.001v-.287C13 3.767 12.233 3 11.288 3H2.712C1.767 3 1 3.767 1 4.712V5zm3.5-5a.5.5 0 01.5.5V2H4V.5a.5.5 0 01.5-.5zm5 0a.5.5 0 01.5.5V2H9V.5a.5.5 0 01.5-.5z"></path></svg>
                                                                        </p>
                                                                        <span className='color-black ms-2'>{moment(contactInformation.dob).format('MM/DD/YYYY')}</span>
                                                                    </div>
                                                                }
                                                                {
                                                                    contactInformation.nationality &&
                                                                    <div className='d-flex align-items-start justify-content-start my-1'>
                                                                        <p className=''>
                                                                            <svg width="14" height="14" xmlns="http://www.w3.org/2000/svg"><path d="M7 0a7 7 0 110 14A7 7 0 017 0zm1.997 7.5l-4.125.001c.096 1.96.732 3.777 1.917 5.495.07.003.14.004.211.004l.208-.004C8.32 11.29 8.914 9.48 8.997 7.5zm-3.379 5.34l-.029-.048c-.967-1.655-1.495-3.414-1.578-5.29L1.051 7.5l-.03-.002a6.004 6.004 0 004.597 5.342zM12.98 7.5H9.991a11.561 11.561 0 01-1.51 5.316 6.005 6.005 0 004.498-5.315zM5.61 1.164l-.135.033A6.004 6.004 0 001.02 6.503l.032-.003h2.957a11.12 11.12 0 011.6-5.337zM7 1l-.211.004-.124.178C5.55 2.834 4.955 4.592 4.87 6.5h4.126C8.905 4.457 8.269 2.577 7.085.808l.127.196A6.109 6.109 0 007 1zm1.48.184l.067.118A11.733 11.733 0 019.99 6.5h2.99a6.004 6.004 0 00-4.5-5.316z"></path></svg>
                                                                        </p>
                                                                        <span className='color-black ms-2'>{contactInformation.nationality}</span>
                                                                    </div>
                                                                }
                                                                {
                                                                    contactInformation.linkedInLink &&
                                                                    <div className='d-flex align-items-start justify-content-start my-1'>
                                                                        <p className=''>
                                                                            <svg width="14" height="12" xmlns="http://www.w3.org/2000/svg"><path d="M9 0a2 2 0 012 2v2h1a2 2 0 012 2v4a2 2 0 01-2 2H5a2 2 0 01-2-2V8H2a2 2 0 01-2-2V2a2 2 0 012-2h7zm3 5h-1v1a2 2 0 01-2 2H4v2a1 1 0 001 1h7a1 1 0 001-1V6a1 1 0 00-1-1zM9 1H2a1 1 0 00-1 1v4a1 1 0 001 1h1V6a2 2 0 012-2h5V2a1 1 0 00-1-1zm1 4H5a1 1 0 00-1 1v1h5a1 1 0 001-1V5z"></path></svg>
                                                                        </p>
                                                                        <span className='color-black ms-2 text-break text-wrap'>{contactInformation.linkedInLink}</span>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </section>
                                                }
                                            </div>
                                            {
                                                stepsInformation.findIndex(x => x.title == 'Objective') > -1 && objectives &&
                                                <section className='objective-sec my-3' onClick={_ => SelectableSectionClicked('objective', navigate, onNavigatedToSection)}>
                                                    <div className='selectable-section'>
                                                        <p className='text-uppercase mb-16 fw-600 pt-3'> {t('Objective')} </p>
                                                        <div className='template-head obj-list-margin-left'>
                                                            <p className='sec-value my-2' dangerouslySetInnerHTML={{ __html: objectives }}></p>
                                                        </div>
                                                    </div>
                                                </section>
                                            }
                                            {
                                                stepsInformation.findIndex(x => x.title == 'Skills') > -1 &&
                                                <div className=''>
                                                    <div className='skill-sec my-3' onClick={_ => SelectableSectionClicked('skills', navigate, onNavigatedToSection)}>
                                                        <div className='selectable-section'>
                                                            <p className='text-uppercase mb-16 fw-600 pt-3'>  {t('Skills')} </p>
                                                            <div className='template-head'>
                                                                <ul className='ps-4 sec-value mb-7'>
                                                                    {
                                                                        skills.length > 0 &&
                                                                        skills.map((s, i) =>
                                                                            <li className='mb-1' key={'skill_' + s.name + s.id + i}>
                                                                                <p className='fw-500'>{s.name}</p>
                                                                            </li>
                                                                        )
                                                                    }

                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='language-sec' onClick={_ => SelectableSectionClicked('languages', navigate, onNavigatedToSection)}>
                                                        <div className='selectable-section'>
                                                            <p className='text-uppercase mb-16 fw-600 pt-3'> {t('Languages')} </p>
                                                            <div className='template-head'>
                                                                <ul className='ps-4 color-black'>
                                                                    {
                                                                        languages.length > 0 &&
                                                                        languages.map((s, i) =>
                                                                            <li className='mb-1' key={'languages_' + s.name + s.id + i}>
                                                                                {
                                                                                    s.name &&
                                                                                    <p className='fw-600'>{s.name}</p>
                                                                                }
                                                                                {
                                                                                    s.level &&
                                                                                    <p className='fw-500'>{s.level}</p>
                                                                                }
                                                                                {
                                                                                    s.course &&
                                                                                    <p className='fw-500'>{s.course}</p>
                                                                                }
                                                                            </li>
                                                                        )
                                                                    }
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </WrapperDiv>
        </>
    )
}

const WrapperDiv = styled.div`
& .sidebar-template-amsterdam, .border-left-amsterdam::before{
    background-color:${(props: { additionalSettings: AdditionalSettings }) => !!props?.additionalSettings?.color && props?.additionalSettings?.color};
}
& .title-objective{
    color:${(props: { additionalSettings: AdditionalSettings }) => !!props?.additionalSettings?.color && props?.additionalSettings?.color};
}
& .sec-value , .sec-value *{
    line-height:${(props: { additionalSettings: AdditionalSettings }) => !!props?.additionalSettings?.lineHeight && props?.additionalSettings?.lineHeight};
    font-size:${(props: { additionalSettings: AdditionalSettings }) => !!props?.additionalSettings?.fontSize && props?.additionalSettings?.fontSize + " !important"};
   
}

& * {
    font-family:${(props: { additionalSettings: AdditionalSettings }) => !!props?.additionalSettings?.fontFamily
        &&
        props?.additionalSettings?.fontFamily === "Classic" ? "Noto Serif, serif;" :
        props?.additionalSettings?.fontFamily === "Modern" ? "Rubik, sans-serif;" :
            props?.additionalSettings?.fontFamily === "Elegant" ? "Libre Baskerville, serif;" :
                props?.additionalSettings?.fontFamily === "Tech" ? "Roboto Mono, monospace;" :
                    props?.additionalSettings?.fontFamily === "Professional" ? "Bitter, serif;" : "default;"}
}
`