import { FaEnvelope } from "react-icons/fa";
import { useForm } from 'react-hook-form';
import axios from "axios";
import { config } from "../../Environments";
import { ApiResponse } from "../../Shared";
import { toast } from "react-toastify";
import { LoadingButton } from "../../Components";
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import * as Yup from "yup";
import { RotatingLines } from 'react-loader-spinner'
const ForgotPassword = () => {

    const { t: at } = useTranslation('auth');
    const { t: ts } = useTranslation('alert');
    const { i18n } = useTranslation();
    const [submitting, setSubmitting] = useState(false);

    const [message, setMessage] = useState('');

    const passwordSchema = Yup.object().shape({

        email: Yup.string().email(ts('signIn.inValidEmail') || 'Invalid email address').required(ts('account.required') || 'Required'),
        lang: Yup.string().required(ts('account.required') || 'Required'),
    });

    const formik = useFormik({
        initialValues: {
            email: '',
            lang: ''
        },
        validationSchema: passwordSchema,
        onSubmit: (values, { resetForm }) => {
            setSubmitting(true);
            axios.post<ApiResponse>(config.identity.authority + '/api/Auth/ForgotPassword', values)
                .then(res => {
                    if (res.data.success) {
                        toast(res.data.message, { type: res.data.success ? 'success' : 'error' });
                    }
                    else {
                        setMessage(res.data?.message || '');
                    }
                    setSubmitting(false);
                    //reset();
                }).catch(err => {
                    console.error(err);
                    setSubmitting(false);
                })
        },
    });
    React.useEffect(() => {
        formik.setFieldValue('lang', i18n.language)

    }, [i18n.language]);

    React.useEffect(() => {
        if (formik.values.email == '') {
            setMessage('');

        }
    }, [formik.values.email]);





    return (
        <>
            <div className="bg-img-height">
                <div className="container">
                    <div className='form-m-center'>
                        <div className="form-section rounded-3">
                            <div className="padding-step1">
                                <h3 className="text-center step1-heading"> {at('forgotPassword.forgotYourPassword')}?</h3>

                                <div className="mb-12">
                                    <div className="mb-4">
                                        <div className="input-group">
                                            <span className="input-group-text group-round" id="Email"><FaEnvelope /></span>
                                            <input placeholder={at('forgotPassword.enterYourEmail').toString()} type="email" name='email' onChange={formik.handleChange} value={formik.values.email} className="form-control key-weight" id="emailaddress" />
                                        </div>
                                        {formik.errors.email && formik.touched.email ? <small className='text-danger'>{formik.errors.email}</small> : ""}
                                        <small className='text-danger'>{message}</small>
                                    </div>

                                    <div className="text-center mb-12">
                                        <button disabled={submitting} type="submit" onClick={formik.handleSubmit as any} className="border-0 p-btn btn-contine text-white fw-600">
                                            {
                                                submitting ? <RotatingLines
                                                    width='20' strokeColor='black'
                                                /> : <div >
                                                    {at('forgotPassword.resetPassword').toString()}
                                                </div>
                                            }
                                        </button>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}
export default ForgotPassword;