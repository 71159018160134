import { useEffect, useState } from 'react';
import Table from "react-bootstrap/Table";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import JobRegistrationPage from "../Jobs/Job-Registration/Index";
import React from 'react';
import { GetAxios } from '../../Shared';
import { config } from '../../Environments';
import { setGlobalLoading } from '../../Store';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from "react-toastify";
import { AnnounceRegister } from '../Announces/Announce-Register/AnnounceRegister';
import TenderRegistration from "../Tender-Call/Tender-Registration/Index";
import { useTranslation } from 'react-i18next';
import { confirmAlert } from "react-confirm-alert";
import { Link, useNavigate } from "react-router-dom";
import { Button, Collapse } from "react-bootstrap";
import { VscChromeClose } from "react-icons/vsc";
import { FaEdit, FaEye, FaTrash, FaToggleOn, FaToggleOff } from "react-icons/fa";
import Modal from 'react-bootstrap/Modal';
import AdInvoiceModal from './AdInvoiceModal';
const AdList = () => {

  const { t } = useTranslation('company');

  const dispatch = useDispatch();
  const [joblist, setJobList] = React.useState<any>([]);
  const [announcelist, setAnnounceList] = React.useState<any>([]);
  const [tenderlist, setTenderList] = React.useState<any>([]);
  const [params] = useSearchParams();
  const [userId, setId] = useState(params?.get('id') || "");
  const [tenderEligible, setTenderEligible] = React.useState(0);
  const [flyerEligible, setFlyerEligible] = React.useState(0);
  const [jobEligible, setJobEligible] = React.useState(0);
  const [currentTab, setCurrentTab] = useState("adslist");

  const [adlist, setAdList] = React.useState<any>([]);

  React.useEffect(() => {
    fetchAdsData();
  }, []);

  function fetchAdsData() {
    dispatch(setGlobalLoading(true));


    GetAxios().get(config.apiBase + '/api/Jobs/MyPostedJobs?id=' + userId).then(res => {
      setJobList(res.data.data || []);
      dispatch(setGlobalLoading(false));
    });
    GetAxios().get(config.apiBase + '/api/Announce/MyPostedAnnounces?id=' + userId).then(res => {
      setAnnounceList(res.data.data || []);
      dispatch(setGlobalLoading(false));
    });
    GetAxios().get(config.apiBase + '/api/Tender/MyPostedTenders?id=' + userId).then(res => {
      setTenderList(res.data.data || []);
      dispatch(setGlobalLoading(false));
    });

  }

  function handleTabSelect(eventKey: any) {
    setCurrentTab(eventKey);
    if (eventKey === 'adslist') {
      fetchAdsData();
    }
    if (eventKey === 'addjobfortender') {

      setTenderEligible(1);
    }
    if (eventKey === 'addseminar') {

      setFlyerEligible(1);
    }
    if (eventKey === 'addjob') {

      setJobEligible(1);
    }
  }

  return (
    <>
      <div className="container" style={{ width: "2000px" }}>
        <div className="row">
          <div className="col-md-12 px-0">
            <div className="pt-5 mb-3">
              <Tabs
                activeKey={currentTab}
                defaultActiveKey={currentTab}
                transition={false}
                id="noanim-tab-example"
                className="listing-tab"
                onSelect={handleTabSelect}>
                <Tab eventKey="adslist" title={t('myAdListing').toString()} className="ad-tabs-list">
                  <MyListing fetch={fetchAdsData} joblist={joblist} announcelist={announcelist} tenderlist={tenderlist}
                  />
                </Tab>
                <Tab
                  eventKey="addjob"
                  title={t('addAJob').toString()}
                  className="fw-700 addjob tab-add-plussign">
                  <JobRegistrationPage isAdmin={false} checkEligible={jobEligible} redirect={handleTabSelect} />
                </Tab>
                <Tab
                  eventKey="addseminar"
                  title={t('addASeminar').toString()}
                  className="fw-700 tab-add-plussign">
                  <AnnounceRegister isAdmin={false} checkEligible={flyerEligible} redirect={handleTabSelect} />
                </Tab>
                <Tab
                  eventKey="addjobfortender"
                  title={t('addCallForTender').toString()}
                  className="fw-700 tab-add-plussign">
                  <TenderRegistration isAdmin={false} checkEligible={tenderEligible} redirect={handleTabSelect} />
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AdList;

export const MyListing = (props: {
  fetch: any,
  joblist: [],
  announcelist: [],
  tenderlist: []

}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation('company');
  const { t: p } = useTranslation('plan');
  const { t: ts } = useTranslation('alert');
  const DeleteJob = (jobId: any) => {
    confirmAlert({
      title: ts('job.areYouSure').toString(),
      message: ts('job.deleteMsg').toString(),
      closeOnClickOutside: false,
      closeOnEscape: false,
      buttons: [
        {
          label: ts('job.yes').toString(),
          className: 'bg-danger',
          onClick: _ => {
            dispatch(setGlobalLoading(true));
            GetAxios(navigate, dispatch).delete(config.apiBase + '/api/Jobs/DeleteJob?id=' + jobId,
            ).then(s => {
              toast(ts('job.delete'), { type: 'success' });
              dispatch(setGlobalLoading(false));
              props.fetch();
            }).catch(s => {
              toast(ts('job.cantDelete'), { type: 'error' });
              dispatch(setGlobalLoading(false));
            });
          }
        },
        {
          label: ts('job.no').toString(),
          onClick: _ => {
            return
          }
        }
      ]
    })
  }
  const DeleteAnnounce = (seminarId: any) => {
    confirmAlert({
      title: ts('job.areYouSure').toString(),
      message: ts('seminar.deleteMsg').toString(),
      closeOnClickOutside: false,
      closeOnEscape: false,
      buttons: [
        {
          label: ts('job.yes').toString(),
          className: 'bg-danger',
          onClick: _ => {
            dispatch(setGlobalLoading(true));
            GetAxios(navigate, dispatch).delete(config.apiBase + '/api/Announce/DeleteAnnounce?id=' + seminarId,
            ).then(s => {
              toast(ts('seminar.delete'), { type: 'success' });
              dispatch(setGlobalLoading(false));
              props.fetch();
            }).catch(s => {
              toast(ts('job.cantDelete'), { type: 'error' });
              dispatch(setGlobalLoading(false));
            });
          }
        },
        {
          label: ts('job.no').toString(),
          onClick: _ => {
            return
          }
        }
      ]
    });
  }
  const DeleteTender = (tenderId: any) => {
    confirmAlert({
      title: ts('job.areYouSure').toString(),
      message: ts('tender.deleteMsg').toString(),
      closeOnClickOutside: false,
      closeOnEscape: false,
      buttons: [
        {
          label: ts('job.yes').toString(),
          className: 'bg-danger',
          onClick: _ => {
            dispatch(setGlobalLoading(true));
            GetAxios(navigate, dispatch).delete(config.apiBase + '/api/Tender/DeleteTender?id=' + tenderId,
            ).then(s => {
              toast(ts('tender.delete'), { type: 'success' });
              dispatch(setGlobalLoading(false));
              props.fetch();
            }).catch(s => {
              toast(ts('job.cantDelete'), { type: 'error' });
              dispatch(setGlobalLoading(false));
            });
          }
        },
        {
          label: ts('job.no').toString(),
          onClick: _ => {
            return
          }
        }
      ]
    });
  }
  return (
    <>
      <div className="table-responsive" style={{ height: '100vh' }}>
        <Table bordered className="bg-white">
          <thead>
            <tr>
              <th className="w-13 fw-700 fw-16 text-center"> {t('myAdsListing.adId')} #</th>
              <th className="w-13 fw-700 fw-16 text-center"> {t('myAdsListing.advertisng')} #</th>
              {/* <th className="fw-700 fw-16 w-18 text-center"> {t('myAdsListing.paymentStatus')} </th> */}
              <th className="fw-700 fw-16 text-center w-15a"> {t('myAdsListing.status')} </th>
              <th className="width-10 fw-700 fw-16 text-center "> {t('myAdsListing.expiresOn')} </th>
              <th className="width-35 fw-700 fw-16 ">
                {t('myAdsListing.shortDescrptionAd')}
              </th>
              <th className="fw-700 fw-16 text-center w-12"></th>
            </tr>
          </thead>
          <tbody>
            {props.joblist.length == 0 && props.announcelist.length == 0 && props.tenderlist.length == 0 &&
              <tr className="align-middle" key={"invoice_key" + 0}>
                <td colSpan={6} className="fw-16 text-center p-5">
                  <h4 className="mx-auto fw-600 px-4">
                    {t('noAdDataShowDesc')}
                  </h4>
                </td>
              </tr>
            }
            {props.joblist.length > 0 &&
              props.joblist.map((item: any, index: any) => (
                <tr key={"jobinvoice_key" + index} className="align-middle">

                  <td className="text-center">
                    <AdInvoiceModal adNo={item.jobNo} itemId={item.id} paid={item.paid} planPurchase={item.planPurchase} type="J" advertisingNo={item.advertisingNo} />
                  </td>
                  <td className="text-center ">{item.advertisingNo}</td>
                  {/* <td className="text-center"> {item.status}</td> */}
                  <td className="text-center">
                    <p>{item.status}</p>
                    {item.paid == true ? (
                      null
                    ) : (
                      <p className="card-text fw-15">
                        {p('common.pleaseClick')}
                        <br />
                        <button type="button" onClick={() => { navigate("/employer-account/upgrade-my-posting-account") }} className="btn btn-danger px-2 py-1"><FaToggleOff size={14} /></button>
                        <br />
                        {p('common.activateYourAd')}
                      </p>
                    )}
                  </td>

                  <td className="text-center ">{item.expiryDate}</td>
                  <td>
                    <div className="ms-0">

                      <h4 className="card-title mb-0 text-start">
                        <Link to={"/job/jobpreview?id=" + item.id} target='_blank' className="text-decoration-none text-dark pointer-event preview-link">
                          {item.title}
                        </Link>
                      </h4>


                      <div className="d-flex justify-content-between align-items-center mb-2">
                        <Link to={"/job/jobpreview?id=" + item.id} state={{ from: "addList" }} target='_blank' className="card-subtitle text-decoration-none text-start pointer-event preview-sublink">
                          {/* {item?.department?.name || ''}*/}
                          {item?.classification}
                        </Link>

                      </div>

                      <p className="card-text pt-1 fs-14 text-start" dangerouslySetInnerHTML={{ __html: item.shortDescription }}>

                      </p>
                    </div>
                  </td>
                  <td>
                    <div className="mt-1 text-center">
                      <button type="button" onClick={() => { navigate("/job/jobpreview?id=" + item.id) }} className="btn btn-primary px-2 py-1"><FaEye className="text-white" size={14} /></button>
                      <button type="button" onClick={() => { navigate('/job/jobregistration?id=' + item.id) }} className="btn btn-info mx-1 px-2 py-1"><FaEdit className="text-white" size={14} /></button>
                      <button type="button" onClick={() => { DeleteJob(item.id) }} className="btn btn-dark px-2 py-1"><FaTrash className="text-white" size={14} /></button>
                    </div>
                  </td>

                </tr>

              ))
            }
            {props.announcelist.length > 0 &&
              props.announcelist.map((item: any, index: any) => (
                <tr key={"announce_key" + index + props.joblist.length} className="align-middle">

                  <td className="text-center text-success">
                    <AdInvoiceModal adNo={item.flyerNo} itemId={item.id} paid={item.paid} planPurchase={item.planPurchase} type="A" advertisingNo={item.advertisingNo} />

                  </td>
                  <td className="text-center ">{item.advertisingNo}</td>
                  {/* <td className="text-center"> {item.status}</td> */}
                  <td className="text-center">
                    <p>{item.status}</p>
                    {item.paid == true ? (
                      null
                    ) : (
                      <p className="card-text fw-15">
                        {p('common.pleaseClick')}
                        <br />
                        <button type="button" onClick={() => { navigate("/employer-account/announce-plans") }} className="btn btn-danger px-2 py-1"><FaToggleOff size={14} /></button>
                        <br />
                        {p('common.activateYourAd')}
                      </p>
                    )}
                  </td>
                  <td className="text-center">{item.expiryDate}</td>
                  <td>
                    <div className="row">
                      <div className="col-md-2 col-12">
                        <div className="text-center ms-1">
                          <img
                            src={config.apiBase + item.logo}
                            className="card-img-top rounded-0"
                            onError={(ev: any) => {
                              ev.target.src = '/images/ImgPlaceholder.png';
                            }}
                            alt="image placeholder"
                          />


                        </div>
                      </div>
                      <div className="col-md-10 col-12">
                        <div className="ms-0">

                          <h4 className="card-title mb-0 text-start">
                            <Link to={"/announce/announcePreview?sid=" + item.id} target='_blank' className="text-decoration-none text-dark pointer-event preview-link">
                              {item.title}
                            </Link>
                          </h4>
                          <div className="d-flex justify-content-between align-items-center mb-2">
                            <Link to={"/announce/announcePreview?sid=" + item.id} className="card-subtitle text-decoration-none text-start pointer-event preview-sublink">
                              {item.departments}
                            </Link>
                            {/* <Link to={'/announce/' + item.id + '/edit'} className="text-decoration-none language-icon text-white"><FaEdit className="mb-1" size={18} /></Link>
                              <Link to="" onClick={() => { DeleteAnnounce(item.id) }} className=" text-decoration-none trash-icon text-white"><FaTrash className="mb-1" size={18} /></Link>*/}
                          </div>
                          <p className="card-text pt-1 fs-14 text-start" >
                            {item.phoneNumber}
                          </p>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='mt-1 text-center'>
                      <button type="button" onClick={() => { navigate("/announce/announcePreview?sid=" + item.id) }} className="btn btn-primary px-2 py-1"><FaEye className="text-white" size={14} /></button>
                      <button type="button" onClick={() => { navigate('/announce/' + item.id + '/edit') }} className="btn btn-info mx-1 px-2 py-1"><FaEdit className="text-white" size={14} /></button>
                      <button type="button" onClick={() => { DeleteAnnounce(item.id) }} className="btn btn-dark px-2 py-1"><FaTrash className="text-white" size={14} /></button>

                    </div>
                  </td>
                </tr>
              ))
            }
            {props.tenderlist.length > 0 &&
              props.tenderlist.map((item: any, index: any) => (

                <>
                  <tr key={"jobinvoice_key" + index + props.joblist.length} className="align-middle">
                    <td className="text-center">
                      <AdInvoiceModal adNo={item.tenderNo} itemId={item.id} paid={item.paid} planPurchase={item.planPurchase} type="T" advertisingNo={item.advertisingNo} />
                    </td>
                    <td className="text-center">{item.advertisingNo}</td>
                    {/* <td className="text-center"> {item.status}</td> */}
                    <td className="text-center">
                      <p>{item.status}</p>
                      {item.paid == true ? (
                        null
                      ) : (

                        <p className="card-text fw-15">
                          {p('common.pleaseClick')}
                          <br />
                          <button type="button" onClick={() => { navigate("/employer-account/tender-plans") }} className="btn btn-danger px-2 py-1"><FaToggleOff size={14} /></button>
                          <br />
                          {p('common.activateYourAd')}
                        </p>
                      )}
                    </td>
                    <td className="text-center">{item.expiryDate}</td>

                    <td>
                      <div className="ms-0">

                        <h4 className="card-title mb-0 text-start">
                          <Link to={"/tender/tenderpreview?tid=" + item.id} target='_blank' className="text-decoration-none text-dark pointer-event preview-link">
                            {item.title}
                          </Link>
                        </h4>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                          <Link to={"/tender/tenderpreview?tid=" + item.id} state={{ from: "addList" }} target='_blank' className="card-subtitle text-decoration-none text-start pointer-event  preview-sublink">
                            {item.classification}
                          </Link>

                        </div>
                        <p className="card-text pt-1 fs-14 text-start" >
                          {item.tel}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div className="mt-1 text-center">
                        <button type="button" onClick={() => { navigate("/tender/tenderpreview?tid=" + item.id) }} className="btn btn-primary px-2 py-1"><FaEye className="text-white" size={14} /></button>
                        <button type="button" onClick={() => { navigate('/tender/registration?tid=' + item.id) }} className="btn btn-info mx-1 px-2 py-1"><FaEdit className="text-white" size={14} /></button>
                        <button type="button" onClick={() => { DeleteTender(item.id) }} className="btn btn-dark px-2 py-1"><FaTrash className="text-white" size={14} /></button>

                      </div>
                    </td>
                  </tr>


                </>

              ))
            }



          </tbody>
        </Table>
      </div>
    </>
  )
}





