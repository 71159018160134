import React, { useCallback, useMemo, useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import * as Yup from "yup";
import { useFormik } from 'formik';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { FaCcMastercard, FaEllipsisV, FaPaypal, FaPencilAlt, FaRegTrashAlt, FaCreditCard, FaPlayCircle } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { GetAxios, UserRole } from '../../Shared';
import { setGlobalLoading } from '../../Store';
import { config } from '../../Environments';
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import Modal from 'react-bootstrap/Modal';
import { VscChromeClose } from "react-icons/vsc";
import { AiOutlineClose } from "react-icons/ai";
import { RotatingLines } from 'react-loader-spinner';
import { UpdatePaymentMethodModel } from './EditPaymentMethod';
export const PaymentMethodList = () => {
    const { t } = useTranslation('plan');
    const { t: ct } = useTranslation('common');
    const { t: ts } = useTranslation('alert');
    const [modalShow, setModalShow] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [list, setList] = useState([]);

    const loadData = () => {

        dispatch(setGlobalLoading(true));
        GetAxios(navigate, dispatch).get(config.apiBase + '/api/Payment/GetPaymentMethods').then(s => {
            setList(s.data.data);
            dispatch(setGlobalLoading(false));
        }).catch(s => {
            dispatch(setGlobalLoading(false));

        });
    }

    useEffect(() => {

        loadData();
    }, []);



    const Delete = (paymentid) => {

        confirmAlert({
            title: ts('job.areYouSure').toString(),
            message: ts('plan.deletepaymentMsg').toString(),
            closeOnClickOutside: false,
            closeOnEscape: false,
            buttons: [
                {
                    label: ts('job.yes').toString(),
                    className: 'bg-danger',
                    onClick: _ => {
                        dispatch(setGlobalLoading(true));
                        GetAxios(navigate, dispatch).delete(config.apiBase + '/api/Payment/Delete', {
                            params: {
                                paymentId: paymentid
                            }
                        }).then(s => {

                            toast(ts('plan.deleteMsg'), { type: 'success' });
                            setList([]);
                            loadData();
                            dispatch(setGlobalLoading(false));
                        }).catch(s => {
                            toast(ts('job.cantDelete'), { type: 'error' });
                        });
                    }
                },
                {
                    label: ts('no').toString(),
                    onClick: _ => {
                        return
                    }
                }
            ]
        })
    }


    return (
        <>
            <div className='container pb-5'>
                <h2 className='fw-700 my-4'> {ct('managePaymentMethod')} </h2>
                <div className='profile-card my-4'>
                    <div className="d-flex align-items-center mb-2">
                        <Link to=''
                            onClick={() => { setModalShow(true) }}
                            className="button-default text-decoration-none cursor-pointer mt-1 border-0 me-1 btn-continue"> {t('common.addPaymentMethod')} </Link>
                        <AddPaymentMethodModel handleModalShow={modalShow} handleModalClose={() => setModalShow(false)} loadData={loadData} />
                    </div>
                    <div className='row gx-3 pb-4'>
                        {list.map((item, index) => {
                            return (
                                <>
                                    <div className='col-md-4 mb-3' key={"methods" + index}>
                                        <div className='card p-4 h-240px'>
                                            <div className='fs-14 text-start position-relative'>
                                                <div className='d-flex align-items-center justify-content-between mb-1'>
                                                    <div>
                                                        <img src="/images/content/mastercard-img.png" alt="Master Card Image" />
                                                    </div>
                                                    <div>
                                                    <DropdownButton  id="payment-method-drops new-resume-list" className="p-0 resume-dropdown" title={<FaEllipsisV className="icon-gray" />}>
                                                        {/* <DropdownButton drop="start" id="payment-method-drops new-resume-list" className="p-0 payment-dropdown" title={<FaEllipsisV className="text-white" />}> */}
                                                            <Dropdown.Item eventKey="1" className='fw-500'>
                                                                <UpdatePaymentMethodModel id={item.id} loadData={loadData} />
                                                            </Dropdown.Item>
                                                            <Dropdown.Item eventKey="2" onClick={() => { Delete(item.id) }} className='fw-500'><FaRegTrashAlt className="me-2 icon-gray" size={14} />{ct('delete')}</Dropdown.Item>
                                                        </DropdownButton>
                                                    </div>
                                                </div>
                                                <div className='detail'>

                                                    <h3 className='fw-700'> {item.cardType} {item.cardNumberAsteriks} </h3>
                                                    <p className='fw-600 text-gray mb-0'> {ct('expires')} {item.expireMonth}/{item.expireYear} </p>
                                                    <div className='last-payment-text'>
                                                        <div className='d-flex align-items-center'>
                                                            {item.isBackupPayment == true &&
                                                                <button className="border-0 btn-contine fs-12 p-2 bg-light text-gray fw-600 text-uppercase me-3">
                                                                    {ct('backupPayment')}
                                                                </button>
                                                            }

                                                            <p className='fw-600 text-gray mb-0 text-uppercase'>  {ct('lastUsed')}: {item.lastUsed}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>

                            )
                        })}


                        {/* <div className='col-md-4 mb-3'>
                            <div className='card p-4 h-240px'>
                                <div className='fs-14 text-start'>
                                    <div className='d-flex align-items-center justify-content-between mb-1'>
                                        <div>
                                            <img src="/images/content/paypal-img.png" alt="Paypal Card Image" />
                                        </div>
                                        <div>
                                            <DropdownButton drop="start" id="payment-method-drops new-resume-list" className="p-0 payment-dropdown" title={<FaEllipsisV className="text-white" />}>
                                                <Dropdown.Item eventKey="1" className='fw-500'><FaPencilAlt className="me-2 icon-gray" size={14} />Edit</Dropdown.Item>
                                                <Dropdown.Item eventKey="2" className='fw-500'><FaRegTrashAlt className="me-2 icon-gray" size={14} />Delete</Dropdown.Item>
                                            </DropdownButton>
                                        </div>
                                    </div>
                                    <div className='detail position-relative'>
                                        <h3 className='fw-700 text-blue'>Paypal </h3>
                                        <p className='fw-600 text-gray mb-0'> Epires 10/2024 </p>
                                        <p className='fw-600 text-gray'> Paypal Agreement ###31C </p>
                                        <div className='last-payment-text'>
                                            <p className='fw-600 text-gray mb-0 text-uppercase'> Last Used: 6/25/2023</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4 mb-3'>
                            <div className='card p-4 h-240px'>
                                <div className='fs-14 text-start position-relative'>
                                    <div className='d-flex align-items-center justify-content-between mb-1'>
                                        <div>
                                            <img src="/images/content/mastercard-img.png" alt="Master Card Image" />
                                        </div>
                                        <div>
                                            <DropdownButton drop="start" id="payment-method-drops new-resume-list" className="p-0 payment-dropdown" title={<FaEllipsisV className="text-white" />}>
                                                <Dropdown.Item eventKey="1" className='fw-500'><FaPencilAlt className="me-2 icon-gray" size={14} />Edit</Dropdown.Item>
                                                <Dropdown.Item eventKey="2" className='fw-500'><FaRegTrashAlt className="me-2 icon-gray" size={14} />Delete</Dropdown.Item>
                                            </DropdownButton>
                                        </div>
                                    </div>
                                    <div className='detail'>
                                        <h3 className='fw-700'> MasterCard .... 8032 </h3>
                                        <p className='fw-600 text-gray mb-0'> Epires 10/2024 </p>
                                        <p className='fw-600 text-gray'> 25bis, 2eme ruelle Nazon, Port-au-Prince, Ouest </p>
                                        <div className='last-payment-text'>
                                            <div className='d-flex align-items-center'>
                                                <button type="submit" className="border-0 btn-contine fs-12 p-2 bg-light text-gray fw-600 text-uppercase me-3">
                                                    Backup Payment
                                                </button>
                                                <p className='fw-600 text-gray mb-0 text-uppercase'> Last Used: 6/25/2023</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4 mb-3' >
                            <div className='card p-4 h-240px'>
                                <div className='fs-14 text-start position-relative'>
                                    <div className='d-flex align-items-center justify-content-between mb-1'>
                                        <div>
                                            <img src="/images/content/mastercard-img.png" alt="Master Card Image" />
                                        </div>
                                        <div>
                                            <DropdownButton drop="start" id="payment-method-drops new-resume-list" className="p-0 payment-dropdown" title={<FaEllipsisV className="text-white" />}>
                                                <Dropdown.Item eventKey="1" className='fw-500'><FaPencilAlt className="me-2 icon-gray" size={14} />Edit</Dropdown.Item>
                                                <Dropdown.Item eventKey="2" className='fw-500'><FaRegTrashAlt className="me-2 icon-gray" size={14} />Delete</Dropdown.Item>
                                            </DropdownButton>
                                        </div>
                                    </div>
                                    <div className='detail'>
                                        <h3 className='fw-700'> MasterCard .... 8032 </h3>
                                        <p className='fw-600 text-gray mb-0'> Epires 10/2024 </p>
                                        <p className='fw-600 text-gray'> 25bis, 2eme ruelle Nazon, Port-au-Prince, Ouest </p>

                                        <div className='last-payment-text'>
                                            <p className='fw-600 text-gray mb-0 text-uppercase'> Last Used: {item.lastUsed}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}


// export interface PaymentMethodList {
//     id: number,
//     cardType: string,
//     cardName: string,
//     cardNumberAsteriks: string,
//     expireMonth: string,
//     expireYear: string,
//     isBackupPayment: boolean,
//     lastUsed: string,
//     nickName: string
// }


export const AddPaymentMethodModel = (props) => {
    const { t: ts } = useTranslation('alert');
    const { t } = useTranslation('plan');
    const { t: ct } = useTranslation('common');
    const { t: c } = useTranslation('company');
    const schema = Yup.object().shape({
        cardNumber: Yup.string().required(ts('account.required') || 'Required'),
        expMonth: Yup.string().required(ts('account.required') || 'Required'),
        expYear: Yup.string().required(ts('account.required') || 'Required'),
        securityCode: Yup.string().required(ts('account.required') || 'Required'),
    });
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const formik = useFormik({
        initialValues: {
            id: 0,
            cardType: "Card",
            cardName: "",
            cardNumber: "",
            expMonth: "",
            expYear: "",
            securityCode: "",
            isBackupPayment: false,
        },
        validationSchema: schema,
        onSubmit: (values) => {
            if (loading) {
                toast(ts('account.pleaseWait'), { type: 'info' });
            }
            setLoading(true);
            const formData = new FormData();
            Object.keys(values).map(s => {
                formData.append(s, (values)[s]);
            });
            GetAxios().post(config.apiBase + '/api/Payment/SavePaymentMethod', formData).then(response => {
                if (response.data.success) {
                    toast(ts('plan.paymentMethodCreate'), { type: 'success' });
                    formik.resetForm();
                    props.loadData();
                } else {
                    if (response.data.errors) {
                        alert(response.data.errors.map((v) => v.description));
                    } else {
                        toast(ts('error.requestSubmit'), { type: 'info' });
                    }
                }
                setLoading(false);
            }).catch(err => {
                console.error(err);
                if (err.status >= 500) {
                    toast(ts('error.internalServer'), { type: 'error' });
                }
            });
        },
    });


    return (
        <>

            <Modal show={props.handleModalShow} onHide={props.handleModalClose} dialogClassName="modal-6inch-wprint">
                <Modal.Body className="p-0">
                    <div className='container mt-2'>
                        <div className="d-flex align-items-center justify-content-between px-1 py-1 mt-2">
                            <h4 className='fw-700 '> {t('common.addPaymentMethod')} </h4>
                            <VscChromeClose onClick={props.handleModalClose} size={28} className="close-padd cursor-pointer" />
                        </div>
                        <div className='profile-card my-2'>
                            <div className='row gx-3 pb-4'>
                                <div className='col-md-12 mb-3'>
                                    <div className='card p-4'>
                                        <div className='fs-14 text-start position-relative'>
                                            <div className='detail'>
                                                <div className='top-bottom'>
                                                    <div className='row'>

                                                        <div className='col-md-12 mb-2'>
                                                            <div className="form-check form-check-inline">
                                                                <div className='d-flex align-items-center justify-content-start'>
                                                                    <input className="form-check-input" type="radio" name="card" id="inlineCheckbox2" value="card" checked />
                                                                    <label className="form-check-label key-weight mx-1 fs-18 fw-600" htmlFor="inlineCheckbox2"> {t('common.card')}  </label>
                                                                    <img src="/images/payment-logos.png" alt="Payment Logos" className='w-100 h-20' />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='col-md-12 mb-2'>
                                                            <div className='line-height-sta'>
                                                                <label className="form-label key-weight" htmlFor="nameCard"> {t('common.nameonCC')} </label>
                                                            </div>
                                                            <input type="text" name="cardName" onChange={formik.handleChange} value={formik.values.cardName} className="form-control key-weight" />
                                                            {formik.errors.cardName && formik.touched.cardName ? <small className='text-danger'>{formik.errors.cardName}</small> : ""}
                                                        </div>
                                                        <div className='col-md-12 col-12 mb-2'>
                                                            <div className='line-height-sta'>
                                                                <label className="form-label key-weight" htmlFor="cardNumber"> {t('common.cardNumber')} </label>
                                                            </div>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control key-weight" name='cardNumber' onChange={(e) => {
                                                                    const reg = /^[0-9\b]+$/;
                                                                    if (e.target.value === '' || reg.test(e.target.value)) {
                                                                        formik.setFieldValue("cardNumber", e.target.value);
                                                                    }
                                                                }} maxLength={16} value={formik.values.cardNumber} placeholder="**** **** **** ****" aria-describedby="cardNumber" />
                                                                <span className="input-group-text group-round"> <FaCreditCard /></span>
                                                            </div>
                                                            {formik.errors.cardNumber && formik.touched.cardNumber ? <small className='text-danger'>{formik.errors.cardNumber}</small> : ""}

                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-md-6 mb-2'>
                                                            <div className='line-height-sta'>
                                                                <label className="form-label key-weight" htmlFor="expiryMonth" > {t('common.expirationDate')} </label>
                                                            </div>
                                                            <select name="expMonth" className=' form-select' onChange={formik.handleChange} value={formik.values.expMonth} >
                                                                <option key={'month+___' + 1} value=""> {ct('pleaseSelect')} </option>
                                                                <option key={'month+___' + 2} value="01">01</option>
                                                                <option key={'month+___' + 3} value="02">02</option>
                                                                <option key={'month+___' + 4} value="03">03</option>
                                                                <option key={'month+___' + 5} value="04">04</option>
                                                                <option key={'month+___' + 6} value="05">05</option>
                                                                <option key={'month+___' + 7} value="06">06</option>
                                                                <option key={'month+___' + 8} value="07">07</option>
                                                                <option key={'month+___' + 9} value="08">08</option>
                                                                <option key={'month+___' + 10} value="09">09</option>
                                                                <option key={'month+___' + 11} value="10">10</option>
                                                                <option key={'month+___' + 12} value="11">11</option>
                                                                <option key={'month+___' + 13} value="12">12</option>
                                                            </select>
                                                            {formik.errors.expMonth && formik.touched.expMonth ? <small className='text-danger'>{formik.errors.expMonth}</small> : ""}

                                                        </div>
                                                        <div className='col-md-6 mb-2'>
                                                            <div className='line-height-sta invisible'>
                                                                <label className="form-label key-weight" htmlFor="expiryYear"></label>
                                                            </div>
                                                            <select name="expYear" onChange={formik.handleChange} value={formik.values.expYear} className='form-select mt-2'>
                                                                <option key={'years+___' + 0} value="">  {ct('pleaseSelect')} </option>
                                                                <option key={'years+___' + 1} value="2023">2023</option>
                                                                <option key={'years+___' + 2} value="2024">2024</option>
                                                                <option key={'years+___' + 3} value="2025">2025</option>
                                                                <option key={'years+___' + 4} value="2026">2026</option>
                                                                <option key={'years+___' + 5} value="2027">2027</option>
                                                                <option key={'years+___' + 6} value="2028">2028</option>
                                                                <option key={'years+___' + 7} value="2029">2029</option>
                                                                <option key={'years+___' + 8} value="2030">2030</option>
                                                                <option key={'years+___' + 9} value="2031">2031</option>
                                                                <option key={'years+___' + 10} value="2032">2032</option>
                                                            </select>
                                                            {formik.errors.expYear && formik.touched.expYear ? <small className='text-danger'>{formik.errors.expYear}</small> : ""}

                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-md-12 col-12 mb-2'>
                                                            <div className='line-height-sta'>
                                                                <label className="form-label key-weight" htmlFor="securityCode">  {t('common.securityCode')} </label>
                                                            </div>
                                                            <div className="input-group">
                                                                <input type="text" onChange={(e) => {
                                                                    const reg = /^[0-9\b]+$/;
                                                                    if (e.target.value === '' || reg.test(e.target.value)) {
                                                                        formik.setFieldValue("securityCode", e.target.value);
                                                                    }
                                                                }}

                                                                    maxLength={3} value={formik.values.securityCode} className="form-control key-weight" name='securityCode' placeholder="***" aria-describedby="securityCode" />
                                                                <span className="input-group-text group-round"> <FaCreditCard /></span>
                                                            </div>
                                                            {formik.errors.securityCode && formik.touched.securityCode ? <small className='text-danger'>{formik.errors.securityCode}</small> : ""}

                                                        </div>
                                                    </div>
                                                    <div className='my-2'>
                                                        <button disabled={loading} type="submit" onClick={formik.handleSubmit} className="border-0 fs-18 p-btn btn-contine text-white fw-600">
                                                            {
                                                                loading ? <RotatingLines
                                                                    strokeColor="white"
                                                                    strokeWidth="5"
                                                                    animationDuration="0.75"
                                                                    width="18"
                                                                    visible={true}
                                                                /> : <>
                                                                    {ct('save')} <FaPlayCircle className='fa-lg ms-1' />
                                                                </>
                                                            }
                                                        </button>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>

        </>
    )
}


