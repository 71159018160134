import React, { useCallback, useMemo, useEffect, useState } from 'react';
import * as Yup from "yup";
import { useFormik } from 'formik';
import { FaCcMastercard, FaEllipsisV, FaChevronCircleDown, FaPaypal, FaPencilAlt, FaRegTrashAlt, FaCreditCard, FaPlayCircle } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { GetAxios, UserRole } from '../../Shared';
import { setGlobalLoading } from '../../Store';
import { config } from '../../Environments';
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import Modal from 'react-bootstrap/Modal';
import { VscChromeClose } from "react-icons/vsc";
import { AiOutlineClose } from "react-icons/ai";
import { RotatingLines } from 'react-loader-spinner';
import { CountrySelect } from '../../Components';
import axios from 'axios';
export const EditBillingInformation = (props) => {
    const { t: ts } = useTranslation('alert');
    const { t } = useTranslation('plan');
    const { t: ct } = useTranslation('common');
    const { t: c } = useTranslation('company');
    const schema = Yup.object().shape({
        firstName: Yup.string().required(ts('account.required') || 'Required'),
        lastName: Yup.string().required(ts('account.required') || 'Required'),
        address1: Yup.string().required(ts('account.required') || 'Required'),
        city: Yup.string().required(ts('account.required') || 'Required'),
        country: Yup.string().required(ts('account.required') || 'Required'),
        zip: Yup.string().when("country", {
            is: "HT",
            then: Yup.string().notRequired(), 
            otherwise:Yup.string().required(ts('account.required')|| 'Required')

        }),
        state: Yup.string().when("country", {
            is: "HT",
            then: Yup.string().notRequired(), 
            otherwise:Yup.string().required(ts('account.required')|| 'Required')

        }),
    });
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const formik = useFormik({
        initialValues: {
            id: 0,
            firstName: "",
            lastName: "",
            address1: "",
            address2: "",
            city: "",
            state: "",
            zip: "",
            phone: "",
            country: "",
            fullDetail: "",
            isDefaultBilling: "Yes",
        },
        validationSchema: schema,
        onSubmit: (values) => {
            if (loading) {
                toast(ts('account.pleaseWait'), { type: 'info' });
            }
            setLoading(true);
            const formData = new FormData();
            Object.keys(values).map(s => {
                formData.append(s, (values)[s]);
            });
            GetAxios().post(config.apiBase + '/api/Payment/SaveBilling', formData).then(response => {
                if (response.data.success) {
                    toast(ts('plan.billingInfo'), { type: 'success' });
                    props.loadData();
                } else {
                    if (response.data.errors) {
                        alert(response.data.errors.map((v) => v.description));
                    } else {
                        toast(ts('error.requestSubmit'), { type: 'info' });
                    }
                }
                setLoading(false);
            }).catch(err => {
                console.error(err);
                if (err.status >= 500) {
                    toast(ts('error.internalServer'), { type: 'error' });
                }
            });
        },
    });
    const [interest, setInterest] = useState(false);
    const handleClose = () => {
        setInterest(false);

    }
    const handleShow = () => {
        setInterest(true);
    }
    useEffect(() => {
        if (props.id > 0) {
            GetAxios(navigate).get(config.apiBase + '/api/Payment/GetBillingDetail?id=' + props.id).then(res => {
                formik.setValues(res.data.data);
            });
            formik.setFieldValue("id", props.id);
        }

    }, [props.id]);

    return (
        <>
            {
                <span className='text-blue' onClick={handleShow}><u>{props.id > 0 ? ct('edit') : ct('add')}</u></span>
            }
            <Modal show={interest} onHide={handleClose} dialogClassName="modal-6inch-wprint">
                <Modal.Body className="p-0">
                    <div className='container mt-2'>
                        <div className="d-flex align-items-center justify-content-between px-1 py-1 mt-2">
                            <h4 className='fw-700 '> {t('common.editBilling')} </h4>
                            <VscChromeClose onClick={handleClose} size={28} className="close-padd cursor-pointer" />
                        </div>
                        <div className='profile-card my-2'>
                            <div className='row gx-3 pb-0'>
                                <div className='col-md-12 mb-3'>
                                    <div className='card p-4'>
                                        <div className='fs-14 text-start position-relative'>
                                            <div className='detail'>
                                                <div className='top-bottom'>
                                                    <div className='row'>
                                                        <div className='col-md-6 mb-2'>
                                                            <div className='line-height-sta'>
                                                                <label className="form-label key-weight" htmlFor="nameCard"> {ct('firstName')} </label>
                                                            </div>
                                                            <input type="text" name="firstName" onChange={formik.handleChange} value={formik.values.firstName} className="form-control key-weight" />
                                                            {formik.errors.firstName && formik.touched.firstName ? <small className='text-danger'>{formik.errors.firstName}</small> : ""}
                                                        </div>
                                                        <div className='col-md-6 col-6 mb-2'>
                                                            <div className='line-height-sta'>
                                                                <label className="form-label key-weight" htmlFor="cardNumber"> {ct('lastName')} </label>
                                                            </div>
                                                            <input type="text" name="lastName" onChange={formik.handleChange} value={formik.values.lastName} className="form-control key-weight" />
                                                            {formik.errors.lastName && formik.touched.lastName ? <small className='text-danger'>{formik.errors.lastName}</small> : ""}

                                                        </div>
                                                        <div className='col-md-12 mb-2'>
                                                            <div className='line-height-sta'>
                                                                <label className="form-label key-weight" htmlFor="nameCard"> {c('editCompProfile.streetAddress1')} </label>
                                                            </div>
                                                            <input type="text" name="address1" onChange={formik.handleChange} value={formik.values.address1} className="form-control key-weight" />
                                                            {formik.errors.address1 && formik.touched.address1 ? <small className='text-danger'>{formik.errors.address1}</small> : ""}
                                                        </div>
                                                        <div className='col-md-12 mb-2'>
                                                            <div className='line-height-sta'>
                                                                <label className="form-label key-weight" htmlFor="nameCard"> {c('editCompProfile.streetAddress2')} </label>
                                                            </div>
                                                            <input type="text" name="address2" onChange={formik.handleChange} value={formik.values.address2} className="form-control key-weight" />
                                                          
                                                        </div>
                                                        <div className='col-md-6 col-6 mb-2'>
                                                            <div className='line-height-sta'>
                                                                <label className="form-label key-weight" htmlFor="cardNumber"> {ct('city')} </label>
                                                            </div>
                                                            <input type="text" name="city" onChange={formik.handleChange} value={formik.values.city} className="form-control key-weight" />
                                                            {formik.errors.city && formik.touched.city ? <small className='text-danger'>{formik.errors.city}</small> : ""}

                                                        </div>
                                                        <div className='col-md-6 mb-2'>
                                                            <div className='line-height-sta'>
                                                                <label className="form-label key-weight" htmlFor="nameCard"> {ct('state')} </label>
                                                            </div>
                                                            <input type="text" name="state" onChange={formik.handleChange} value={formik.values.state} className="form-control key-weight" />
                                                            {formik.errors.state && formik.touched.state ? <small className='text-danger'>{formik.errors.state}</small> : ""}
                                                        </div>
                                                        <div className='col-md-6 col-6 mb-2'>
                                                            <div className='line-height-sta'>
                                                                <label className="form-label key-weight" htmlFor="cardNumber"> {ct('zipCode')} </label>
                                                            </div>
                                                            <input type="text" name="zip" onChange={formik.handleChange} value={formik.values.zip} className="form-control key-weight" />
                                                            {formik.errors.zip && formik.touched.zip ? <small className='text-danger'>{formik.errors.zip}</small> : ""}

                                                        </div>
                                                        <div className='col-md-6 col-6 mb-2'>
                                                            <div className='line-height-sta'>
                                                                <label className="form-label key-weight" htmlFor="cardNumber"> {ct('counrtry')} </label>
                                                            </div>

                                                            <CountrySelect
                                                                value={formik.values.country}
                                                                onChange={(code) => {
                                                                    formik.setFieldValue("country", code);
                                                                }}
                                                            />

                                                            {formik.errors.country && formik.touched.country ? <small className='text-danger'>{formik.errors.country}</small> : ""}

                                                        </div>
                                                        {/* <div className='col-md-12 col-12 mb-2'>
                                                            <div className='line-height-sta'>
                                                                <label className="form-label key-weight" htmlFor="cardNumber"> Default Billing  </label>
                                                            </div>
                                                            <div className='d-flex align-items-center mt-1'>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" checked={formik.values.isDefaultBilling === "Yes" ? true : false} type="radio" onChange={formik.handleChange} name="isDefaultBilling" id="inlineCheckbox1" value="Yes" />
                                                                    <label className="form-check-label key-weight" htmlFor="inlineCheckbox1"> Yes </label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" checked={formik.values.isDefaultBilling === "No" ? true : false} onChange={formik.handleChange} type="radio" name="isDefaultBilling" id="inlineCheckbox2" value="No" />
                                                                    <label className="form-check-label key-weight" htmlFor="inlineCheckbox2"> No </label>
                                                                </div>
                                                                {formik.errors.isDefaultBilling && formik.touched.isDefaultBilling ? <small className='text-danger'>{formik.errors.isDefaultBilling}</small> : ""}
                                                            </div>
                                                        </div> */}

                                                    </div>

                                                    <div className='my-2'>
                                                        <button disabled={loading} type="submit" onClick={formik.handleSubmit} className="border-0 fs-18 p-btn btn-contine text-white fw-600">
                                                            {
                                                                loading ? <RotatingLines
                                                                    strokeColor="white"
                                                                    strokeWidth="5"
                                                                    animationDuration="0.75"
                                                                    width="18"
                                                                    visible={true}
                                                                /> : <>
                                                                    {ct('save')}  <FaPlayCircle className='fa-lg ms-1' />
                                                                </>
                                                            }
                                                        </button>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>

        </>
    )
} 