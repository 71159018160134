import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

export const ResumeBankIndex = () => {
    const location = useLocation();
    return(
        <>
         <div>This is the my resume bank page.</div>
        </>
    )
}