import React, { useState } from 'react';
import { FaPlayCircle } from "react-icons/fa";
import Form from 'react-bootstrap/Form';
import { useFormik, Field, Formik } from 'formik';
import * as Yup from 'yup';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { config } from '../../Environments';
import { GetAxios, KeyPairValue } from '../../Shared';
import { RotatingLines } from 'react-loader-spinner';
import { useTranslation } from 'react-i18next';


export const FeedbackIndex = () => {

  const { t } = useTranslation('sendFeedback');
  const { t: ct } = useTranslation('common');
  const { t: ts } = useTranslation('alert');
  const { i18n } = useTranslation();
  const feedbackSchema = Yup.object().shape({

    isFirstVisit: Yup.string().required(ts('account.required') || 'Required'),
    isAbletoFind: Yup.string().required(ts('account.required') || 'Required'),
    isEasytoFindInfo: Yup.string().required(ts('account.required') || 'Required'),
    willReturntoSite: Yup.string().required(ts('account.required') || 'Required'),
    lang: Yup.string().required(ts('account.required') || 'Required'),
  });


  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      isFirstVisit: "",
      primaryReason: "",
      isAbletoFind: "",
      seekingItems: "",
      isEasytoFindInfo: "",
      willReturntoSite: "",
      comments: "",
      lang: ""
    },
    validationSchema: feedbackSchema,
    onSubmit: (values, { resetForm }) => {

      // values => {
      if (loading) {
        toast(ts('account.pleaseWait'), { type: 'info' });
      }
      setLoading(true);
      const formData = new FormData();
      Object.keys(values).map(s => {
        formData.append(s, (values as any)[s]);
      });
      GetAxios().post(config.apiBase + '/api/Contact/FeedbackUsMail', formData).then(response => {
        if (response) {
          toast(ts('feedback.send'), { type: 'success' });
          resetForm();
        } else {
          toast(ts('feedback.unSend'), { type: 'error' });
        }
        setLoading(false);
      }).catch(err => {
        console.error(err);
        if (err.status >= 500) {
          toast(ts('error.internalServer'), { type: 'error' });
        }
      });
    },
  });

  React.useEffect(() => {
    formik.setFieldValue('lang', i18n.language)

  }, [i18n.language]);


  // const [firstWebsite, setFirstWebsite] = useState('');
  // const [findNeeded, setFindNeeded] = useState('');
  // const [findSite, setFindSite] = useState('');
  // const [likehoodSite, setLikehoodSite] = useState('');

  // const handleFirstWebsite = (event: any) => {
  //   setFirstWebsite(event.target.value);
  // };

  // const handleNeeded = (event: any) => {
  //   setFindNeeded(event.target.value);
  // };

  // const handleInfoSite = (event: any) => {
  //   setFindSite(event.target.value);
  // };

  // const handleLikehoodSite = (event: any) => {
  //   setLikehoodSite(event.target.value);
  // };




  return (
    <>
      <div className="container py-50">
        <div className="card">
          <div className="card-body pt-4">
            <div className="container px-1">
              <h2 className="mt-0 fw-600 text-blue text-capitalize"> {t('websiteFeedbackTitle')} </h2>
              <div className="row">
                <div className="col-12">
                  <div className="form-flyer p-3">
                    <div className="heading-flyer">
                      {/* <h5 className="fw-700 mt-0 text-uppercase text-gray"> {t('websiteFeedbackTitle')} </h5> */}
                      <p> {t('feedbackDesc')}.</p>
                    </div>
                    <div className="form-detail pt-1">
                      <div className="row">
                        <div className="col-md-6 col-12 mb-2">
                          <div className="border">
                            <div className="gray-form">
                              <label htmlFor="email" className="form-label fw-700 ps-3 text-uppercase mt-1"> {ct('email')} <span className="text-gray fw-400 text-lowercase ms-2">({t('optional')})</span> </label>
                            </div>
                            <input type="email" name="email" onChange={formik.handleChange} value={formik.values.email} className="form-control " id="email" />

                          </div>
                        </div>
                        <div className="col-md-6 col-12 mb-2">
                          <div className="border">
                            <div className="gray-form">
                              <label htmlFor="name" className="form-label fw-700 ps-3 text-uppercase mt-1">{ct('name')} <span className="text-gray fw-400 text-lowercase ms-2">({t('optional')})</span> </label>
                            </div>
                            <input type="text" name="name" onChange={formik.handleChange} value={formik.values.name} className="form-control " id="name" />

                          </div>
                        </div>
                      </div>

                      <div className="border border-bottom my-3"></div>

                      <div className="row">
                        <div className="col-md-6 col-12 mb-2">
                          <div className="website-test">
                            <h6 className="fw-500"> {t('isFirstWebsite')}?</h6>
                            <div className="feedback-web-checkbox">
                              <div className="form-check mb-3 d-flex align-items-center">
                                <input className="form-check-input feedback-input" name="isFirstVisit" id="yesWebsite" checked={formik.values.isFirstVisit === "Yes" ? true : false} type="radio" onChange={formik.handleChange} value="Yes" />
                                <label className="form-check-label fs-13 ms-2" htmlFor="yesWebsite">
                                  {t('yes')}
                                </label>
                              </div>
                              <div className="form-check mb-3 d-flex align-items-center">
                                <input className="form-check-input feedback-input" name="isFirstVisit" id="noWebsite" checked={formik.values.isFirstVisit === "No" ? true : false} type="radio" onChange={formik.handleChange} value="No" />
                                <label className="form-check-label fs-13 ms-2" htmlFor="noWebsite">
                                  {t('no')}
                                </label>
                              </div>
                              {formik.errors.isFirstVisit && formik.touched.isFirstVisit ? <small className='text-danger d-block'>{formik.errors.isFirstVisit}</small> : ""}

                            </div>
                          </div>
                          <div className="primary-reason">
                            <Form.Group className="mb-0" controlId="primaryReason">
                              <Form.Label className="fw-500"> {t('primaryReason')}<br /> {t('website')}?</Form.Label>
                              <Form.Control as="textarea" name="primaryReason" onChange={formik.handleChange} value={formik.values.primaryReason} rows={3} />
                            </Form.Group>
                          </div>
                        </div>
                        <div className="col-md-6 col-12 mb-2">
                          <div className="website-test">
                            <h6 className="fw-500"> {t('findNeeded')}?</h6>
                            <div className="feedback-web-checkbox">
                              <div className="form-check mb-3 d-flex align-items-center">
                                <input className="form-check-input feedback-input" type="radio" id="yesNeeded" name='isAbletoFind' checked={formik.values.isAbletoFind === 'Yes' ? true : false} onChange={formik.handleChange} value="Yes" />
                                <label className="form-check-label fs-13 ms-2" htmlFor="yesNeeded">
                                  {t('yes')}
                                </label>
                              </div>
                              <div className="form-check mb-3 d-flex align-items-center">
                                <input className="form-check-input feedback-input" type="radio" id="noNeeded" name="isAbletoFind" checked={formik.values.isAbletoFind === 'No' ? true : false} onChange={formik.handleChange} value="No" />
                                <label className="form-check-label fs-13 ms-2" htmlFor="noNeeded">
                                  {t('no')}
                                </label>
                              </div>
                              {formik.errors.isAbletoFind && formik.touched.isAbletoFind ? <small className='text-danger d-block'>{formik.errors.isAbletoFind}</small> : ""}

                            </div>
                          </div>
                          <div className="primary-reason">
                            <Form.Group className="mb-0" controlId="seekingItems">
                              <Form.Label className="fw-500 text-lg-nowrap text-md-nowrap"> {t('notNeededSeeking')}?</Form.Label>
                              <Form.Control as="textarea" name="seekingItems" onChange={formik.handleChange} value={formik.values.seekingItems} rows={3} />

                            </Form.Group>
                          </div>
                        </div>
                      </div>

                      <div className="border border-bottom my-3"></div>

                      <div className="row">
                        <div className="col-md-6 col-12 mb-2">
                          <div className="website-test">
                            <h6 className="fw-500"> {t('findInformationSite')}?</h6>
                            <div className="feedback-web-checkbox">
                              <div className="form-check mb-3 d-flex align-items-center">

                                <input className="form-check-input feedback-input" type="radio" id="isEasytoFindInfo" name="isEasytoFindInfo" checked={formik.values.isEasytoFindInfo === 'Very Easy' ? true : false} onChange={formik.handleChange} value="Very Easy" />
                                <label className="form-check-label fs-13 ms-2" htmlFor="veryEasy">
                                  {t('veryEasy')}
                                </label>
                              </div>
                              <div className="form-check mb-3 d-flex align-items-center">
                                <input className="form-check-input feedback-input" type="radio" id="isEasytoFindInfo" name="isEasytoFindInfo" checked={formik.values.isEasytoFindInfo === 'Easy' ? true : false} onChange={formik.handleChange} value="Easy" />
                                <label className="form-check-label fs-13 ms-2" htmlFor="easy">
                                  {t('easy')}
                                </label>
                              </div>
                              <div className="form-check mb-3 d-flex align-items-center">
                                <input className="form-check-input feedback-input" type="radio" id="isEasytoFindInfo" name="isEasytoFindInfo" checked={formik.values.isEasytoFindInfo === 'Average' ? true : false} onChange={formik.handleChange} value="Average" />
                                <label className="form-check-label fs-13 ms-2" htmlFor="average">
                                  {t('average')}
                                </label>
                              </div>
                              <div className="form-check mb-3 d-flex align-items-center">
                                <input className="form-check-input feedback-input" type="radio" id="isEasytoFindInfo" name="isEasytoFindInfo" checked={formik.values.isEasytoFindInfo === 'Difficult' ? true : false} onChange={formik.handleChange} value="Difficult" />
                                <label className="form-check-label fs-13 ms-2" htmlFor="difficult">
                                  {t('difficult')}
                                </label>
                              </div>
                              <div className="form-check mb-3 d-flex align-items-center">
                                <input className="form-check-input feedback-input" type="radio" id="isEasytoFindInfo" name="isEasytoFindInfo" checked={formik.values.isEasytoFindInfo === 'Very Difficult' ? true : false} onChange={formik.handleChange} value="Very Difficult" />
                                <label className="form-check-label fs-13 ms-2" htmlFor="veryDifficult">
                                  {t('veryDifficult')}
                                </label>
                              </div>
                              {formik.errors.isEasytoFindInfo && formik.touched.isEasytoFindInfo ? <small className='text-danger d-block'>{formik.errors.isEasytoFindInfo}</small> : ""}

                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-12 mb-2">
                          <div className="website-test">
                            <h6 className="fw-500"> {t('likehoodReturnSite')}?</h6>
                            <div className="feedback-web-checkbox">
                              <div className="form-check mb-3 d-flex align-items-center">
                                <input className="form-check-input feedback-input" type="radio" id="willReturntoSite" name="willReturntoSite" checked={formik.values.willReturntoSite === 'Extremely Likely' ? true : false} onChange={formik.handleChange} value="Extremely Likely" />

                                <label className="form-check-label fs-13 ms-2" htmlFor="extremelyLikely">
                                  {t('extremelyLikely')}
                                </label>
                              </div>
                              <div className="form-check mb-3 d-flex align-items-center">
                                <input className="form-check-input feedback-input" type="radio" id="willReturntoSite" name="willReturntoSite" checked={formik.values.willReturntoSite === 'Very Likely' ? true : false} onChange={formik.handleChange} value="Very Likely" />
                                <label className="form-check-label fs-13 ms-2" htmlFor="veryLikely">
                                  {t('veryLikely')}
                                </label>
                              </div>
                              <div className="form-check mb-3 d-flex align-items-center">
                                <input className="form-check-input feedback-input" type="radio" id="willReturntoSite" name="willReturntoSite" checked={formik.values.willReturntoSite === 'Moderately Likely' ? true : false} onChange={formik.handleChange} value="Moderately Likely" />
                                <label className="form-check-label fs-13 ms-2" htmlFor="moderatelyLikely">
                                  {t('moderatelyLikely')}
                                </label>
                              </div>
                              <div className="form-check mb-3 d-flex align-items-center">
                                <input className="form-check-input feedback-input" type="radio" id="willReturntoSite" name="willReturntoSite" checked={formik.values.willReturntoSite === 'Slightly Likely' ? true : false} onChange={formik.handleChange} value="Slightly Likely" />
                                <label className="form-check-label fs-13 ms-2" htmlFor="slightlyLikely">
                                  {t('slightlyLikely')}
                                </label>
                              </div>
                              <div className="form-check mb-3 d-flex align-items-center">
                                <input className="form-check-input feedback-input" type="radio" id="willReturntoSite" name="willReturntoSite" checked={formik.values.willReturntoSite === 'Unlikely to Return' ? true : false} onChange={formik.handleChange} value="Unlikely to Return" />
                                <label className="form-check-label fs-13 ms-2" htmlFor="unlikelyReturn">
                                  {t('unlikelyReturn')}
                                </label>
                              </div>
                              {formik.errors.willReturntoSite && formik.touched.willReturntoSite ? <small className='text-danger d-block'>{formik.errors.willReturntoSite}</small> : ""}

                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="border border-bottom my-3"></div>

                      <div className="row">
                        <div className="col-md-12 col-12 mb-2">
                          <div className="primary-reason px-lg-4 px-md-3 px-sm-3">
                            <Form.Group className="mb-0" controlId="comments">
                              <Form.Label className="fw-500">{t('pleaseAdditionalComments')}. <span className="text-gray fw-400 text-lowercase ms-1">{t('optional')}</span></Form.Label>
                              <Form.Control as="textarea" name="comments" onChange={formik.handleChange} value={formik.values.comments} rows={3} />

                            </Form.Group>
                          </div>
                        </div>
                      </div>

                      <div className="text-start mt-4">
                        <button disabled={loading} type="submit" onClick={formik.handleSubmit as any} className="border-0  p-btn btn-contine text-white fw-600 text-uppercase">
                          {
                            loading ? <RotatingLines
                              strokeColor="white"
                              strokeWidth="5"
                              animationDuration="0.75"
                              width="18"
                              visible={true}
                            /> : <>
                              {t('send')} <FaPlayCircle className='fa-lg ms-1' size={20} />
                            </>
                          }
                        </button>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
