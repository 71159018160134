import React from 'react';
import { Container, ProgressBar } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate, useParams, useLocation } from 'react-router-dom';
import styled from "styled-components";
import { config } from '../../../Environments';
import { GetAxios } from '../../../Shared';
import { setGlobalLoading } from '../../../Store';
import { setResumeForEdit } from '../../../Store/Slices/ResumeSlice';
import { useTranslation } from 'react-i18next';

const StepTitle = styled.div`
color: rgb(30, 32, 33);
font-size: 20px;
line-height: 28px;
font-weight: 500;
`
const StepNumber = styled.div`
color: rgb(150, 155, 158);
text-transform: uppercase;
line-height: 20px;
font-weight: 500;
letter-spacing: 1.5px;
`
function Index() {

  const { t } = useTranslation('resume');

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { resumeId } = useParams();

  const resume = useSelector((state: AppStore) => state.resume);

  const totalSteps = resume.stepsInformation.length;

  const location = useLocation();
  const path = location.pathname.split('/').filter(s => s);
  const lastPath = path[4];
  const stepTitle = location.pathname.toLowerCase().endsWith('finish') ? resume.title : t(resume.stepsInformation[resume.activeStep - 1]?.title);



  const loadData = () => {
    dispatch(setGlobalLoading(true));
    GetAxios(navigate, dispatch).get(config.apiBase + '/api/Resume/GetResume', {
      params: {
        id: resumeId
      }
    }).then(s => {
      dispatch(setResumeForEdit({
        ...s.data.data,
        customSection: s.data.data?.sections,
        imageData: JSON.parse(s.data.data?.imageData || '{}'),
        additionalSettings: JSON.parse(s.data.data?.additionalSettings || '{}'),
        image: s.data.data.image ? config.apiBase + s.data.data.image : undefined
      }));
      dispatch(setGlobalLoading(false));
    }).catch(err => {
      dispatch(setGlobalLoading(false));
    });
  };

  React.useEffect(() => {
    loadData();
  }, []);

  return (
    <Container className='my-3'>
      <Card>
        <div className='d-flex align-items-center justify-content-between border-bottom py-3 px-3'>
          {/* <StepTitle>{t(resume.stepsInformation[resume.activeStep - 1]?.title)}</StepTitle> */}
          <StepTitle>{stepTitle}</StepTitle>
          <StepNumber>{t('selectTemplate.step')} {resume.activeStep} {t('selectTemplate.of')} {totalSteps}</StepNumber>
        </div>
        <ProgressBar className='progress-green' now={(resume.activeStep * 100) / totalSteps} />
        <Card.Body>
          <Outlet />
        </Card.Body>
      </Card>
    </Container>
  );
}

export default Index;
