import React, { useState } from "react";
import { Button, Collapse } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import { FaAngleDown, FaAngleUp, FaFacebookF, FaLinkedin, FaMinus, FaPlus, FaTwitter, FaWhatsapp } from "react-icons/fa";
import { HiUpload } from "react-icons/hi";
import { VscChromeClose } from "react-icons/vsc";
import { RotatingLines } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { config } from "../../../Environments";
import { GetAxios, UserRole } from "../../../Shared";
import { setGlobalLoading, setShowModel } from "../../../Store";
import { useTranslation } from 'react-i18next';



export const TenderItem = (props: {
    userId: string,
    tender: any,
    i: number
}) => {

    const { t: ct } = useTranslation('common');
    const { t: jt } = useTranslation('job');
    const { t: tc } = useTranslation('company');
    const { t: ts } = useTranslation('alert');

    const navigate = useNavigate();
    const item = props.tender
    const index = props.i
    const currentuserId = props.userId;

    const [applied, setApplied] = useState(false);
    const [expanded, setExpended] = useState(false);
    const [expanding, setExpending] = useState(false);
    const [tenderDetail, setTenderdetail] = useState<any>();
    const [modalShow, setModalShow] = useState(false);
    const dispatch = useDispatch();
    const [logo, setLogo] = useState('');
    const { i18n } = useTranslation();
    const [gender, setGender] = useState('');
    const convertToPlain = (html: string) => {
        var tempDiv = document.createElement("p");
        tempDiv.innerHTML = html;
        var result = (tempDiv.innerText.length >= 250) ? tempDiv.innerText.substring(0, 250) : tempDiv.innerText;
        return result;
    }

    React.useEffect(() => {
        if (expanding) {
            dispatch(setGlobalLoading(true))
            GetAxios().get(config.apiBase + '/api/Tender/PublicView?tid=' + item.id).then(res => {
                setApplied(res.data?.data?.isAlreadyApplied || false);
                setTenderdetail(res.data.data);
                setExpending(false);
                setExpended(true);

                dispatch(setGlobalLoading(false))
            })
        }

    }, [expanding]);
    React.useEffect(() => {

        if (i18n.language == 'fr' && item.isAdminCreated == true) {
            setLogo(config.apiBase + item.logo2);
        }
        else {
            setLogo(config.apiBase + item.logo);
        }

    }, [i18n.language]);

    const auth = useSelector((state: AppStore) => state.auth);

    React.useEffect(() => {
        if (auth.isAuthenticated) {
            GetAxios().get(config.apiBase + '/api/General/GetUserSex')
                .then(res => {
                    setGender(res.data.data || '');
                })
        }
    }, [gender]);


    const userRole = UserRole(auth?.raw?.access_token);

    return (

        <div className="card mb-4 mt-3 border-0 rounded-0 p-0">
            <div className="card-body">
                <div className="row g-0">
                    <div className="col-md-2 col-4">
                        <div className="text-center">
                            <Link to={'/company/profile?id=' + item.companyUserId} target='_blank' >
                                <img src={logo}
                                    className="img-fluid"
                                    alt="image placeholder"
                                    onError={(e: any) => {
                                        e.target.src = '/images/ImgPlaceholder.png';
                                    }}
                                />
                            </Link>
                        </div>
                    </div>
                    <div className="col-md-10 col-8">
                        <div className="ms-3">
                            <div className="d-flex align-items-baseline justify-content-between flexcolumn-mob-start-j02">

                                <div>
                                    <h4 className="card-title mb-0 text-start">
                                        <Link to={"/tender/tenderpreview?tid=" + item.id} state={{ from: "searchTender" }} title={ct('clickTender').toString()}
                                            className="text-decoration-none text-dark pointer-event preview-link "> {item.title}</Link>

                                    </h4>
                                </div>
                                <div>
                                    <h6 className="fw-600 mb-0 text-job-id">
                                        {tc('tendersearch.tenderId')} {item.tenderNo}
                                    </h6>
                                </div>


                            </div>
                            <Link to={"/tender/tenderpreview?tid=" + item.id} state={{ from: "searchTender" }} title={ct('clickTender').toString()} className="card-subtitle text-decoration-none text-start pointer-event preview-sublink">
                                {item.sector}
                            </Link>
                            {/* <p className="card-text pt-1 fs-14 text-start" dangerouslySetInnerHTML={{ __html: item?.object || '' }}>
                         */}
                            <p className="card-text pt-1 fs-14 text-start" >
                                {convertToPlain(item.object)}
                            </p>
                        </div>
                    </div>
                    {/* <Collapse in={expanded}>
                        <div
                            id={"tender-description" + index}>
                            <div className="pt-4 pb-0">
                                <div className="row">
                                    <div className="col-md-4 col-sm-12">
                                        <div className="card border-0 rounded-0">
                                            <img
                                                src={config.apiBase + item.logo}
                                                className="card-img-top rounded-0"
                                                onError={(ev: any) => {
                                                    ev.target.src = '/images/ImgPlaceholder.png';
                                                }}
                                            />
                                            <div className="card-body pb-5">
                                                <div className="">
                                                    <div className="mt-2 border-bottom border-secondary">
                                                        <h5 className="text-color-33 fw-600 text-uppercase mb-2">
                                                            {tc('callTender.tenderDetails')}
                                                        </h5>
                                                    </div>
                                                    <div className="row pt-3">
                                                        <div className="col-6">
                                                            <div>
                                                                <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600 ">
                                                                    {tc('callTender.tenderTitle')}:
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 px-0">
                                                            <div>
                                                                <span className="fw-12">{tenderDetail?.title}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-3">
                                                        <div className="col-6">
                                                            <div>
                                                                <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600 ">
                                                                    {tc('callTender.companyName')}:
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 px-0">
                                                            <div>
                                                                <span className="fw-12">{tenderDetail?.companyName}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-3">
                                                        <div className="col-6">
                                                            <div>
                                                                <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">
                                                                    {tc('callTender.address')}:
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 px-0">
                                                            <div>
                                                                <span className="fw-12">{tenderDetail?.address}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-3">
                                                        <div className="col-6">
                                                            <div>
                                                                <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">
                                                                    {tc('callTender.city')}:
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 px-0">
                                                            <div>
                                                                <span className="fw-12">{tenderDetail?.city}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-3">
                                                        <div className="col-6">
                                                            <div>
                                                                <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600" >
                                                                    {tc('callTender.departments')}:
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 px-0">
                                                            <div>
                                                                <span className="fw-12" style={{ marginLeft: "12px" }}>{tenderDetail?.department}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-3">
                                                        <div className="col-6">
                                                            <div>
                                                                <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">
                                                                    {tc('callTender.tel')}
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 px-0">
                                                            <div>
                                                                <span className="fw-12">{tenderDetail?.tel || "N/A"}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-3">
                                                        <div className="col-6">
                                                            <div>
                                                                <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">
                                                                    {tc('callTender.email')}:
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 px-0">
                                                            <div>
                                                                <span className="fw-12">{tenderDetail?.email || "N/A"}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-3">
                                                        <div className="col-6">
                                                            <div>
                                                                <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">
                                                                    {jt('job.contactPerson')}:
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 px-0">
                                                            <div>
                                                                <span className="fw-12">{tenderDetail?.contactPerson|| "N/A"}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-3">
                                                        <div className="col-6">
                                                            <div>
                                                                <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">
                                                                    {jt('job.sectorOfActivity')}:
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 px-0">
                                                            <div>
                                                                <span className="fw-12">
                                                                    {tenderDetail?.sector}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-3">
                                                        <div className="col-6">
                                                            <div>
                                                                <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">
                                                                    {jt('job.classification')}:
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 px-0">
                                                            <div>
                                                                <span className="fw-12" style={{ marginLeft: "12px" }}>
                                                                    {tenderDetail?.classification}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-3">
                                                        <div className="col-6">
                                                            <div>
                                                                <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">
                                                                    {tc('callTender.informationAboutRecurrence')}:
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 px-0">
                                                            <div>
                                                                <span className="fw-12" style={{ marginLeft: "6px" }}>
                                                                    {tenderDetail?.recurrence}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-3">
                                                        <div className="col-6">
                                                            <div>
                                                                <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">
                                                                    {tc('callTender.dateOfDispatch')} {tc('callTender.ofThisTender')}:
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 px-0">
                                                            <div>
                                                                <span className="fw-12">
                                                                    {tenderDetail?.dispatchDate}
                                                               
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-3">
                                                        <div className="col-12">
                                                            <div>
                                                                <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">
                                                                    {tc('callTender.conditionsForOpeningTenders')}:
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-3">
                                                        <div className="col-6">
                                                            <div>
                                                                <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">
                                                                    {tc('callTender.date')}:
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 px-0">
                                                            <div>
                                                                <span className="fw-12">
                                                                    {tenderDetail?.openingDate}
                                                                  
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-3">
                                                        <div className="col-6">
                                                            <div>
                                                                <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">
                                                                    {jt('job.expiresOn')}:
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 px-0">
                                                            <div>
                                                                <span className="fw-12">
                                                                    {tenderDetail?.expireDate}
                                                                 
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-3">
                                                        <div className="col-6">
                                                            <div>
                                                                <label className="mb-0 fw-600 text-uppercase fsize-13 fw-600">
                                                                    {tc('callTender.time')}:
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 px-0">
                                                            <div>
                                                                <span className="fw-12">
                                                                    {tenderDetail?.time}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-md-8 col-sm-12">
                                        <div className="candidate-sec">
                                            <div className="candidate-title">
                                            <p className="h3 mb-3">{tenderDetail?.objectTitle}</p>
                                              
                                            </div>
                                            <div className="detail-content-preview">
                                                <p className="fs-14 lh-lg">
                                                    <div dangerouslySetInnerHTML={{ __html: tenderDetail?.object }}></div>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="candidate-sec">
                                            <div className="candidate-title">
                                            <p className="h3 mb-3">{tenderDetail?.procedureTitle}</p>
                                             
                                            </div>
                                            <div className="detail-content-preview">
                                                <p className="fs-14 lh-lg">
                                                    <div dangerouslySetInnerHTML={{ __html: tenderDetail?.procedure }}></div>
                                                </p>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="candidate-title ">
                                                <p className="h3 text-nowrap">{tc('callTender.legalEconomic')} {tc('callTender.financialAnd')} <br />{tc('callTender.technicalInformation')}</p>
                                            </div>
                                        </div>
                                        <div className="candidate-sec">
                                            <div className="candidate-title ">
                                            <p className="h3 mb-3">{tenderDetail?.participationTitle}</p>
                                             
                                            </div>
                                            <div className="detail-content-preview">
                                                <p className="fs-14 lh-lg">
                                                    <div dangerouslySetInnerHTML={{ __html: tenderDetail?.participation }}></div>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="candidate-sec">
                                            <div className="candidate-title ">
                                            <p className="h3 mb-3">{tenderDetail?.economicStandingTitle}</p>
                                              
                                            </div>
                                            <div className="detail-content-preview">
                                                <p className="fs-14 lh-lg">
                                                    <div dangerouslySetInnerHTML={{ __html: tenderDetail?.economicStanding }}></div>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="candidate-sec">
                                            <div className="candidate-title">
                                            <p className="h3 mb-3">{tenderDetail?.technicalAbilityTitle}</p>
                                            
                                            </div>
                                            <div className="detail-content-preview">
                                                <p className="fs-14 lh-lg">
                                                    <div dangerouslySetInnerHTML={{ __html: tenderDetail?.technicalAbility }}></div>
                                                </p>
                                            </div>
                                        </div>

                                        <div className="candidate-sec">
                                            <div className="candidate-title mt-3 d-flex align-items-center justify-content-between">

                                                <ul className="list-inline d-flex p-0 align-items-center">
                                                    <li><p className="h5 fw-bold me-2 mb-0"> {ct('share')}: </p></li>
                                                    {
                                                        tenderDetail?.facebookShare == true &&
                                                        <li><a onClick={() => { handleShare("FaceBook") }} className="tags text-decoration-none mb-0 me-2 facebook-color pointer-event"> <FaFacebookF size={18} /></a></li>
                                                    }
                                                    {
                                                        tenderDetail?.linkedInShare == true &&
                                                        <li><a onClick={() => { handleShare("LinkedIn") }} className="tags text-decoration-none mb-0 me-2 linkedIn-color pointer-event"><FaLinkedin size={18} /></a></li>
                                                    }
                                                    {
                                                        tenderDetail?.whatsappShare == true &&
                                                        <li><a onClick={() => { handleShare("Whatsapp") }} className="tags text-decoration-none mb-0 me-2 whatsapp-bgcolor pointer-event"><FaWhatsapp size={18} /></a></li>
                                                    }
                                                    {
                                                        tenderDetail?.twitterShare == true &&
                                                        <li><a onClick={() => { handleShare("Twitter") }} className="tags text-decoration-none mb-0 me-2 twitter-color pointer-event"><FaTwitter size={18} /></a></li>
                                                    }

                                                </ul>
                                                <div className="text-end">

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </Collapse> */}
                    <div className="d-flex justify-content-end mb-2 mt-lg-1 mt-sm-2 m-top-mob">
                        <div className="accordion-item">
                            {
                                ((currentuserId !== item.companyUserId) && userRole == 'Employer' || !userRole) &&
                                <Button className="border-0 btn-interest fw-600 rounded-0 me-4" onClick={() => {
                                    if (auth.isAuthenticated) {

                                        setModalShow(true)
                                    } else {
                                        localStorage.setItem("isPreviousPath", window.location.pathname);
                                        toast(ts('company.applyAlert'), { type: 'error' });
                                        dispatch(setShowModel(true));
                                    }
                                }}>
                                    {gender == 'Female' ? ct('femaleImInterested') : ct('maleImInterested')}
                                </Button>
                            }
                            <ProposalPopupModal tendercallId={item.id} handleModalShow={modalShow} handleModalClose={() => setModalShow(false)} />

                        </div>
                        <div className="accordion-item">
                            <Button
                                className="border-0 button-default accordion-header SeeMore1 fw-600 rounded-0 text-nowrap"
                                onClick={() => {
                                    navigate("/tender/tenderpreview?tid=" + item.id, { state: { from: "searchTender" } });
                                }}
                                aria-controls={"tender-description" + index}
                            >{ct('moreInfo')}

                            </Button>
                            {/* <Button
                                className="border-0 button-default accordion-header SeeMore1 fw-600 rounded-0 text-nowrap"
                                onClick={() => {
                                    if (expanded)
                                        setExpended(false);
                                    else
                                        setExpending(true);
                                }}
                                aria-controls={"job-description" + index}
                                aria-expanded={expanded} disabled={expanding}>
                                {
                                    expanding ? <RotatingLines width="20" /> :
                                        <>
                                            {
                                                expanded ? <FaAngleUp className="me-1" size={25} /> : <FaAngleDown className="me-1" size={25} />
                                            }
                                            {expanded ? <>{ct('lessInfo')}</> : <>{ct('moreInfo')}</>}
                                        </>
                                }
                            </Button> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}





export const ProposalPopupModal = (props: any) => {

    const fileTypes = [
        "application/pdf",
        ".doc",
        ".docx",
        "application/msword",
        ".pdf",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "text/plain",
        "text/html",
    ];
    const [applied, setApplied] = useState(false);
    const [applying, setApplying] = useState(false);
    const [fileList, setFileList] = useState<FileList | null>(null);


    const files = fileList ? [...fileList] : [];
    const { t: ct } = useTranslation('common');
    const { t: ts } = useTranslation('alert');

    function validFileType(files: any) {
        let isValid = true;
        for (let i = 0; i < files.length; i++) {
            isValid = fileTypes.includes(files[i].type);
        }
        return isValid;

    }
    function checkFileSize(files: any) {
        let isValid = true;
        for (let i = 0; i < files.length; i++) {
            let size = files[i].size;

            if (size < 1024) {
                isValid = true;
            }
            else if (size >= 1024 && size < 1048576) {
                if (Number((size / 1024).toFixed(1)) > 500) {

                    isValid = false;
                    return isValid;
                }
                else {
                    isValid = true;
                }
            } else if (size >= 1048576) {
                isValid = false;
                return isValid;
            }
        }

        return isValid;


    }
    const onFileSelected = (e: any) => {
        setFileList(e.target.files);

    };
    const handleFileChange = (e: any) => {
        if (e.target.files && e.target.files.length > 0) {
            if (validFileType(e.target.files)) {
                if (checkFileSize(e.target.files)) {
                    setFileList(e.target.files);
                }
                else {
                    toast(ts('resume.fileSize'), { type: 'error' });
                }
            }
            else {
                toast(ts('resume.inValidFile'), { type: 'error' });
            }
        }

    }
    const handleSubmit = (e: any) => {
        e.preventDefault();
        if (!fileList) {
            return;
        }
        const formData = new FormData();
        formData.append('Id', props.tendercallId);
        for (let i = 0; i < files.length; i++) {
            formData.append('Proposals', files[i], files[i].name);
            // formData.append('Proposals', fileList[i]);
        }
        setApplying(true);
        GetAxios().post(config.apiBase + '/api/TenderProposal/ProposeTender', formData
            , { headers: { "Content-Type": "multipart/form-data" } },
        ).then(res => {
            if (res.data.success) {
                toast(ts('tender.applySuccess'), { type: 'success' });
                setApplying(false);
                setApplied(true);
            } else {
                toast(ts('tender.alreadyApply'), { type: 'warning' })
                setApplying(false);
                setApplied(true);
            }
        }).catch(err => {
            if (err.status == 401 || err.status == 403) {
                toast(ts('tender.onlyEmployer'), { type: 'error' });
            }
            setApplying(false);
        });

    };


    return (
        <>
            <Modal show={props.handleModalShow} onHide={props.handleModalClose} className="border-blue-resume">
                <Modal.Header style={{ borderBottom: '0px', display: 'block' }}>
                    <div className="d-flex align-items-center justify-content-between">
                        <div>
                            <Modal.Title className="fw-700"> {ct('selectProposal')}

                            </Modal.Title>
                        </div>
                        <VscChromeClose onClick={props.handleModalClose} size={28} className="close-padd cursor-pointer" />

                    </div>
                </Modal.Header>
                <Modal.Body style={{ paddingTop: '0px', paddingBottom: '0px' }}>
                    <div className="text-center">
                        <div className="border-bottom border-dark border-1">
                        </div>
                        <div className="my-4">
                            <p className="text-start fw-500 fs-15"> {ct('uploadPdfText')} </p>
                            <div className="custom-file-button d-flex justify-content-center">
                                <label className="custom-label-files" htmlFor="raised-button-file">
                                    <span className="custom-file-button">
                                        <HiUpload size={26} /> {ct('uploadProposal')}
                                    </span>
                                </label>
                                {/* <input type="file" multiple accept=".doc,.docx,application/msword,.pdf,application/pdf,text/plain,text/html" style={{ display: 'none' }}
                                    onChange={onFileSelected} id="raised-button-file" /> */}
                                <input type="file" multiple accept=".doc,.docx,application/msword,.pdf,application/pdf,text/plain,text/html" style={{ display: 'none' }}
                                    onChange={handleFileChange} id="raised-button-file" />
                                <input type="text" className="w-50 form-control" value='' />

                            </div>

                        </div>


                    </div>
                    <div className="border-bottom border-dark border-1 mb-3">
                        <ul>
                            {files.map((file: any, i) => (
                                <li key={i}>
                                    {file.name} - {file.type}
                                </li>
                            ))}
                        </ul>
                    </div>
                </Modal.Body>
                <Modal.Footer style={{ borderTop: '0px' }}>
                    <Button className="fs-20 rounded ptb-15 text-white bg-dark-blue border-0 fw-600"
                        disabled={applying || applied}
                        onClick={handleSubmit}>{applying ? <RotatingLines width="20" /> : applied ? <div>{ct('applied')}</div> : <div>{ct('apply')}</div>}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}