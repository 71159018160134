import * as React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


export const CookiesPolicy = () => {

    const { t } = useTranslation('home');

    return (
        <>
            <div className="container">
                <div className="row py-50 mx-auto">
                    <div className="col-12 fontsize-privacy">
                        <div>
                            <h1 className="mb-3 mt-0 fw-600 title-privacy"> {t('cookiePolicy.cookiesPolicyTitle')}</h1>
                            <p><strong>{t('cookiePolicy.effectiveDate')}: </strong> {t('cookiePolicy.date')}.</p>
                            <p>{t('cookiePolicy.informUsers')} <strong>({t('cookiePolicy.hereinAfter')}, “{t('cookiePolicy.you')}”)</strong> {t('cookiePolicy.thatThisWebsite')} <strong>“{t('cookiePolicy.Website')}”</strong>)  {t('cookiePolicy.policyDesc1')}.</p>
                            <p>{t('cookiePolicy.policyDesc2')}.</p>
                            <div>
                                <p className='fw-600 subtitle-privacy'>{t('cookiePolicy.whatAreCookies')}?</p>
                                <p><strong>“{t('cookiePolicy.cookies')}”</strong> {t('cookiePolicy.whatAreCookiesDesc1')}.
                                </p>
                                <p>{t('cookiePolicy.whatAreCookiesDesc2')}.</p>
                            </div>
                            <div>
                                <p className='fw-600 subtitle-privacy'>{t('cookiePolicy.whenInstallCookies')}?</p>
                                <p>{t('cookiePolicy.whenInstallCookiesDesc1')}.
                                </p>
                                <ul className='ps-3'>
                                    <li><p><strong>{t('cookiePolicy.sessionCookies')}: </strong> {t('cookiePolicy.sessionCookiesDesc')}.</p>
                                    </li>
                                    <li><p><strong>{t('cookiePolicy.persistentCookies')}: </strong> {t('cookiePolicy.persistentCookiesDesc')}.</p>
                                    </li>
                                    <li><p><strong>{t('cookiePolicy.ownedCookies')}:  </strong> {t('cookiePolicy.ownedCookiesDesc')}.</p>
                                    </li>
                                    <li><p><strong>{t('cookiePolicy.thirdPartyCookies')}: </strong> {t('cookiePolicy.thirdPartyCookiesDesc')}.</p>
                                    </li>
                                </ul>
                                <p>{t('cookiePolicy.toInstallCookies')}:</p>
                                <ul className='ps-3'>
                                    <li><p>{t('cookiePolicy.cookieList1')}</p>
                                    </li>
                                    <li><p>{t('cookiePolicy.cookieList2')}</p>
                                    </li>
                                    <li><p>{t('cookiePolicy.cookieList3')}</p>
                                    </li>
                                    <li><p>{t('cookiePolicy.cookieList4')}</p>
                                    </li>
                                    <li><p>{t('cookiePolicy.cookieList5')}</p>
                                    </li>
                                    <li><p>{t('cookiePolicy.cookieList6')}</p>
                                    </li>
                                    <li><p>{t('cookiePolicy.cookieList7')}</p>
                                    </li>
                                </ul>
                                <p>{t('cookiePolicy.cookieList8')}.
                                </p>
                                <p>{t('cookiePolicy.cookieList9')}.
                                </p>
                                <div className='mb-3'>
                                    <span className='fs-18'>{t('cookiePolicy.installedBrowser')}:</span>
                                </div>
                                <ul className='ps-3'>
                                    <li><p className=''><strong>{t('cookiePolicy.technical')}: </strong> {t('cookiePolicy.technicalDesc')}.</p>
                                    </li>
                                    <li><p><strong>{t('cookiePolicy.analytical')}: </strong> {t('cookiePolicy.analyticalDesc')}.</p>
                                    </li>
                                    <li><p><strong>{t('cookiePolicy.ownedCookies')}:  </strong> {t('cookiePolicy.ownedCookiesDesc')}.</p>
                                    </li>
                                    <li><p><strong>{t('cookiePolicy.advertisement')}: </strong> {t('cookiePolicy.advertisementDesc')}.</p>
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <p className='fw-600 subtitle-privacy'>{t('cookiePolicy.refuseCookies')}?</p>
                                <p>{t('cookiePolicy.refuseCookiesDesc1')}:
                                </p>
                            </div>
                            <ol className='my-2'>
                                <li>
                                    <p className='text-uppercase mb-0'><a target="_blank" href="" className='text-decoration-none'><strong>{t('cookiePolicy.googleChrome')}</strong></a></p>
                                </li>
                                <li>
                                    <p className='text-uppercase mb-0'><a target="_blank" href="" className='text-decoration-none'><strong>{t('cookiePolicy.mozillaFirefox')}</strong></a></p>
                                </li>
                                <li>
                                    <p className='text-uppercase mb-0'><a target="_blank" href="" className='text-decoration-none'><strong>{t('cookiePolicy.internetExplorer')}</strong></a></p>
                                </li>
                                <li>
                                    <p className='text-uppercase mb-0'><a target="_blank" href="" className='text-decoration-none'><strong>{t('cookiePolicy.safari')}</strong></a></p>
                                </li>
                                <li>
                                    <p className='text-uppercase mb-0'><a target="_blank" href="" className='text-decoration-none'><strong>{t('cookiePolicy.chromePara')}</strong></a></p>
                                </li>
                                <li>
                                    <p className='text-uppercase mb-0'><a target="_blank" href="" className='text-decoration-none'><strong>{t('cookiePolicy.opera')}</strong></a></p>
                                </li>
                            </ol>
                            <p>{t('cookiePolicy.refuseCookiesDesc2')}.</p>
                            <p>{t('cookiePolicy.refuseCookiesDesc3')}.
                            </p>
                            <p>{t('cookiePolicy.refuseCookiesDesc4')}:</p>
                            <ul className='ps-3'>
                                <li><p className=''><strong>{t('cookiePolicy.privateBrowsing')},  </strong> {t('cookiePolicy.privateBrowsingDesc')}</p>
                                </li>
                                <li><p><strong>{t('cookiePolicy.analytical')}: </strong> {t('cookiePolicy.analyticalDesc')}.</p>
                                </li>
                                <li><p><strong>{t('cookiePolicy.nonTrackingFunction')},  </strong> {t('cookiePolicy.nonTrackingFunctionDesc')}.</p>
                                </li>
                            </ul>
                            <div>
                                <p className='fw-600 subtitle-privacy'>{t('cookiePolicy.youReachUs')}?</p>
                                <p>{t('cookiePolicy.youReachUsDesc')}: <a target="_blank" href="https://mail.google.com/mail/?view=cm&fs=1&to=contact@bonjanjob.com" className='text-decoration-none'>contact@bonjanjob.com</a>, or via ou <a target="_blank" href="/contact" className='text-decoration-none'>{t('cookiePolicy.contactForm')}.</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}