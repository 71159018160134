import { config } from '../../../Environments';
import { GetAxios, UserRole } from "../../../Shared";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { RotatingLines } from 'react-loader-spinner';
import { useDispatch, useSelector } from "react-redux";
import { setGlobalLoading } from '../../../Store';
import { useTranslation } from 'react-i18next';
import { confirmAlert } from "react-confirm-alert";


export const AnnouncePreview = () => {
  const [searchParams] = useSearchParams();
  const [data, setData] = React.useState<any>();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [picture, setPicture] = useState('');
  const [active, setActive] = useState(false);
  const { t: ts } = useTranslation('alert');
  const { t } = useTranslation('admin');
  const { t: c } = useTranslation('company');
  const { t: ct } = useTranslation('common');
  function handleApproveClick(seminarId: any) {
    confirmAlert({
      title: t('common.confirmtoActive').toString(),
      message: t('common.sureActive').toString(),
      buttons: [
        {
          label: t('common.yes').toString(),
          onClick: () => {
            dispatch(setGlobalLoading(true));
            GetAxios()
              .get(config.apiBase + "/api/Admin/UpdateAnnounceStatus?seminarId=" + seminarId)
              .then((res) => {
                setActive(true);
                toast(ts('account.statusUpdate'), { type: "success" });

                dispatch(setGlobalLoading(false));
              })
              .catch((error) => {
                toast(ts('error.wentWrong'), { type: "error" });
              });
          },
        },
        {
          label: t('common.no').toString(),
          onClick: () => { },
        },
      ],
    });
  }

  function handleDisapproveClick(seminarId: any) {
    confirmAlert({
      title: t('common.confirmtoInactive').toString(),
      message: t('common.sureInactive').toString(),
      buttons: [
        {
          label: t('common.yes').toString(),
          onClick: () => {
            dispatch(setGlobalLoading(true));
            GetAxios()
              .get(config.apiBase + "/api/Admin/UpdateAnnounceStatus?seminarId=" + seminarId)
              .then((res) => {
                setActive(false);
                toast(ts('account.statusUpdate'), { type: "success" });

                dispatch(setGlobalLoading(false));
              })
              .catch((error) => {
                toast(ts('error.wentWrong'), { type: "error" });
              });
          },
        },
        {
          label: t('common.no').toString(),
          onClick: () => { },
        },
      ],
    });
  }


  React.useEffect(() => {
    dispatch(setGlobalLoading(true));
    if (searchParams.get('sid')) {

      GetAxios(navigate).get(config.apiBase + '/api/Announce/PublicView?sid=' + searchParams.get('sid')).then(res => {
        if (!res.data.success) {
          dispatch(setGlobalLoading(false));
          toast(ts('error.ungetSeminar'), { type: 'error' });
        } else {

          console.warn(res.data.data);
          setData(res.data.data);
          setActive(res.data.data.status);
          setPicture(config.apiBase + res.data.data?.banner);
          dispatch(setGlobalLoading(false));
        }
      });
    }
  }, [searchParams, active]);

  const auth = useSelector((state: AppStore) => state.auth);
  const userRole = UserRole(auth?.raw?.access_token);


  return (
    <>
      <div className="container py-50">
        <div className="card">
          <div className="card-body pt-4">
            <div className="d-flex align-items-center justify-content-between mb-3 px-4 mx-3">
              <h2 className="mt-0 fw-700">{c('seminarTab.announceDetail')}</h2>
              <div>
                {
                  (userRole == 'Admin' || !userRole) &&
                  <button type="button" onClick={() => {
                    if (active) {
                      handleDisapproveClick(searchParams.get('sid'))
                    }
                    else {
                      handleApproveClick(searchParams.get('sid'))
                    }
                  }
                  } className="button-default text-decoration-none cursor-pointer mt-1 border-0"> {active ? t('common.setInactive') : t('common.setActive')} </button>
                }
              </div>
            </div>


            <div className="row px-5">
              <div className="col-12 px-lg-5 px-md-4 px-sm-2 seminar-bg-form">
                <div className="card my-3">
                  <div className="card-body">
                    <div className="form-flyer p-3">
                      <div className="form-detail pt-1">
                        <div className="row my-3">
                          <div className="col-md-6 col-12 mb-12">
                            <h6 className="fw-700">{c('seminarTab.informationAboutSeminar')}</h6>
                            {data?.name}
                          </div>
                          <div className="col-md-6 col-12 mb-12">
                            <h6 className="fw-700">{c('seminarTab.dateOfSeminar')}</h6>
                            {data?.announceDate}
                          </div>
                        </div>
                        <div className="row my-3">
                          <div className="col-md-6 col-12 mb-12">
                            <h6 className="fw-700">{c('seminarTab.telephone')}</h6>
                            {data?.phoneNumber}
                          </div>
                          <div className="col-md-6 col-12 mb-12">
                            <h6 className="fw-700">{ct('department')}</h6>
                            {data?.department}
                          </div>
                        </div>
                        <div className="row my-2">
                          <div className="col-md-6 col-12 mb-12">
                            <h6 className="fw-700">{c('seminarTab.flyerImg')}</h6>
                            <div className="">
                              <img src={!!picture ? picture : "/images/logo-banner-bg.jpg"} alt="logo banner bg" className="img-fluid rounded institution-logo" />
                            </div>
                          </div>
                        </div>
                        <div className="text-start my-3">

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
